import { inject, ref, computed, onMounted, onUnmounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useScriptTag } from '@vueuse/core'
import { useTheme } from 'vuetify'
import { getSecureFieldsOptions } from '@/app/system/plugins/datatrans'
import { PAYMENT_METHOD_CODES } from '@/app/constants'
import {
  getErrorMessageWithTimestamp,
  showAlertPopup
} from '@/app/system/helper'
import SentryClient from '@/app/system/SentryClient'

export function useSecureFields() {
  const emitter = inject('emitter')
  const store = useStore()
  const { t } = useI18n()
  const theme = useTheme()

  const secureFields = ref(null)
  const isDarkTheme = computed(() => theme.global.current.value.dark)

  const iframeFields = computed(() => ({
    cardNumber: {
      placeholderElementId: 'card-number',
      inputType: 'tel',
      placeholder: t('Checkout#Payment#Field Credit Card Number')
    },
    cvv: {
      placeholderElementId: 'card-cvv',
      inputType: 'tel',
      placeholder: t('Checkout#Payment#Field CVV')
    }
  }))

  const { scriptTag, load, unload } = useScriptTag(
    `${process.env.DATATRANS_LIB_URL}`,
    () => {
      initSecureFields(iframeFields.value)
    },
    { manual: true }
  )

  function initSecureFields(fields) {
    secureFields.value = new window.SecureFields()
    secureFields.value.initTokenize(process.env.DATATRANS_MERCHANT_ID, fields, {
      ...getSecureFieldsOptions(isDarkTheme.value),
      paymentMethods: store.getters.acceptedPaymentCards.map(
        (card) => PAYMENT_METHOD_CODES[card.code] || ''
      )
    })
    bindSecureFieldsEvents()
  }

  function bindSecureFieldsEvents() {
    if (!secureFields.value) return

    secureFields.value.on('ready', () => {
      emitter.emit('secure-fields:ready')
    })

    secureFields.value.on('change', (data) => {
      data && emitter.emit('secure-fields:change', data)
    })

    secureFields.value.on('validate', (data) => {
      emitter.emit('secure-fields:validate', data)
    })

    secureFields.value.on('success', (data) => {
      emitter.emit('secure-fields:success', data)
    })

    secureFields.value.on('error', (data) => {
      emitter.emit('secure-fields:error', data)
      handleSecureFieldsError(data)
    })
  }

  function handleSecureFieldsError(data) {
    SentryClient.captureExceptionWithScope(
      'Datatrans config has been failed',
      {
        extraKey: 'Response data',
        extraData: { data },
        tags: { type: 'datatrans_config_failed' }
      },
      'fatal'
    )

    const errorMsg = getErrorMessageWithTimestamp({
      errorDescription: `${t('Checkout#DatatransPayment#Error Payment processing error')}: ${data.error}`,
      sessionId: store.getters.bookingRequest.sessionId,
      i18nParams: store.getters.hotelContacts
    })

    showAlertPopup(errorMsg).then(() => {
      store.commit('SET_RESERVATION_LOADING', false)
    })
  }

  onMounted(async () => {
    await nextTick()
    await load()
  })

  onUnmounted(() => {
    secureFields.value?.destroy()
  })

  return {
    secureFields,
    iframeFields,
    initSecureFields,
    load,
    unload,
    scriptTag
  }
}
