import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "room__info-block block-packages"
}
const _hoisted_2 = { class: "block__content" }
const _hoisted_3 = { class: "block__items block__accordion" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_item = _resolveComponent("item")

  return ($options.upgrades.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.upgrades, (item, index) => {
              return (_openBlock(), _createBlock(_component_item, {
                key: index,
                id: item.productId,
                title: item.rate?.ratePlanName,
                price: item.totalPrice,
                discount: item.discount,
                "main-price": $props.mainPrice,
                description: item.rate?.descriptions,
                "mode-link": "mode-link"
              }, null, 8 /* PROPS */, ["id", "title", "price", "discount", "main-price", "description"]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}