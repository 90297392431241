import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useHead } from '@unhead/vue'
import AppHeader from '@/components/Common/AppHeader.vue'
import RoomLanding from '@/components/Rooms/RoomLanding.vue'
import AppToolbar from '@/components/Common/AppToolbar.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleRoom',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Single Room')
})

const store = useStore()

const loading = computed<boolean>(
  () =>
    !store.getters.availabilitiesLoaded ||
    !store.getters.productsLoaded ||
    !store.getters.descriptiveInfoLoaded
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AppHeader),
    _createVNode(RoomLanding),
    (!loading.value)
      ? (_openBlock(), _createBlock(AppToolbar, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})