import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item__info" }
const _hoisted_2 = { class: "item__title" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "item__price" }
const _hoisted_5 = { class: "item__description" }

import { ref } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAccordion',
  setup(__props) {

const isExpanded = ref(false)

return (_ctx: any,_cache: any) => {
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['item accordion-item', { 'is-active': isExpanded.value }])
  }, [
    _createElementVNode("div", {
      class: "item__heading",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isExpanded.value = !isExpanded.value))
    }, [
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "item__icon theme-container" }, [
          _createElementVNode("i", { class: "icon-angle-down" })
        ], -1 /* HOISTED */))
      ]),
      _renderSlot(_ctx.$slots, "info", {}, () => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
              _cache[2] || (_cache[2] = _createTextVNode(": ")),
              _renderSlot(_ctx.$slots, "title")
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "price")
          ])
        ])
      ])
    ]),
    _createVNode(_component_v_expand_transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ], 512 /* NEED_PATCH */), [
          [_vShow, isExpanded.value]
        ])
      ]),
      _: 3 /* FORWARDED */
    })
  ], 2 /* CLASS */))
}
}

})