import { mapActions, mapMutations, mapGetters } from 'vuex'
import { useCookies } from '@vueuse/integrations/useCookies'
import Swal from 'sweetalert2'
import { localStorageHandler } from './localStorage'
import { LocalStorage } from '@/app/services/storage.service'
import { formatDate } from '../helper'
import { LOCAL_STORAGE_KEY_SORT_BY } from '../rooms-sorters'

export default {
  computed: {
    ...mapGetters(['guestsBounds', 'isAdultsOnlyHotel'])
  },
  methods: {
    ...mapMutations([
      'UPDATE_CURRENT_ROOM_STAY',
      'UPDATE_ORDER_INFO',
      'UPDATE_BOOKING_REQUEST',
      'ADD_ORDER_TO_ROOM_STAY',
      'COMPLETE_ROOM_STAY_BOOKING',
      'SET_LOADING'
    ]),
    clearAndRefreshSession() {
      const cookies = useCookies(['sessionId', 'bookingRequest'])
      cookies.remove('sessionId')
      cookies.remove('bookingRequest')
      LocalStorage.removeItem('reservationId')
      LocalStorage.removeItem('roomId')
      LocalStorage.removeItem(LOCAL_STORAGE_KEY_SORT_BY)
      this.$root?.refreshSession?.()
    },
    shouldSessionBeStarted(routeName) {
      const routesWithSessionDisabled = [
        'AfterPay',
        'customization-form',
        'customer-account-privacy-policy',
        'customer-account-general-terms',
        'ReservationModify',
        'ReservationCancel',
        'ReservationPayment'
      ]
      return !routesWithSessionDisabled.includes(routeName)
    },
    async sessionExpirationHandler() {
      const { isConfirmed } = await Swal.fire({
        width: '28em',
        icon: 'info',
        title: this.$t('App#Session#Popup#Title Session expired'),
        text: this.$t(
          'App#Session#Popup#Text Your session has expired, please refresh your search to check availabilities'
        ),
        confirmButtonText: this.$t('App#Session#Popup#Button Refresh'),
        allowEscapeKey: false,
        allowOutsideClick: false
      })
      if (isConfirmed) {
        this.clearAndRefreshSession()
        if (
          this.$route.name !== 'ReservationPayment' ||
          this.$route.name !== 'ReservationModify'
        ) {
          this.goHomeAndReload(true)
        }
      }
    },
    submitSearchInfo() {
      const bookingRequest = this.$store.getters.bookingRequest
      const currentRoomStayGuests = this.$store.getters.currentRoomStayGuests
      if (!currentRoomStayGuests) {
        return
      }

      // Update BookingRequest object to save it to cookies "src/app/store:22".
      this.UPDATE_BOOKING_REQUEST(bookingRequest)

      const { adults, children, infants } = currentRoomStayGuests
      const { checkIn, checkOut, nbNights, promoCodeInput } = bookingRequest

      this.UPDATE_CURRENT_ROOM_STAY({
        nbAdults: parseInt(adults),
        nbChildren: parseInt(children),
        nbInfants: parseInt(infants),
        startDate: checkIn,
        endDate: checkOut,
        promotionCode: promoCodeInput?.toUpperCase() || '',
        datesDiff: nbNights,
        services: []
      })

      this.UPDATE_ORDER_INFO({
        datesDiff: nbNights
      })
    },
    getAvailabilitiesWithUpdatedDates(dates) {
      if (dates) {
        this.UPDATE_BOOKING_REQUEST({
          checkIn: dates.start,
          checkOut: dates.end
        })
        this.submitSearchInfo()
        this.$store.dispatch('getRoomsAvailabilities')
      }
    },
    submitCurrentRoomStayInfo(roomId, product) {
      this.UPDATE_ORDER_INFO({
        product, // back compatibility.
        products: {
          // TODO: refactor (no property "products" in getter "orderInfo")
          ...this.$store.getters.orderInfo.products,
          [product.reference]: product
        }
      })
      this.UPDATE_CURRENT_ROOM_STAY({
        ...product,
        guestRoomId: roomId,
        productId: product.productId,
        ratePlanId: product.rate.ratePlanCode,
        reference: product.reference
      })
    },
    addOrderToRoomStay(roomId, productId) {
      const product = this.$store.state.productsAvailable.find(
        (product) => product.productId === productId
      )
      const room = this.$store.getters.roomById(product.guestRoomId)

      if (product) {
        this.submitCurrentRoomStayInfo(product.guestRoomId, product)
        this.UPDATE_CURRENT_ROOM_STAY({
          productId,
          room: room.room,
          currency: this.$store.getters.currency,
          totalPrice: product.totalPrice,
          hotelTotalPrice: product.hotelTotalPrice,
          totalDiscount:
            product.totalDiscount ??
            product.discount /* + services discount? */,
          hotelTotalDiscount: product.hotelTotalDiscount
        })
        const order = { ...this.$store.getters.currentRoomStay }
        order.startDate = formatDate(order.startDate)
        order.endDate = formatDate(order.endDate)

        this.ADD_ORDER_TO_ROOM_STAY({
          order,
          roomStayIndex: this.$store.getters.currentRoom
        })
      }
    },
    goToServices(roomId) {
      this.loading = true

      if (!this.$store.getters.currentRoomStay.hasServices) {
        this.COMPLETE_ROOM_STAY_BOOKING(this.$store.getters.currentRoom)
        if (this.$store.getters.isAllRoomStaysBooked) {
          this.$router.push({ name: 'CheckoutGuestInfo' }).catch(() => {})
        } else {
          this.bookNextRoom()
        }
        return
      }

      LocalStorage.setItem('roomId', roomId)
      setTimeout(() => {
        this.$router
          .push({ name: 'CheckoutAddServices', params: { roomId } })
          .catch(() => {})
      }, 200)
    },
    goHomeAndReload(doReload = false) {
      if (this.$route.name === 'home') {
        if (doReload) {
          location.reload()
        }
        return
      }

      this.$router.push({ name: 'home' }, () => {
        if (doReload) {
          location.reload()
        }
      })
    },
    guestsCountString(adultNb, childrenNb, infantsNb, isGenitive = false) {
      const { adults, children, infants } = this.guestsBounds

      const adultsTotal = adultNb || adults.min
      const childrenTotal = childrenNb || children.min
      const infantsTotal = infantsNb || infants.min

      if (this.isAdultsOnlyHotel) {
        return !isGenitive
          ? this.$t('Generic#GuestCount person', adultsTotal)
          : this.$t('Generic#GuestCount#Genitive person', adultsTotal)
      }

      const adultStr = !isGenitive
        ? this.$t('Generic#GuestCount adult', adultsTotal)
        : this.$t('Generic#GuestCount#Genitive adult', adultsTotal)

      const andStr = this.$t('Generic#GuestCount and')
      let allGuestsStr = adultStr

      if (childrenTotal > 0) {
        const childStr = !isGenitive
          ? this.$t('Generic#GuestCount child', childrenTotal)
          : this.$t('Generic#GuestCount#Genitive child', childrenTotal)
        allGuestsStr +=
          infantsTotal > 0 ? `, ${childStr}` : ` ${andStr} ${childStr}`
      }
      if (infantsTotal > 0) {
        const infantStr = !isGenitive
          ? this.$t('Generic#GuestCount infant', infantsTotal)
          : this.$t('Generic#GuestCount#Genitive infant', infantsTotal)
        allGuestsStr += ` ${andStr} ${infantStr}`
      }

      return allGuestsStr
    },
    bookNextRoom() {
      setTimeout(() => {
        LocalStorage.removeItem('priceRange')
        this.submitSearchInfo()
        this.goHomeAndReload()
        this.$store.dispatch('getRoomsAvailabilities')
      })
    }
  }
}
