/* eslint-disable no-console */
import router from '@/app/router'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { isEqual, chain, omit } from 'lodash'
import { HttpService } from '@/app/services/http.service'
import { LocalStorage } from '@/app/services/storage.service'
import { emitter } from '@/app/system/plugins/mitt'
import { imageKit } from '../system/imagekit'
import { createBookingRequest } from './modules/bookingRequest'
import {
  captureRequestException,
  captureExceptionWithScope
} from '../system/errorHandling'
import { i18n, browserLang, fallbackLang } from '../vue-config/i18n'
import { ERROR_CODES, JSON_STUB } from '../constants'
import { ReservationStatus } from '../system/constants/reservation'
import {
  formatDate,
  getTimestampForError,
  getTranslatedHotelContacts,
  getInternalCodeFromResponse,
  getErrorMessageWithTimestamp,
  isLocaleSupported,
  isMaintenanceMode,
  parseLocale,
  showAlertPopup,
  parseJson
} from '../system/helper'

const headers = {
  contentType: JSON_STUB.content,
  'JsonStub-User-Key': JSON_STUB.userKey,
  'JsonStub-Project-Key': JSON_STUB.projectKey
}

export default {
  setCancellationLoading({ commit }, data) {
    commit('SET_CANCELLATION_LOADING', data)
  },
  setLoading({ commit }, data) {
    commit('SET_LOADING', data)
  },
  setLanguage({ commit }, data) {
    commit('SET_LANGUAGE', data)
  },
  async setConfig({ getters, commit }) {
    try {
      commit('SET_LOADING', true)
      const config = await HttpService.request({
        url: `config/${getters.hotelId}`
      })
      config && commit('SET_CONFIG', config)
    } catch (error) {
      showAlertPopup(
        i18n.global.t("App#Error Can't load hotel data") +
          ` (${getters.hotelId})`
      )
      captureRequestException('Config request has been failed', error)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getPrivacyPolicy({ getters, commit }) {
    try {
      const policy = await HttpService.request({
        url: `config/privacy-policy/${getters.language}/${getters.hotelId}`
      })
      policy && commit('SET_PRIVACY_POLICY', policy)
      return policy
    } catch (error) {
      captureRequestException('Privacy policy request has been failed', error)
      try {
        const policyDefault = await HttpService.request({
          url: `config/privacy-policy/${getters.language}/default`
        })
        policyDefault && commit('SET_PRIVACY_POLICY', policyDefault)

        return policyDefault
      } catch (error) {
        captureRequestException(
          'Default privacy policy request has been failed',
          error
        )
        return null
      }
    }
  },
  async getDescriptiveInfo({ getters, commit, dispatch }) {
    commit('SET_LOADING', true)
    const descriptiveInfo = await HttpService.requestSecured(
      {
        url: `descriptive-info/${getters.language}/${getters.hotelId}`
      },
      'Descriptive info request has been failed'
    )

    if (descriptiveInfo) {
      commit('SET_DESCRIPTIVE_INFO', descriptiveInfo)
      if (location.search.indexOf('currency') === -1) {
        // check if there's any currency indication in the URL
        const sessionCurrency = LocalStorage.getItem('currency')
        if (!sessionCurrency) {
          commit('SET_CURRENCY', descriptiveInfo.currencyCode) // set currency to the hotel's one by default
        }
      }
      commit('SET_DESCRIPTIVE_INFO_LOADED', true)

      if (isMaintenanceMode()) {
        return
      }

      dispatch('getProductsAndPackages')
      return descriptiveInfo
    }
  },
  async getPromotionInfo({ getters, commit }) {
    const promotions = await HttpService.requestSecured(
      {
        url: `promotions/${getters.hotelId}`
      },
      'Promotions info request has been failed'
    )

    promotions && commit('SET_PROMOTIONS_INFO', promotions)
    return promotions
  },
  async getProducts({ getters, commit }) {
    const products = await HttpService.requestSecured(
      {
        url: `products/${getters.language}/${getters.hotelId}`
      },
      'Products request has been failed'
    )

    products && commit('SET_PRODUCTS', products)
    return products
  },
  async getHotelPackages({ state, getters, commit }) {
    try {
      state.hotelPackagesLoaded = false

      // Make API call only if test is true or subscription is not 'light'
      if (state.test || state.subscription !== 'light') {
        const hotelPackages = await HttpService.request({
          url: `hotel-packages/${getters.language}/${getters.hotelId}`
        })

        hotelPackages && commit('SET_HOTEL_PACKAGES', hotelPackages)
        return hotelPackages
      }
    } catch (error) {
      captureRequestException('Packages request has been failed', error)
    } finally {
      state.hotelPackagesLoaded = true
    }
  },
  async getProductsAndPackages({ dispatch }) {
    await dispatch('getProducts')
    await dispatch('getHotelPackages')

    if (router.currentRoute.value.name !== 'package') {
      dispatch('getRoomsAvailabilities')
    }
  },
  async getRoomsAvailabilities({ state, getters, commit, dispatch }, payload) {
    const resetSearchParams = async () => {
      emitter.emit('reset-search-params')
      await dispatch('replaceRoute', { name: 'home' })
      dispatch('getRoomsAvailabilities')
    }

    commit('SET_LOADING', true)
    commit('SET_AVAILABILITIES_LOADED', false)
    commit('SET_ROOMS', [])

    const { startDate, endDate, nbAdults, nbChildren, nbInfants } =
      getters.currentRoomStay
    const today = dayjs().startOf('days')

    if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
      resetSearchParams()
      return
    }

    if (dayjs(startDate).isBefore(today)) {
      commit('SET_LOADING', false)
      const text = i18n.global.t(
        'App#Error Your check-in date is in the past. Please, select a valid date'
      )
      showAlertPopup({
        text,
        icon: 'warning'
      }).then(() => {
        emitter.emit('reset-search-params')
        dispatch('replaceRoute', { name: 'home' })
      })
      return
    }

    const queryParams = {
      sessionId: getters.bookingRequest.sessionId,
      currencyCode: state.currency,
      promoCode: getters.bookingRequest.promoCodeInput?.toUpperCase() || '',
      roomStayIndex: getters.currentRoom + 1,
      start: startDate,
      end: endDate,
      nbAdults,
      nbChildren,
      nbInfants
    }

    if (payload?.hotelPackageId) {
      queryParams.nbChildren = 0
      queryParams.nbInfants = 0
      queryParams.hotelPackageId = payload.hotelPackageId
    }

    if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
      resetSearchParams()
    }

    try {
      const availabilities = await HttpService.request({
        url: `availabilities/${getters.hotelId}`,
        query: queryParams
      })

      if (availabilities) {
        if (availabilities.length) {
          commit('SET_ROOMS', availabilities)
        }
        commit('SET_LOADING', false)
        commit('SET_AVAILABILITIES_LOADED', true)

        if (!isEqual(queryParams, state.searchParams)) {
          commit('SET_SEARCH_PARAMS', queryParams)
        }

        return availabilities
      }
    } catch (error) {
      const errorMessage = getErrorMessageWithTimestamp({
        sessionId: getters.bookingRequest.sessionId,
        i18nParams: getters.hotelContacts
      })

      showAlertPopup(errorMessage).then(resetSearchParams)

      captureRequestException('Availability request has been failed', error)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getPackagesAvailabilities({ state, getters, commit }, payload) {
    commit('SET_LOADING', true)
    const today = dayjs().startOf('days')

    const { startDate, endDate, nbAdults, nbChildren, nbInfants } =
      getters.currentRoomStay

    if (dayjs(startDate).isBefore(today)) {
      commit('SET_LOADING', false)
      const text = i18n.global.t(
        'App#Error Your check-in date is in the past. Please, select a valid date'
      )
      showAlertPopup({
        text,
        icon: 'warning'
      })
      return
    }

    const query = {
      sessionId: getters.bookingRequest.sessionId,
      hotelPackageId: payload.hotelPackageId,
      currencyCode: state.currency,
      start: startDate,
      end: endDate,
      nbAdults,
      nbChildren: 0,
      nbInfants: 0
    }

    try {
      const availabilities = await HttpService.request({
        url: `availabilities/${getters.hotelId}`,
        query
      })

      availabilities && commit('SET_HOTEL_PACKAGES_AVAILABLE', availabilities)
      return availabilities
    } catch (err) {
      commit('SET_HOTEL_PACKAGES_AVAILABLE', [])
      const errorMessage = getErrorMessageWithTimestamp({
        sessionId: getters.bookingRequest.sessionId,
        i18nParams: getters.hotelContacts
      })

      showAlertPopup(errorMessage)

      captureRequestException(
        'Packages availability request has been failed',
        err
      )
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getCancellationPolicies({ getters, dispatch }, params) {
    try {
      const policies = await HttpService.request({
        url: `cancel-penalties/${getters.language}/${getters.hotelId}`,
        query: params
      })

      return policies
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      if (internalErrorCode === 5601) {
        dispatch(
          'showModalWithHomeRedirect',
          getErrorMessageWithTimestamp({
            sessionId: getters.bookingRequest.sessionId,
            internalErrorCode,
            i18nParams: getters.hotelContacts
          })
        )
      }
      captureRequestException(
        'Cancellation policies request has been failed',
        error,
        params
      )
      return {
        error: i18n.global.t(ERROR_CODES[internalErrorCode]),
        code: internalErrorCode
      }
    }
  },
  async initPaymentTransaction({ state, getters, commit }, payload) {
    try {
      commit('SET_RESERVATION_LOADING', true)
      const { reservationId, amount } = payload
      const { guestInfo } = getters.bookingRequest
      const hotelId = getters.hotelId

      const parameters = {
        hotelId: hotelId,
        reservationId,
        amount: {
          value: amount?.toString(),
          currency: state.hotelCurrency
        },
        customerEmail: guestInfo.email,
        customerId:
          payload.profileId ?? `${guestInfo.givenName}${guestInfo.surname}`,
        customerFirstName: guestInfo.givenName,
        customerLastName: guestInfo.surname,
        customerPhone: guestInfo.phone,
        returnUrl: `${document.location.origin}/${hotelId}/checkout/payment-callback`
      }

      const response = await fetch(
        `${process.env.PAY_ENDPOINT}/${hotelId}/transaction/init`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode: 'cors',
          body: JSON.stringify(parameters)
        }
      )

      const data = await response.json()

      if (!response.ok || data.status === 'error') {
        throw new Error(data.error || 'Payment initialization failed')
      }

      return data
    } catch (error) {
      captureRequestException(
        'Payment transaction initialization failed',
        error,
        payload
      )
      throw new Error(error)
    } finally {
      commit('SET_RESERVATION_LOADING', false)
    }
  },
  async getTransactionStatus({ getters }, { reservationId }) {
    try {
      const hotelId = getters.hotelId
      const response = await fetch(
        `${process.env.PAY_ENDPOINT}/${hotelId}/transaction/${reservationId}`,
        {
          mode: 'cors'
        }
      )

      return await response.json()
    } catch (error) {
      captureRequestException(
        'Failed to get payment transaction status',
        error,
        reservationId
      )
      return { status: 'error', transactionStatus: 'error' }
    }
  },
  async threeDsAuthentication({ getters, dispatch }, body) {
    try {
      const response = await HttpService.request({
        url: `payment/${getters.hotelId}/threeDsAuthentication`,
        method: 'POST',
        rawText: true,
        body
      })
      if (response) {
        const reservation = parseJson(response)

        return reservation
      }
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      captureRequestException(
        'threeDsAuthentication request has been failed',
        error,
        body
      )

      if (internalErrorCode === '5908') {
        showAlertPopup({
          title: i18n.global.t(ERROR_CODES[internalErrorCode]),
          text: i18n.global.t(
            'Checkout#Payment#Check#Fail Please, try again or change the payment card.'
          ),
          icon: 'error',
          buttons: {
            confirm: i18n.global.t('Checkout#Payment#Check#Fail Try again')
          }
        }).then(() => {
          router.push({ name: 'home' })
        })
      } else if (internalErrorCode === '5912') {
        dispatch(
          'showModalWithHomeRedirect',
          getErrorMessageWithTimestamp({
            sessionId: getters.bookingRequest.sessionId,
            internalErrorCode,
            i18nParams: getters.hotelContacts
          })
        )
      } else {
        dispatch(
          'showModalWithHomeRedirect',
          getErrorMessageWithTimestamp({
            sessionId: getters.bookingRequest.sessionId,
            internalErrorCode,
            errorDescription: error.body.error,
            i18nParams: getters.hotelContacts
          })
        )
      }

      return error
    }
  },
  getUpgrades({ getters, commit }, params) {
    const products = getters.roomsBeforeRender
    if (products === undefined) {
      commit('SET_UPGRADES', [])
    } else {
      let upgrades = products.filter(
        (product) =>
          product.room?.id == params.roomId &&
          !!product.rate &&
          product.rate?.ratePlanCode != params.ratePlanCode &&
          product.totalPrice - product.discount > params.price
      )
      commit('SET_UPGRADES', upgrades)
    }
  },
  getUpsell({ getters, commit }, params) {
    const products = getters.roomsBeforeRender
    if (products === undefined) {
      commit('SET_UPSELL', [])
    } else {
      let upsell = products.filter(
        (product) =>
          !!product.rate &&
          product.rate?.ratePlanCode === params.ratePlanCode &&
          product.totalPrice - product.discount > params.price
      )
      commit('SET_UPSELL', upsell)
    }
  },
  async getServices({ getters, commit, dispatch }) {
    commit('SET_SERVICES', [])
    commit('SET_SERVICES_LOADED', false)
    const {
      bookingRequest,
      currency,
      currentRoomStay: { reference, promotionCode }
    } = getters

    const queryParams = {
      sessionId: bookingRequest.sessionId,
      promoCode: promotionCode.toUpperCase(),
      roomStayReference: reference,
      currencyCode: currency
    }

    if (!reference) {
      dispatch('replaceRoute', { name: 'home' })
      return
    }

    const services = await HttpService.requestSecured(
      {
        url: `services/${getters.language}/${getters.hotelId}`,
        query: queryParams
      },
      'Services request has been failed'
    )
    if (services) {
      commit('SET_SERVICES', services)
    }
    commit('SET_SERVICES_LOADED', true)
    return services
  },
  getBundleData({ commit, getters, dispatch }, { sessionId, bundleId }) {
    return new Promise((resolve, reject) => {
      // update current sessionId
      // TODO: cookies
      cookies.set('sessionId', sessionId, {
        expires: process.env.SESSION_LIFETIME
      })

      HttpService.request({
        url: `bundles/${getters.hotelId}/${sessionId}/${bundleId}`
      }).then(
        (response) => {
          if (response.status == 200) {
            const bundleData = JSON.parse(response)
            const {
              arrival: bundleArrival,
              departure: bundleDeparture,
              currency: bundleCurrency
            } = bundleData
            let nbAdultsInBundle = 0,
              nbChildrenInBundle = 0,
              nbInfantsInBundle = 0
            // update currency
            commit('SET_CURRENCY', bundleCurrency)
            // update session params
            /** @var {BookingRequest} */
            const bookingRequest = createBookingRequest(getters.bookingRequest)
            bookingRequest.sessionId = sessionId
            bookingRequest.checkIn = bundleArrival
            bookingRequest.checkOut = bundleDeparture
            bookingRequest.roomStays = []
            bundleData.roomStays.forEach((rs) => {
              let sellableProduct = getters.productById(rs.sellableProductId)
              bookingRequest.roomStays.push({
                adults: rs.nbAdults,
                children: rs.nbChildren,
                infants: rs.nbInfants || 0,
                order: {
                  reference: rs.reference,
                  startDate: bundleArrival,
                  endDate: bundleDeparture,
                  currency: bundleCurrency,
                  guestRoomId: rs.guestRoomId,
                  ratePlanId: rs.ratePlanId,
                  nbAdults: rs.nbAdults,
                  nbChildren: rs.nbChildren,
                  nbInfants: rs.nbInfants,
                  product: {
                    nbNights: bookingRequest.nbNights,
                    totalPrice: rs.totalPrice,
                    discount: rs.totalDiscount,
                    totalVat: rs.totalVat,
                    totalLocalTax: rs.totalLocalTax,
                    rate: {
                      ratePlanName: sellableProduct.ratePlans[0].ratePlanName
                    },
                    room: {
                      roomTypeName: sellableProduct.roomTypes[0].roomTypeName
                    }
                  },
                  services: [],
                  totalDiscount: rs.totalDiscount,
                  totalPrice: rs.totalPrice
                }
              })
              nbAdultsInBundle += rs.nbAdults
              nbChildrenInBundle += rs.nbChildren
              nbInfantsInBundle += rs.nbInfants
            })
            commit('UPDATE_BOOKING_REQUEST', bookingRequest)
            // update "currentRoomStay" info
            commit('UPDATE_CURRENT_ROOM_STAY', {
              startDate: bundleArrival,
              endDate: bundleDeparture,
              nbAdults: nbAdultsInBundle,
              nbChildren: nbChildrenInBundle,
              nbInfants: nbInfantsInBundle
            })

            return resolve(true)
          }
        },
        (response) => {
          captureRequestException('Bundle request has been failed', response)
          reject()
          showAlertPopup({
            text: i18n.global.t(
              'App#Error This link has expired and is not valid anymore.'
            ),
            icon: 'warning'
          }).then(() => {
            dispatch('replaceRoute', { name: 'home' })
            emitter.emit('refresh-session')
          })
        }
      )
    })
  },
  showModalWithHomeRedirect({ commit, dispatch }, msg, severity = 'error') {
    showAlertPopup(msg, severity).then(() => {
      commit('SET_RESERVATION_LOADING', false)
      dispatch('replaceRoute', { name: 'home' })
      emitter.emit('refresh-session')
    })
  },
  async sendBookingRequest({ state, getters, commit, dispatch }, payload) {
    commit('SET_RESERVATION_LOADING', true)
    const { reservation, requestType } = payload
    reservation.language = state.language

    if (state.analytics.GA4.clientId) {
      reservation.gtmCid = state.analytics.GA4.clientId
    }

    if (state.analytics.GA4.sessionId) {
      reservation.gtmSession = state.analytics.GA4.sessionId
    }

    try {
      const response = await HttpService.request({
        url: `reservations/${
          getters.hotelId
        }/actions/${requestType.toLowerCase()}`,
        method: 'POST',
        body: omit(reservation, ['requestType']),
        ...(requestType == ReservationStatus.Pay && {
          query: { token: router.currentRoute.value.query.t }
        })
      })

      if (response) {
        const { status, reservationId, createDateTime, payment } = response
        if (
          status === ReservationStatus.Book ||
          status === ReservationStatus.Modify ||
          status === ReservationStatus.Pay
        ) {
          commit('UPDATE_ORDER_INFO', {
            reservationId,
            createDateTime,
            payment
          })
          LocalStorage.setItem(
            'reservation',
            JSON.stringify({ id: reservationId, ...payment })
          )
          commit('COMPLETE_BOOKING', true)
        } else {
          captureRequestException(
            `Unable to ${requestType} the reservation`,
            response
          )
          dispatch(
            'showModalWithHomeRedirect',
            getErrorMessageWithTimestamp({
              sessionId: getters.bookingRequest.sessionId,
              internalErrorCode: ERROR_CODES.BOOKING_FAILED,
              i18nParams: getters.hotelContacts
            })
          )
        }
        return response
      }
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      if (internalErrorCode == 5050) {
        commit('SET_INLINE_BOOKING_REQUEST_ERROR', true)
      } else {
        const errorMessage = getErrorMessageWithTimestamp({
          sessionId: getters.bookingRequest.sessionId,
          internalErrorCode,
          i18nParams: getters.hotelContacts
        })

        dispatch('showModalWithHomeRedirect', errorMessage)
      }
      captureRequestException(`Failed to ${requestType} reservation`, error)
    } finally {
      commit('SET_RESERVATION_LOADING', false)
    }
  },
  async discardReservation({ getters, commit }, { reservationId }) {
    const sessionId = getters.bookingRequest.sessionId
    try {
      const response = await HttpService.request({
        url: `reservations/${getters.hotelId}/actions/cancel`,
        method: 'POST',
        body: {
          reservationEmail: getters.bookingRequest.guestInfo.email,
          reservationId
        }
      })

      if (response?.status === ReservationStatus.Cancel) {
        commit('SET_CHANNEL_ID', Number(response.bookingChannelId))
        commit('UPDATE_CANCELLED_ORDER_INFO', response)
      } else {
        captureRequestException(
          'Discard reservation request has been failed',
          response,
          { reservationId, sessionId },
          'cancellation_failed'
        )
      }
    } catch (error) {
      captureRequestException(
        'Discard reservation request has been failed',
        error,
        { sessionId },
        'cancellation_failed'
      )
    } finally {
      commit('SET_CANCELLATION_LOADING', false)
      emitter.emit('refresh-session')
    }
  },
  sendCancellationRequest({ getters, commit, dispatch }, payload) {
    const sessionId = getters.bookingRequest.sessionId
    const reservationId = payload.reservationId

    return new Promise(async (_resolve, reject) => {
      HttpService.request({
        url: `reservations/${getters.hotelId}/actions/cancel`,
        method: 'POST',
        body: payload
      }).then(
        (response) => {
          if (response?.status === ReservationStatus.Cancel) {
            const { bookingChannelId } = response
            commit('SET_CHANNEL_ID', Number(bookingChannelId))
            commit('UPDATE_CANCELLED_ORDER_INFO', response)
            commit('SET_ALLOW_CANCELLATION_CONFIRMATION', true)
            commit('SET_CANCELLATION_LOADING', false)
            dispatch('replaceRoute', {
              name: 'ReservationCancelConfirmation',
              params: { reservationId }
            })
          } else {
            let errorMsg = i18n.global.t(
              'App#Error Cancellation failed. Please check that the reservation ID and email address are correct.'
            )
            errorMsg += `\n\n${i18n.global.t(
              getTranslatedHotelContacts(getters.hotelContacts)
            )}`
            errorMsg += getTimestampForError({
              hotelId: getters.hotelId,
              sessionId,
              internalErrorCode: getInternalCodeFromResponse(response)
            })
            showAlertPopup(errorMsg).then(() => {
              commit('SET_CANCELLATION_LOADING', false)
            })
            captureRequestException(
              'Cancellation request has been failed',
              response,
              { reservationId, sessionId },
              'cancellation_failed'
            )
          }
        },
        (response) => {
          const internalErrorCode = getInternalCodeFromResponse(response)
          if (internalErrorCode == 5610) {
            reject()
          } else {
            let errorMsg = i18n.global.t(
              'App#Error Cancellation failed. Please check that the reservation ID and email address are correct.'
            )
            errorMsg += `\n\n${t(
              getTranslatedHotelContacts(getters.hotelContacts)
            )}`
            errorMsg += getTimestampForError({
              hotelId: getters.hotelId,
              sessionId,
              internalErrorCode: getInternalCodeFromResponse(response)
            })
            showAlertPopup(errorMsg).then(() => {
              commit('SET_CANCELLATION_LOADING', false)
              dispatch('replaceRoute', { name: 'home' })
              emitter.emit('refresh-session')
            })
          }
          captureRequestException(
            'Cancellation request has been failed',
            response,
            { sessionId },
            'cancellation_failed'
          )
        }
      )
    })
  },
  async getReservation(
    { getters, commit, dispatch },
    { reservationId, token }
  ) {
    try {
      commit('SET_RESERVATION_LOADING', true)
      const reservation = await HttpService.request({
        url: `reservations/${getters.hotelId}/${getters.language}/${reservationId}`,
        query: {
          token
        }
      })

      if (reservation) {
        commit('SET_CURRENCY', reservation.currency)
      }
      return reservation
    } catch (error) {
      dispatch(
        'showModalWithHomeRedirect',
        getErrorMessageWithTimestamp({
          sessionId: getters.bookingRequest.sessionId,
          internalErrorCode: getInternalCodeFromResponse(error),
          i18nParams: getters.hotelContacts
        })
      )

      captureRequestException(
        'Get reservation data request has been failed',
        error
      )
    } finally {
      commit('SET_RESERVATION_LOADING', false)
    }
  },
  async getReservationTaxes({ getters, commit, dispatch }) {
    commit('SET_LOADING', true)
    const request = getters.bookingRequestToBeSent
    const roomStays = request.roomStays.map((roomStay) =>
      omit(roomStay, ['room', 'products', 'rate', 'taxes'])
    )
    try {
      const taxes = await HttpService.request({
        url: `reservations/${getters.hotelId}/actions/getTaxes`,
        method: 'POST',
        body: {
          ...request,
          roomStays
        }
      })
      taxes && commit('UPDATE_ROOM_STAYS_TAXES', taxes)
      return taxes
    } catch ({ status, body }) {
      const internalErrorCode = getInternalCodeFromResponse(body)
      const sessionId = getters.bookingRequest.sessionId
      dispatch(
        'showModalWithHomeRedirect',
        getErrorMessageWithTimestamp({
          sessionId,
          internalErrorCode,
          errorDescription: body.error,
          i18nParams: getters.hotelContacts
        })
      )
      captureRequestException(
        'Tax service request has been failed',
        body,
        { sessionId },
        'tax_service_failed'
      )
    } finally {
      commit('SET_LOADING', false)
    }
  },
  createReservationModifyRequest({ getters, commit }, reservation) {
    const activeRoomStays = reservation.roomStays.filter(
      (roomStay) => roomStay.status != ReservationStatus.Cancel
    )
    const bookingRequest = createBookingRequest({
      checkIn: reservation.startDate,
      checkOut: reservation.endDate,
      guestInfo: reservation.guestInfo,
      roomStays: activeRoomStays,
      promoCodeInput: reservation.promoCode || '',
      paymentInfo: reservation.paymentInfo,
      payableAmount: reservation.payableAmount
    })

    commit('UPDATE_BOOKING_REQUEST', bookingRequest)

    activeRoomStays.forEach((roomStay, roomStayIndex) => {
      const sellableProduct = getters.productMatchingRoomAndRateCode(
        roomStay.guestRoomId,
        roomStay.ratePlanId
      )

      commit('ADD_ORDER_TO_ROOM_STAY', {
        order: {
          nbNights: bookingRequest.nbNights,
          currency: roomStay.currencyCode,
          currencyCode: roomStay.currencyCode,
          guestRoomId: roomStay.guestRoomId,
          ratePlanId: roomStay.ratePlanId,
          startDate: formatDate(roomStay.startDate),
          endDate: formatDate(roomStay.endDate),
          datesDiff: roomStay.datesDiff,
          nbAdults: roomStay.nbAdults,
          nbChildren: roomStay.nbChildren,
          nbInfants: roomStay.nbInfants,
          reference: roomStay.reference,
          services: roomStay.services,
          totalDiscount: roomStay.totalDiscount,
          totalPrice: roomStay.totalPrice,
          totalLocalTax: roomStay.totalLocalTax,
          totalVat: roomStay.totalVat,
          rate: {
            ratePlanName: sellableProduct?.ratePlans[0]?.ratePlanName
          },
          room: {
            roomTypeName: sellableProduct?.roomTypes[0]?.roomTypeName
          }
        },
        roomStayIndex
      })
      /**
       * this commit is needed to request cancellation policies by
       * @method getCancellationPolicies
       */
      commit('UPDATE_CURRENT_ROOM_STAY', {
        ratePlanId: sellableProduct?.ratePlans[0]?.ratePlanCode,
        guestRoomId: sellableProduct?.roomTypes[0]?.roomTypeCode
      })
    })
    return true
  },
  async cancelRoomStay(
    { getters, commit },
    { reservationId, roomStayIndex, token }
  ) {
    try {
      commit('SET_RESERVATION_LOADING', true)
      const response = await HttpService.request({
        url: `roomstays/${getters.hotelId}/${reservationId}/${roomStayIndex}`,
        method: 'DELETE',
        query: {
          token
        }
      })

      if (response) {
        const roomStay = getters.bookingRequest.roomStays.find(
          (rs) => rs.roomStayIndex === roomStayIndex
        )
        commit('REMOVE_ROOM_STAY', roomStay)
        return response
      }
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      const errorMessage = getErrorMessageWithTimestamp({
        reservationId,
        internalErrorCode,
        errorDescription: getErrorDescription(internalErrorCode),
        errorCodeParams: { roomStayIndex },
        i18nParams: getters.hotelContacts
      })

      Swal.fire({
        icon: 'error',
        title: i18n.global.t('App#Error Oops!'),
        text: errorMessage
      })

      captureRequestException('Room stay cancel has been failed', error)
    } finally {
      commit('SET_RESERVATION_LOADING', false)
    }
  },
  async payForReservation({ getters }, payload) {
    return await HttpService.requestSecured(
      {
        url: `reservations/${getters.hotelId}/actions/pay`,
        method: 'POST',
        body: payload
      },
      'Reservation payment has been failed'
    )
  },
  async getConfigCustomization({ getters, commit }) {
    const customization = await HttpService.requestSecured(
      {
        url: `config/customization/${getters.hotelId}`
      },
      'Customization data could not be found for this hotel'
    )

    customization && commit('UPDATE_CUSTOMIZATION_CONFIG', customization)
    return customization
  },
  async sendCustomizationData({ state, getters, dispatch }) {
    const response = await HttpService.requestSecured(
      {
        url: `config/customization/${getters.hotelId}`,
        method: 'PUT',
        body: state.customization
      },
      'Customization update request has been failed'
    )
    response && dispatch('getConfigCustomization')
  },
  async uploadImageKitFile({ getters, commit }, { id, file, fileName }) {
    commit('SET_LOADING', true)
    try {
      const authResponse = await fetch(
        `${process.env.AUTH_ENDPOINT}/multimedia/token?publicKey=${process.env.IK_PUBLIC_KEY}&urlEndpoint=${process.env.IK_URL_ENDPOINT}${getters.hotelId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          mode: 'cors'
        }
      )
      if (!authResponse.ok) {
        captureRequestException(
          'Failed to fetch ImageKit authentication data',
          authResponse
        )
      }
      const { data } = await authResponse.json()

      const response = await imageKit.upload({
        ...data,
        file,
        fileName,
        folder: getters.hotelId,
        useUniqueFileName: false
      })

      const imageUrl = imageKit.url({
        urlEndpoint: process.env.IK_URL_ENDPOINT,
        path: response.filePath
      })

      commit('UPDATE_CUSTOMIZATION_CONFIG', { [id]: imageUrl })
    } catch (error) {
      const errorText = 'An error occured while uploading a file to ImageKit'
      captureRequestException(errorText, error)
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('App#Error Oops!'),
        text: error.message || errorText
      })
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async trackTrivagoConversion(_, body) {
    try {
      return await HttpService.request({
        url: 'meta/trivago/tracking/booking',
        method: 'POST',
        body
      })
    } catch (error) {
      captureExceptionWithScope(
        'Trivago conversion request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { error, requestBody: body },
          tags: { type: 'trivago_request_failed' }
        },
        'fatal'
      )
    }
  },
  async trackTrivagoCancellation(_, body) {
    try {
      return await HttpService.request({
        url: 'meta/trivago/tracking/booking',
        method: 'DELETE',
        body
      })
    } catch (error) {
      captureExceptionWithScope(
        'Trivago cancellation request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { error, requestBody: body },
          tags: { type: 'trivago_request_failed' }
        },
        'fatal'
      )
    }
  },
  async trackTrivagoModification(_, body) {
    try {
      return await HttpService.request({
        url: 'meta/trivago/tracking/booking',
        method: 'PUT',
        body
      })
    } catch (error) {
      captureExceptionWithScope(
        'Trivago modification request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { error, requestBody: body },
          tags: { type: 'trivago_request_failed' }
        },
        'fatal'
      )
    }
  },
  async trackTripAdvisorConversion(_, body) {
    try {
      return await HttpService.request({
        url: 'meta/tripadvisor/tracking',
        body
      })
    } catch (error) {
      captureExceptionWithScope(
        'TripAdvisor conversion request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { error, requestBody: body },
          tags: { type: 'tripadvisor_request_failed' }
        },
        'fatal'
      )
    }
  },
  async getAllLanguages() {
    return await HttpService.request({
      urlCustom: `${process.env.TRANSLATIONS_ENDPOINT}/HS/languages/list.json`
    })
  },
  async getRecommendedLanguages() {
    return await HttpService.request({
      urlCustom: `${process.env.TRANSLATIONS_ENDPOINT}/HS/languages/recommended.json`
    })
  },
  async getLanguages({ dispatch, commit }) {
    try {
      const [langs, recommended] = await Promise.all([
        dispatch('getAllLanguages'),
        dispatch('getRecommendedLanguages')
      ])

      if (!langs || !recommended) {
        throw new Error('Failed to fetch language data')
      }

      const langPairs = Object.entries(langs)

      const supportedLangPairs = langPairs.filter(([key]) =>
        isLocaleSupported(key)
      )

      supportedLangPairs.sort((a, b) => {
        const aIndex = recommended.indexOf(a[0])
        const bIndex = recommended.indexOf(b[0])

        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex
        }

        if (aIndex !== -1) return -1

        if (bIndex !== -1) return 1

        return 0
      })

      const languages = Object.fromEntries(supportedLangPairs)

      commit('SET_LANGUAGES', languages)

      return languages
    } catch (err) {
      captureRequestException('Languages requests have been failed', err)
    }
  },
  async setPreferredLanguage({ state, commit, dispatch }) {
    const languages = await dispatch('getLanguages')

    const queryLocale = router.currentRoute.query?.lang
    const lsLocale = LocalStorage.getItem('language')
    const fallbackLocale = browserLang || fallbackLang

    let locale

    if (!lsLocale && !queryLocale) {
      locale = fallbackLocale
    }

    if (queryLocale && isLocaleSupported(queryLocale)) {
      locale = queryLocale
    }

    if (
      queryLocale &&
      !isLocaleSupported(queryLocale) &&
      parseLocale(queryLocale)
    ) {
      locale = parseLocale(queryLocale) || fallbackLocale
    } else if (queryLocale && !parseLocale(queryLocale)) {
      locale = lsLocale || fallbackLocale
    }

    if (!queryLocale && lsLocale) {
      locale = lsLocale
    }

    LocalStorage.setItem('language', locale)

    if (Reflect.has(state.languages, locale)) {
      commit('SET_LANGUAGE', locale)
    }
  },
  async setCurrencies({ commit }) {
    const currencies = await HttpService.requestSecured(
      {
        url: 'currencies'
      },
      'Currencies request has been failed'
    )

    currencies && commit('SET_CURRENCIES', currencies)
    return currencies
  },
  async getCurrenciesTranslations({ getters }) {
    const currenciesTranslations = await HttpService.requestSecured(
      {
        urlCustom: `${process.env.TRANSLATIONS_ENDPOINT}/out/ISO4217/${getters.language}.json`
      },
      'Currencies translations request has been failed'
    )
    if (currenciesTranslations) {
      i18n.global.mergeLocaleMessage(getters.language, currenciesTranslations)
      return currenciesTranslations
    }
    return null
  },
  updateSearchInfoDates({ commit }, { start: checkIn, end: checkOut }) {
    commit('UPDATE_BOOKING_REQUEST_DATES', {
      checkIn,
      checkOut
    })
  }
}
