import { watch, ref } from 'vue'
import { useCssVar } from '@vueuse/core'
import { useTheme as useVuetifyTheme } from 'vuetify'
import { lightenColor, opaqueColor } from '@/app/system/helper'
import store from '../store'

const cssVars = {
  bg: '--hs-body-bg',
  bgImg: '--hs-body-bg-img',
  btn: '--hs-color-btn',
  btnBg: '--hs-color-btn-bg',
  btnBgLight: '--hs-color-btn-bg-light',
  btnBgOpaque: '--hs-color-btn-bg-opaque',
  btnFont: '--hs-font-family-btn',
  titleFont: '--hs-font-family-title',
  titleFontSize: '--hs-font-size-title',
  btnFontSize: '--hs-font-size-btn',
  link: '--hs-color-link',
  primary: '--hs-color-primary',
  primaryLight: '--hs-color-primary-light',
  primaryFont: '--hs-font-family-primary',
  primaryFontSize: '--hs-font-size-primary',
  text: '--hs-text-color',
  textOpaque: '--hs-text-color-opaque',
  borderColor: '--hs-border-color'
}

const getPrimaryFontSize = (size = 'medium') =>
  ({
    small: '0.75rem',
    large: '1.1rem'
  })[size] || '0.8rem'

const getTitleFontSize = (size = 'medium') =>
  ({
    small: '1.6rem',
    large: '1.75rem'
  })[size] || '1.4rem'

const vars = Object.fromEntries(
  Object.entries(cssVars).map(([key, value]) => [key, useCssVar(value)])
)

export default function useTheme() {
  const theme = useVuetifyTheme()
  const isDark = ref(theme.global.current.value.dark)

  const updateTheme = (newVal) => {
    if (!newVal) return

    // Update CSS variables
    vars.btn.value = newVal.colorBtn?.hex || '#1976D2'
    vars.btnBg.value = newVal.colorBtnBg?.hex || '#1976D2'
    vars.btnFont.value = newVal.fontFamilyBtn || 'Roboto'
    vars.titleFont.value = newVal.fontFamilyTitle || 'Roboto'
    vars.titleFontSize.value = getTitleFontSize(newVal.fontSizeTitle)
    vars.btnFontSize.value = getPrimaryFontSize(newVal.fontSizeBtn)
    vars.link.value = newVal.colorLink?.hex || '#2196F3'
    vars.primary.value = newVal.colorPrimary?.hex || '#1976D2'
    vars.primaryFont.value = newVal.fontFamilyPrimary || 'Roboto'
    vars.primaryFontSize.value = getPrimaryFontSize(newVal.fontSizePrimary)
    vars.text.value = newVal.colorText?.hex || '#000000'
    vars.borderColor.value = opaqueColor(
      newVal.colorPrimary?.hex || '#1976D2',
      0.25
    )
    vars.primaryLight.value = lightenColor(
      newVal.colorPrimary?.hex || '#1976D2',
      0.88
    )
    vars.btnBgLight.value = lightenColor(
      newVal.colorBtnBg?.hex || '#1976D2',
      0.88
    )
    vars.btnBgOpaque.value = opaqueColor(
      newVal.colorBtnBg?.hex || '#1976D2',
      0.8
    )
    vars.textOpaque.value = opaqueColor(newVal.colorText?.hex || '#000000', 0.5)

    // Update Vuetify theme
    theme.themes.value.light = {
      ...theme.themes.value.light,
      colors: {
        ...theme.themes.value.light.colors,
        primary: newVal.colorBtnBg?.hex || '#1976D2',
        secondary: newVal.colorPrimary?.hex || '#424242'
      }
    }

    theme.themes.value.dark = {
      ...theme.themes.value.dark,
      colors: {
        ...theme.themes.value.dark.colors,
        primary: newVal.colorBtnBg?.hex || '#2196F3',
        secondary: newVal.colorPrimary?.hex || '#424242'
      }
    }

    if (newVal.bgUrl) {
      vars.bgImg.value = `url('${newVal.bgUrl}')`
    }
  }

  watch(() => store.state.customization, updateTheme, { deep: true })

  watch(isDark, (newVal) => {
    const newTheme = newVal ? 'dark' : 'light'
    theme.global.name.value = newTheme

    // For Sweetalert
    document.body.classList.remove(`v-theme--${newVal ? 'light' : 'dark'}`)
    document.body.classList.add(`v-theme--${newTheme}`)
  })

  // Initial theme update
  updateTheme(store.state.customization)

  return {
    theme: isDark,
    isDark
  }
}
