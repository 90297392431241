import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "rooms-container mt-1" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "text-all_loaded text--secondary" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_skeleton_room_item = _resolveComponent("skeleton-room-item")
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_packages_list_item = _resolveComponent("packages-list-item")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_sheet = _resolveComponent("v-sheet")
  const _component_router_link = _resolveComponent("router-link")
  const _component_rooms_list_item = _resolveComponent("rooms-list-item")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      tag: "div",
      class: "rooms-container__list",
      name: "list"
    }, {
      default: _withCtx(() => [
        ($options.loading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (index) => {
              return _createVNode(_component_skeleton_room_item, { key: index })
            }), 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!$options.sortedRooms.length)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: "error",
                    class: "mt-5",
                    border: "top",
                    color: "primary",
                    type: "info",
                    elevation: "2"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                "Search#Results#NoResults Can't find rooms matching this selection"
              )), 1 /* TEXT */),
                      _createElementVNode("p", {
                        class: "alert-contact-details",
                        innerHTML: $options.contactDetails
                      }, null, 8 /* PROPS */, _hoisted_2)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.showHotelPackages)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.hotelPackagesAmongResults, (item) => {
                          return (_openBlock(), _createBlock(_component_packages_list_item, {
                            key: item.id,
                            item: item
                          }, null, 8 /* PROPS */, ["item"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      : _createCommentVNode("v-if", true),
                    (
              _ctx.showHotelPackages &&
              _ctx.hasHotelPackagesNotAmongResults &&
              _ctx.hasHotelPublicPackages
            )
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: "showMorePackages",
                          class: "text-center d-flex justify-center",
                          to: { name: 'packages' }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_sheet, { class: "my-6" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  class: "text-none",
                                  color: "primary",
                                  ripple: false,
                                  size: "x-large",
                                  variant: "outlined",
                                  tile: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.hasHotelPackagesAmongResults
                    ? _ctx.$t('Search#Results#AllPackages See all packages')
                    : _ctx.$t('Search#Results#AllPackages See special offers')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedRooms, (item, i) => {
                      return (_openBlock(), _createBlock(_component_rooms_list_item, {
                        key: item.id,
                        item: item,
                        "is-expanded": i === $data.expandedItemIndex,
                        index: i,
                        onExpand: $options.toggleRatePlan
                      }, null, 8 /* PROPS */, ["item", "is-expanded", "index", "onExpand"]))
                    }), 128 /* KEYED_FRAGMENT */)),
                    _createVNode(_component_v_sheet, { key: "all-loaded" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('Search#Results#AllResults All results loaded')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ], 64 /* STABLE_FRAGMENT */))
            ], 64 /* STABLE_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    })
  ]))
}