import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "datatrans__block" }
const _hoisted_2 = {
  key: 0,
  class: "block__loader"
}
const _hoisted_3 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loader = _resolveComponent("loader")
  const _component_BaseField = _resolveComponent("BaseField")
  const _component_PaymentBaseCard = _resolveComponent("PaymentBaseCard")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: _normalizeClass($setup.isDesktop && 'pa-5')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!$data.isDatatransLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_loader)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
          class: _normalizeClass(["datatrans__block__content", { 'non-visible': !$data.isDatatransLoaded }])
        }, [
          _createVNode(_component_PaymentBaseCard, {
            "is-credit-card-valid": $options.isCreditCardValid,
            "is-cvv-valid": $options.isCvvValid,
            onSubmit: $options.submit,
            onSetFieldHeight: $options.setFieldHeight
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.fields, ({
              slotName,
              isValid,
              isDirty,
              errorText,
              id,
              isFocused
            }) => {
                return (_openBlock(), _createBlock(_component_BaseField, {
                  "has-error": isDirty && !isValid,
                  "error-message": errorText,
                  "portal-target": _ctx.portalTarget
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["theme-container", {
                  focused: isFocused,
                  'has-error': isDirty && !isValid
                }]),
                      id: id,
                      ref_for: true,
                      ref: id
                    }, null, 10 /* CLASS, PROPS */, _hoisted_3)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["has-error", "error-message", "portal-target"]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["is-credit-card-valid", "is-cvv-valid", "onSubmit", "onSetFieldHeight"])
        ], 2 /* CLASS */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]))
}