
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Swal from 'sweetalert2'
import mitt from 'mitt'
import store from '@/app/store'

export default defineComponent({
  name: 'PaymentCallback',
  setup() {
    interface CCAvenueParams {
      intent?: string
      result?: string
      datatransTrxId?: string
      transactionId?: string
      transactionError?: string
    }

    interface JuspayParams {
      status_id: JuspayStatus
      status: string
      order_id: string
      signature: string
      signature_algorithm: string
    }

    interface PayrailsParams {
      status: PaymentStatus
      orderId: string
    }

    enum JuspayStatus {
      // Success statuses
      CHARGED = '21',
      COD_INITIATED = '29',
      PARTIAL_CHARGED = '37',
      AUTHORIZED = '25',

      // Pending statuses
      NEW = '10',
      PENDING_VBV = '23',
      AUTHORIZING = '28',
      STARTED = '20',
      VOID_INITIATED = '32',
      CAPTURE_INITIATED = '33',

      // Failure statuses
      JUSPAY_DECLINED = '22',
      AUTHENTICATION_FAILED = '26',
      AUTHORIZATION_FAILED = '27',
      VOIDED = '31',
      CAPTURE_FAILED = '34',
      VOID_FAILED = '35',
      AUTO_REFUNDED = '36',
      NOT_FOUND = '40'
    }

    const SUCCESS_STATUSES = [
      JuspayStatus.CHARGED,
      JuspayStatus.COD_INITIATED,
      JuspayStatus.PARTIAL_CHARGED,
      JuspayStatus.AUTHORIZED
    ]

    const PENDING_STATUSES = [
      JuspayStatus.NEW,
      JuspayStatus.PENDING_VBV,
      JuspayStatus.AUTHORIZING,
      JuspayStatus.STARTED,
      JuspayStatus.VOID_INITIATED,
      JuspayStatus.CAPTURE_INITIATED
    ]

    const FAILURE_STATUSES = [
      JuspayStatus.JUSPAY_DECLINED,
      JuspayStatus.AUTHENTICATION_FAILED,
      JuspayStatus.AUTHORIZATION_FAILED,
      JuspayStatus.VOIDED,
      JuspayStatus.CAPTURE_FAILED,
      JuspayStatus.VOID_FAILED,
      JuspayStatus.AUTO_REFUNDED,
      JuspayStatus.NOT_FOUND
    ]

    type PaymentStatus = 'success' | 'pending' | 'fail'

    interface PaymentResult {
      status: PaymentStatus
      orderId: string
    }

    type Events = {
      'ccavenue-params': CCAvenueParams
      'payment-result': PaymentResult
    }

    const emitter = mitt<Events>()

    const getPaymentStatus = (statusId: JuspayStatus): PaymentStatus => {
      if (SUCCESS_STATUSES.includes(statusId)) return 'success'
      if (PENDING_STATUSES.includes(statusId)) return 'pending'
      return 'fail'
    }

    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()

    const processPaymentError = async () => {
      setTimeout(async () => {
        const { isConfirmed, isDismissed } = await Swal.fire({
          icon: 'error',
          title: t(
            'Checkout#Payment#Error Something went wrong during payment initialization'
          ),
          text: t(
            'Checkout#Payment#Error Your reservation is still confirmed, and the payment transaction will be validated manually. We will get in touch with you soon to confirm the final status of your reservation.'
          ),
          confirmButtonText: t('Checkout#Payment#Check#Fail Try again')
        })
        if (isConfirmed || isDismissed) {
          router.push({ name: 'home' })
        }
      }, 1500)
    }

    /**
     * "paymentAttempt" event was fired from juspay iframe
     * ("proceed to pay" button clicked [juspay])
     * start polling transaction status until "status" is not "pending"
     * afterwards, navigate to confirmation if "success"
     * display error poopup if "error"/"fail"
     */
    const pollTransactionStatus = async (reservationId: string) => {
      let retries = 0
      const maxRetries = 30
      const delay = 2100

      store.commit('SET_RESERVATION_LOADING', true)

      const pollingInterval = setInterval(async () => {
        retries++
        if (retries > maxRetries) {
          clearInterval(pollingInterval)
          store.commit('SET_RESERVATION_LOADING', false)
          return
        }

        try {
          const { status } = await store.dispatch('getTransactionStatus', {
            reservationId
          })
          if (status === 'success') {
            clearInterval(pollingInterval)
            store.commit('SET_RESERVATION_LOADING', false)
            store.dispatch('replaceRoute', {
              name: 'ReservationCheckoutConfirmation',
              params: { reservationId }
            })
          } else if (status === 'error' || status === 'fail') {
            processPaymentError()
            throw new Error('Polling transaction status resulted in error')
          }
        } catch (error) {
          clearInterval(pollingInterval)
          store.commit('SET_RESERVATION_LOADING', false)
        }
      }, delay)
    }

    onMounted(async () => {
      // Handle CCAvenue parameters
      // TODO: Afterpay need to be refactored for this
      if (route.query.intent || route.query.result) {
        const ccavenueParams: CCAvenueParams = {
          intent: route.query.intent as string,
          result: route.query.result as string,
          datatransTrxId: route.query.datatransTrxId as string,
          transactionId: route.query.transactionId as string,
          transactionError: route.query.transactionError as string
        }
        emitter.emit('ccavenue-params', ccavenueParams)
      }

      // Handle Payrails parameters
      if (route.query.payrails_status && route.query.order_id) {
        const payrailsParams: PayrailsParams = {
          status: route.query.payrails_status as PaymentStatus,
          orderId: route.query.order_id as string
        }

        emitter.emit('payment-result', {
          status: payrailsParams.status,
          orderId: payrailsParams.orderId
        })

        if (payrailsParams.status === 'success') {
          store.dispatch('replaceRoute', {
            name: 'ReservationCheckoutConfirmation',
            params: { reservationId: payrailsParams.orderId }
          })
        } else if (
          payrailsParams.status === 'error' ||
          payrailsParams.status === 'fail'
        ) {
          processPaymentError()
        }
      }
      // Handle JusPay parameters
      else if (route.query.status_id || route.query.order_id) {
        const juspayParams: JuspayParams = {
          status_id: route.query.status_id as JuspayStatus,
          status: route.query.status as string,
          order_id: route.query.order_id as string,
          signature: route.query.signature as string,
          signature_algorithm: route.query.signature_algorithm as string
        }

        if (!Object.values(JuspayStatus).includes(juspayParams.status_id)) {
          console.error(
            'Invalid Juspay status_id received:',
            juspayParams.status_id
          )
          return
        }

        const paymentStatus = getPaymentStatus(juspayParams.status_id)
        emitter.emit('payment-result', {
          status: paymentStatus,
          orderId: juspayParams.order_id
        })

        if (paymentStatus === 'success') {
          store.dispatch('replaceRoute', {
            name: 'ReservationCheckoutConfirmation',
            params: { reservationId: juspayParams.order_id }
          })
        } else if (paymentStatus === 'pending') {
          store.commit('SET_RESERVATION_LOADING', true)
          store.dispatch('replaceRoute', {
            name: 'ReservationCheckoutConfirmation',
            params: { reservationId: juspayParams.order_id }
          })
          pollTransactionStatus(juspayParams.order_id)
        } else if (paymentStatus === 'error' || paymentStatus === 'fail') {
          processPaymentError()
        }
      }
    })

    return () => null
  }
})
