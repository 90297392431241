import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "item__info" }
const _hoisted_2 = { class: "item__title" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "item__price" }
const _hoisted_5 = { class: "item__button" }
const _hoisted_6 = {
  key: 0,
  class: "value"
}
const _hoisted_7 = {
  class: "item__description",
  "data-role": "content"
}
const _hoisted_8 = {
  class: "content-inner",
  "data-role": "content-inner"
}
const _hoisted_9 = {
  key: 0,
  class: "text description"
}
const _hoisted_10 = {
  key: 1,
  class: "images"
}
const _hoisted_11 = {
  key: 2,
  class: "text"
}
const _hoisted_12 = { class: "text__price--old text-decoration-line-through text-disabled" }
const _hoisted_13 = { class: "text__price--discount" }

import { computed } from 'vue'
import { useDisplay } from 'vuetify'
import { isMobile } from '@/app/composables/useBreakpoints'


import { mapGetters } from 'vuex'
import {
  animateHeight,
  formatPrice,
  getImageKitUrls,
  getResolution
} from '@/app/system/helper'
import { tabs } from '@/app/system/mixins/tabs'
import { CURRENCY_DEFAULT } from '@/app/constants'

import ImageSlider from '@/components/UI/Vuetify/ImageSlider'
import InputQuantity from '@/components/UI/Controls/Inputs/InputQuantity'
import Price from '@/components/Common/Price/Price'

const __default__ = {
  name: 'ReservationServicesAccordion',
  components: {
    ImageSlider,
    Price,
    InputQuantity
  },
  mixins: [tabs],
  emits: ['select', 'remove', 'change-quantity'],
  props: {
    modeLink: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String,
      required: true
    },
    unitPrice: {
      type: Number,
      required: false
    },
    price: {
      type: Number,
      default: 0
    },
    pricingType: {
      type: String,
      required: false
    },
    discount: {
      type: Number,
      default: 0
    },
    mainPrice: {
      type: Number,
      default: 0
    },
    minQuantity: {
      type: Number,
      required: false
    },
    maxQuantity: {
      type: Number,
      required: false
    },
    description: {
      type: Object,
      default: () => ({
        images: [],
        texts: []
      })
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isDefault: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['currency']),
    btnText() {
      return this.isDefault
        ? this.$t('Checkout#Services Included')
        : this.$t('Checkout#Services Choose')
    },
    images() {
      return getImageKitUrls(
        this.description.images,
        getResolution(this.$vuetify.display)
      )
    },
    unitPriceFormatted() {
      return formatPrice(this.unitPrice, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    }
  },
  methods: {
    changeState(e) {
      if (e.target.closest('.item__button')) {
        return false
      }
      this.active = !this.active
      animateHeight(this.active ? 1 : 0, this.$el)
    },
    select() {
      this.$emit('select', this.id)
    },
    remove() {
      this.$emit('remove', this.id)
    },
    toggle() {
      this.isSelected ? this.remove() : this.select()
    },
    switchRoom() {
      if (this.$route.params.roomId != this.id) {
        this.$router.push({ name: 'room', params: { roomId: this.id } })
      }
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

const display = useDisplay()

const carouselHeight = computed(() => {
  const heights = {
    Mobile: 'auto',
    Tablet: 250,
    Desktop: 300
  }
  return heights[getResolution(display)]
})

return (_ctx, _cache) => {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item accordion-item", { 'is-active': _ctx.active, 'is-default': __props.isDefault }])
  }, [
    _createElementVNode("div", {
      class: "item__heading",
      onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.changeState && _ctx.changeState(...args)))
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "item__icon theme-container" }, [
        _createElementVNode("i", { class: "icon-angle-down" })
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(__props.title), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "price", {
            context: { price: __props.price, discount: __props.discount, mainPrice: __props.mainPrice, currency: _ctx.currency, isRequired: __props.isRequired }
          }, () => [
            _createVNode(_unref(Price), {
              price: __props.price,
              discount: __props.discount,
              "main-price": __props.mainPrice,
              currency: _ctx.currency
            }, null, 8 /* PROPS */, ["price", "discount", "main-price", "currency"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (__props.isDefault || __props.modeLink)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              class: _normalizeClass([
            'service-selection-button text-none',
            {
              'service-selection-button--unselected': !__props.isSelected && !__props.isRequired
            }
          ]),
              color: __props.isSelected || __props.isRequired ? 'primary' : 'secondary',
              animation: !_unref(isMobile),
              width: _unref(isMobile) ? 40 : null,
              "min-width": 40,
              elevation: "0",
              tile: "",
              onClick: _cache[0] || (_cache[0] = $event => (!__props.modeLink ? _ctx.toggle() : _ctx.switchRoom()))
            }, {
              default: _withCtx(() => [
                (!_unref(isMobile))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.btnText), 1 /* TEXT */))
                  : (_openBlock(), _createBlock(_component_v_icon, {
                      key: 1,
                      size: 20
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("mdi-check")
                      ])),
                      _: 1 /* STABLE */
                    }))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class", "color", "animation", "width"]))
          : (_openBlock(), _createBlock(_unref(InputQuantity), {
              key: 1,
              min: __props.minQuantity,
              max: __props.maxQuantity ?? 1,
              "text-min": "Toast#Services#Mandatory This service is mandatory",
              "text-max": "Toast#Services#Max Maximum quantity for this service is {max}",
              "is-required": __props.isRequired,
              onChange: _cache[1] || (_cache[1] = $event => (_ctx.$emit('change-quantity', $event)))
            }, null, 8 /* PROPS */, ["min", "max", "is-required"]))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (__props.description.texts.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.description.texts, (sentence) => {
                return (_openBlock(), _createElementBlock("p", null, _toDisplayString(sentence), 1 /* TEXT */))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        (__props.description.images.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    lg: "12",
                    md: "9",
                    sm: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(ImageSlider), {
                        images: _ctx.images,
                        title: __props.title,
                        height: carouselHeight.value
                      }, null, 8 /* PROPS */, ["images", "title", "height"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : _createCommentVNode("v-if", true),
        (__props.unitPrice && __props.pricingType)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(__props.pricingType) + ":  ", 1 /* TEXT */),
                (__props.discount)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.unitPriceFormatted), 1 /* TEXT */),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(formatPrice)(__props.unitPrice - __props.discount, { currency: _ctx.currency })), 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.unitPriceFormatted), 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ], 2 /* CLASS */))
}
}

})