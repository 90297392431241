/* eslint-disable quotes */
export const SESSION_DURATION = 20
const JSON_STUB = {
  oldLink: 'http://jsonstub.com/',
  content: 'application/json',
  userKey: '38b81223-6b68-404a-8a40-335d6af7a2c1',
  projectKey: 'e4193d51-a754-4978-9fc0-0c1a78a35732'
}
const DATE_FORMAT = ['YYYY-M-D', 'YYYY-MM-DD']

const DATE_TIME_FORMAT_DAY_LONG = {
  weekday: 'long',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}
const DATE_TIME_FORMAT_DAY_SHORT = {
  ...DATE_TIME_FORMAT_DAY_LONG,
  ...{ weekday: 'short' }
}
const DATE_TIME_FORMAT_DAY_MONTH_LONG = {
  ...DATE_TIME_FORMAT_DAY_LONG,
  ...{ month: 'long' }
}
const NOT_FOUND_REDIRECT_TIMEOUT = 5000
const PRICE_ADDITIONAL_LENGTH = 1
const PRODUCT_DESC_MAX_LEN = 200
const RATE_DESC_MAX_LEN = 100
const SESSION_EXPIRATION_TIMEOUT = 900000 // 15m
const TRANSITION_TIME = 300
const TRANSITION_TIME_LONG = TRANSITION_TIME * 1.4

// GUESTS BOUND
const MIN_ADULTS = 1
const MAX_ADULTS = 8
const MIN_CHILDREN = 0
const MAX_CHILDREN = 4
const MIN_INFANTS = 0
const MAX_INFANTS = 4

const BOUNDS = {
  MIN_ADULTS,
  MAX_ADULTS,
  MIN_CHILDREN,
  MAX_CHILDREN,
  MIN_INFANTS,
  MAX_INFANTS
}

// TAX SERVICE COUNTRIES
export const TAX_SERVICE_COUNTRIES = ['IN', 'BD']

// OTA to Datatrans payment method mapping
const PAYMENT_METHOD_CODES = {
  AX: 'AMX',
  BC: 'BAC',
  BL: 'CBL',
  CB: 'CBL',
  DN: 'DIN',
  DS: 'DIS',
  EC: 'ECA',
  JC: 'JCB',
  MA: 'MAU',
  MC: 'ECA',
  CU: 'CUP',
  TP: 'UAP',
  VE: 'VIS',
  VI: 'VIS'
}

const LANGUAGES = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  ar: 'العربية',
  bn: 'বেঙ্গলি; বাংলা',
  cs: 'Čeština',
  da: 'Dansk',
  el: 'Ελληνική, Νέα',
  gu: 'ગુજરાતી',
  he: 'עברית (מודרנית)',
  hi: 'हिंदी',
  hr: 'hrvatski',
  hu: 'Magyar',
  ja: '日本語',
  ko: '한국어',
  mr: 'मराठी',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ro: 'română',
  ru: 'русский',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sv: 'Svenska',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  th: 'ไทย',
  zh: '中文'
}

const CURRENCIES = {
  preferred: ['EUR', 'USD', 'GBP', 'CHF'],
  others: [
    'AED',
    'ARS',
    'AUD',
    'BHD',
    'BRL',
    'CAD',
    'CLP',
    'CNY',
    'COP',
    'CZK',
    'DKK',
    'HUF',
    'ILS',
    'INR',
    'JOD',
    'JPY',
    'KRW',
    'KWD',
    'MXN',
    'NOK',
    'OMR',
    'PLN',
    'QAR',
    'RON',
    'RUB',
    'SAR',
    'SEK',
    'THB',
    'ZAR'
  ]
}

const CURRENCY_DEFAULT = 'CHF'

const PRESET_BASE = {
  bgUrl: '',
  logoUrl: '',
  colorBg: { hex: '#FFFFFF', a: 1 },
  colorBtn: { hex: '#FFFFFF', a: 1 },
  colorBtnBg: { hex: '#2B2A2A', a: 1 },
  colorLink: { hex: '#2B2A2A', a: 1 },
  colorPrimary: { hex: '#2B2A2A', a: 1 },
  fontFamilyBtn: '"Open Sans HSB", sans-serif',
  fontFamilyPrimary: '"Open Sans HSB", sans-serif',
  fontFamilyTitle: '"Open Sans HSB", sans-serif',
  fontSizeBtn: 'medium',
  fontSizePrimary: 'medium',
  fontSizeTitle: 'medium'
}

const COLOR_SCHEME = {
  light: {
    ...PRESET_BASE,
    isDarkMode: false,
    colorText: { hex: '#2B2A2A', a: 1 }
  },
  dark: {
    ...PRESET_BASE,
    isDarkMode: true,
    colorText: { hex: '#FFFFFF', a: 1 }
  }
}

const ERROR_CODES = {
  5025: "App#Error#No#Availabilities Unfortunately, there's no more availabilities left. Please try again later",
  5050: '',
  5601: 'App#Error#Reservation already cancelled',
  5610: 'App#Error Cancellation failed, server error. Please check that the reservation ID and email address are correct.',
  5611: 'App#Error Failed loading reservation data. Please try again.',
  5615: 'App#Error#Expired Cannot modify expired reservations',
  5901: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5902: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5903: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5904: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5908: 'App#Payment Cannot charge credit card',
  5912: 'App#Payment Cannot update reservation after successful payment',
  5915: 'App#Error#Payment Transaction already processed',
  BOOKING_FAILED: 'App#Error Booking failed. Please try again.'
}

const ROOM_AMENITY_TYPE = {
  // https://tourisoft.atlassian.net/wiki/spaces/HOT/pages/4325585/Room+Amenity+Type+RMA
  // code: 'icon-class' // description
  // to get 'icon-class' see 'src/app/static/css/ui/_icons.scss'

  1: 'door-open', // Adjoining rooms
  2: 'air-conditioner', // Air conditioning
  3: 'alarm-clock', // Alarm clock
  4: 'tv', // All news channel
  5: 'radio', // AM/FM radio
  6: 'walkie-talkie', //Baby listening device
  7: 'window-frame', // Balcony/Lanai/Terrace
  8: 'grill', // Barbeque grills
  9: 'hot-tub-person', // Bath tub with spray jets
  10: 'person-half-dress', // Bathrobe
  11: 'soap', // Bathroom amenities
  12: 'phone', // Bathroom telephone
  13: 'bath', // Bathtub
  14: 'bath', // Bathtub only
  15: 'bath', //Bathtub/shower combination
  16: 'toilet', // Bidet
  17: 'bottle-water ', // Bottled water
  18: 'tv', // Cable television
  19: 'mug-hot', // Coffee/Tea maker
  20: 'tv', // Color television
  21: 'computer-classic', // Computer
  22: 'door-open', // Connecting rooms
  23: 'outlet', // Converters/ Voltage adaptors
  24: 'print', // Copier
  25: 'phone', // Cordless phone
  26: 'baby', // Cribs
  27: 'server', // Data port
  28: 'briefcase-blank', // Desk
  29: 'lamp-desk', //Desk with lamp
  30: 'plate-utensils', // Dining guide
  31: 'phone', // Direct dial phone number
  32: 'kitchen-set', //Dishwasher
  33: 'bed-front', //Double beds
  34: 'outlet', // Dual voltage outlet
  35: 'outlet', // Electrical current voltage
  36: 'chair-office', // Ergonomic chair
  37: 'worm', // Extended phone cord
  38: 'fax', // Fax machine
  39: 'sensor-fire', // Fire alarm
  40: 'sensor-fire', // Fire alarm with light
  41: 'fireplace', // Fireplace
  42: 'phone-volume', // Free toll free calls
  43: 'phone-volume', // Free calls
  44: 'phone-volume', // Free credit card access calls
  45: 'phone-volume', // Free local calls
  46: 'film', // Free movies/video
  47: 'kitchen-set', // Full kitchen
  48: 'refrigerator', // Grab bars in bathroom
  49: 'bath', // Grecian tub
  50: 'fan', // Hairdryer
  51: 'globe', // High speed internet connection
  52: 'tv', // Interactive web TV
  53: 'dial', // International direct dialing
  54: 'wifi', // Internet access
  55: 'horizontal-rule', // Iron
  56: 'ticket-perforated', // Ironing board
  57: 'hot-tub-person', // Whirpool
  58: 'bed-front', // King bed
  59: 'kitchen-set', // Kitchen
  60: 'kitchen-set', // Kitchen supplies
  61: 'kitchen-set', // Kitchenette
  62: 'lightbulb', // Knock light
  63: 'laptop', // Laptop
  64: 'briefcase-blank', // Large desk
  65: 'briefcase-blank', // Large work area
  66: 'basket-shopping-simple', // Laundry basket/clothes hamper
  67: 'stairs', // Loft
  68: 'microwave', // Microwave
  69: 'refrigerator', // Minibar
  70: 'router', // Modem
  71: 'router', // Modem jack
  72: 'phone', // Multi-line phone
  73: 'newspaper', // Newspaper
  74: 'ban-smoking', // Non-smoking
  75: 'memo', // Notepads
  76: 'stapler', // Office supplies
  77: 'oven', // Oven
  78: 'tv', // Pay per view movies on TV
  79: 'pen', // Pens
  80: 'phone', // Phone in bathroom
  81: 'kitchen-set', // Plates and bowls
  82: 'pan-frying', // Pots and pans
  83: 'rug', // Prayer mats
  84: 'print', // Printer
  85: 'bath', // Private bathroom
  86: 'bed-front', // Queen bed
  87: 'seat-airline', // Recliner
  88: 'refrigerator', // Refrigerator
  89: 'refrigerator', // Refrigerator with ice maker
  90: 'gamepad', // Remote control television
  91: 'bed-empty', // Rollaway bed
  92: 'vault', // Safe
  93: 'scanner-image', // Scanner
  94: 'clothes-hanger', // Separate closet
  95: 'router', // Separate modem line available
  96: 'shoe-prints', // Shoe polisher
  97: 'shower', // Shower only
  98: 'utensils', // Silverware/utensils
  99: 'loveseat', // Sitting area
  100: 'sensor-cloud', // Smoke detectors
  101: 'smoking', // Smoking
  102: 'couch', // Sofa bed
  103: 'volume', // Speaker phone
  104: 'boombox', // Stereo
  105: 'oven', // Stove
  106: 'cassette-tape', // Tape recorder
  107: 'phone', // Telephone
  108: 'phone', // Telephone for hearing impaired
  109: 'phone', // Telephones with message light
  110: 'oven', // Toaster oven
  111: 'horizontal-rule', // Trouser/Pant press
  112: 'bell-concierge', // Turn down service
  113: 'bed-front', // Twin bed
  114: 'house', // Vaulted ceilings
  115: 'film', // VCR movies
  116: 'film', // VCR player
  117: 'gamepad-modern', // Video games
  118: 'phone', // Voice mail
  119: 'alarm-clock', // Wake-up calls
  120: 'toilet', // Water closet
  121: 'glass-water', // Water purification system
  122: 'glass-citrus', // Wet bar
  123: 'wifi', // Wireless internet connection
  124: 'keyboard', // Wireless keyboard
  125: 'outlet', // Adaptor available for telephone PC use
  126: 'air-conditioner', // Air conditioning individually controlled in room
  127: 'hot-tub-person', // Bathtub &whirlpool separate
  128: 'phone', // Telephone with data ports
  129: 'compact-disc', // CD  player
  130: 'phone-volume', // Complimentary local calls time limit
  131: 'person', // Extra person charge for rollaway use
  132: 'feather', // Down/feather pillows
  133: 'outlet', // Desk with electrical outlet
  134: 'court-sport', // ESPN available
  135: 'mattress-pillow', // Foam pillows
  136: 'film', // HBO available
  137: 'house', // High ceilings
  138: 'sparkles', // Marble bathroom
  139: 'film', // List of movie channels available
  140: 'dog', // Pets allowed
  141: 'person-swimming', // Oversized bathtub
  142: 'shower', // Shower
  143: 'sink', // Sink in-room
  144: 'volume-low', // Soundproofed room
  145: 'cabinet-filing', // Storage space
  146: 'table-picnic', // Tables and chairs
  147: 'phone', // Two-line phone
  148: 'clothes-hanger', // Walk-in closet
  149: 'washing-machine', // Washer/dryer
  150: 'weight-scale', // Weight scale
  151: 'gift', // Welcome gift
  152: 'outlet', // Spare electrical outlet available at desk
  153: 'paw', // Non-refundable charge for pets
  154: 'paw', // Refundable deposit for pets
  155: 'bath', // Separate tub and shower
  156: 'door-closed', // Entrance type to guest room
  157: 'fan', // Ceiling fan
  158: 'tv', // CNN available
  159: 'outlet', // Electrical adaptors available
  160: 'croissant', // Buffet breakfast
  161: 'wheelchair-move', // Accessible room
  162: 'clothes-hanger', // Closets in room
  163: 'compact-disc', // DVD player
  164: 'refrigerator', // Mini-refrigerator
  165: 'phone', // Separate line billing for multi-line phone
  166: 'heat', // Self-controlled heating/cooling system
  167: 'bread-slice', // Toaster
  168: 'nfc', // Analog data port
  169: 'phone-volume', // Collect calls
  170: 'phone-volume', // International calls
  171: 'router', // Carrier access
  172: 'phone-volume', // Interstate calls
  173: 'phone-volume', // Intrastate calls
  174: 'phone-volume', // Local calls
  175: 'phone-volume', // Long distance calls
  176: 'phone-volume', // Operator-assisted calls
  177: 'phone-volume', // Credit card access calls
  178: 'phone-volume', // Calling card calls
  179: 'phone-volume', // Toll free calls
  180: 'outlet', // Universal AC/DC adaptors
  181: 'bath', // Bathtub seat
  182: 'bed-front', // Canopy/poster bed
  183: 'mug', // Cups/glassware
  184: 'tv-music', // Entertainment center
  185: 'family', // Family/oversized room
  186: 'mattress-pillow', // Hypoallergenic bed
  187: 'mattress-pillow', // Hypoallergenic pillows
  188: 'lamp', // Lamp
  189: 'croissant', // Meal included - breakfast
  190: 'mug-saucer', // Meal included - continental breakfast
  191: 'plate-utensils', // Meal included - dinner
  192: 'plate-utensils', // Meal included - lunch
  193: 'pump-soap', // Shared bathroom
  194: 'phone', // Telephone TDD/Textphone
  195: 'bed-front', // Water bed
  196: 'person', // Extra adult charge
  197: 'child', // Extra child charge
  198: 'child', // Extra child charge for rollaway use
  199: 'pancakes', // Meal included:  full American breakfast
  200: 'bed-front', // Futon
  201: 'bed-front', // Murphy bed
  202: 'bed-front', // Tatami mats
  203: 'bed', // Single bed
  204: 'house', // Annex room
  205: 'newspaper', // Free newspaper
  206: 'gem', // Honeymoon suites
  207: 'wifi', // Complimentary high speed internet in room
  208: 'vacuum', // Maid service
  209: 'computer-classic', // PC hook-up in room
  210: 'satellite-dish', // Satellite television
  211: 'star', // VIP rooms
  212: 'bolt', // Cell phone recharger
  213: 'film', // DVR player
  214: 'mp3-player', // iPod docking station
  215: 'photo-film-music', // Media center
  216: 'photo-film-music', // Plug & play panel
  217: 'satellite-dish', // Satellite radio
  218: 'film', // Video on demand
  219: 'house', // Exterior corridors
  220: 'mountains', // Gulf view
  221: 'accessible-icon', // Accessible room,
  222: 'house', // Interior corridors,
  223: 'mountains', // Mountain view,
  224: 'mountains', // Ocean view,
  225: 'globe', // High speed internet access fee,
  226: 'wifi', // High speed wireless,
  227: 'film', // Premium movie channels,
  228: 'socks', // Slippers,
  229: 'treasure-chest', // First nighters' kit,
  230: 'chair', // Chair provided with desk,
  231: 'mattress-pillow', // Pillow top mattress,
  232: 'feather', // Feather bed,
  233: 'mattress-pillow', // Duvet,
  234: 'mattress-pillow', // Luxury linen type,
  235: 'tv', // International channels,
  236: 'jar', // Pantry,
  237: 'soap', // Dish-cleaning supplies,
  238: 'sink', // Double vanity,
  239: 'wand-magic-sparkles', // Lighted makeup mirror,
  240: 'spray-can-sparkles', // Upgraded bathroom amenities,
  241: 'cassette-tape', // VCR player available at front desk,
  242: 'hot-tub-person', // Instant hot water,
  243: 'house-tree', // Outdoor space,
  244: 'bath', // Hinoki tub,
  245: 'water-ladder', // Private pool,
  246: 'tv', // High Definition (HD) Flat Panel Television  - 32 inches or greater ,
  247: 'window-frame-open', // Room windows open,
  248: 'mattress-pillow', // Bedding type unknown or unspecified,
  249: 'bed-front', // Full bed,
  250: 'bed-front', // Round bed,
  251: 'tv', // TV,
  252: 'child', // Child rollaway,
  253: 'compact-disc', // DVD player available at front desk,
  254: 'gamepad-modern', // Video game player:                                      ,
  255: 'gamepad-modern', // Video game player available at front desk,
  256: 'chair', // Dining room seats,
  257: 'wand-magic-sparkles', // Full size mirror,
  258: 'mobile', // Mobile/cellular phones,
  259: 'film', // Movies,
  260: 'clothes-hanger', // Multiple closets,
  261: 'glass-empty', // Plates/glassware,
  262: 'vault', // Safe large enough to accommodate a laptop,
  263: 'mattress-pillow', // Bed linen thread count,
  264: 'booth-curtain', // Blackout curtain,
  265: 'compact-disc', // Bluray player,
  266: 'mp3-player', // Device with mp3,
  267: 'film', // No adult channels or adult channel lock,
  268: 'house', // Non-allergenic room,
  269: 'mattress-pillow', // Pillow type,
  270: 'loveseat', // Seating area with sofa/chair,
  271: 'toilet', // Separate toilet area,
  272: 'globe', // Web enabled,
  273: 'tv', // Widescreen TV,
  274: 'globe', // Other data connection,
  275: 'phone-volume', // Phoneline billed separately,
  276: 'bath', // Separate tub or shower,
  277: 'gamepad', // Video games,
  278: 'fan', // Roof ventilator,
  279: 'teddy-bear', // Children's playpen,
  280: 'water-ladder', // Plunge pool,
  5017: 'window-frame' // Balcony
}

export {
  CURRENCIES,
  CURRENCY_DEFAULT,
  DATE_TIME_FORMAT_DAY_LONG,
  DATE_TIME_FORMAT_DAY_SHORT,
  DATE_TIME_FORMAT_DAY_MONTH_LONG,
  ERROR_CODES,
  JSON_STUB,
  LANGUAGES,
  DATE_FORMAT,
  NOT_FOUND_REDIRECT_TIMEOUT,
  PAYMENT_METHOD_CODES,
  PRICE_ADDITIONAL_LENGTH,
  PRODUCT_DESC_MAX_LEN,
  RATE_DESC_MAX_LEN,
  ROOM_AMENITY_TYPE,
  SESSION_EXPIRATION_TIMEOUT,
  MIN_ADULTS,
  MAX_ADULTS,
  MIN_CHILDREN,
  MAX_CHILDREN,
  MIN_INFANTS,
  MAX_INFANTS,
  BOUNDS,
  COLOR_SCHEME,
  TRANSITION_TIME_LONG
}
