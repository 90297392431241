import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page__content checkout" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row row-margin" }
const _hoisted_4 = { class: "cell large-8 large-offset-2 module__checkout" }

import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import AppHeader from '@/components/Bundle/BundleHeader.vue'
import BundleLoader from '@/components/Bundle/Bundle.vue'


export default {
  __name: 'Bundle',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Bundle')
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "shine" }, null, -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(AppHeader),
          _createVNode(BundleLoader)
        ])
      ])
    ])
  ]))
}
}

}