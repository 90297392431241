import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module__heading" }
const _hoisted_2 = { class: "module__title" }
const _hoisted_3 = { class: "mb-0 text-h6" }


export default {
  __name: 'HeadingMobile',
  props: {
  title: {
    type: String,
    required: true
  }
},
  emits: ['clear', 'close'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const props = __props

return (_ctx, _cache) => {
  const _component_v_divider = _resolveComponent("v-divider")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "action__clear",
        onClick: _cache[0] || (_cache[0] = $event => (emit('clear')))
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(__props.title), 1 /* TEXT */)
      ]),
      _createElementVNode("div", {
        class: "action__close",
        onClick: _cache[1] || (_cache[1] = $event => (emit('close')))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "icon-close" }, null, -1 /* HOISTED */)
      ]))
    ]),
    _createVNode(_component_v_divider)
  ]))
}
}

}