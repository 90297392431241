import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "room__images" }
const _hoisted_2 = { class: "modal-title px-3 py-1 rounded" }
const _hoisted_3 = { class: "modal-slider mx-auto" }

import { getCurrentInstance, reactive, onMounted, type PropType } from 'vue'
import { isDesktop, isMobile } from '@/app/composables/useBreakpoints'
import { checkActiveSlide } from '@/app/system/helper'
import DefaultImage from '@/components/Common/DefaultImage.vue'
import Modal from '@/components/UI/Modal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageSlider',
  props: {
  height: {
    type: [Number, String],
    required: false,
    default: 'auto'
  },
  images: {
    type: Array as PropType<
      Array<{
        imageId: string
        imageUrl: string
        src: string
        srcset: string
        sizes: string
        lazySrc: string
      }>
    >,
    required: true
  },
  title: {
    type: String,
    required: true
  }
},
  setup(__props) {

const instance = getCurrentInstance()



const state: {
  carouselItemIndex: number
  showModal: boolean
} = reactive({
  carouselItemIndex: 0,
  showModal: false
})

onMounted(() => {
  checkActiveSlide()
})

function openModal() {
  state.showModal = true
  instance?.proxy.$root.toggleModal(1)
}

function closeModal() {
  state.showModal = false
  instance?.proxy.$root.toggleModal(0)
}

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.images.length)
      ? (_openBlock(), _createBlock(_component_v_carousel, {
          key: 0,
          modelValue: state.carouselItemIndex,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.carouselItemIndex) = $event)),
          height: _unref(isDesktop) && __props.height ? __props.height : 'auto',
          interval: state.showModal ? 40000 : 4000,
          "show-arrows": __props.images.length > 1 && 'hover',
          "hide-delimiters": __props.images.length <= 1,
          "hide-delimiter-background": ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images, (item) => {
              return (_openBlock(), _createBlock(_component_v_carousel_item, {
                key: item.imageId,
                onClick: openModal
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: "item.src",
                    "lazy-src": item.lazySrc,
                    sizes: item.sizes,
                    height: __props.height ?? 'auto',
                    srcset: item.srcset,
                    cover: ""
                  }, {
                    placeholder: _withCtx(() => [
                      _createVNode(_component_v_row, {
                        class: "fill-height ma-0",
                        align: "center",
                        justify: "center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_progress_circular, {
                            indeterminate: "",
                            color: "grey lighten-5"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["lazy-src", "sizes", "height", "srcset"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "height", "interval", "show-arrows", "hide-delimiters"]))
      : (_openBlock(), _createBlock(DefaultImage, {
          key: 1,
          class: "image__container",
          height: !_unref(isMobile) && __props.height ? __props.height : 'auto',
          "low-scale": ""
        }, null, 8 /* PROPS */, ["height"])),
    _withDirectives(_createVNode(Modal, {
      ref: "modalSlider",
      onClose: closeModal
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(__props.title), 1 /* TEXT */)
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_carousel, {
            modelValue: state.carouselItemIndex,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.carouselItemIndex) = $event)),
            "show-arrows": __props.images.length > 1 && 'hover',
            "hide-delimiters": __props.images.length <= 1,
            "hide-delimiter-background": "",
            height: "auto"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images, (item) => {
                return (_openBlock(), _createBlock(_component_v_carousel_item, {
                  key: item.imageUrl,
                  src: item.imageUrl,
                  "min-width": "100vw"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      src: "item.src",
                      class: "mx-auto",
                      "lazy-src": item.lazySrc,
                      srcset: item.srcset,
                      sizes: item.sizes,
                      width: _unref(isDesktop) ? '50vw' : '100vw'
                    }, {
                      placeholder: _withCtx(() => [
                        _createVNode(_component_v_row, {
                          class: "fill-height ma-0",
                          align: "center",
                          justify: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_progress_circular, {
                              indeterminate: "",
                              color: "grey lighten-5"
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["lazy-src", "srcset", "sizes", "width"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["src"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "show-arrows", "hide-delimiters"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, state.showModal]
    ])
  ]))
}
}

})