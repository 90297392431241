import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page__content customer-account" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppHeader = _resolveComponent("AppHeader")
  const _component_AccountMenu = _resolveComponent("AccountMenu")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "shine" }, null, -1 /* HOISTED */)),
    _createVNode(_component_AppHeader),
    _createVNode(_component_AccountMenu)
  ]))
}