import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-error text-center"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "count" }

import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'


import { mapGetters } from 'vuex'
import { event } from 'vue-gtag'
import localStorage from '@/app/system/mixins/localStorage'
import ReservationConfirmation from '@/components/Common/Confirmation.vue'
import OrderReceipt from '@/components/Order/OrderReceipt.vue'
import CompareMe from '@/components/Tracking/CompareMe123.vue'
import MyHotelShop from '@/components/Tracking/MyHotelShop.vue'
import TrackingByChannel from '@/components/Tracking/TrackingByChannel.vue'

const __default__ = {
  name: 'ReservationCheckoutConfirmation',
  components: {
    ReservationConfirmation,
    OrderReceipt,
    CompareMe,
    MyHotelShop,
    TrackingByChannel
  },
  mixins: [localStorage],
  computed: {
    ...mapGetters([
      'channelId',
      'ga4Purchase',
      'gaReservation',
      'isOwnChannel',
      'orderInfo',
      'paymentType',
      'bookingRequest'
    ]),
    reservation: (vm) => JSON.parse(vm.lsGet('reservation')),
    salutation: (vm) =>
      vm.orderInfo.userName
        ? `${vm.$t('Checkout#ThankYou#Salutation Dear')} ${
            vm.orderInfo.userName
          }`
        : `${vm.$t('Checkout#ThankYou#Salutation Dear guest')}`
  },
  mounted() {
    this.$nextTick(function () {
      this.clearAndRefreshSession()
    })
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Reservation Checkout Confirmation')
})

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(ReservationConfirmation, null, _createSlots({
    "confirmation-header": _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.salutation), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('Checkout#ThankYou Thank you for booking!')), 1 /* TEXT */)
    ]),
    "confirmation-body": _withCtx(() => [
      (!_ctx.reservation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('Checkout#DatatransPayment#Error Payment processing error')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (
          _ctx.reservation && (_ctx.paymentType === 'pay' || _ctx.reservation.intent === 'pay')
        )
        ? (_openBlock(), _createBlock(OrderReceipt, {
            key: 1,
            reservation: _ctx.reservation
          }, null, 8 /* PROPS */, ["reservation"]))
        : _createCommentVNode("v-if", true)
    ]),
    "confirmation-3rd-scripts": _withCtx(() => [
      (!!_ctx.orderInfo.reservationId)
        ? (_openBlock(), _createBlock(CompareMe, { key: 0 }))
        : _createCommentVNode("v-if", true),
      (!!_ctx.orderInfo.reservationId)
        ? (_openBlock(), _createBlock(MyHotelShop, { key: 1 }))
        : _createCommentVNode("v-if", true),
      (!!_ctx.orderInfo.reservationId && !_ctx.isOwnChannel)
        ? (_openBlock(), _createBlock(TrackingByChannel, {
            key: 2,
            trivagoConversionMethod: "confirmation"
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.reservation)
      ? {
          name: "confirmation-order-number",
          fn: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('Checkout#ThankYou Order number')), 1 /* TEXT */),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.reservation.id), 1 /* TEXT */)
          ]),
          key: "0"
        }
      : undefined
  ]), 1024 /* DYNAMIC_SLOTS */))
}
}

})