import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "room-facilities" }

import { computed } from 'vue'
import store from '@/app/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomFacilities',
  props: {
  items: {
    type: Array,
    required: true
  }
},
  setup(__props) {

const props = __props

const facilities = computed(() => store.getters.roomFacilitiesCodes)
const amenities = computed(() => {
  return props.items
    .filter(({ codeDetail, roomAmenityCode }) => codeDetail && roomAmenityCode)
    .map(({ codeDetail, roomAmenityCode }) => ({
      desc: codeDetail,
      code: facilities.value[roomAmenityCode] || ''
    }))
})

return (_ctx: any,_cache: any) => {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(amenities.value, (amenity, index) => {
      return (_openBlock(), _createBlock(_component_v_list_item, {
        class: "px-0",
        key: index
      }, {
        prepend: _withCtx(() => [
          _createVNode(_component_fa_icon, {
            class: "pr-2",
            icon: ['fat', amenity.code]
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        default: _withCtx(() => [
          (amenity.desc && amenity.code)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(amenity.desc), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})