import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "filter-item__content price-range" }
const _hoisted_2 = { class: "price-result" }
const _hoisted_3 = { class: "price-result__min" }
const _hoisted_4 = { class: "price-result__max" }
const _hoisted_5 = { class: "price-message" }
const _hoisted_6 = { class: "price-histogram" }
const _hoisted_7 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vue_slider = _resolveComponent("vue-slider")
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createBlock(_component_v_sheet, {
    elevation: $setup.isDesktop ? 1 : 0,
    class: "pa-4 mb-lg-8"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['filter-item', { 'is-active': $data.isExpanded }])
      }, [
        _createElementVNode("div", {
          class: "filter-item__title",
          onClick: _cache[0] || (_cache[0] = $event => ($data.isExpanded = !$data.isExpanded))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(($setup.isDesktop && $data.isExpanded) || !$setup.isDesktop ? 'mb-3' : 'mb-0')
          }, _toDisplayString(_ctx.$t('App#Filters#PriceRange#Title Price Range')), 3 /* TEXT, CLASS */)
        ]),
        _createVNode(_component_v_expand_transition, null, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString($options.priceResult.min), 1 /* TEXT */),
                _createElementVNode("span", _hoisted_4, _toDisplayString($options.priceResult.max), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('App#Filters#PriceRange Average nightly rate')), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.histogramOptions.columns, (i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: _normalizeClass([
                'price-histogram__column',
                { 'is-active': $options.isColumnActive(i) }
              ]),
                    style: _normalizeStyle({
                height: $data.histogramColumns[`col-${i}`]
                  ? $data.histogramColumns[`col-${i}`].perc + 'px'
                  : 0
              }),
                    onClick: $event => ($options.setColumnActive(i))
                  }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_7))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              ($data.isComponentReady)
                ? (_openBlock(), _createBlock(_component_vue_slider, {
                    key: 0,
                    ref: "slider",
                    modelValue: $data.sliderValue,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.sliderValue) = $event)),
                    min: $data.sliderMin,
                    max: $data.sliderMax,
                    height: 2,
                    dotSize: 16,
                    width: '100%',
                    tooltip: 'hover',
                    tooltipPlacement: ['bottom', 'top'],
                    tooltipFormatter: $options.formatTooltip
                  }, null, 8 /* PROPS */, ["modelValue", "min", "max", "tooltipFormatter"]))
                : _createCommentVNode("v-if", true)
            ], 512 /* NEED_PATCH */), [
              [_vShow, $data.isExpanded]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["elevation"]))
}