import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block__title"
}
const _hoisted_2 = { class: "block__content" }
const _hoisted_3 = {
  class: "content-inner",
  "data-role": "content-inner"
}
const _hoisted_4 = {
  key: 1,
  class: "text"
}
const _hoisted_5 = {
  key: 2,
  class: "products block__accordion"
}
const _hoisted_6 = { class: "item__info" }
const _hoisted_7 = { class: "item__title rate-plan__name" }
const _hoisted_8 = { class: "item__price" }

import { computed, ref, getCurrentInstance, watch, type Ref } from 'vue'
import { chain, filter } from 'lodash'
import { mapGetters } from 'vuex'
import { useGtm } from '@gtm-support/vue-gtm'
import { isMobile } from '@/app/composables/useBreakpoints'
import { formatDate, formatPrice, getMaxLength } from '@/app/system/helper'
import { PRICE_ADDITIONAL_LENGTH } from '@/app/constants'
import store from '@/app/store'

import BaseAccordion from '@/components/UI/BaseAccordion.vue'
import PackageRoomDescription from './PackageRoomDescription.vue'
import ProductCancellationPolicy from '@/components/Rooms/RoomCancellationPolicy.vue'
import PriceDetails from '@/components/Rooms/RoomPriceDetails.vue'
import PriceSeparated from '@/components/Common/Price/PriceSeparated.vue'
import Price from '@/components/Common/Price/Price.vue'
import ButtonAnimated from '@/components/UI/Controls/ButtonAnimated.vue'
import Loader from '@/components/UI/Loader.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PackageRoomsDetails',
  props: {
  hotelPackageId: {
    type: String,
    required: true
  }
},
  setup(__props) {

const instance = getCurrentInstance()
const gtm = useGtm()

const props = __props

const isExpanded: Ref<boolean> = ref(false)

const hotelPackageRooms = computed(() =>
  store.getters.getRoomsByHotelPackageId(props.hotelPackageId)
)

const hotelPackage = computed(() =>
  store.getters.getHotelPackageById(props.hotelPackageId)
)

const longestPriceString = computed(() =>
  getMaxLength([
    formatPrice(
      chain(hotelPackageRooms.value.map((room) => room.packages[0]))
        .map((product) => product.totalPrice)
        .max()
        .value(),
      { currency: store.getters.currency }
    ) + PRICE_ADDITIONAL_LENGTH
  ])
)

const ga4HotelAttributes = computed(() => store.getters.ga4HotelAttributes)

const bookRoom = (product) => {
  const {
    hotelPackageId,
    cancellationPolicy,
    totalPrice,
    totalDiscount,
    hotelTotalDiscount,
    ratePlanId = '',
    discount
  } = product.packages[0]

  store.commit('UPDATE_CURRENT_ROOM_STAY', {
    isPackage: true,
    hasServices: false,
    hotelPackageId: hotelPackage.value.id,
    hotelPackageName: hotelPackage.value.name,
    cancellationPenaltyId: cancellationPolicy,
    guaranteeType: hotelPackage.value.guaranteeType,
    nbNights: store.getters.orderInfo.datesDiff,
    room: {
      roomTypeName: store.getters.roomInfoById(product.guestRoomId).roomTypeName
    },
    guestRoomId: product.guestRoomId,
    reference: product.packages[0].reference,
    currency: store.getters.currency,
    ratePlanId,
    hotelTotalDiscount,
    totalPrice,
    totalDiscount: totalDiscount ?? discount
  })

  const order = { ...store.getters.currentRoomStay }
  order.startDate = formatDate(order.startDate)
  order.endDate = formatDate(order.endDate)

  store.commit('ADD_ORDER_TO_ROOM_STAY', {
    order,
    roomStayIndex: store.getters.currentRoom
  })

  instance.proxy.goToServices(product.guestRoomId)
}

watch(
  hotelPackage,
  () => {
    gtm.trackEvent({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_category: 'package',
            item_category2: hotelPackage.value?.id,
            item_variant: hotelPackage.value?.name,
            ...ga4HotelAttributes.value
          }
        ]
      }
    })
  },
  { immediate: true, deep: true }
)

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["room__info-block", ['mt-5', { 'is-active': isExpanded.value }]]),
    style: {"width":"100%"}
  }, [
    (!_unref(store).getters.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "title d-flex justify-space-between mb-3",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isExpanded.value = !isExpanded.value))
          }, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t('SinglePackageView#PackageView#Title Select rooms for stay')), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_unref(store).getters.loading)
          ? (_openBlock(), _createBlock(Loader, {
              key: 0,
              class: "my-3",
              absolute: false
            }))
          : (hotelPackageRooms.value.length < 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                  'SingleRoomView#RoomView#Available rooms No rooms available for these dates'
                )), 1 /* TEXT */)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hotelPackageRooms.value, (product) => {
                  return (_openBlock(), _createBlock(BaseAccordion, {
                    key: product.guestRoomId
                  }, {
                    info: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(product.room.roomTypeName), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(Price, {
                            price: product.packages[0].totalPrice,
                            discount: product.packages[0].discount,
                            numNights: product.packages[0].nbNights
                          }, {
                            default: _withCtx(({ price }) => [
                              _createVNode(PriceSeparated, {
                                price: price,
                                width: !_unref(isMobile) ? longestPriceString.value : 5,
                                "big-font": !_unref(isMobile)
                              }, null, 8 /* PROPS */, ["price", "width", "big-font"])
                            ]),
                            "price-old": _withCtx(({ price }) => [
                              _createVNode(PriceSeparated, {
                                price: price,
                                width: !_unref(isMobile) ? longestPriceString.value : 5,
                                "big-font": !_unref(isMobile)
                              }, null, 8 /* PROPS */, ["price", "width", "big-font"])
                            ]),
                            "price-discount": _withCtx(({ price }) => [
                              _createVNode(PriceSeparated, {
                                price: price,
                                width: !_unref(isMobile) ? longestPriceString.value : 5,
                                "big-font": !_unref(isMobile)
                              }, null, 8 /* PROPS */, ["price", "width", "big-font"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["price", "discount", "numNights"])
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: "products__item__btn actions-toolbar",
                        onClick: _withModifiers(() => {}, ["prevent","stop"])
                      }, [
                        _createVNode(_component_v_btn, {
                          class: "text-none",
                          color: "primary",
                          ripple: false,
                          size: "large",
                          block: "",
                          tile: "",
                          onClick: ($event: any) => (bookRoom(product))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('General#Button Book now')), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            lg: "6",
                            md: "8",
                            sm: "8",
                            xs: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(PackageRoomDescription, {
                                "multimedia-descriptions": 
                        product.room.multimediaDescriptions
                      ,
                                "modal-title": 
                        product.room.roomTypeName || hotelPackage.value.name
                      
                              }, null, 8 /* PROPS */, ["multimedia-descriptions", "modal-title"]),
                              _createVNode(PriceDetails, {
                                item: {
                        ...product.packages[0],
                        hotelPackageName: hotelPackage.value.name
                      }
                              }, null, 8 /* PROPS */, ["item"]),
                              _createVNode(ProductCancellationPolicy, {
                                roomId: product.guestRoomId,
                                ratePlanId: hotelPackage.value.ratePlanId,
                                "init-on": "mobile"
                              }, null, 8 /* PROPS */, ["roomId", "ratePlanId"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
      ])
    ])
  ], 2 /* CLASS */))
}
}

})