import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "app-carousel room__images" }
const _hoisted_2 = { class: "modal-title px-3 py-1 rounded" }
const _hoisted_3 = { class: "modal-slider mx-auto" }

import { getCurrentInstance, reactive, computed, type PropType } from 'vue'
import { isDesktop, isMobile } from '@/app/composables/useBreakpoints'
import DefaultImage from '@/components/Common/DefaultImage.vue'
import DefaultPic from '@/app/static/images/no-photo.png'
import thumbnail from '@/app/static/images/thumbnail.jpg'
import Modal from '@/components/UI/Modal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomImages',
  props: {
  carouselHeight: {
    type: Number,
    required: false,
    default: 300
  },
  images: {
    type: Array as PropType<
      Array<{
        imageId: string
        imageUrl: string
        src: string
        srcset: string
        sizes: string
        lazySrc: string
      }>
    >,
    required: true
  },
  lazy: {
    type: Boolean,
    required: false,
    default: true
  },
  linkTo: {
    type: Object as PropType<{
      name: 'room' | 'package'
      params: {
        roomId?: 'string'
        packageId?: 'string'
      }
    }>,
    required: false,
    default: null
  },
  modalTitle: {
    type: String,
    required: false,
    default: ''
  }
},
  setup(__props) {

const instance = getCurrentInstance()



const state: {
  showModal: boolean
} = reactive({
  showModal: false
})

const defaultPic = computed(() => DefaultPic)

function openModal() {
  state.showModal = true
  instance.proxy.$root.toggleModal(1)
}

function closeModal() {
  state.showModal = false
  instance.proxy.$root.toggleModal(0)
}

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.images.length)
      ? (_openBlock(), _createBlock(_component_v_carousel, {
          key: 0,
          height: __props.carouselHeight,
          "show-arrows": __props.images.length > 1 && 'hover',
          "hide-delimiters": __props.images.length <= 1,
          "hide-delimiter-background": ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images.slice(0, 5), (item) => {
              return (_openBlock(), _createBlock(_component_v_carousel_item, {
                key: item.imageId,
                to: { ...__props.linkTo },
                transition: "false",
                "reverse-transition": "false",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.linkTo ? null : openModal()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: item.src,
                    "lazy-src": item.lazySrc,
                    height: __props.carouselHeight,
                    cover: ""
                  }, {
                    placeholder: _withCtx(() => [
                      _createVNode(_component_v_row, {
                        class: "fill-height ma-0",
                        align: "center",
                        justify: "center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_progress_circular, {
                            indeterminate: "",
                            color: "grey lighten-5"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["src", "lazy-src", "height"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["height", "show-arrows", "hide-delimiters"]))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: { ...__props.linkTo }
        }, {
          default: _withCtx(() => [
            _createVNode(DefaultImage, {
              "low-scale": !_unref(isMobile),
              height: __props.carouselHeight
            }, null, 8 /* PROPS */, ["low-scale", "height"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])),
    _withDirectives(_createVNode(Modal, {
      ref: "modalSlider",
      onClose: closeModal
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(__props.modalTitle), 1 /* TEXT */)
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_carousel, {
            "hide-delimiter-background": "",
            height: _unref(isDesktop) ? '100vh' : 'auto'
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images, (item) => {
                return (_openBlock(), _createBlock(_component_v_carousel_item, {
                  key: item.src,
                  src: item.src,
                  "lazy-src": defaultPic.value,
                  width: "100vw"
                }, null, 8 /* PROPS */, ["src", "lazy-src"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["height"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, state.showModal]
    ])
  ]))
}
}

})