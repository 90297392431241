import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "module__info-line with-book-button",
  ref: "blockMain"
}
const _hoisted_2 = { class: "info-line__control mb-5" }
const _hoisted_3 = { class: "actions-toolbar back-link" }
const _hoisted_4 = { class: "action tertiary" }
const _hoisted_5 = {
  class: "form form-search-rooms datesearch-for-single-room",
  ref: "blockDate"
}
const _hoisted_6 = { class: "module__content" }
const _hoisted_7 = { class: "field__control" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "label__title" }
const _hoisted_10 = { class: "my-1" }
const _hoisted_11 = { class: "field__control" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "label__title" }

import { isMobile, isDesktop } from '@/app/composables/useBreakpoints'

import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import DateRangePicker from '@/components/Search/Fields/DateRangePicker.vue'
import modals from '@/app/system/mixins/modals'
import Date from '@/components/Search/Fields/Date.vue'
import ButtonBookNow from '@/components/UI/Controls/ButtonBookNow.vue'

const __default__ = {
  components: {
    ButtonBookNow,
    DateRangePicker,
    DateForMobile: Date
  },
  mixins: [modals],
  props: {
    roomId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      value: {
        start: null,
        checkOut: null
      },
      isDateModalShown: false
    }
  },
  computed: {
    ...mapGetters(['currentRoomStay']),
    nbGuests: ({
      guestsCountString,
      currentRoomStay: { nbAdults, nbChildren, nbInfants }
    }) => guestsCountString(nbAdults, nbChildren, nbInfants),
    dates() {
      return {
        end: this.currentRoomStay.endDate,
        start: this.currentRoomStay.startDate
      }
    }
  },
  methods: {
    applyDates(dates) {
      this.getAvailabilitiesWithUpdatedDates(dates)
    },
    updateDates() {
      this.UPDATE_BOOKING_REQUEST({
        checkIn: this.dates.start,
        checkOut: this.dates.end
      })
      this.submitSearchInfo()
      this.$store.dispatch('getRoomsAvailabilities')
    },
    toggleDatesModal() {
      this.isDateModalShown =
        !this.$refs.modalDates.$el.classList.contains('is-active')

      // TODO: test
      if (this.isDateModalShown) {
        this.updateDates()
      }

      this.$_toggleModal('dates', this.$refs.modalDates)
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'InfoLine',
  setup(__props) {


return (_ctx, _cache) => {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_router_link = _resolveComponent("router-link")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_fa_icon = _resolveComponent("fa-icon")
  const _component_DateForMobile = _resolveComponent("DateForMobile")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "icon-back",
        to: { name: 'home' }
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_v_btn, {
            class: "icon-back",
            variant: "text",
            ripple: false
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "icon-angle-left" }, null, -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          }, 512 /* NEED_PATCH */), [
            [_vShow, !_unref(isMobile)]
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "info-line__heading" }, null, -1 /* HOISTED */))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, { to: { name: 'home' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Checkout#ViewOrder#Button Change')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, _unref(isMobile)]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createVNode(_TransitionGroup, {
        class: "fieldset",
        tag: "div",
        name: "inputs"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            class: "field date no-scroll_dates",
            key: _ctx.nbGuests
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(DateRangePicker, {
                class: "mr-3",
                range: _ctx.dates,
                "start-date": _ctx.currentRoomStay.startDate,
                "end-date": _ctx.currentRoomStay.endDate,
                key: "dateRangePicker",
                onInput: _cache[0] || (_cache[0] = $event => (_ctx.applyDates($event)))
              }, null, 8 /* PROPS */, ["range", "start-date", "end-date"]),
              _createVNode(_component_v_divider, { vertical: "" }),
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["field__item number_guests", { 'ml-3': _unref(isDesktop), 'mt-4': !_unref(isDesktop) }])
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[4] || (_cache[4] = _createElementVNode("i", { class: "icon-person" }, null, -1 /* HOISTED */)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('Search#Results#Header Guests')), 1 /* TEXT */)
                      ]),
                      _createElementVNode("div", {
                        class: "text-body-1 px-4 d-flex align-center theme-container",
                        style: _normalizeStyle(`height: ${_unref(isDesktop) ? 53 : 36}px`)
                      }, _toDisplayString(_ctx.nbGuests), 5 /* TEXT, STYLE */)
                    ])
                  ])
                ])
              ], 2 /* CLASS */), [
                [_vShow, _unref(isDesktop)]
              ])
            ])
          ]))
        ]),
        _: 1 /* STABLE */
      })
    ], 512 /* NEED_PATCH */), [
      [_vShow, _unref(isDesktop)]
    ]),
    _withDirectives(_createVNode(_component_v_btn, {
      class: "theme-container",
      variant: "outlined",
      block: "",
      tile: "",
      onClick: _ctx.toggleDatesModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fa_icon, { icon: ['fat', 'calendar'] }),
        _createTextVNode(" " + _toDisplayString(_ctx.dates.start) + "  ", 1 /* TEXT */),
        _createVNode(_component_fa_icon, { icon: ['fat', 'arrow-right'] }),
        _createTextVNode(" " + _toDisplayString(_ctx.dates.end), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onClick"]), [
      [_vShow, !_unref(isDesktop)]
    ]),
    (!_unref(isDesktop))
      ? (_openBlock(), _createBlock(_component_DateForMobile, {
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.value) = $event)),
          key: "modalDates",
          ref: "modalDates",
          onToggleDates: _ctx.toggleDatesModal
        }, null, 8 /* PROPS */, ["modelValue", "onToggleDates"]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["field__item number_guests", { 'ml-3': _unref(isDesktop), 'mt-4': !_unref(isDesktop) }])
    }, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[5] || (_cache[5] = _createElementVNode("i", { class: "icon-person" }, null, -1 /* HOISTED */)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Search#Results#Header Guests')), 1 /* TEXT */),
            _createElementVNode("div", {
              class: "text-body-1 px-4 mt-4 d-flex align-center theme-container",
              style: _normalizeStyle(`height: ${_unref(isDesktop) ? 52 : 36}px`)
            }, _toDisplayString(_ctx.nbGuests), 5 /* TEXT, STYLE */)
          ])
        ])
      ])
    ], 2 /* CLASS */), [
      [_vShow, !_unref(isDesktop)]
    ])
  ], 512 /* NEED_PATCH */))
}
}

})