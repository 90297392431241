import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-menu" }

import { ref, computed } from 'vue'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'
import CustomerAccountMenu from '@/components/CustomerAccount/CustomerAccountMenu.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuMobile',
  setup(__props) {

const { mdAndUp, width } = useDisplay()
const store = useStore()
const showDrawer = ref<boolean>(false)

const drawerWidth = computed<number>(() => {
  if (mdAndUp.value) {
    return width.value * 0.4
  } else {
    return width.value
  }
})

return (_ctx: any,_cache: any) => {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).getters.customization.bgUrl)
      ? (_openBlock(), _createBlock(_component_v_sheet, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_app_bar_nav_icon, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (showDrawer.value = true))
            })
          ]),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (showDrawer.value = true))
        })),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: showDrawer.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showDrawer).value = $event)),
      temporary: "",
      width: drawerWidth.value
    }, {
      default: _withCtx(() => [
        _createVNode(CustomerAccountMenu, {
          active: showDrawer.value,
          onCloseMenu: _cache[2] || (_cache[2] = ($event: any) => (showDrawer.value = false))
        }, null, 8 /* PROPS */, ["active"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "width"])
  ]))
}
}

})