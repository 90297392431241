import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block__content" }
const _hoisted_2 = { class: "text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('SingleRoomView#Time#CheckIn Check-in: from {timeCheckIn} to {toCheckInTime}', {timeCheckIn: _ctx.fromCheckInTime, toCheckInTime: _ctx.toCheckInTime})), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('SingleRoomView#Time#CheckOut Check-out: from {fromCheckOutTime} to {toCheckOutTime}', {fromCheckOutTime: _ctx.fromCheckOutTime, toCheckOutTime: _ctx.toCheckOutTime})), 1 /* TEXT */)
    ])
  ]))
}