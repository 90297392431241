import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-5" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "count" }

import { getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useHead } from '@unhead/vue'
import ReservationHeader from '@/components/Reservation/ReservationHeader.vue'
import ReservationConfirmation from '@/components/Common/Confirmation.vue'
import TrackingByChannel from '@/components/Tracking/TrackingByChannel.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservationCancelConfirmation',
  setup(__props) {

const { proxy } = getCurrentInstance()

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Reservation Cancel Confirmation')
})

const store = useStore()

onMounted(() => {
  proxy.clearAndRefreshSession()
})

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            md: "12",
            lg: "8",
            class: "pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(ReservationHeader)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "6",
            class: "pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(ReservationConfirmation, null, {
                "confirmation-header": _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)(
                  'Cancellation#Confirmation Your reservation has been cancelled!'
                )), 1 /* TEXT */)
                ]),
                "confirmation-order-number": _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('Cancellation#Confirmation Order number')), 1 /* TEXT */),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$route.params.reservationId), 1 /* TEXT */)
                ]),
                default: _withCtx(() => [
                  (!_unref(store).getters.isOwnChannel)
                    ? (_openBlock(), _createBlock(TrackingByChannel, {
                        key: 0,
                        slot: "confirmation-3rd-scripts",
                        trivagoConversionMethod: "cancellation",
                        cancellation: ""
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})