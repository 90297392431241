import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "customization__form" }
const _hoisted_2 = { class: "block__title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "d-flex row small-align-justify customization__row" }
const _hoisted_5 = { class: "customization__block customization__block--preview" }
const _hoisted_6 = { class: "customization__title" }
const _hoisted_7 = { class: "fieldset__item d-flex my-4" }
const _hoisted_8 = { class: "text-h5 mr-4" }
const _hoisted_9 = { class: "no-theme title" }
const _hoisted_10 = { class: "text-h5 mb-4" }
const _hoisted_11 = { class: "no-theme title" }
const _hoisted_12 = {
  class: "d-flex justify-space-between align-center",
  style: {"width":"100%"}
}
const _hoisted_13 = { class: "fieldset" }
const _hoisted_14 = { class: "mb-5" }
const _hoisted_15 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_16 = {
  key: 1,
  class: "mb-6"
}
const _hoisted_17 = { class: "fieldset__item mb-6" }
const _hoisted_18 = { class: "text-h5 mb-4" }
const _hoisted_19 = { class: "no-theme title" }
const _hoisted_20 = { class: "fieldset__item" }
const _hoisted_21 = { class: "text-h5 mb-4" }
const _hoisted_22 = { class: "no-theme title" }

import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { debounce } from 'lodash'
import Swal from 'sweetalert2'
import { isDesktop, isTablet, isMobile } from '@/app/composables/useBreakpoints'
import { COLOR_SCHEME } from '@/app/constants'
import store from '@/app/store'

import AllRooms from '@/views/AllRooms.vue'
import fieldColor from '@/components/UI/Controls/Inputs/fieldColor.vue'
import fieldRadioGroup from '@/components/UI/Controls/Inputs/fieldRadioGroup.vue'
import fieldSwitcher from '@/components/UI/Controls/Inputs/fieldSwitcher.vue'
import CustomizationFontDropdown from './CustomizationFontDropdown.vue'
import InputImage from '@/components/UI/Controls/Inputs/InputImage.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomizationForm',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Customization')
})

const isFormShow = ref(false)
const isFontsConfigExpanded = ref(false)

const fontSizes = [{ value: 'small' }, { value: 'medium' }, { value: 'large' }]

const customization = computed(() => store.state.customization)

onMounted(() => {
  document.querySelector('body').style.background = '#ffffff'
  store.dispatch('getConfigCustomization')
})

onBeforeUnmount(() => {
  document.querySelector('body').style.backgroundColor = ''
})

function debounceLoadCustomization() {
  debounce(() => {
    store.dispatch('getConfigCustomization')
  }, 1000)()
}

function debounceUpdateField(field, value) {
  debounce(() => {
    updateField(field, value)
  }, 500)()
}

function updateField(field, value) {
  store.commit('UPDATE_CUSTOMIZATION_CONFIG', { [field]: value })
}

function resetCustomization() {
  debounce(() => {
    const config = customization.value.isDarkMode
      ? COLOR_SCHEME.dark
      : COLOR_SCHEME.light
    store.commit('UPDATE_CUSTOMIZATION_CONFIG', config)
    store.dispatch('sendCustomizationData')
  }, 500)()
}

async function confirmResetCustomization() {
  const { isConfirmed } = await Swal.fire({
    icon: 'warning',
    title: t('CustomizationForm#Reset#Title Reset customization'),
    text: t(
      'CustomizationForm#Reset#Text You are about to reset all your customization config. This action is irreversible. Are you sure you want to reset your customization?'
    ),
    showCancelButton: true,
    cancelButtonText: t('App#Button Cancel'),
    confirmButtonText: t('CustomizationForm#Reset#Confirm Yes, reset')
  })
  if (isConfirmed) {
    resetCustomization()
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      class: "text-none customization__toggle",
      color: "primary",
      ripple: false,
      rounded: "0",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isFormShow.value = !isFormShow.value))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_unref(t)('CustomizationForm#Toggle form')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_unref(t)('CustomizationForm#Title Customization form')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "disable-overlay" }, null, -1 /* HOISTED */)),
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_unref(t)('CustomizationForm#Subtitle Your booking engine')), 1 /* TEXT */),
        _createVNode(AllRooms)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([
          'customization__block customization__block--form',
          { hide: isFormShow.value, 'w-100': _unref(isMobile) }
        ])
      }, [
        _createVNode(_component_v_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "px-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)('CustomizationForm#Mode Dark mode')), 1 /* TEXT */)
                      ]),
                      _createVNode(fieldSwitcher, {
                        value: customization.value.isDarkMode,
                        onInput: _cache[1] || (_cache[1] = (value) => updateField('isDarkMode', value))
                      }, null, 8 /* PROPS */, ["value"])
                    ]),
                    _createVNode(_component_v_divider),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).getters.customizationColorFields, (value, name) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: name,
                        class: "fieldset__item mb-4"
                      }, [
                        (name === 'colorBtnBg')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)('CustomizationForm#Field colorPrimary')), 1 /* TEXT */)
                              ]),
                              _createVNode(fieldColor, {
                                "color-default": customization.value[name],
                                onColorChange: (value) => debounceUpdateField(name, value)
                              }, null, 8 /* PROPS */, ["color-default", "onColorChange"]),
                              _createVNode(_component_v_divider, { class: "mt-6" })
                            ], 64 /* STABLE_FRAGMENT */))
                          : _createCommentVNode("v-if", true)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */)),
                    _createVNode(_component_v_btn, {
                      class: _normalizeClass([
                  'text-none mt-6',
                  {
                    'mb-6': _unref(isDesktop) && !isFontsConfigExpanded.value,
                    'mb-3': isFontsConfigExpanded.value,
                    'my-6': !_unref(isDesktop)
                  }
                ]),
                      color: "primary",
                      ripple: false,
                      block: "",
                      size: "large",
                      variant: "outlined",
                      rounded: "0",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (isFontsConfigExpanded.value = !isFontsConfigExpanded.value))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", {
                            class: _normalizeClass({
                      'text-h5': _unref(isDesktop),
                      'text-body-1 font-weight-bold': !_unref(isDesktop)
                    })
                          }, _toDisplayString(_unref(t)('CustomizationForm#FontsAccordion Fonts customization')), 3 /* TEXT, CLASS */),
                          _createElementVNode("i", {
                            class: _normalizeClass(["d-block icon-angle-down ml-3", { 'rotate-180': isFontsConfigExpanded.value }]),
                            style: {"font-size":"0.5rem"}
                          }, null, 2 /* CLASS */)
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"]),
                    _createVNode(_component_v_expand_transition, null, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["no-theme mb-3", {
                        'text-h6': _unref(isDesktop),
                        'text-body-2': !_unref(isDesktop)
                      }])
                            }, _toDisplayString(_unref(t)('CustomizationForm#PrimaryFont Primary font')), 3 /* TEXT, CLASS */),
                            (customization.value.fontFamilyPrimary)
                              ? (_openBlock(), _createBlock(CustomizationFontDropdown, {
                                  key: 0,
                                  "selected-font": customization.value.fontFamilyPrimary,
                                  onInput: _cache[3] || (_cache[3] = 
                        (value) => updateField('fontFamilyPrimary', value)
                      )
                                }, null, 8 /* PROPS */, ["selected-font"]))
                              : _createCommentVNode("v-if", true),
                            _createVNode(fieldRadioGroup, {
                              data: {
                        default: customization.value.fontSizePrimary,
                        list: fontSizes
                      },
                              onChangeValue: _cache[4] || (_cache[4] = 
                        (value) => updateField('fontSizePrimary', value)
                      )
                            }, null, 8 /* PROPS */, ["data"])
                          ]),
                          (customization.value.fontFamilyBtn)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["no-theme mb-3", {
                        'text-h6': _unref(isDesktop),
                        'text-body-2': !_unref(isDesktop)
                      }])
                                }, _toDisplayString(_unref(t)('CustomizationForm#ButtonFont Button font')), 3 /* TEXT, CLASS */),
                                _createVNode(CustomizationFontDropdown, {
                                  "selected-font": customization.value.fontFamilyBtn,
                                  onInput: _cache[5] || (_cache[5] = (value) => updateField('fontFamilyBtn', value))
                                }, null, 8 /* PROPS */, ["selected-font"]),
                                _createVNode(fieldRadioGroup, {
                                  data: {
                        default: customization.value.fontSizeBtn,
                        list: fontSizes
                      },
                                  onChange: _cache[6] || (_cache[6] = (value) => updateField('fontSizeBtn', value))
                                }, null, 8 /* PROPS */, ["data"])
                              ]))
                            : _createCommentVNode("v-if", true),
                          (customization.value.fontFamilyTitle)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["no-theme mb-3", {
                        'text-h6': _unref(isDesktop),
                        'text-body-2': !_unref(isDesktop)
                      }])
                                }, _toDisplayString(_unref(t)('CustomizationForm#TitleFont Title font')), 3 /* TEXT, CLASS */),
                                _createVNode(CustomizationFontDropdown, {
                                  "selected-font": customization.value.fontFamilyTitle,
                                  onInput: _cache[7] || (_cache[7] = (value) => updateField('fontFamilyTitle', value))
                                }, null, 8 /* PROPS */, ["selected-font"]),
                                _createVNode(fieldRadioGroup, {
                                  data: {
                        default: customization.value.fontSizeTitle,
                        list: fontSizes
                      },
                                  onChange: _cache[8] || (_cache[8] = (value) => updateField('fontSizeTitle', value))
                                }, null, 8 /* PROPS */, ["data"])
                              ]))
                            : _createCommentVNode("v-if", true)
                        ], 512 /* NEED_PATCH */), [
                          [_vShow, isFontsConfigExpanded.value]
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_divider, { class: "mb-4" }),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(t)('CustomizationForm#Logo Select new logo')), 1 /* TEXT */)
                      ]),
                      _createVNode(InputImage, {
                        "image-url": customization.value.logoUrl,
                        dimensions: { w: 600, h: 600 },
                        onChange: _cache[9] || (_cache[9] = ($event: any) => (
                    _unref(store).dispatch('uploadImageKitFile', {
                      id: 'logoUrl',
                      ...$event
                    })
                  ))
                      }, null, 8 /* PROPS */, ["image-url"])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(t)(
                        'CustomizationForm#BackgroundImage Select background image'
                      )), 1 /* TEXT */)
                      ]),
                      _createVNode(InputImage, {
                        "image-url": customization.value.bgUrl,
                        removable: Boolean(customization.value.bgUrl),
                        dimensions: { w: 1280, h: 1024 },
                        onChange: _cache[10] || (_cache[10] = ($event: any) => (
                    _unref(store).dispatch('uploadImageKitFile', {
                      id: 'bgUrl',
                      ...$event
                    })
                  )),
                        onRemove: _cache[11] || (_cache[11] = ($event: any) => (
                    _unref(store).commit('UPDATE_CUSTOMIZATION_CONFIG', {
                      bgUrl: ''
                    })
                  ))
                      }, null, 8 /* PROPS */, ["image-url", "removable"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, { class: "px-2 mb-1" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_divider, { class: "mb-4" }),
                    _createVNode(_component_v_btn, {
                      class: "text-none my-4",
                      color: "primary",
                      loading: _unref(store).getters.loading,
                      disabled: _unref(store).getters.loading,
                      ripple: false,
                      block: "",
                      rounded: "0",
                      size: "x-large",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_unref(store).dispatch('sendCustomizationData')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('CustomizationForm#Save Save')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["loading", "disabled"]),
                    _createVNode(_component_v_btn, {
                      class: "text-none mb-4",
                      color: "primary",
                      loading: _unref(store).getters.loading,
                      disabled: _unref(store).getters.loading,
                      ripple: false,
                      block: "",
                      variant: "outlined",
                      rounded: "0",
                      size: "x-large",
                      onClick: _withModifiers(debounceLoadCustomization, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('CustomizationForm#Button Reload')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["loading", "disabled"]),
                    _createVNode(_component_v_btn, {
                      class: "text-none",
                      color: "primary",
                      loading: _unref(store).getters.loading,
                      disabled: _unref(store).getters.loading,
                      ripple: false,
                      variant: "outlined",
                      rounded: "0",
                      size: "x-large",
                      block: "",
                      onClick: _withModifiers(confirmResetCustomization, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('CustomizationForm#Button Factory reset')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["loading", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ], 2 /* CLASS */)
    ])
  ]))
}
}

})