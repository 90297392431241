import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_portal_target = _resolveComponent("portal-target")
  const _component_v_footer = _resolveComponent("v-footer")

  return (_openBlock(), _createBlock(_component_v_footer, {
    fixed: "",
    padless: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_portal_target, { name: "fixed-footer" })
    ]),
    _: 1 /* STABLE */
  }))
}