import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelDynamic as _vModelDynamic, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["type"]

import { ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseInput',
  props: /*@__PURE__*/_mergeModels({
    type: {},
    modelValue: {},
    maxLength: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['update:modelValue', 'change'], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const size = (value: string | number): number => String(value).length

const props = __props

const emit = __emit

const model = _useModel<string>(__props, "modelValue")

const inputKey = ref(0)

// Watch for changes in the type prop and force an update
watch(
  () => props.type,
  () => {
    forceUpdate()
  }
)

const onInput = (newValue: string) => {
  // If maxLength is defined and not exceeded, update model
  if (props.maxLength === undefined || props.maxLength >= size(newValue)) {
    model.value = newValue
  }

  // Emit the updated value for v-model (update:modelValue)
  emit('update:modelValue', model.value)
}

const onChange = (newValue: string) => {
  // Emit the change event with the new value
  emit('change', newValue)
}

const forceUpdate = () => {
  inputKey.value += 1
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    key: inputKey.value,
    type: _ctx.type,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    onInput: _cache[1] || (_cache[1] = ($event: any) => (onInput(($event.target as HTMLInputElement).value))),
    onChange: _cache[2] || (_cache[2] = ($event: any) => (onChange(($event.target as HTMLInputElement).value)))
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)), [
    [_vModelDynamic, model.value]
  ])
}
}

})