import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "actions-toolbar mt-3"
}
const _hoisted_2 = { class: "actions-toolbar stick-to-bottom" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_compare_me = _resolveComponent("compare-me")
  const _component_room_search = _resolveComponent("room-search")
  const _component_room_filters = _resolveComponent("room-filters")
  const _component_v_col = _resolveComponent("v-col")
  const _component_dates = _resolveComponent("dates")
  const _component_mobile_heading = _resolveComponent("mobile-heading")
  const _component_guests = _resolveComponent("guests")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_promo_codes = _resolveComponent("promo-codes")
  const _component_filters_toolbar = _resolveComponent("filters-toolbar")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_rooms_list = _resolveComponent("rooms-list")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createBlock(_component_v_row, { class: "mt-lg-16 mb-lg-10" }, {
    default: _withCtx(() => [
      (_ctx.availabilitiesLoaded && _ctx.filteredRooms?.length > 0)
        ? (_openBlock(), _createBlock(_component_compare_me, { key: 0 }))
        : _createCommentVNode("v-if", true),
      ($setup.isDesktop)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 1,
            lg: "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_room_search, {
                modelValue: _ctx.bookingRequest,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.bookingRequest) = $event)),
                onSubmit: $options.submit
              }, null, 8 /* PROPS */, ["modelValue", "onSubmit"]),
              _createVNode(_component_room_filters)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (!$setup.isDesktop)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_room_filters, {
              ref: "modalFilters",
              active: $data.filtersActiveState,
              onToggleFilters: _cache[1] || (_cache[1] = $event => ($options.toggleModal('filters', _ctx.$refs.modalFilters)))
            }, null, 8 /* PROPS */, ["active"]),
            _createVNode(_component_dates, {
              ref: "modalDates",
              active: $data.datesActiveState,
              onToggleDates: _cache[2] || (_cache[2] = $event => ($options.toggleModal('dates', _ctx.$refs.modalDates)))
            }, null, 8 /* PROPS */, ["active"]),
            _createElementVNode("div", {
              class: _normalizeClass(["module__aside-menu", { 'is-active': $data.guestsActiveState }]),
              ref: "modalGuests"
            }, [
              ($setup.isMobile)
                ? (_openBlock(), _createBlock(_component_mobile_heading, {
                    key: 0,
                    title: _ctx.$t('Search#Form#Field#NumGuests Guests'),
                    onClose: _cache[3] || (_cache[3] = $event => ($options.toggleModal('guests', _ctx.$refs.modalGuests))),
                    onClear: $options.clearDateInputs
                  }, null, 8 /* PROPS */, ["title", "onClear"]))
                : _createCommentVNode("v-if", true),
              (_openBlock(), _createElementBlock("div", { key: $data.guestsUpdateKey }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingRequest.roomStays, (roomStay, index) => {
                  return (_openBlock(), _createBlock(_component_guests, {
                    key: `guests_${index}`,
                    "room-stay-index": index,
                    "room-stay": _ctx.bookingRequest.roomStays[index],
                    onToggleGuests: $options.applyGuests,
                    onRemoveRoom: $event => (_ctx.removeRoom(roomStay))
                  }, null, 8 /* PROPS */, ["room-stay-index", "room-stay", "onToggleGuests", "onRemoveRoom"]))
                }), 128 /* KEYED_FRAGMENT */))
              ])),
              ($options.roomsCount < _ctx.maxNumberOfRooms)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_v_btn, {
                      class: "text-none mt-4",
                      type: "submit",
                      color: "primary",
                      ripple: false,
                      size: "x-large",
                      block: "",
                      tile: "",
                      onClick: _ctx.addRoom
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#Button Add another room')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onClick"])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_btn, {
                  class: "text-none",
                  color: "primary",
                  width: "50%",
                  ripple: false,
                  size: "x-large",
                  tile: "",
                  onClick: $options.applyGuests
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#Button Apply')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_v_btn, {
                  class: "text-none",
                  width: "50%",
                  ripple: false,
                  variant: "plain",
                  size: "x-large",
                  tile: "",
                  onClick: $options.clearGuests
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#Button Reset Guests')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ])
            ], 2 /* CLASS */),
            (_ctx.hasPromotions)
              ? (_openBlock(), _createBlock(_component_promo_codes, {
                  key: 0,
                  modelValue: $data.searchPromoCodeInput,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.searchPromoCodeInput) = $event)),
                  ref: "modalPromoCodes",
                  active: $data.promoCodesActiveState,
                  onTogglePromoCode: _cache[5] || (_cache[5] = $event => ($options.toggleModal('promoCodes', _ctx.$refs.modalPromoCodes)))
                }, null, 8 /* PROPS */, ["modelValue", "active"]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_v_col, { lg: "8" }, {
        default: _withCtx(() => [
          _createVNode(_component_filters_toolbar, {
            filtersActive: $data.filtersActiveState,
            onToggleFilters: _cache[6] || (_cache[6] = $event => ($options.toggleModal('filters', _ctx.$refs.modalFilters))),
            datesActive: $data.datesActiveState,
            onToggleDates: _cache[7] || (_cache[7] = $event => ($options.toggleModal('dates', _ctx.$refs.modalDates))),
            guestsActive: $data.guestsActiveState,
            onToggleGuests: _cache[8] || (_cache[8] = $event => ($options.toggleModal('guests', _ctx.$refs.modalGuests))),
            promoCodesActive: $data.promoCodesActiveState,
            onTogglePromoCode: _cache[9] || (_cache[9] = $event => ($options.toggleModal('promoCodes', _ctx.$refs.modalPromoCodes)))
          }, null, 8 /* PROPS */, ["filtersActive", "datesActive", "guestsActive", "promoCodesActive"]),
          _createVNode(_component_v_divider, {
            class: _normalizeClass({ 'mt-3': !_ctx.$store.getters.customization.bgUrl })
          }, null, 8 /* PROPS */, ["class"]),
          _createVNode(_component_rooms_list)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}