// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import objectFitImages from 'object-fit-images'
import { createApp, provide } from 'vue'
import { createHead, UnheadPlugin } from '@unhead/vue'
import dayjs from 'dayjs'

import App from './App.vue'
// Plugins
import { initSentry } from './app/vue-config/sentry-config'
import router from './app/router'
import store from './app/store'
import { createGtm } from '@gtm-support/vue-gtm'
import PortalVue from 'portal-vue'
import { setupI18n } from './app/vue-config/i18n'

// Initialize with default language
import { MittPlugin } from '@/app/system/plugins/mitt'
import vuetify from '@/app/vue-config/vuetify'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as icons from '@/app/system/fontAwesome'
import { isDevMode, isMockEnabled } from './app/system/helper'
import mainMixin from '@/app/system/mixins/mainMixin'
import '@/app/static/css/main.scss'

// Initialize Font Awesome library
library.add(icons)

const head = createHead()
if (isDevMode() && isMockEnabled()) {
  const { worker } = require('./mocks/browser')
  worker.start({
    onUnhandledRequest: 'bypass'
  })
}

const i18n = setupI18n()

// object-fit polyfill for IE11
// TODO: object-fit-images may not be necessary for modern browsers and could throw errors in some setups.
objectFitImages()

const app = createApp(App)

app.config.compatConfig = {
  MODE: 3
}

// Initialize Sentry
initSentry(app, router)

app.use(router)
app.use(
  createGtm({
    id: [...new Set(store.state.gtmContainers || [])],
    defer: false,
    compatibility: false,
    enabled: true,
    debug: isDevMode(),
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
    source: `${process.env.HS_GTM_SS_URL}/gtm.js`
  })
)
app.use(MittPlugin) // Custom event bus implementation
app.use(head)
//app.use(VueFriendlyIframe) // Custom component for iframes deprecated in Vue 3
// TODO: vue-intersect-directive deprecated in Vue 3, use useIntersectionObserver instead
//app.use(VueIntersectDirective)
app.use(i18n)
app.use(vuetify)
app.use(PortalVue)
app.mixin(mainMixin)
// Register global components
app.component('fa-icon', FontAwesomeIcon)
// Disable production tip
// TODO: does not exist in vue 3
// app.config.productionTip = false

router.isReady().then(() => {
  app.use(store)
  app.mount('#app')
})

export { app }
