import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "order-receipt d-flex flex-column" }
const _hoisted_2 = { class: "order-receipt__header" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "order-receipt__item flex-center justify-space-between" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "field" }
const _hoisted_7 = {
  key: 0,
  class: "order-receipt__item"
}
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "field total font-weight-bold" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_header_success = _resolveComponent("header-success")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_header_success, null, {
        header: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('Order#Payment Successful')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('Order#Receipt Transaction ID')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_6, _toDisplayString($props.reservation.transactionId), 1 /* TEXT */)
    ]),
    ($props.reservation.amount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('Order#Receipt Total')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_9, _toDisplayString($options.reservationAmount), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}