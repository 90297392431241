import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reservation-modification-header" }
const _hoisted_2 = { class: "my-5 d-flex justify-center" }
const _hoisted_3 = { class: "text-subtitle-1 font-weight-bold" }
const _hoisted_4 = { class: "text-subtitle-1" }
const _hoisted_5 = { class: "text-subtitle-1 font-weight-bold" }
const _hoisted_6 = { class: "text-subtitle-1" }
const _hoisted_7 = { class: "text-subtitle-1 font-weight-bold" }
const _hoisted_8 = { class: "text-subtitle-1" }
const _hoisted_9 = { class: "text-subtitle-1 font-weight-bold" }
const _hoisted_10 = {
  class: "text-subtitle-1",
  style: {"height":"35px"}
}
const _hoisted_11 = { class: "reservation-modification__rooms" }
const _hoisted_12 = { class: "text-body-2" }
const _hoisted_13 = { class: "total grand-total" }
const _hoisted_14 = { class: "text-body-2 mb-8" }
const _hoisted_15 = {
  class: "modification-deadline-text mt-3 px-4 text-caption text-center text-disabled font-weight-bold",
  slot: "modification-deadline"
}
const _hoisted_16 = { class: "d-flex justify-space-between mt-4" }
const _hoisted_17 = { class: "py-10 text-center" }

import { reactive, computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { isMobile, isTablet } from '@/app/composables/useBreakpoints'
import { DATE_TIME_FORMAT_DAY_SHORT } from '@/app/constants'
import { ReservationStatus as ReservationRole } from '@/app/system/constants/reservation'
import store from '@/app/store'

import Loader from '@/components/UI/Loader.vue'
import OrderTotalPrice from '@/components/Order/OrderTotalPrice.vue'
import PaymentCard from '@/components/Checkout/Payment/PaymentCard.vue'
import ReservationForm from '@/components/Reservation/ReservationForm.vue'
import ReservationRooms from '@/components/Order/OrderList.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservationModify',
  props: {
  reservationId: {
    type: String,
    required: true
  },
  token: {
    type: String,
    required: false
  }
},
  setup(__props) {

const router = useRouter()

const { t } = useI18n()

const title = computed(() => t('App#PageTitle#Reservation Modify'))

useHead({ title })

const props = __props

const state: {
  activeStep: number
  roomStaysData: Array<{
    roomStayIndex: number
  }>
  isProductCreated: boolean
  isProfileFormValid: boolean
  isReservationExpired: boolean
  modificationDeadlineTime: string
  modificationDeadlineDate: string
} = reactive({
  activeStep: 1,
  roomStaysData: [],
  isProductCreated: false,
  isProfileFormValid: false,
  isReservationExpired: false,
  isPaymentRequired: true,
  modificationDeadlineTime: '',
  modificationDeadlineDate: ''
})

onBeforeMount(async () => {
  const reservation = await store.dispatch('getReservation', {
    reservationId: props.reservationId,
    token: props.token ?? ''
  })

  if (reservation) {
    state.roomStaysData = reservation.roomStays
    state.isProductCreated = await store.dispatch(
      'createReservationModifyRequest',
      reservation
    )
    state.isReservationExpired = dayjs(
      reservation.modificationDeadline
    ).isBefore()
    state.modificationDeadlineTime = dayjs(reservation.modificationDeadline)
      .locale(store.state.language)
      .format('h:mm A')
    state.modificationDeadlineDate = dayjs(reservation.modificationDeadline)
      .locale(store.state.language)
      .format('DD/MM/YYYY')
    state.isPaymentRequired = reservation.payableAmount > 0
  }
})

const loading = computed(
  () =>
    store.state.reservationLoading ||
    !store.state.descriptiveInfoLoaded ||
    !store.state.productsLoaded
)

const roomStays = computed(() => {
  const roomStaysWithIndex = []
  store.getters.bookingRequest.roomStays.forEach((roomStay, roomStayIndex) => {
    roomStay.roomStayIndex = state.roomStaysData[roomStayIndex].roomStayIndex
    roomStaysWithIndex.push(roomStay)
  })
  return roomStaysWithIndex
})

const checkIn = computed(() =>
  store.getters.formatDate(
    store.getters.bookingRequest.checkIn,
    DATE_TIME_FORMAT_DAY_SHORT
  )
)

const checkOut = computed(() =>
  store.getters.formatDate(
    store.getters.bookingRequest.checkOut,
    DATE_TIME_FORMAT_DAY_SHORT
  )
)

const modificationDeadlineText = computed(() =>
  state.isReservationExpired
    ? `${t(
        'Reservation#Modify#Deadline This past reservation cannot be modified anymore'
      )}.`
    : `${t(
        'Reservation#Modify#Allow The reservation can be modified until {deadlineTime} on the {deadlineDate}. You will not be able to modify or cancel it afterwards',
        {
          deadlineTime: state.modificationDeadlineTime,
          deadlineDate: state.modificationDeadlineDate
        }
      )}.`
)

const infoCardWidth = computed(() => {
  if (isMobile.value) {
    return '100%'
  } else if (isTablet.value) {
    return '55%'
  } else {
    return '40%'
  }
})

async function modifyProfile() {
  if (!state.isReservationExpired) {
    store.commit('SET_RESERVATION_LOADING', true)
    const reservation = { ...store.getters.bookingRequestToBeSent }
    reservation.reservationId = props.reservationId

    const isModified = await store.dispatch('sendBookingRequest', {
      reservation,
      requestType: ReservationRole.Modify
    })

    if (isModified) {
      let isPaymentRequired = true
      if (!isPaymentRequired) {
        router.push({
          name: 'ReservationModifyConfirmation',
          params: {
            reservationId: props.reservationId
          }
        })
      }
    }
  } else {
    state.activeStep += 1
  }
}

function cancelReservation() {
  router.push({
    name: 'ReservationCancel',
    params: {
      reservationId: props.reservationId,
      email: store.getters.bookingRequest.guestInfo.email
    }
  })
}

async function reservationPaymentRequest() {
  const payload = {}
  const isPayed = await store.dispatch('reservationPaymentRequest', payload)
  if (isPayed) {
    router.push({
      name: 'ReservationModifyConfirmation',
      params: {
        reservationId: props.reservationId
      }
    })
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_nobr = _resolveComponent("nobr")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_stepper_item = _resolveComponent("v-stepper-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_stepper_header = _resolveComponent("v-stepper-header")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_stepper_window_item = _resolveComponent("v-stepper-window-item")!
  const _component_v_stepper_window = _resolveComponent("v-stepper-window")!
  const _component_v_stepper = _resolveComponent("v-stepper")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "reservation-modification" }, {
    default: _withCtx(() => [
      (!loading.value)
        ? (_openBlock(), _createBlock(_Transition, {
            key: 0,
            name: "fade",
            mode: "out-in",
            appear: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_sheet, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", {
                      class: _normalizeClass([
              'mb-4 pa-2',
              {
                'text-h4': !_unref(isMobile),
                'text-h6 font-weight-bold': _unref(isMobile)
              }
            ])
                    }, _toDisplayString(_unref(t)('Reservation#Modify#Heading Manage your reservation')), 3 /* TEXT, CLASS */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_sheet, {
                    elevation: "1",
                    width: infoCardWidth.value
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_table, {
                        class: "pt-2 pb-0",
                        density: "compact"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("tbody", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, [
                                _createVNode(_component_nobr, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_fa_icon, { icon: ['fat', 'ticket'] }),
                                    _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('Reservation#Modify#Step Reservation')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _createElementVNode("td", _hoisted_4, [
                                _createVNode(_component_nobr, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(__props.reservationId), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, [
                                _createVNode(_component_nobr, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_fa_icon, { icon: ['fat', 'hotel'] }),
                                    _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('App#Hotel Hotel')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _createElementVNode("td", _hoisted_6, _toDisplayString(_unref(store).getters.hotelName), 1 /* TEXT */)
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, [
                                _createVNode(_component_nobr, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_fa_icon, { icon: ['fat', 'calendar'] }),
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(isMobile)
                            ? _unref(t)('Reservation#Field Arrival')
                            : _unref(t)('Search#Form#Field Check In')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _createElementVNode("td", _hoisted_8, [
                                _createVNode(_component_nobr, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(checkIn.value), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ]),
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, [
                                _createVNode(_component_nobr, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_fa_icon, { icon: ['fat', 'calendar'] }),
                                    _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(isMobile)
                            ? _unref(t)('Reservation#Field Departure')
                            : _unref(t)('Search#Form#Field Check Out')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _createElementVNode("td", _hoisted_10, [
                                _createVNode(_component_nobr, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(checkOut.value), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["width"])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (loading.value)
        ? (_openBlock(), _createBlock(Loader, { key: 1 }))
        : (_openBlock(), _createBlock(_Transition, {
            key: 2,
            class: "d-flex align-start",
            slot: "reservation-content",
            tag: "div",
            name: "fade",
            mode: "out-in",
            appear: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_stepper, {
                modelValue: state.activeStep,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((state.activeStep) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_stepper_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_stepper_item, {
                        step: "1",
                        complete: state.activeStep > 1,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (state.activeStep = 1))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('Reservation#Modify#Step Reservation')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["complete"]),
                      _createVNode(_component_v_divider),
                      _createVNode(_component_v_stepper_item, {
                        step: "2",
                        complete: state.activeStep > 2,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (state.activeStep = 2))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('Reservation#Modify#Step Profile')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["complete"]),
                      _createVNode(_component_v_divider),
                      _createVNode(_component_v_stepper_item, {
                        step: "3",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (state.activeStep = 3))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('Reservation#Modify#Step Payment')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_stepper_window, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_stepper_window_item, { step: "1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "d-flex justify-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                lg: "6",
                                md: "9",
                                sm: "12",
                                class: "mt-1"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, [
                                    _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(t)(
                        'Reservation#Modify#Step#Reservation#Caption Please verify the details of your reservation below and contact us if anything is not correct.'
                      )), 1 /* TEXT */),
                                    (state.isProductCreated)
                                      ? (_openBlock(), _createBlock(ReservationRooms, {
                                          key: 0,
                                          "is-reservation-expired": state.isReservationExpired,
                                          "room-stays": roomStays.value,
                                          "show-toggler": false
                                        }, null, 8 /* PROPS */, ["is-reservation-expired", "room-stays"]))
                                      : _createCommentVNode("v-if", true),
                                    _createElementVNode("div", _hoisted_13, [
                                      _createVNode(OrderTotalPrice)
                                    ])
                                  ])
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_row, { class: "d-flex justify-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                lg: "3",
                                sm: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    color: "primary",
                                    class: "text-none mb-3",
                                    disabled: false,
                                    ripple: false,
                                    block: "",
                                    rounded: "0",
                                    size: "x-large",
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => (state.activeStep += 1))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(t)('Form#Button#Next Next')), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_stepper_window_item, { step: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "d-flex justify-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                lg: "6",
                                md: "9",
                                sm: "12",
                                class: "mt-1"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(t)(
                      'Reservation#Modify#Step#Profile#Caption Please verify and complete your profile information below to speed-up your check-in at the hotel.'
                    )), 1 /* TEXT */),
                                  _createVNode(ReservationForm, {
                                    role: _unref(ReservationRole).Modify,
                                    "show-title": false,
                                    "guest-info": _unref(store).getters.bookingRequest.guestInfo,
                                    "is-reservation-expired": state.isReservationExpired,
                                    onValidityState: _cache[4] || (_cache[4] = ($event: any) => (state.isProfileFormValid = $event)),
                                    onCancelReservation: cancelReservation
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_15, _toDisplayString(modificationDeadlineText.value), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["role", "guest-info", "is-reservation-expired"]),
                                  _createElementVNode("div", _hoisted_16, [
                                    _createVNode(_component_v_btn, {
                                      color: "primary",
                                      class: _normalizeClass([
                      'text-none',
                      {
                        'mr-10': !_unref(isMobile),
                        'mb-7': _unref(isMobile)
                      }
                    ]),
                                      width: "33%",
                                      disabled: false,
                                      ripple: false,
                                      variant: "outlined",
                                      rounded: "0",
                                      size: "x-large",
                                      onClick: _cache[5] || (_cache[5] = ($event: any) => (state.activeStep--))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(t)('Cancellation#Form#Popup#Button#Cancel Cancel')), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["class"]),
                                    _createVNode(_component_v_btn, {
                                      color: "primary",
                                      class: _normalizeClass(['text-none mb-3', { 'ml-10': !_unref(isMobile) }]),
                                      width: "33%",
                                      disabled: 
                      !state.isReservationExpired && !state.isProfileFormValid
                    ,
                                      ripple: false,
                                      rounded: "0",
                                      size: "x-large",
                                      onClick: modifyProfile
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(t)('Form#Button#Next Next')), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["class", "disabled"])
                                  ])
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_stepper_window_item, { step: "3" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "d-flex justify-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                lg: "6",
                                md: "9",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createCommentVNode(" <payment-card\n                  v-if=\"state.activeStep === 3 && state.isPaymentRequired\"\n                  :reservation=\"reservationId\"\n                /> "),
                                  _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(t)(
                      'Checkout#Payment#Unavailable No payment options are available at the moment.'
                    )), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_row, { class: "d-flex justify-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                lg: "4",
                                sm: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    color: "primary",
                                    class: "text-none",
                                    disabled: false,
                                    ripple: false,
                                    block: "",
                                    variant: "outlined",
                                    rounded: "0",
                                    size: "x-large",
                                    onClick: _cache[6] || (_cache[6] = ($event: any) => (state.activeStep--))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(t)('Cancellation#Form#Popup#Button#Cancel Cancel')), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }))
    ]),
    _: 1 /* STABLE */
  }))
}
}

})