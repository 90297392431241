import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createBlock(_component_v_btn, {
    class: "text-none",
    color: "primary",
    disabled: _ctx.allowPayment || _ctx.isAllRoomStaysBooked || $options.isRoomSoldOut,
    ripple: false,
    size: "x-large",
    block: "",
    tile: "",
    onClick: $options.bookRoom
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('General#Button Book now')), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled", "onClick"]))
}