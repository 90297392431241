import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-caption text-capitalize" }
const _hoisted_2 = { class: "toolbar__filters toolbar__button toolbar__button_with-bg" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "toolbar__dates toolbar__button toolbar__button_with-icon theme-container small-order--1 medium-order-0" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "toolbar__guests toolbar__button toolbar__button_with-icon theme-container small-order--1 medium-order-0" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "toolbar__promoCodes toolbar__button toolbar__button_with-icon theme-container small-order--1 medium-order-0"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 0,
  class: "toolbar__button_with-icon small-order--1 medium-order-0"
}

import { isDesktop, isMobile } from '@/app/composables/useBreakpoints'


import { mapGetters } from 'vuex'
import { isDateValid, getLocalizedDate } from '@/app/system/helper'
import localStorage from '@/app/system/mixins/localStorage'
import dropdown from '@/components/UI/Controls/Inputs/Dropdown.vue'
import SortDropdown from '@/components/Common/SortDropdown.vue'

const __default__ = {
  components: {
    SortDropdown,
    dropdown
  },
  mixins: [localStorage],
  props: {
    filtersActive: {
      type: Boolean,
      default: false
    },
    datesActive: {
      type: Boolean,
      default: false
    },
    guestsActive: {
      type: Boolean,
      default: false
    },
    promoCodesActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'hasPromotions',
      'numberOfActiveFilters',
      'bookingRequest',
      'currentRoomStayGuests',
      'totalRooms',
      'roomsInfo'
    ]),
    promoText() {
      if (this.bookingRequest.promoCodeInput?.length > 0) {
        return this.bookingRequest.promoCodeInput
      } else {
        return this.$t('Search#Results#Header Promo code')
      }
    },
    guestText() {
      const currentGuests = this.currentRoomStayGuests
      if (typeof currentGuests === 'object') {
        const { adults, children, infants } = currentGuests

        return this.guestsCountString(adults, children, infants)
      } else {
        return this.$t('Search#Results#Header Guests')
      }
    },
    dateText() {
      const { checkIn, checkOut, nbNights } = this.bookingRequest
      const locale = this.language || 'en'

      if (nbNights === 0) {
        return this.$t('Search#Results#Header Dates')
      }

      const formatDate = (date) =>
        isDateValid(date) ? getLocalizedDate(date, locale) : (date ?? '')

      const localizedCheckIn = formatDate(checkIn)
      const localizedCheckOut = formatDate(checkOut)

      return `
          <span class="dates-text">${localizedCheckIn}</span>
          <i class="icon-arrow-right dates-arrow"></i>
          <span class="dates-text">${localizedCheckOut}</span>
        `
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'Toolbar',
  emits: [
  'toggleFilters',
  'toggleDates',
  'toggleGuests',
  'togglePromoCode'
],
  setup(__props, { emit: __emit }) {

const emit = __emit

return (_ctx, _cache) => {
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toolbar", {
      'pa-2 mb-3': _unref(isDesktop),
      'pa-2': _ctx.$store.state.customization.bgUrl
    }])
  }, [
    (_unref(isDesktop) && (_ctx.totalRooms > 0 || _ctx.roomsInfo.length > 0))
      ? (_openBlock(), _createBlock(_component_v_sheet, {
          key: 0,
          class: "py-1 px-2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Search#Results#Header found:')) + " " + _toDisplayString(_ctx.$t('Search#Results#ResultsNumber results', _ctx.totalRooms)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass(["toggle", { 'is-active': __props.filtersActive }]),
        onClick: _cache[0] || (_cache[0] = $event => (emit('toggleFilters')))
      }, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('Search#Results#Header Filters')), 1 /* TEXT */),
        _withDirectives(_createElementVNode("div", { class: "number_of_filters" }, _toDisplayString(_ctx.numberOfActiveFilters), 513 /* TEXT, NEED_PATCH */), [
          [_vShow, _ctx.numberOfActiveFilters > 0]
        ])
      ], 2 /* CLASS */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_unref(isDesktop)]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: _normalizeClass(["toggle", { 'is-active': __props.datesActive }]),
        onClick: _cache[1] || (_cache[1] = $event => (emit('toggleDates')))
      }, [
        _cache[4] || (_cache[4] = _createElementVNode("i", { class: "icon-calendar" }, null, -1 /* HOISTED */)),
        _createElementVNode("span", { innerHTML: _ctx.dateText }, null, 8 /* PROPS */, _hoisted_5)
      ], 2 /* CLASS */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_unref(isDesktop)]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: _normalizeClass(["toggle", { 'is-active': __props.guestsActive }]),
        onClick: _cache[2] || (_cache[2] = $event => (emit('toggleGuests')))
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "icon-person" }, null, -1 /* HOISTED */)),
        _createElementVNode("span", { innerHTML: _ctx.guestText }, null, 8 /* PROPS */, _hoisted_7)
      ], 2 /* CLASS */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_unref(isDesktop)]
    ]),
    (_ctx.hasPromotions)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("button", {
            class: _normalizeClass(["toggle", { 'is-active': __props.promoCodesActive }]),
            onClick: _cache[3] || (_cache[3] = $event => (emit('togglePromoCode')))
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "icon-pricetags" }, null, -1 /* HOISTED */)),
            _createElementVNode("span", {
              class: "promo-text",
              innerHTML: _ctx.promoText
            }, null, 8 /* PROPS */, _hoisted_9)
          ], 2 /* CLASS */)
        ], 512 /* NEED_PATCH */)), [
          [_vShow, !_unref(isDesktop)]
        ])
      : _createCommentVNode("v-if", true),
    _createVNode(_component_v_sheet, {
      class: _normalizeClass({ 'ml-auto': _ctx.$vuetify.display.mdAndUp }),
      style: _normalizeStyle(_ctx.$vuetify.display.smAndDown && 'flex: 0 0 48%')
    }, {
      default: _withCtx(() => [
        (_ctx.totalRooms > 0 || _ctx.roomsInfo.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(SortDropdown)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "style"])
  ], 2 /* CLASS */))
}
}

})