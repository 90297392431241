import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  class: "field date no-scroll_dates",
  key: "dateRange"
}

import { inject, computed } from 'vue'
import { isDesktop } from '@/app/composables/useBreakpoints'
import fieldDateRange from './Fields/DateRangePicker.vue'
import fieldPromo from './Fields/Promo.vue'
import Guests from './Fields/Guests.vue'
import ButtonAnimated from '@/components/UI/Controls/ButtonAnimated.vue'
import Loader from '@/components/UI/Loader.vue'
import store from '@/app/store'


import Swal from 'sweetalert2'
import { mapGetters, mapMutations } from 'vuex'
import roomStayGuests from '@/app/system/mixins/roomStayGuests'

const __default__ = {
  name: 'Search',
  components: {
    fieldDateRange,
    Guests,
    fieldPromo
  },
  mixins: [roomStayGuests],
  emits: ['submit'],
  computed: {
    ...mapGetters([
      'isAllRoomStaysBooked',
      'allowPayment',
      'bookingRequest',
      'customization',
      'device',
      'hasPromotions',
      'hotelPackagesLoaded',
      'loading'
    ]),
    btnText() {
      return this.loading || !this.hotelPackagesLoaded
        ? ''
        : this.isAllRoomStaysBooked || this.allowPayment
          ? this.$t('Search#Form#Button Checkout')
          : this.$t('Search#Form#Button Search')
    },
    dates() {
      return {
        start: this.bookingRequest.checkIn,
        end: this.bookingRequest.checkOut
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.isAllRoomStaysBooked || this.allowPayment) {
        const { isConfirmed } = await Swal.fire({
          icon: 'warning',
          title: this.$t(
            'Search#Form#CompletingReservation#title Completing reservation'
          ),
          text: this.$t(
            'Search#Form#CompletingReservation#Text Do you want to complete your reservation?'
          ),
          showCancelButton: true,
          cancelButtonText: this.$t(
            'Search#Form#CompletingReservation#Button No, reset my search'
          ),
          confirmButtonText: this.$t(
            'Search#Form#CompletingReservation#Button Complete the reservation'
          ),
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        if (isConfirmed) {
          this.$emit('submit')
        } else {
          this.$emitter.emit('refresh-session')
        }
      }
    })
  },
  methods: {
    ...mapMutations(['UPDATE_BOOKING_REQUEST']),
    submitForm(dates) {
      this.UPDATE_BOOKING_REQUEST(dates)
      this.$emit('submit')
    },
    applyDates(dates) {
      this.getAvailabilitiesWithUpdatedDates(dates)
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  emits: ['submit'],
  setup(__props, { emit: __emit }) {

const emitter = inject('emitter')
const emit = __emit

emitter.on('dropdown', () => emit('submit'))

const hotelDates = computed(() => ({
  latestCheckInTimeDate: store.getters.descriptiveInfo.latestCheckInTimeDate,
  latestReservationDate: store.getters.descriptiveInfo.latestReservationDate
}))

return (_ctx, _cache) => {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createBlock(_component_v_sheet, { class: "module__search-rooms elevation-2 pa-4 mb-8" }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "form form-search-rooms",
        id: "dates",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.submitForm(_ctx.dates)), ["prevent"]))
      }, [
        _createVNode(_TransitionGroup, {
          class: "fieldset",
          tag: "div",
          name: "inputs"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(fieldDateRange, {
                range: _ctx.dates,
                "start-date": _ctx.bookingRequest.checkIn,
                "end-date": _ctx.bookingRequest.checkOut,
                "max-date": hotelDates.value.latestReservationDate,
                "min-date": hotelDates.value.latestCheckInTimeDate,
                onInput: _cache[0] || (_cache[0] = $event => (_ctx.applyDates($event)))
              }, null, 8 /* PROPS */, ["range", "start-date", "end-date", "max-date", "min-date"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingRequest.roomStays, (roomStay, index) => {
              return (_openBlock(), _createBlock(Guests, {
                class: "mt-6",
                key: `guests_${index}`,
                "room-stay-index": index,
                "room-stay": _ctx.bookingRequest.roomStays[index],
                onRemoveRoom: $event => (_ctx.removeRoom(roomStay))
              }, null, 8 /* PROPS */, ["room-stay-index", "room-stay", "onRemoveRoom"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }),
        (_unref(isDesktop) && _ctx.device === 'desktop' && _ctx.isPossibleToAddRoomStay)
          ? (_openBlock(), _createBlock(ButtonAnimated, {
              key: 0,
              class: "mt-8",
              disabled: _ctx.loading,
              onClick: _withModifiers(_ctx.addRoom, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#Button Add another room')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.hasPromotions)
          ? (_openBlock(), _createBlock(_TransitionGroup, {
              key: 1,
              class: "fieldset",
              tag: "div",
              name: "inputs"
            }, {
              default: _withCtx(() => [
                _createVNode(fieldPromo, {
                  key: "promoCode",
                  modelValue: _ctx.bookingRequest.promoCodeInput,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.bookingRequest.promoCodeInput) = $event)),
                  disabled: _ctx.loading,
                  onSubmit: _cache[2] || (_cache[2] = $event => (emit('submit')))
                }, null, 8 /* PROPS */, ["modelValue", "disabled"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_v_btn, {
          class: "text-none mt-6",
          type: "submit",
          color: "primary",
          disabled: _ctx.loading || !_ctx.hotelPackagesLoaded,
          ripple: false,
          size: "x-large",
          block: "",
          tile: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.btnText), 1 /* TEXT */),
            (_ctx.loading || !_ctx.hotelPackagesLoaded)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[5] || (_cache[5] = _createTextVNode("   ")),
                  _createVNode(Loader, {
                    size: 30,
                    absolute: false,
                    thin: ""
                  })
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]),
        _createVNode(_component_v_btn, {
          class: "text-none mt-6",
          ripple: false,
          variant: "text",
          block: "",
          onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_unref(emitter).emit('refresh-session')), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#ResetButton Reset your search')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ], 32 /* NEED_HYDRATION */)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})