import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "theme-toggler" }

import { computed } from 'vue'
import store from '@/app/store'
import useTheme from '@/app/composables/useTheme'


export default {
  __name: 'ThemeToggler',
  setup(__props) {

const { isDark } = useTheme()

const icon = computed(() => {
  const fa = ['fat']
  return isDark.value ? [...fa, 'sun'] : [...fa, 'moon-stars']
})

const toggleTheme = () => {
  isDark.value = !isDark.value
  store.commit('UPDATE_CUSTOMIZATION_CONFIG', {
    isDarkMode: isDark.value
  })
}

return (_ctx, _cache) => {
  const _component_fa_icon = _resolveComponent("fa-icon")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      block: "",
      rounded: "",
      width: "36",
      height: "36",
      onClick: _cache[0] || (_cache[0] = $event => (toggleTheme()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fa_icon, {
          icon: icon.value,
          class: "fa-lg"
        }, null, 8 /* PROPS */, ["icon"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

}