import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import IconItemList from '@/components/Common/IconItemList.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CancellationConditionBadge',
  props: {
  condition: {
    type: String as PropType<'flexible' | 'free' | 'nonRefundable'>,
    required: true
  }
},
  setup(__props) {

const { t } = useI18n()



const itemMap = {
  flexible: {
    text: t('Badge#Cancellation Flexible'),
    id: 'cancellation-flexible',
    icon: 'fat calendars'
  },
  free: {
    text: t('Badge#Cancellation Free Cancellation'),
    id: 'cancellation-free',
    icon: 'fas check'
  },
  nonRefundable: {
    text: t('Badge#Cancellation Non-refundable'),
    id: 'cancellation-non-refundable',
    icon: 'fas exclamation',
    iconColor: 'rgb(var(--v-theme-error))'
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(IconItemList, {
    items: [itemMap[__props.condition]]
  }, null, 8 /* PROPS */, ["items"]))
}
}

})