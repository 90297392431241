import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block__loader"
}

import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import Loader from '@/components/UI/Loader.vue'


import Swal from 'sweetalert2'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import SentryClient from '@/app/system/SentryClient'
import localStorage from '@/app/system/mixins/localStorage'

const __default__ = {
  name: 'AfterPay',
  mixins: [localStorage],
  props: [
    'intent',
    'result',
    'datatransTrxId',
    'transactionId',
    'transactionError'
  ],
  data() {
    return {
      verified: null
    }
  },
  computed: {
    ...mapGetters(['tenantId']),
    errorMessage() {
      return {
        title:
          this.tenantId === 3
            ? this.transactionError
            : this.$t(
                'Checkout#Payment#Check#Fail 3D-Secure check has been failed'
              ),
        text:
          this.tenantId === 3
            ? this.$t(
                'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.'
              )
            : this.$t(
                'Checkout#Payment#Check#Fail 3D-Secure check has been failed'
              )
      }
    }
  },
  watch: {
    tenantId(id) {
      if (id) {
        this.handleAfterPay()
      }
    }
  },
  methods: {
    ...mapActions(['threeDsAuthentication']),
    ...mapMutations(['SET_PAYMENT_TYPE']),
    async handleAfterPay() {
      const reservation = JSON.parse(this.lsGet('reservation'))
      const reservationId = reservation?.id

      SentryClient.captureExceptionWithScope(
        'Handle Afterpay',
        {
          extraKey: 'Afterpay params',
          extraData: { data: { ...this.$props, reservationId } },
          tags: { type: 'afterpay' }
        },
        'info'
      )

      if (this.result !== 'success') {
        this.showTransactionErrorModal()
        return
      } else if (this.transactionId && this.tenantId === 3) {
        this.lsSaveReservation({
          transactionId: this.transactionId,
          ...reservation
        })
        this.reservationConfirmation(reservationId)
      } else if (this.intent && this.datatransTrxId && this.tenantId !== 3) {
        const language = this.lsGet('language')
        const response = await this.threeDsAuthentication({
          reservationId,
          language: language,
          transactionId: this.datatransTrxId,
          intent: this.intent
        })
        if (response && response.success) {
          const { reservationId: id, payment } = response
          this.lsSaveReservation({
            id: reservationId,
            transactionId: this.datatransTrxId,
            ...payment
          })
          this.reservationConfirmation(id)
        }
      }
    },
    lsSaveReservation(reservation) {
      const lsReservation = JSON.stringify({
        intent: this.intent || 'pay',
        ...reservation
      })
      this.lsSave('reservation', lsReservation)
    },
    reservationConfirmation(id) {
      this.$router.push({
        name: 'ReservationCheckoutConfirmation',
        params: { reservationId: id }
      })
    },
    async showTransactionErrorModal() {
      const { title, text } = this.errorMessage
      const { isConfirmed } = await Swal.fire({
        icon: 'error',
        title,
        text,
        confirmButtonText: this.$t('Checkout#Payment#Check#Fail Try again'),
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      if (isConfirmed) {
        this.$router.push({ name: 'home' })
      }
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#AfterPay')
})

return (_ctx, _cache) => {
  return (!_ctx.verified)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Loader)
      ]))
    : _createCommentVNode("v-if", true)
}
}

})