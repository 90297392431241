import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-body-2 pt-4"
}

import { ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { isEmpty } from 'lodash'
import { emitter } from '@/app/system/plugins/mitt'
import store from '@/app/store'

import ReservationRooms from '@/components/Order/OrderList.vue'
import PaymentCard from './PaymentCard.vue'
import PaymentOffline from './PaymentOffline.vue'
import PaymentAvenue from './PaymentAvenue.vue'
import PaymentBookingPreview from './PaymentBookingPreview.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentDetails',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Card Details')
})

const orderListExpanded: Ref<boolean> = ref(
  store.getters.isIndianPaymentSolutionProvider ||
    store.getters.bookingRequest.roomStays[0].isPackage ||
    !store.getters.isCreditCardRequired ||
    store.getters.isTaxServiceEnabled
)

/**
 * Collapse order when payment client was init
 * Expand order could also be triggered
 * if "payrails-client-init" event was emitted with value "false":
 * (currently not used anywhere)
 * so the "init" value is reverted depending on init state
 */
emitter.on('payrails-client-init', (init: boolean) => {
  orderListExpanded.value = !init
})

emitter.on('juspay-client-init', (init: boolean) => {
  orderListExpanded.value = !init
})

return (_ctx: any,_cache: any) => {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "11",
            lg: "8"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, {
                elevation: "1",
                class: "px-5"
              }, {
                default: _withCtx(() => [
                  (!_unref(isEmpty)(_unref(store).getters.bookingRequest.guestInfo))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(`${_unref(store).getters.bookingRequest.guestInfo.givenName} ${_unref(store).getters.bookingRequest.guestInfo.surname}`), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (_unref(store).getters.bookingRequest.roomStays)
                    ? (_openBlock(), _createBlock(ReservationRooms, {
                        key: 1,
                        class: "pt-4",
                        "room-stays": _unref(store).getters.bookingRequest.roomStays,
                        "active-state": orderListExpanded.value,
                        "init-on": "desktop",
                        cancellable: ""
                      }, null, 8 /* PROPS */, ["room-stays", "active-state"]))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_v_divider)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_row, {
        justify: "center",
        class: "my-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "11",
            lg: "8",
            class: "py-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, {
                elevation: "1",
                class: "pa-5"
              }, {
                default: _withCtx(() => [
                  (_unref(store).getters.isIndianPaymentSolutionProvider)
                    ? (_openBlock(), _createBlock(PaymentBookingPreview, {
                        key: 0,
                        "payment-provider": _unref(store).state.paymentSolutionsProvider
                      }, null, 8 /* PROPS */, ["payment-provider"]))
                    : (_unref(store).getters.isDatatransPayment)
                      ? (_openBlock(), _createBlock(PaymentCard, { key: 1 }))
                      : (_unref(store).getters.isCCAvenuePayment)
                        ? (_openBlock(), _createBlock(PaymentAvenue, { key: 2 }))
                        : (_openBlock(), _createBlock(PaymentOffline, { key: 3 }))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})