import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "date-range-inputs w-100" }
const _hoisted_2 = { class: "date-inputs-container flex-center w-100" }
const _hoisted_3 = { class: "input-wrapper" }
const _hoisted_4 = { class: "input-label flex-center mb-1 ml-2" }
const _hoisted_5 = { class: "text-caption" }
const _hoisted_6 = ["value", "onInput", "onKeydown", "onBlur"]
const _hoisted_7 = { class: "input-wrapper" }
const _hoisted_8 = { class: "input-label flex-center mb-1 ml-2" }
const _hoisted_9 = { class: "text-caption" }
const _hoisted_10 = ["value", "onInput", "onKeydown", "onBlur"]
const _hoisted_11 = {
  key: 0,
  class: "text-caption position-absolute mt-1",
  style: {"right":"0"}
}

import { ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTheme } from 'vuetify'
import dayjs from 'dayjs'
import VueDatePicker from '@vuepic/vue-datepicker'
import { RangeConfig, DatePickerMarker } from '@vuepic/vue-datepicker'
import { dateDifference } from '@/app/system/helper'
import { isDesktop } from '@/app/composables/useBreakpoints'
import '@vuepic/vue-datepicker/dist/main.css'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateRangePicker',
  props: {
    labels: { type: Boolean },
    range: { default: () => ({
      partialRange: true
    }) },
    inline: { type: Boolean, default: false },
    startDate: {},
    endDate: {}
  },
  emits: ["input"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit

const isSelectingRange = ref(false)
const rangeStartDate = ref(null)

const handleRangeStart = (value) => {
  isSelectingRange.value = true
  rangeStartDate.value = value
}

const handleRangeEnd = () => {
  isSelectingRange.value = false
  rangeStartDate.value = null
}

const handleClose = () => {
  isSelectingRange.value = false
  rangeStartDate.value = null
}

const dateMarkers = computed(() => {
  // Only show markers during range selection and when we have minRange
  if (
    !isSelectingRange.value ||
    !rangeStartDate.value ||
    !props.range?.minRange
  )
    return []

  const startDate = dayjs(rangeStartDate.value)
  const markers: DatePickerMarker[] = []

  // Add markers for dates before and after the selected date
  const offsets = [...Array(props.range.minRange - 1).keys()].map((i) => i + 1)

  offsets.forEach((offset) => {
    const beforeDate = startDate.subtract(offset, 'day')
    const afterDate = startDate.add(offset, 'day')
    ;[beforeDate, afterDate].forEach((markerDate) => {
      markers.push({
        date: markerDate.toDate(),
        type: 'dot',
        color: '#ffffff00',
        tooltip: [
          {
            text: t('DateRangePicker#Marker#Minimum stay required'),
            color: '#ffffff00'
          }
        ]
      })
    })
  })

  return markers
})

const props = __props

const { locale, t } = useI18n()
const theme = useTheme()

// Initialize date with props or default values
const date = ref<string[]>([
  props.startDate ||
    (props.range && 'start' in props.range
      ? props.range.start
      : dayjs().format('YYYY-MM-DD')),
  props.endDate ||
    (props.range && 'end' in props.range
      ? props.range.end
      : dayjs().add(1, 'day').format('YYYY-MM-DD'))
])

// Watch for prop changes to update the date
watch(
  () => [props.startDate, props.endDate, props.range],
  () => {
    const newStartDate =
      props.startDate ||
      (props.range && 'start' in props.range
        ? props.range.start
        : date.value[0])
    const newEndDate =
      props.endDate ||
      (props.range && 'end' in props.range ? props.range.end : date.value[1])

    if (newStartDate !== date.value[0] || newEndDate !== date.value[1]) {
      date.value = [newStartDate, newEndDate]
    }
  },
  { deep: true }
)

const today = dayjs().format('YYYY-MM-DD')
const numNights = computed(() =>
  dateDifference(date.value?.[0], date.value?.[1])
)

watch(
  date,
  (newDates) => {
    if (newDates && newDates.length === 2) {
      emit('input', {
        start: dayjs(newDates[0]).format('YYYY-MM-DD'),
        end: dayjs(newDates[1]).format('YYYY-MM-DD')
      })
    }
  },
  { deep: true }
)

const datepicker = ref<{
  openMenu: () => void
  selectDate: () => void
} | null>(null)

const openMenu = (): void => {
  datepicker.value?.openMenu()
}

const formatDateValue = (value: string, index: number): string => {
  if (!value) return ''
  const dates = value.split(' - ')
  return dates[index] || ''
}

const selectDate = (): void => {
  datepicker.value?.selectDate()
}

return (_ctx: any,_cache: any) => {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VueDatePicker), {
      modelValue: date.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event)),
      ref_key: "datepicker",
      ref: datepicker,
      dark: _unref(theme).global.current.value.dark ? true : false,
      locale: _unref(locale),
      inline: _ctx.inline,
      clearable: false,
      "enable-time-picker": false,
      "auto-apply": "",
      "multi-calendars": "",
      "min-date": "today",
      position: "left",
      range: _ctx.range,
      markers: dateMarkers.value,
      onRangeStart: handleRangeStart,
      onRangeEnd: handleRangeEnd,
      onClosed: handleClose
    }, {
      "dp-input": _withCtx(({ value, onInput, onEnter, onBlur }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_fa_icon, { icon: ['fat', 'calendar'] }),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('Search#Form#Field Check In')), 1 /* TEXT */)
            ]),
            _createElementVNode("input", {
              class: "date-input theme-container",
              type: "text",
              value: formatDateValue(value, 0),
              onInput: onInput,
              onKeydown: _withKeys(onEnter, ["enter"]),
              onBlur: onBlur
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_fa_icon, { icon: ['fat', 'calendar'] }),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('Search#Form#Field Check Out')), 1 /* TEXT */)
            ]),
            _createElementVNode("input", {
              class: "date-input theme-container",
              type: "text",
              value: formatDateValue(value, 1),
              onInput: onInput,
              onKeydown: _withKeys(onEnter, ["enter"]),
              onBlur: onBlur
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_10)
          ])
        ]),
        (_unref(isDesktop))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)('Search#Form#NumNights nights stay', numNights.value)), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "dark", "locale", "inline", "range", "markers"])
  ]))
}
}

})