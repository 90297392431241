import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["switcher-field", { isActive: $data.checked }])
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      value: $data.checked,
      onInput: _cache[0] || (_cache[0] = (...args) => ($options.modeChange && $options.modeChange(...args)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)
  ], 2 /* CLASS */))
}