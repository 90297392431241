import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import store from '@/app/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'SkeletonRoomItem',
  setup(__props) {

const attrs = {
  class: 'my-3 mx-auto skeleton-room',
  boilerplate: true,
  elevation: 1,
  dark: store.state.customization.isDarkMode,
  light: !store.state.customization.isDarkMode,
  loading: store.state.loading,
  minHeight: 300,
  type: 'image, article, button'
}

return (_ctx: any,_cache: any) => {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, { elevation: "1" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_skeleton_loader, _normalizeProps(_guardReactiveProps(attrs)), null, 16 /* FULL_PROPS */)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})