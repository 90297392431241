import { LocalStorage } from '@/app/services/storage.service';

export const localStorageHandler = {
  /**
   * Saves the value by the key in the local storage.
   * @param key {string}
   * @param value {string | boolean | number}
   */
  lsSave(key, value) {
    LocalStorage.setItem(key, value);
  },
  /**
   * Returns the value by the key from the local storage.
   * @param key {string}
   * @return {string|null}
   */
  lsGet(key) {
    return LocalStorage.getItem(key);
  },
  /**
   * Deletes the value by the key from the local storage.
   * @param key {string}
   */
  lsRemove(key) {
    LocalStorage.removeItem(key);
  },
};

export default {
  methods: {
    ...localStorageHandler,
  },
};
