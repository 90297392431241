import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "column-reverse" }
const _hoisted_2 = { class: "column-reverse" }
const _hoisted_3 = { class: "price-from text-capitalize text-body-1 mt-2" }
const _hoisted_4 = { class: "text-h6" }
const _hoisted_5 = { class: "text-body-2" }
const _hoisted_6 = {
  key: 0,
  class: "text-caption text-uppercase text-error mt-4"
}
const _hoisted_7 = { class: "card-amenities d-flex flex-wrap mt-3 position-relative" }
const _hoisted_8 = { class: "text-caption text-truncate" }
const _hoisted_9 = {
  class: "text-body-2 py-3",
  style: {"line-height":"1rem"}
}
const _hoisted_10 = { style: {"white-space":"pre-wrap"} }

import { reactive, computed, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { min } from 'lodash'
import store from '@/app/store'
import {
  getImageKitUrls,
  formatPrice,
  getCSSVariable
} from '@/app/system/helper'
import { PRODUCT_DESC_MAX_LEN } from '@/app/constants'
import MaxOccupancy from '@/components/Common/MaxOccupancy.vue'
import RoomImages from './RoomImages.vue'
import RatePlanItem from './RatePlanItem.vue'
import ButtonAnimated from '@/components/UI/Controls/ButtonAnimated.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomsListItem',
  props: {
  item: {
    type: Object as PropType<{
      id: string
      maxAdultOccupancy: number
      maxChildOccupancy: number
      maxOccupancy: number
      minOccupancy: number
      quantity: number
      roomTypeName: string
      amenities: Array<{
        codeDetail: string
        roomAmenityCode: number
      }>
      multimediaDescriptions: {
        images: Array<{
          createDateTime: string
          imageId: string
          imagePath: string
          lastModifyDateTime: string
          sort: number
        }>
        images: Array<{
          imagePath: string
          sort: number
          createDateTime: string
          imageId: string
          imagePath: string
          lastModifyDateTime: string
          sort: number
        }>
        texts: Array<string>
      }
      products: Array<{
        availability: number
        cancellationConditions: 'flexible' | 'nonRefundable'
        currency: string
        discount: number
        hasServices: boolean
        hotelCurrency: string
        hotelTotalDiscount: number
        hotelTotalLocalTax: number
        hotelTotalPrice: number
        hotelTotalVat: number
        isBar: boolean
        limitedOffer: boolean
        nbNights: number
        popularity: number
        productId: string
        ratePlanId: string
        reference: string
        totalLocalTax: number
        totalPrice: number
        totalVat: number
        rate: {
          currencyCode: string
          guaranteeType: 'CC' | 'Voucher' | 'None'
          ratePlanCode: string
          ratePlanName: string
          ratePlanType: string
          taxes: object[]
          descriptions: {
            images: Array<{
              createDateTime: string
              imageId: string
              imagePath: string
              lastModifyDateTime: string
              sort: number
            }>
            texts: Array<string>
          }
        }
      }>
    }>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isExpanded: {
    type: Boolean,
    required: true
  }
},
  emits: ["expand"],
  setup(__props, { emit: __emit }) {

const emit = __emit

const props = __props

const state: {
  showRatePlans: boolean
} = reactive({
  showRatePlans: props.isExpanded
})

const { t } = useI18n()

watch(
  () => props.isExpanded,
  (current) => {
    state.showRatePlans = current
  }
)

function toggleRatePlan() {
  state.showRatePlans = !state.showRatePlans
  emit('expand', {
    index: props.index,
    id: props.item.id,
    expanded: state.showRatePlans,
    collapsed: !state.showRatePlans
  })
}

const isRoomSoldOut = computed<boolean>(
  () => store.getters.roomAvailabilities[props.item?.id] < 1
)

const selectedImages = computed(() =>
  getImageKitUrls(props.item.multimediaDescriptions.images, 'Mobile')
)

const amenities = computed(() => {
  if (!props.item?.amenities?.length) {
    return []
  }
  return props.item.amenities
    .filter(
      ({ codeDetail, roomAmenityCode }, i) => codeDetail && roomAmenityCode
    )
    .map(({ codeDetail, roomAmenityCode }) => ({
      desc: codeDetail,
      code: store.getters.roomFacilitiesCodes[roomAmenityCode] || ''
    }))
    .slice(0, 4)
})

const minProductPrice = computed<string>(() => {
  let currency = ''
  const prices = props.item.products.map((product) => {
    currency = product.currency
    return (product.totalPrice - product.discount) / product.nbNights
  })
  return formatPrice(min(prices), { currency })
})

const roomDescription = computed<string>(() => {
  const text = props.item.multimediaDescriptions.texts[0]?.text || ''
  return text.length < PRODUCT_DESC_MAX_LEN
    ? text
    : text.slice(0, PRODUCT_DESC_MAX_LEN) + '...'
})

const btnText = computed<string>(() => {
  if (store.getters.loading) {
    return t('Search#Results#Room#Button Please wait')
  } else if (!isRoomSoldOut.value) {
    return t('AvailabilityItem#Button Details & Book')
  } else {
    return t('Search#Results#Room#Button Room is sold out')
  }
})

return (_ctx: any,_cache: any) => {
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "container--fluid px-0 py-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(['overflow-hidden', { 'sold-out': isRoomSoldOut.value }]),
        elevation: "2",
        rounded: "0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            sm: "column",
            class: "ma-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "pa-0" }, {
                default: _withCtx(() => [
                  (
              __props.item.products.some((product) => product.isBar) && !isRoomSoldOut.value
            )
                    ? (_openBlock(), _createBlock(_component_v_badge, {
                        key: 0,
                        content: _unref(t)('Search#Results#Room Best price on this date'),
                        color: _unref(getCSSVariable)('--v-theme-success'),
                        "offset-y": 20,
                        rounded: false,
                        inline: ""
                      }, null, 8 /* PROPS */, ["content", "color"]))
                    : _createCommentVNode("v-if", true),
                  (__props.item.id)
                    ? (_openBlock(), _createBlock(RoomImages, {
                        key: 1,
                        images: selectedImages.value,
                        "link-to": { name: 'room', params: { roomId: __props.item.id } }
                      }, null, 8 /* PROPS */, ["images", "link-to"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { class: "pa-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, {
                    sm: "row",
                    xs: "column",
                    class: "ma-0 fill-height"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        lg: "6",
                        class: "py-0 position-relative"
                      }, {
                        default: _withCtx(() => [
                          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "gradient-layer" }, null, -1 /* HOISTED */)),
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_1, [
                              (__props.item)
                                ? (_openBlock(), _createBlock(MaxOccupancy, {
                                    key: 0,
                                    room: __props.item
                                  }, null, 8 /* PROPS */, ["room"]))
                                : _createCommentVNode("v-if", true),
                              (__props.item.id)
                                ? (_openBlock(), _createBlock(_component_router_link, {
                                    key: 1,
                                    to: { name: 'room', params: { roomId: __props.item.id } },
                                    style: {"text-decoration":"none","color":"inherit"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, { class: "text-body-1 font-weight-bold pa-0" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(__props.item.roomTypeName), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["to"]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _createCommentVNode(" Average price per night "),
                            _createElementVNode("div", _hoisted_2, [
                              _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('Checkout#ViewOrder#From from')), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_4, _toDisplayString(minProductPrice.value), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('Price#Display#Module Average price per night')), 1 /* TEXT */)
                              ]),
                              (
                      __props.item?.quantity > 5 &&
                      !isRoomSoldOut.value &&
                      __props.item?.availability <= 5
                    )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(t)(
                        'Search#Results#Room#NumAvailableRooms rooms left',
                        __props.item.availability
                      )), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _createElementVNode("div", _hoisted_7, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(amenities.value, (amenity, i) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: `${amenity.code || i}-${amenity.desc || i}`
                                }, [
                                  (amenity.desc)
                                    ? (_openBlock(), _createBlock(_component_v_chip, {
                                        key: 0,
                                        class: "mr-2 mb-2 text-truncate border-thin",
                                        size: "small",
                                        variant: "outlined",
                                        border: "",
                                        density: "comfortable"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_tooltip, {
                                            activator: "parent",
                                            location: "top",
                                            "open-delay": "800"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(amenity.desc), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */),
                                          (amenity.code)
                                            ? (_openBlock(), _createBlock(_component_fa_icon, {
                                                key: 0,
                                                class: "mr-2",
                                                icon: ['fat', amenity.code]
                                              }, null, 8 /* PROPS */, ["icon"]))
                                            : _createCommentVNode("v-if", true),
                                          _createElementVNode("span", _hoisted_8, _toDisplayString(amenity.desc), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */))
                                    : _createCommentVNode("v-if", true)
                                ], 64 /* STABLE_FRAGMENT */))
                              }), 128 /* KEYED_FRAGMENT */))
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, {
                        lg: "6",
                        class: "pt-0 pr-1 pb-1 d-flex flex-column"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_spacer),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, _toDisplayString(roomDescription.value), 1 /* TEXT */),
                            (__props.item.id)
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  to: { name: 'room', params: { roomId: __props.item.id } },
                                  style: {"text-decoration":"none","color":"inherit"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      class: "text-none text-decoration-underline px-1",
                                      ripple: false,
                                      variant: "text"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(t)('AvailabilityItem#Button Show more')), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["to"]))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _createVNode(_component_v_spacer),
                          _createVNode(ButtonAnimated, {
                            class: "flex-grow-0",
                            disabled: isRoomSoldOut.value,
                            large: "",
                            onClick: toggleRatePlan
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(btnText.value), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["disabled"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createCommentVNode(" Room rates list "),
          _createVNode(_component_v_expand_transition, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_v_divider),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.products, (product) => {
                  return (_openBlock(), _createBlock(RatePlanItem, {
                    key: product.ratePlanId,
                    "room-id": __props.item.id,
                    item: product
                  }, null, 8 /* PROPS */, ["room-id", "item"]))
                }), 128 /* KEYED_FRAGMENT */))
              ], 512 /* NEED_PATCH */), [
                [_vShow, state.showRatePlans]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})