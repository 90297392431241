import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex align-center mb-2 text-caption" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-center mr-1"
}
const _hoisted_3 = {
  class: "icon-person-bold",
  style: {"font-size":"1rem"}
}
const _hoisted_4 = {
  class: "icon-person-outline",
  style: {"font-size":"1rem"}
}
const _hoisted_5 = {
  key: 1,
  class: "text-uppercase"
}
const _hoisted_6 = {
  key: 2,
  class: "text-uppercase"
}
const _hoisted_7 = {
  key: 3,
  class: "text-uppercase"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.room.maxOccupancy)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentRoomStay.nbAdults, (n) => {
            return (_openBlock(), _createElementBlock("i", _hoisted_3))
          }), 256 /* UNKEYED_FRAGMENT */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.numberOfFreePlaces($props.room.maxOccupancy), (n) => {
            return (_openBlock(), _createElementBlock("i", _hoisted_4))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ($options.roomType.standardNumBedrooms)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(
          'Search#Results#Room#NumBedRooms Bedrooms',
          $options.roomType.standardNumBedrooms
        )), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($options.roomType.standardNumBeds)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('Search#Results#Room#NumBeds Beds', $options.roomType.standardNumBeds)), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($options.roomType.standardNumBathrooms)
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t(
          'Search#Results#Room#NumBathroomsSingular Bathroom',
          $options.roomType.standardNumBathrooms
        )), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}