import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "filter-item__content overflow-y-auto",
  style: {"max-height":"300px"}
}

import { inject, ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { isArray, union, without } from 'lodash'
import { isDesktop, isMobile } from '@/app/composables/useBreakpoints'
import { LocalStorage } from '@/app/services/storage.service'
import { getCSSVariable, parseJson } from '@/app/system/helper'

interface Choice {
  code: number | string
  label: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterItem',
  props: {
    choices: { default: () => [] },
    defaultFilter: {},
    filterId: {},
    title: { default: 'Filters' }
  },
  emits: ["filter"],
  setup(__props: any, { emit: __emit }) {

const emitter = inject('emitter')
const emit = __emit

const props = __props

const store = useStore()

const isExpanded = ref<boolean>(true)
const activeFilters = ref<Array<number | string>>([])
const checkboxModels = ref<Record<string | number, boolean>>({})

const localStorageKeyName = computed<string>(() => `filters_${props.filterId}`)
const codes = computed<Array<number | string>>(() =>
  props.choices.map(({ code }) => code)
)
const rooms = computed(() => store.getters.rooms)

const isAllFiltersSelected = computed<boolean>({
  get: () => {
    return (
      isArray(activeFilters.value) &&
      isArray(codes.value) &&
      (props.filterId === 'titles'
        ? !activeFilters.value.length ||
          activeFilters.value.length === props.choices.length
        : activeFilters.value.length === props.choices.length)
    )
  },
  set: (value: boolean) => {
    // This will be handled by toggleAllFilters
  }
})

const updateCheckboxModels = (): void => {
  const models: Record<string | number, boolean> = {}
  props.choices.forEach((choice: Choice) => {
    models[choice.code] = activeFilters.value.includes(choice.code)
  })
  checkboxModels.value = models
}

const toggleAllFilters = (checked: boolean): void => {
  if (props.filterId === 'titles') {
    activeFilters.value = []
  }
  if (props.filterId === 'amenities') {
    activeFilters.value = checked ? [...codes.value] : []
  }
  updateCheckboxModels()
}

const resetAllFilters = (): void => {
  store.commit('RESET_ROOM_FILTERS')
  activeFilters.value = []
  updateCheckboxModels()
}

const updateFilter = (checked: boolean, code: number | string): void => {
  if (
    props.filterId === 'titles' &&
    activeFilters.value.length === props.choices.length - 1 &&
    checked
  ) {
    activeFilters.value = []
  } else {
    activeFilters.value = checked
      ? union(activeFilters.value, [code])
      : without(activeFilters.value, code)
  }
}

const applyFilters = (filters: Array<number | string>): void => {
  emit('filter', { [props.filterId]: filters })
}

watch(
  rooms,
  (newRooms: any[]) => {
    if (newRooms.length < 1) {
      return
    }
    activeFilters.value =
      parseJson(LocalStorage.getItem(localStorageKeyName.value)) || []
    updateCheckboxModels()
    emitter.emit('apply-filters')
  },
  { immediate: true }
)

watch(
  activeFilters,
  (newActiveFilters: Array<number | string>) => {
    if (!isArray(newActiveFilters) || !isArray(codes.value)) {
      return
    }

    const activeFiltersAsArray = Array.from(newActiveFilters)
    LocalStorage.setItem(
      localStorageKeyName.value,
      JSON.stringify(activeFiltersAsArray)
    )

    updateCheckboxModels()

    if (!isMobile.value) {
      applyFilters(newActiveFilters)
    }
  },
  { deep: true }
)

onMounted(() => {
  // Initialize checkbox models
  updateCheckboxModels()

  emitter.on('reset-filters', resetAllFilters)
  emitter.on('apply-filters', () => {
    applyFilters(activeFilters.value)
  })
  emitter.on('refresh-session', () => {
    LocalStorage.removeItem(localStorageKeyName.value)
    resetAllFilters()
  })
})

onBeforeUnmount(() => {
  emitter.off('reset-filters')
  emitter.off('apply-filters')
})

return (_ctx: any,_cache: any) => {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    elevation: _unref(isDesktop) ? 1 : 0,
    class: "pa-4 mb-lg-8"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['filter-item', { 'is-active': isExpanded.value }])
      }, [
        _createElementVNode("div", {
          class: "filter-item__title",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isExpanded.value = !isExpanded.value))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass({ 'mb-3': isExpanded.value, 'mb-0': !isExpanded.value })
          }, _toDisplayString(_ctx.title), 3 /* TEXT, CLASS */)
        ]),
        _createVNode(_component_v_expand_transition, null, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_1, [
              _createVNode(_component_v_checkbox, {
                modelValue: isAllFiltersSelected.value,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((isAllFiltersSelected).value = $event)),
                  toggleAllFilters
                ],
                label: _ctx.defaultFilter,
                readonly: _ctx.filterId === 'titles' && !activeFilters.value.length,
                color: _unref(getCSSVariable)('--hs-color-btn-bg'),
                ripple: false,
                density: "compact",
                "hide-details": ""
              }, null, 8 /* PROPS */, ["modelValue", "label", "readonly", "color"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choices, (choice) => {
                return (_openBlock(), _createBlock(_component_v_checkbox, {
                  key: choice.code,
                  modelValue: checkboxModels.value[choice.code],
                  "onUpdate:modelValue": [($event: any) => ((checkboxModels.value[choice.code]) = $event), 
              (checked) => updateFilter(checked, choice.code)
            ],
                  label: choice.label,
                  name: choice.label,
                  color: _unref(getCSSVariable)('--hs-color-btn-bg'),
                  ripple: false,
                  density: "compact",
                  "hide-details": ""
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "name", "color"]))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512 /* NEED_PATCH */), [
              [_vShow, isExpanded.value]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["elevation"]))
}
}

})