import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

import { computed, getCurrentInstance, type PropType } from 'vue'
import { isMobile } from '@/app/composables/useBreakpoints'
import { getImageKitUrls, getResolution } from '@/app/system/helper'
import ImageSlider from '@/components/UI/Vuetify/ImageSlider.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PackageRoomDescription',
  props: {
  modalTitle: {
    type: String,
    required: false,
    default: ''
  },
  multimediaDescriptions: {
    type: Object as PropType<{
      images: Array<{
        createDateTime: string
        imageId: string
        imagePath: string
        lastModifyDateTime: string
        sort: number
      }>
      texts: Array<{
        createDateTime: string
        text: string
      }>
    }>,
    required: true
  }
},
  setup(__props) {

const instance = getCurrentInstance()

const props = __props

const roomPictures = computed(() =>
  getImageKitUrls(
    props.multimediaDescriptions.images,
    getResolution(instance?.proxy.$vuetify.display)
  )
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ImageSlider, {
      class: "mt-5",
      images: roomPictures.value,
      title: __props.modalTitle
    }, null, 8 /* PROPS */, ["images", "title"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.multimediaDescriptions.texts, (item) => {
      return (_openBlock(), _createElementBlock("pre", {
        key: item.imageId,
        class: "mt-6 font-weight-light",
        style: {"white-space":"pre-wrap"}
      }, _toDisplayString(item.text), 1 /* TEXT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})