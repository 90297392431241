import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TrivagoConversion = _resolveComponent("TrivagoConversion")
  const _component_TripAdvisorConversion = _resolveComponent("TripAdvisorConversion")

  return (_openBlock(), _createElementBlock("div", null, [
    ($options.isTrivago)
      ? (_openBlock(), _createBlock(_component_TrivagoConversion, {
          key: 0,
          conversion: $props.trivagoConversionMethod
        }, null, 8 /* PROPS */, ["conversion"]))
      : _createCommentVNode("v-if", true),
    ($options.isTripAdvisor && !$props.cancellation)
      ? (_openBlock(), _createBlock(_component_TripAdvisorConversion, { key: 1 }))
      : _createCommentVNode("v-if", true)
  ]))
}