import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "module__search" }

import { inject, computed } from 'vue'
import { useRoute } from 'vue-router'
import { RangeConfig } from '@vuepic/vue-datepicker'
import { isDesktop, isTablet, isMobile } from '@/app/composables/useBreakpoints'
import { addDurationToDate, getDisabledWeekDays } from '@/app/system/helper'
import store from '@/app/store'
import Dates from './Fields/DateRangePicker.vue'
import Guests from './Fields/Guests.vue'
import Loader from '@/components/UI/Loader.vue'


import { mapGetters, mapMutations } from 'vuex'
import Swal from 'sweetalert2'
import roomStayGuests from '@/app/system/mixins/roomStayGuests'

const __default__ = {
  name: 'SearchPackage',
  components: {
    Dates,
    Guests
  },
  mixins: [roomStayGuests],
  computed: {
    ...mapGetters([
      'hasPromotions',
      'bookingRequest',
      'device',
      'allowPayment',
      'isAllRoomStaysBooked',
      'loading'
    ]),
    btnText() {
      return this.loading
        ? this.$t('Search#Form#Button Please wait')
        : this.isAllRoomStaysBooked || this.allowPayment
          ? this.$t('Search#Form#Button Checkout')
          : this.$t('Search#Form#Button Search')
    },
    dates() {
      return {
        start: this.bookingRequest.checkIn,
        end: this.bookingRequest.checkOut
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.isAllRoomStaysBooked || this.allowPayment) {
        const { isConfirmed } = await Swal.fire({
          icon: 'warning',
          title: this.$t(
            'Search#Form#CompletingReservation#title Completing reservation'
          ),
          text: this.$t(
            'Search#Form#CompletingReservation#Text Do you want to complete your reservation?'
          ),
          showCancelButton: true,
          cancelButtonText: this.$t(
            'Search#Form#CompletingReservation#Button No, reset my search'
          ),
          confirmButtonText: this.$t(
            'Search#Form#CompletingReservation#Button Complete the reservation'
          ),
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        if (isConfirmed) {
          this.$emit('submit')
        } else {
          this.$emitter.emit('refresh-session')
        }
      }
    })
  },
  methods: {
    ...mapMutations(['UPDATE_BOOKING_REQUEST']),
    submitForm(params) {
      this.UPDATE_BOOKING_REQUEST({ ...params })
      this.$emit('submit')
    },
    applyDates({ start = null, end = null }) {
      this.UPDATE_BOOKING_REQUEST({
        checkIn: start,
        checkOut: end
      })
      this.submitSearchInfo()
      this.$store.dispatch('getPackagesAvailabilities', {
        hotelPackageId: this.$route.params.packageId
      })
    },
    goBackHome() {
      this.$router.push({ name: 'home' }, () => {
        this.clearAndRefreshSession()
      })
    }
  }
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  emits: ["submit"],
  setup(__props, { emit: __emit }) {

const { params } = useRoute()
const emitter = inject('emitter')
const emit = __emit

emitter.on('dropdown', () => emit('submit'))

const packageConstraints = computed(() =>
  store.getters.getPackageConstraintsById(params.packageId)
)

const minDate = computed(() => {
  if (!packageConstraints.value?.minABO) return new Date()
  return addDurationToDate(packageConstraints.value.minABO)
})

const maxDate = computed(() => {
  if (!packageConstraints.value?.maxABO) return undefined
  return addDurationToDate(packageConstraints.value.maxABO)
})

const disabledWeekDays = computed(() =>
  getDisabledWeekDays(packageConstraints.value?.arrivalRestriction)
)

const rangeConfig = computed((): RangeConfig => {
  if (!packageConstraints.value) return { partialRange: true }

  return {
    partialRange: true,
    minRange: packageConstraints.value?.minLOS,
    maxRange: packageConstraints.value?.maxLOS
  }
})

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "form form-search-rooms",
      id: "dates",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submitForm(_ctx.dates)), ["prevent"]))
    }, [
      _withDirectives(_createVNode(_component_v_btn, {
        class: "icon-back",
        ripple: false,
        variant: "plain",
        onClick: _ctx.goBackHome
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "icon-angle-left" }, null, -1 /* HOISTED */)
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]), [
        [_vShow, !_unref(isMobile)]
      ]),
      _createVNode(_component_v_row, {
        class: _normalizeClass([
          'fieldset',
          {
            'align-center': _unref(isDesktop),
            'align-start': !_unref(isDesktop),
            'justify-end': _unref(isDesktop),
            'justify-center': !_unref(isDesktop)
          }
        ])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            class: _normalizeClass({ 'pb-0': !_unref(isDesktop) }),
            lg: "4",
            sm: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(Dates, {
                class: "field date",
                rows: 1,
                range: rangeConfig.value,
                "min-date": minDate.value,
                "max-date": maxDate.value,
                "disabled-week-days": disabledWeekDays.value,
                onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.applyDates($event)))
              }, null, 8 /* PROPS */, ["range", "min-date", "max-date", "disabled-week-days"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          (_unref(isDesktop))
            ? (_openBlock(), _createBlock(_component_v_divider, {
                key: 0,
                vertical: ""
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_v_col, {
            lg: "4",
            sm: "6"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingRequest.roomStays, (roomStay, index) => {
                return (_openBlock(), _createBlock(Guests, {
                  key: `guests_${index}`,
                  "room-stay-index": index,
                  "room-stay": _ctx.bookingRequest.roomStays[index],
                  "show-room-index": false
                }, null, 8 /* PROPS */, ["room-stay-index", "room-stay"]))
              }), 128 /* KEYED_FRAGMENT */)),
              (!_unref(isDesktop))
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    type: "submit",
                    class: _normalizeClass([
                'text-none',
                { 'mt-6': _unref(isDesktop), 'mt-8': _unref(isTablet), 'mt-10': _unref(isMobile) }
              ]),
                    color: "primary",
                    ripple: false,
                    size: "x-large",
                    block: "",
                    tile: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.btnText), 1 /* TEXT */),
                      (_ctx.loading)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _cache[3] || (_cache[3] = _createTextVNode("   ")),
                            _createVNode(Loader, {
                              size: 30,
                              absolute: false,
                              thin: ""
                            })
                          ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["class"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          (_unref(isDesktop))
            ? (_openBlock(), _createBlock(_component_v_divider, {
                key: 1,
                vertical: ""
              }))
            : _createCommentVNode("v-if", true),
          (_unref(isDesktop))
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 2,
                lg: "2",
                sm: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    type: "submit",
                    color: "primary",
                    class: _normalizeClass([
              'text-none',
              { 'mt-6': _unref(isDesktop), 'mt-8': _unref(isTablet), 'mt-10': _unref(isMobile) }
            ]),
                    ripple: false,
                    disabled: _ctx.loading,
                    size: "x-large",
                    block: "",
                    tile: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.btnText), 1 /* TEXT */),
                      (_ctx.loading)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _cache[4] || (_cache[4] = _createTextVNode("   ")),
                            _createVNode(Loader, {
                              size: 30,
                              absolute: false,
                              thin: ""
                            })
                          ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["class", "disabled"])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"])
    ], 32 /* NEED_HYDRATION */)
  ]))
}
}

})