import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "block__accordion"
}
const _hoisted_2 = {
  key: 2,
  class: "block__loader"
}
const _hoisted_3 = { class: "block__subtotal" }
const _hoisted_4 = { class: "total total_room" }
const _hoisted_5 = { class: "d-flex align-center justify-space-between text-body-2 mb-1" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "title__price" }
const _hoisted_8 = { class: "title__nights" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "count" }
const _hoisted_11 = {
  key: 0,
  class: "d-flex align-center justify-space-between text-body-2"
}
const _hoisted_12 = { class: "title" }
const _hoisted_13 = { class: "flex-center" }
const _hoisted_14 = {
  key: 0,
  class: "text-uppercase"
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "count font-discounted" }
const _hoisted_17 = { class: "value" }
const _hoisted_18 = { class: "total total_services-default mb-2" }
const _hoisted_19 = { class: "title" }
const _hoisted_20 = { class: "count" }
const _hoisted_21 = { class: "block__grandtotal" }
const _hoisted_22 = { class: "total grand-total" }
const _hoisted_23 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_24 = { class: "font-weight-bold text-uppercase" }
const _hoisted_25 = { class: "font-weight-bold" }
const _hoisted_26 = {
  key: 0,
  class: "actions-toolbar mt-6"
}
const _hoisted_27 = {
  key: 0,
  class: "actions-toolbar mt-4"
}

import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { isDesktop } from '@/app/composables/useBreakpoints'


import { mapState, mapGetters, mapMutations } from 'vuex'
import { each, find, get, map, orderBy } from 'lodash'
import Swal from 'sweetalert2'
import store from '@/app/store'
import localStorage from '@/app/system/mixins/localStorage'
import roomStayGuests from '@/app/system/mixins/roomStayGuests'
import SentryClient from '@/app/system/SentryClient'
import { formatPrice, getMaxLength } from '@/app/system/helper'
import { CURRENCY_DEFAULT } from '@/app/constants'

import Accordion from '@/components/Reservation/ReservationServicesAccordion.vue'
import ButtonAnimated from '@/components/UI/Controls/ButtonAnimated.vue'
import CheckoutAnimatedServices from '@/components/Checkout/CheckoutAnimatedServices.vue'
import OrderInfoLine from '@/components/Order/OrderInfoLine.vue'
import Loader from '@/components/UI/Loader.vue'
import Price from '@/components/Common/Price/Price.vue'
import PriceSeparated from '@/components/Common/Price/PriceSeparated.vue'

const __default__ = {
  name: 'CheckoutAddServices',
  components: {
    Accordion,
    ButtonAnimated,
    CheckoutAnimatedServices,
    OrderInfoLine,
    Loader,
    Price,
    PriceSeparated
  },
  mixins: [localStorage, roomStayGuests],
  beforeRouteLeave(to, from, next) {
    if (to.name === 'home') {
      store.commit('REMOVE_LAST_ROOM_STAY_ORDER')
      store.commit('UPDATE_CURRENT_ROOM_STAY', {
        services: []
      })
    }
    next()
  },
  data() {
    return {
      selectedServices: [],
      allServices: [],
      _isRemoving: false
    }
  },
  computed: {
    ...mapState(['route']),
    ...mapGetters([
      'loading',
      'availabilitiesLoaded',
      'servicesLoaded',
      'servicesInfo',
      'currency',
      'currentRoomStay',
      'currentRoomStayGuests',
      'currentRoom',
      'bookingRequest',
      'nbUnbookedRoomStays',
      'isAllRoomStaysBooked',
      'isGoogleAdsChannel',
      'selectedRoomTypes'
    ]),
    currentRoomStayOrder() {
      return this.currentRoomStayGuests?.order
    },
    titleText() {
      const roomTypeName = this.currentRoomStayOrder?.room?.roomTypeName || ''
      if (!roomTypeName) {
        return ''
      }

      const roomNumber = this.selectedRoomTypes[roomTypeName]

      if (roomNumber <= 0) {
        return ''
      }

      const ordinalTranslations = {
        2: this.$t('Checkout#Services#Title#Genitive the second'),
        3: this.$t('Checkout#Services#Title#Genitive the third'),
        4: this.$t('Checkout#Services#Title#Genitive the fourth')
      }

      const translations = {
        withRoomNumber:
          'Checkout#Services#Title Add services for {roomNumberText} {roomName}',
        withoutRoomNumber: 'Checkout#Services#Title Add services for {roomName}'
      }

      const roomNumberText = ordinalTranslations[roomNumber]

      return roomNumber === 1
        ? this.$t(translations.withoutRoomNumber, { roomName: roomTypeName })
        : this.$t(translations.withRoomNumber, {
            roomNumberText,
            roomName: roomTypeName
          })
    },
    roomPriceDiscounted() {
      if (!this.currentRoomStayOrder) {
        return ''
      }
      const { totalPrice, totalDiscount } = this.currentRoomStayOrder
      return this.formattedPrice(totalPrice - totalDiscount)
    },
    totalPriceIncLocalTax() {
      if (!this.currentRoomStayOrder) {
        return ''
      }
      const { totalPrice, totalDiscount, totalLocalTax } =
        this.currentRoomStayOrder
      return this.isGoogleAdsChannel
        ? this.formattedPrice(totalPrice + totalLocalTax - totalDiscount)
        : this.formattedPrice(totalPrice - totalDiscount)
    },
    isDiscounted: (vm) => vm.currentRoomStay.totalDiscount > 0,
    checkoutNextStep() {
      // If only one room is unbooked, then we have to redirect a user to the next step.
      return this.nbUnbookedRoomStays <= 1
    },
    btnText() {
      return !this.servicesLoaded
        ? ''
        : this.checkoutNextStep
          ? this.$t('Checkout#Services#Button Enter your contact details')
          : this.$t('Checkout#Services Book next room')
    },
    longestServicePriceString() {
      return getMaxLength(
        map(this.allServices, (service) =>
          this.formattedPrice(service.totalPrice)
        )
      )
    },
    longestPriceString() {
      return getMaxLength([
        ...map(this.selectedServices, (service) =>
          this.formattedPrice(service.totalPrice)
        ),
        this.formattedPrice(this.currentRoomStay.totalPrice),
        this.roomPriceDiscounted,
        this.currentRoomStayOrder
          ? this.formattedPrice(
              this.currentRoomStayOrder.totalPrice -
                this.currentRoomStayOrder.totalDiscount
            )
          : 0
      ])
    },
    textIncluded: (vm) =>
      vm.$t('Checkout#Services#IncludedServiceName (Included)'),
    textSelected: (vm) =>
      vm.$t('Checkout#Services#IncludedServiceName (Mandatory)')
  },
  watch: {
    selectedServices: {
      handler() {
        this.updateSelectedServices()
      },
      deep: true,
      immediate: true
    },
    servicesLoaded(servicesLoaded) {
      if (!servicesLoaded) {
        return
      }

      let allServices = this.servicesInfo

      if (this.currentRoomStay.services.length) {
        each(this.currentRoomStay.services, ({ serviceId }) => {
          const serviceInfo = find(this.servicesInfo, { serviceId })
          if (!!serviceInfo && serviceInfo.pricingType != 'Per use') {
            this.selectedServices.push(serviceInfo)
          }
        })
      } else {
        for (let serviceInfo of allServices) {
          const selectedService = find(this.selectedServices, {
            serviceId: serviceInfo.serviceId
          })
          if (
            (serviceInfo.included || serviceInfo.required) &&
            !selectedService
          ) {
            serviceInfo.quantity = 1
            this.selectedServices.push(serviceInfo)
          }
        }
      }

      if (servicesLoaded && allServices.length === 0) {
        if (this.$route.redirectedFrom === 'CheckoutGuestInfo') {
          this.goHomeAndReload()
        }
        this.COMPLETE_ROOM_STAY_BOOKING(this.currentRoom)
        if (this.isAllRoomStaysBooked) {
          this.goToGuestInfo()
        } else {
          this.bookNextRoom()
        }
      }
      this.allServices = allServices
      this.setServicesMinQuantity()
    },
    availabilitiesLoaded(availabilitiesLoaded) {
      if (availabilitiesLoaded) {
        this.$store.dispatch('getServices')
      }
    }
  },
  created() {
    if (this.availabilitiesLoaded) {
      this.$store.dispatch('getServices')
    }
  },
  methods: {
    ...mapMutations(['ADD_ORDER_TO_ROOM_STAY', 'REMOVE_LAST_ROOM_STAY_ORDER']),
    formattedPrice(price) {
      return formatPrice(price, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    },
    getSameIdComparator(givenServiceId) {
      return ({ serviceId }) => serviceId === givenServiceId
    },
    select(id) {
      this.selectedServices.push(this.getService(id))
    },
    remove(id) {
      const index = this.selectedServices.findIndex(
        this.getSameIdComparator(id)
      )
      const serviceToRemove = this.selectedServices[index]

      // Set a flag to prevent updateSelectedServices from triggering ADD_ORDER_TO_ROOM_STAY
      this._isRemoving = true

      this.selectedServices.splice(index, 1)

      // When removing a service, trigger the dedicated mutation for GA4 tracking purposes
      const currentOrder = this.currentRoomStayOrder || this.currentRoomStay
      if (currentOrder) {
        this.$store.commit('REMOVE_SERVICE_FROM_ROOM_STAY', {
          order: { ...currentOrder },
          roomStayIndex: this.currentRoom,
          service: serviceToRemove
        })
      }

      this.$nextTick(() => {
        this._isRemoving = false
      })
    },
    isSelected(id) {
      return (
        this.selectedServices.some(this.getSameIdComparator(id)) ||
        this.isDefault(id)
      )
    },
    isDefault(id) {
      return this.getService(id).included
    },
    getService(id) {
      return this.allServices.find(this.getSameIdComparator(id))
    },
    getServicePricingTypeString(pricingType) {
      switch (pricingType) {
        case 'Per night':
          return this.$t('Checkout#Services#PricingType Per night')
        case 'Per person':
          return this.$t('Checkout#Services#PricingType Per person')
        case 'Per person per night':
          return this.$t('Checkout#Services#PricingType Per person per night')
        case 'Per stay':
          return this.$t('Checkout#Services#PricingType Per stay')
        case 'Per use':
          return this.$t('Checkout#Services#PricingType Per use')
        default:
          // return raw value if unknown
          return pricingType
      }
    },
    completeRoomStay() {
      this.COMPLETE_ROOM_STAY_BOOKING(this.currentRoom)
    },
    goToGuestInfo() {
      setTimeout(() => {
        this.$router.push({ name: 'CheckoutGuestInfo' })
      }, 200)
    },
    addRoomAndGoBack() {
      this.addRoom()
      this.completeRoomStay()
      this.bookNextRoom()
    },
    proceedToNextStep() {
      this.completeRoomStay()
      if (this.isAllRoomStaysBooked) {
        this.goToGuestInfo()
      } else {
        this.bookNextRoom()
      }
    },
    getDefaultServiceName(service) {
      return `${service.name} ${
        service.required ? this.textSelected : this.textIncluded
      }`
    },
    setServicesMinQuantity() {
      this.allServices.forEach((service) => {
        if (
          service.pricingType == 'Per use' &&
          service.minQuantity < service.maxQuantity &&
          !this.selectedServices.includes(service)
        ) {
          service.quantity = service.minQuantity ?? service.defaultQuantity
          if (service.quantity > 0) {
            service.totalDiscount = service.totalDiscount * service.quantity
            service.totalPrice = service.totalPrice * service.quantity
            this.selectedServices.push(service)
          }
        }
      })
    },
    updateServicesQuantity({ serviceId, quantity }) {
      const updatedService = this.getService(serviceId)
      // all services should have default quantity of 1
      updatedService.quantity = 1

      if (updatedService.pricingType === 'Per use') {
        updatedService.quantity = quantity
        updatedService.totalDiscount = updatedService.baseDiscount * quantity
        updatedService.totalPrice = updatedService.basePrice * quantity

        if (quantity == 0 && this.selectedServices.includes(updatedService)) {
          this.remove(serviceId)
        } else if (!this.selectedServices.includes(updatedService)) {
          this.select(serviceId)
        }
      } else {
        if (quantity > 0) {
          this.select(serviceId)
        } else {
          this.remove(serviceId)
        }
      }

      this.updateSelectedServices()
    },
    updateSelectedServices() {
      const services = this.selectedServices.map(
        ({
          serviceId,
          pricingType,
          currency,
          included,
          instance,
          mealPlanCode,
          basePrice,
          totalDiscount,
          hotelTotalPrice,
          hotelTotalDiscount,
          totalPrice,
          quantity
        }) => ({
          serviceId,
          pricingType,
          currency,
          included,
          instance,
          mealPlanCode,
          basePrice,
          totalDiscount,
          hotelTotalPrice,
          hotelTotalDiscount,
          totalPrice,
          quantity
        })
      )

      const totalServicesPrice = this.selectedServices.reduce(
        (totalServicesPrice, service) =>
          totalServicesPrice + service.totalPrice,
        0
      )
      const totalServicesDiscount = services.reduce(
        (totalServicesDiscount, service) =>
          totalServicesDiscount + service.totalDiscount,
        0
      )

      let totalPrice = this.currentRoomStay.totalPrice + totalServicesPrice

      this.UPDATE_CURRENT_ROOM_STAY({ services })
      this.ADD_ORDER_TO_ROOM_STAY({
        order: {
          ...this.currentRoomStay,
          totalPrice,
          totalDiscount:
            this.currentRoomStay.totalDiscount + totalServicesDiscount
        },
        roomStayIndex: this.currentRoom
      })
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Add Services')
})

return (_ctx, _cache) => {
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: "v-sheet-margins pa-4",
    elevation: "1"
  }, {
    default: _withCtx(() => [
      (_ctx.availabilitiesLoaded)
        ? (_openBlock(), _createBlock(_component_v_container, {
            key: 0,
            class: "checkout__block block-services pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { justify: "center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "",
                    xs: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(OrderInfoLine),
                      _createVNode(_component_v_divider, { class: "my-4" })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, {
                class: "my-0",
                justify: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "py-0",
                    xs: "12"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.titleText)
                        ? (_openBlock(), _createBlock(_component_v_card_title, {
                            key: 0,
                            class: "text-h6 font-weight-bold pl-0 pt-0 pb-4"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.titleText), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true),
                      (_ctx.allServices.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allServices, (service) => {
                              return (_openBlock(), _createBlock(Accordion, {
                                activeState: service.featured,
                                key: service.serviceId,
                                id: service.serviceId,
                                title: service.name || '',
                                "pricing-type": _ctx.getServicePricingTypeString(service.pricingType),
                                "unit-price": service.basePrice,
                                discount: service.baseDiscount,
                                description: service.descriptions,
                                "min-quantity": service.minQuantity ?? service.defaultQuantity,
                                "max-quantity": service.maxQuantity,
                                "is-selected": _ctx.isSelected(service.serviceId),
                                "is-default": service.included,
                                "is-required": service.required,
                                onSelect: _cache[0] || (_cache[0] = $event => (_ctx.select($event))),
                                onRemove: _cache[1] || (_cache[1] = $event => (_ctx.remove($event))),
                                onChangeQuantity: $event => (
                _ctx.updateServicesQuantity({
                  serviceId: service.serviceId,
                  quantity: $event
                })
              )
                              }, {
                                price: _withCtx(() => [
                                  _createVNode(Price, {
                                    price: service.basePrice,
                                    discount: service.baseDiscount,
                                    "main-price": service.mainPrice,
                                    vertical: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(PriceSeparated, {
                                        width: _ctx.longestServicePriceString,
                                        price: 
                      service.pricingType === 'Per use'
                        ? _ctx.formattedPrice(service.basePrice)
                        : _ctx.formattedPrice(service.totalPrice)
                    
                                      }, null, 8 /* PROPS */, ["width", "price"]),
                                      _createCommentVNode(" <price-separated\n                    #price-old\n                    class=\"text-decoration-line-through text-disabled\"\n                    :class=\"{ 'default-color': service.isRequired }\"\n                    :width=\"longestServicePriceString\"\n                    :price=\"formattedPrice(service.basePrice)\"\n                  />\n                  <price-separated\n                    #price-discount\n                    :width=\"longestServicePriceString\"\n                    :price=\"\n                      formattedPrice(service.basePrice - service.baseDiscount)\n                    \"\n                  /> ")
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["price", "discount", "main-price"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activeState", "id", "title", "pricing-type", "unit-price", "discount", "description", "min-quantity", "max-quantity", "is-selected", "is-default", "is-required", "onChangeQuantity"]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true),
                      (!_ctx.servicesLoaded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(Loader)
                          ]))
                        : _createCommentVNode("v-if", true),
                      _createVNode(_component_v_divider, { class: "mt-5 mb-4" })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, {
                class: "my-0",
                justify: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "py-0",
                    xs: "12"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.currentRoomStayOrder?.room?.roomTypeName || ''), 1 /* TEXT */),
                              _createElementVNode("span", _hoisted_8, [
                                _createElementVNode("span", _hoisted_9, " × " + _toDisplayString(_ctx.$t(
                          'Checkout#Services#NumNights nights',
                          _ctx.bookingRequest.nbNights
                        )), 1 /* TEXT */)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(PriceSeparated, {
                                width: _ctx.longestPriceString,
                                price: _ctx.formattedPrice(_ctx.currentRoomStay.totalPrice)
                              }, null, 8 /* PROPS */, ["width", "price"])
                            ])
                          ]),
                          (_ctx.isDiscounted)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("span", _hoisted_13, [
                                    _cache[2] || (_cache[2] = _createElementVNode("i", { class: "icon-pricetags price-discount-icon" }, null, -1 /* HOISTED */)),
                                    _cache[3] || (_cache[3] = _createTextVNode("  ")),
                                    (_ctx.bookingRequest.promoCodeInput)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.bookingRequest.promoCodeInput), 1 /* TEXT */))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('SingleRoomView#PriceDetails#Table Discount')), 1 /* TEXT */))
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_16, [
                                  _createElementVNode("span", _hoisted_17, [
                                    _createVNode(PriceSeparated, {
                                      width: _ctx.longestPriceString,
                                      price: _ctx.formattedPrice(_ctx.currentRoomStay.totalDiscount)
                                    }, null, 8 /* PROPS */, ["width", "price"])
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _createVNode(CheckoutAnimatedServices, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedServices, (selectedService) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "d-flex align-center justify-space-between text-body-2 mb-2",
                                key: selectedService.serviceId
                              }, [
                                (!selectedService.included && !selectedService.required)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createElementVNode("div", {
                                        class: "title",
                                        ref_for: true,
                                        ref: "item_title"
                                      }, _toDisplayString(selectedService.name), 513 /* TEXT, NEED_PATCH */),
                                      _createElementVNode("div", {
                                        class: "count",
                                        ref_for: true,
                                        ref: "item_count"
                                      }, [
                                        _createElementVNode("span", {
                                          class: _normalizeClass(["value", {
                        'font-discounted': selectedService.totalDiscount > 0
                      }])
                                        }, [
                                          _createVNode(PriceSeparated, {
                                            width: _ctx.longestPriceString,
                                            price: 
                          _ctx.formattedPrice(
                            selectedService.totalPrice -
                              selectedService.totalDiscount
                          )
                        
                                          }, null, 8 /* PROPS */, ["width", "price"])
                                        ], 2 /* CLASS */)
                                      ], 512 /* NEED_PATCH */)
                                    ], 64 /* STABLE_FRAGMENT */))
                                  : _createCommentVNode("v-if", true)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createElementVNode("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedServices, (selectedService) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "d-flex align-center justify-space-between text-body-2",
                              key: selectedService.serviceId
                            }, [
                              (selectedService.included || selectedService.required)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.getDefaultServiceName(selectedService)), 1 /* TEXT */),
                                    _createElementVNode("div", _hoisted_20, [
                                      _createVNode(PriceSeparated, {
                                        width: _ctx.longestPriceString,
                                        price: _ctx.formattedPrice(selectedService.totalPrice)
                                      }, null, 8 /* PROPS */, ["width", "price"])
                                    ])
                                  ], 64 /* STABLE_FRAGMENT */))
                                : _createCommentVNode("v-if", true)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('Checkout#Services Total')), 1 /* TEXT */),
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(PriceSeparated, {
                                width: _ctx.longestPriceString,
                                price: _ctx.totalPriceIncLocalTax
                              }, null, 8 /* PROPS */, ["width", "price"])
                            ])
                          ])
                        ])
                      ]),
                      (!_unref(isDesktop) && _ctx.checkoutNextStep)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                            _createVNode(ButtonAnimated, {
                              color: "secondary",
                              disabled: !_ctx.servicesLoaded,
                              onClick: _ctx.addRoomAndGoBack,
                              size: "x-large"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Checkout#Services#Button Add another room stay')), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["disabled", "onClick"])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, {
                class: "my-0",
                justify: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "fixed-toolbar mt-4 py-0",
                    xs: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: _normalizeClass(['text-none', { 'mt-2': _unref(isDesktop) }]),
                        color: "primary",
                        disabled: _ctx.loading || !_ctx.servicesLoaded,
                        ripple: false,
                        size: "x-large",
                        block: "",
                        tile: "",
                        onClick: _ctx.proceedToNextStep
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.btnText), 1 /* TEXT */),
                          (_ctx.loading || !_ctx.servicesLoaded)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[4] || (_cache[4] = _createTextVNode("   ")),
                                _createVNode(Loader, {
                                  size: 30,
                                  absolute: false,
                                  thin: ""
                                })
                              ], 64 /* STABLE_FRAGMENT */))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["class", "disabled", "onClick"]),
                      (_unref(isDesktop) && _ctx.isPossibleToAddRoomStay && _ctx.checkoutNextStep)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createVNode(ButtonAnimated, {
                              color: "secondary",
                              disabled: !_ctx.servicesLoaded,
                              onClick: _ctx.addRoomAndGoBack,
                              size: "x-large"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Checkout#Services#Button Add another room stay')), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["disabled", "onClick"])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})