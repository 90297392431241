import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

import { reactive, ref, computed, watch, onMounted, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { HttpService } from '@/app/services/http.service'
import router from '@/app/router'
import { autocomplete } from '@/app/system/constants/form'
import {
  isRequiredValidator,
  isEmailValidator,
  phoneValidator,
  postalCodeValidator
} from '@/app/system/validators.ts'
import { getCSSVariable } from '@/app/system/helper'

import ButtonAnimated from '@/components/UI/Controls/ButtonAnimated.vue'

interface IGuestInfo {
  givenName: string
  surname: string
  email: string
  phone: string
  specialRequest: string
  address?: {
    addressLine: string
    postalCode: string
    city: string
    state?: string
    country: string
  }
  address2?: {
    addressLine: string
    postalCode: string
    city: string
    state?: string
    country: string
  }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservationForm',
  props: {
  role: {
    type: String as PropType<'Book' | 'Modify'>,
    required: false,
    default: 'Book'
  },
  guestInfo: {
    type: Object as PropType<IGuestInfo>,
    required: true
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  isReservationExpired: {
    type: Boolean,
    required: false,
    default: false
  }
},
  emits: ["validity-state", "submit-complete", "cancel-reservation"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()

const emit = __emit

const props = __props

const store = useStore()

const state: {
  isLoading: boolean
  isFormValid: boolean
  givenName: string
  surname: string
  phone: string
  email: string
  emailCheck: string
  addressLine: string
  postalCode: string
  city: string
  state: string
  countryCode: string
  countryName: string
  countriesTranslations: Record<string, string> | null
  countries: Array<{ value: string; text: string }>
  specialRequest: string
  isBusinessTravel: boolean
  timeout: ReturnType<typeof setTimeout> | null
} = reactive({
  isLoading: false,
  isFormValid: false,
  givenName: props.guestInfo.givenName || '',
  surname: props.guestInfo.surname || '',
  phone: props.guestInfo.phone || '',
  email: props.guestInfo.email || '',
  emailCheck: props.role == 'Modify' ? props.guestInfo.email : '',
  addressLine:
    props.guestInfo.address?.addressLine ||
    props.guestInfo.address2?.addressLine ||
    '',
  postalCode:
    props.guestInfo.address?.postalCode ||
    props.guestInfo.address2?.postalCode ||
    '',
  city: props.guestInfo.address?.city || props.guestInfo.address2?.city || '',
  state:
    props.guestInfo.address?.state || props.guestInfo.address2?.state || '',
  countryCode:
    props.guestInfo.address?.country || props.guestInfo.address2?.country || '',
  countryName: '',
  countriesTranslations: null,
  countries: [],
  specialRequest: props.guestInfo.specialRequest || '',
  isBusinessTravel: false,
  timeout: null
})

watch(
  () => props.guestInfo,
  () => {
    state.givenName = props.guestInfo.givenName
    state.surname = props.guestInfo.surname
    state.email = props.guestInfo.email
    state.phone = props.guestInfo.phone
    state.specialRequest = props.guestInfo.specialRequest
    state.addressLine =
      props.guestInfo.address?.addressLine ||
      props.guestInfo.address2?.addressLine ||
      ''
    state.postalCode =
      props.guestInfo.address?.postalCode ||
      props.guestInfo.address2?.postalCode ||
      ''
    state.city =
      props.guestInfo.address?.city || props.guestInfo.address2?.city || ''
    state.state =
      props.guestInfo.address?.state || props.guestInfo.address2?.state || ''
    state.countryCode =
      props.guestInfo.address?.country ||
      props.guestInfo.address2?.country ||
      ''
  }
)

watch(
  () => store.getters.availabilitiesLoaded,
  (isLoaded) => {
    if (
      isLoaded &&
      !store.getters.isAllRoomStaysBooked &&
      props.role == 'Book'
    ) {
      router.push({ name: 'home' })
    }
  }
)

watch(
  () => state.isFormValid,
  (validityState) => {
    emit('validity-state', validityState)
  },
  {
    immediate: true
  }
)

onMounted(() => {
  setCountries()
})

const countrySelect = ref()

const showAddressFields = computed(
  () => store.getters.addressRequired || props.role == 'Modify'
)

const submitBtnText = computed(() => {
  return state.isLoading
    ? ''
    : store.getters.isCreditCardRequired
      ? t('Checkout#Contact#Button Enter payment details')
      : t('Checkout#Contact#Button Validate my reservation')
})

async function setCountries() {
  state.countriesTranslations = await HttpService.requestSecured(
    {
      urlCustom: `${process.env.TRANSLATIONS_ENDPOINT}/out/ISO3166-1/2/${store.getters.language}.json`
    },
    'Countries translations request has been failed'
  )
  if (state.countriesTranslations) {
    state.countries = Object.entries(state.countriesTranslations).map(
      ([code, name]) => ({ value: code, text: name as string })
    )
  }

  const countryCode = props.guestInfo.address2?.country
  if (countryCode) {
    state.countryCode = countryCode
    if (state.countriesTranslations) {
      state.countryName = state.countriesTranslations[countryCode] as string
    }
  }
}

function onChangeCountry(value: string) {
  if (typeof value === 'string') {
    const selectedCountry = state.countries.find(
      (country) => country.text === value
    )
    if (selectedCountry) {
      state.countryCode = selectedCountry.value
      state.countryName = value
      countrySelect.value.blur()
    }
  }
}

function onUpdateCountry(value: string) {
  if (value && state.countries.find((item) => item.text === value)) {
    const selectedCountry = state.countries.find((item) => item.text === value)
    if (selectedCountry) {
      state.countryCode = selectedCountry.value
      state.countryName = value
      countrySelect.value.blur()
    }
  }
}

function onKeyDownCountry(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    countrySelect.value.blur()
  }
}

function submit() {
  state.isLoading = true
  state.timeout && clearTimeout(state.timeout)
  const guestInfo = {
    givenName: state.givenName,
    surname: state.surname,
    phone: state.phone,
    email: state.email,
    specialRequest: state.specialRequest,
    businessTravel: state.isBusinessTravel,
    address: {
      addressLine: state.addressLine,
      postalCode: state.postalCode,
      city: state.city,
      state: state.state,
      country: state.countryCode
    }
  }
  store.commit('UPDATE_BOOKING_REQUEST', { guestInfo })
  emit('submit-complete', guestInfo)

  state.timeout = setTimeout(() => {
    props.role == 'Book' && router.push({ name: 'PaymentDetails' })
  }, 200)
}

const emailMatches = (value: string) =>
  value === state.email ||
  'Form#Validation The given email addresses are different'

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_loader = _resolveComponent("loader")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    modelValue: state.isFormValid,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((state.isFormValid) = $event)),
    onSubmit: _withModifiers(submit, ["prevent"])
  }, {
    default: _withCtx(() => [
      (__props.showTitle)
        ? (_openBlock(), _createBlock(_component_v_card_title, {
            key: 0,
            class: "text-h6 font-weight-bold pl-0 pt-0 pb-4"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('Checkout#Contact#Title Your Contact Information')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_v_text_field, {
        class: "mb-2",
        modelValue: state.givenName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.givenName) = $event)),
        name: "givenName",
        label: _unref(t)('Checkout#Contact#Field First Name'),
        autocomplete: _unref(autocomplete).firstName,
        rules: [_unref(isRequiredValidator)],
        disabled: __props.isReservationExpired,
        color: _unref(getCSSVariable)('--hs-color-btn-bg'),
        variant: "outlined",
        rounded: "0"
      }, {
        message: _withCtx(({ message }) => [
          _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]),
      _createVNode(_component_v_text_field, {
        class: "mb-2",
        modelValue: state.surname,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.surname) = $event)),
        name: "surname",
        label: _unref(t)('Checkout#Contact#Field Last Name'),
        autocomplete: _unref(autocomplete).lastName,
        rules: [_unref(isRequiredValidator)],
        disabled: __props.isReservationExpired,
        color: _unref(getCSSVariable)('--hs-color-btn-bg'),
        variant: "outlined",
        rounded: "0"
      }, {
        message: _withCtx(({ message }) => [
          _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]),
      _createVNode(_component_v_text_field, {
        class: "mb-2",
        modelValue: state.phone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.phone) = $event)),
        type: "tel",
        name: "phone",
        label: _unref(t)('Checkout#Contact#Field Phone Number'),
        autocomplete: _unref(autocomplete).phone,
        rules: [_unref(isRequiredValidator), _unref(phoneValidator)],
        disabled: __props.isReservationExpired,
        color: _unref(getCSSVariable)('--hs-color-btn-bg'),
        variant: "outlined",
        rounded: "0"
      }, {
        message: _withCtx(({ message }) => [
          _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]),
      _createVNode(_component_v_text_field, {
        class: "mb-2",
        modelValue: state.email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((state.email) = $event)),
        type: "email",
        name: "email",
        label: _unref(t)('Checkout#Contact#Field Email'),
        autocomplete: _unref(autocomplete).email,
        rules: [_unref(isRequiredValidator), _unref(isEmailValidator)],
        disabled: __props.role == 'Modify',
        color: _unref(getCSSVariable)('--hs-color-btn-bg'),
        variant: "outlined",
        rounded: "0"
      }, {
        message: _withCtx(({ message }) => [
          _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]),
      (__props.role == 'Book')
        ? (_openBlock(), _createBlock(_component_v_text_field, {
            key: 1,
            modelValue: state.emailCheck,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((state.emailCheck) = $event)),
            class: "mb-2",
            type: "email",
            name: "email-check",
            label: _unref(t)('Checkout#Contact#Field Confirm your email'),
            autocomplete: _unref(autocomplete).email,
            rules: [_unref(isRequiredValidator), _unref(isEmailValidator), emailMatches],
            color: _unref(getCSSVariable)('--hs-color-btn-bg'),
            variant: "outlined",
            rounded: "0"
          }, {
            message: _withCtx(({ message }) => [
              _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "color"]))
        : _createCommentVNode("v-if", true),
      (showAddressFields.value)
        ? (_openBlock(), _createBlock(_component_v_text_field, {
            key: 2,
            modelValue: state.addressLine,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((state.addressLine) = $event)),
            class: "mb-2",
            name: "street",
            label: _unref(t)('Reservation#Modify#Field#Street Street address'),
            autocomplete: _unref(autocomplete).street,
            rules: showAddressFields.value ? [_unref(isRequiredValidator)] : [],
            disabled: __props.isReservationExpired,
            color: _unref(getCSSVariable)('--hs-color-btn-bg'),
            variant: "outlined",
            rounded: "0"
          }, {
            message: _withCtx(({ message }) => [
              _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]))
        : _createCommentVNode("v-if", true),
      (showAddressFields.value)
        ? (_openBlock(), _createBlock(_component_v_text_field, {
            key: 3,
            modelValue: state.postalCode,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((state.postalCode) = $event)),
            class: "mb-2",
            name: "postal-code",
            label: _unref(t)('Reservation#Modify#Field#Postal Postal code'),
            autocomplete: _unref(autocomplete).postalCode,
            rules: 
        showAddressFields.value
          ? [_unref(isRequiredValidator), _unref(postalCodeValidator)]
          : [_unref(postalCodeValidator)]
      ,
            disabled: __props.isReservationExpired,
            color: _unref(getCSSVariable)('--hs-color-btn-bg'),
            variant: "outlined",
            rounded: "0"
          }, {
            message: _withCtx(({ message }) => [
              _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]))
        : _createCommentVNode("v-if", true),
      (showAddressFields.value)
        ? (_openBlock(), _createBlock(_component_v_text_field, {
            key: 4,
            modelValue: state.city,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((state.city) = $event)),
            class: "mb-2",
            name: "city",
            label: _unref(t)('Reservation#Modify#Field#City City'),
            autocomplete: _unref(autocomplete).city,
            rules: showAddressFields.value ? [_unref(isRequiredValidator)] : [],
            disabled: __props.isReservationExpired,
            color: _unref(getCSSVariable)('--hs-color-btn-bg'),
            variant: "outlined",
            rounded: "0"
          }, {
            message: _withCtx(({ message }) => [
              _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]))
        : _createCommentVNode("v-if", true),
      (showAddressFields.value && _unref(store).state.tenantId == 3)
        ? (_openBlock(), _createBlock(_component_v_text_field, {
            key: 5,
            modelValue: state.state,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((state.state) = $event)),
            class: "mb-2",
            name: "state",
            label: _unref(t)('Reservation#Modify#Field#State State'),
            autocomplete: _unref(autocomplete).state,
            rules: 
        showAddressFields.value && _unref(store).state.tenantId === 3
          ? [_unref(isRequiredValidator)]
          : []
      ,
            disabled: __props.isReservationExpired,
            color: _unref(getCSSVariable)('--hs-color-btn-bg'),
            variant: "outlined",
            rounded: "0"
          }, {
            message: _withCtx(({ message }) => [
              _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "disabled", "color"]))
        : _createCommentVNode("v-if", true),
      (showAddressFields.value)
        ? (_openBlock(), _createBlock(_component_v_autocomplete, {
            key: 6,
            ref_key: "countrySelect",
            ref: countrySelect,
            name: "country",
            class: "mb-2",
            modelValue: state.countryName,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((state.countryName) = $event)),
            "item-title": "text",
            "item-value": "text",
            items: state.countries,
            label: _unref(t)('Reservation#Modify#Field#Country Country'),
            autocomplete: _unref(autocomplete).country,
            "no-data-text": _unref(t)('Country#Switcher#NoResults Country not found'),
            rules: [_unref(isRequiredValidator)],
            disabled: __props.isReservationExpired,
            color: _unref(getCSSVariable)('--hs-color-btn-bg'),
            variant: "outlined",
            rounded: "0",
            eager: "",
            onKeydown: onKeyDownCountry,
            "onUpdate:searchInput": onUpdateCountry,
            onChange: onChangeCountry
          }, {
            message: _withCtx(({ message }) => [
              _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "items", "label", "autocomplete", "no-data-text", "rules", "disabled", "color"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_v_textarea, {
        modelValue: state.specialRequest,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((state.specialRequest) = $event)),
        name: "special-request",
        label: _unref(t)('Checkout#Contact#Field Special requests'),
        disabled: __props.isReservationExpired,
        color: _unref(getCSSVariable)('--hs-color-btn-bg'),
        variant: "outlined",
        rounded: "0",
        "hide-details": "",
        "no-resize": ""
      }, null, 8 /* PROPS */, ["modelValue", "label", "disabled", "color"]),
      _createVNode(_component_v_checkbox, {
        class: "ml-2",
        modelValue: state.isBusinessTravel,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((state.isBusinessTravel) = $event)),
        label: _unref(t)('Checkout#Contact#Field Business travel'),
        disabled: __props.isReservationExpired,
        color: _unref(getCSSVariable)('--hs-color-btn-bg'),
        "hide-details": ""
      }, null, 8 /* PROPS */, ["modelValue", "label", "disabled", "color"]),
      (__props.role == 'Book')
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 7,
            class: "reservation-form__button text-none",
            type: "submit",
            color: "primary",
            disabled: !state.isFormValid || __props.isReservationExpired,
            ripple: false,
            size: "x-large",
            block: "",
            tile: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(submitBtnText.value), 1 /* TEXT */),
              (_unref(store).getters.loading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[14] || (_cache[14] = _createTextVNode("   ")),
                    _createVNode(_component_loader, {
                      size: 30,
                      absolute: false,
                      thin: ""
                    })
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"]))
        : _createCommentVNode("v-if", true),
      (__props.role == 'Modify' && !__props.isReservationExpired)
        ? (_openBlock(), _createBlock(ButtonAnimated, {
            key: 8,
            class: "reservation-form__button",
            size: "x-large",
            onClick: _cache[12] || (_cache[12] = ($event: any) => (emit('cancel-reservation', $event)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('Reservation#Modify#Button#Cancel Cancel the reservation')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "modification-deadline")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})