import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-quantity d-flex align-center" }

import { reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { TranslateResult } from 'vue-i18n'
import { inRange } from 'lodash'
import Snackbar from '@/components/UI/Vuetify/Snackbar.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'InputQuantity',
  props: {
  min: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  },
  default: {
    type: Number,
    required: false
  },
  textMin: {
    type: String,
    required: false
  },
  textMax: {
    type: String,
    required: false
  },
  dimensions: {
    type: Number,
    required: false,
    default: 40
  },
  isRequired: {
    type: Boolean,
    required: false,
    default: false
  }
},
  emits: ['change'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()

const props = __props

const state: {
  quantity: number
  showSnackbar: boolean
  snackbarText: TranslateResult
} = reactive({
  quantity: props.default ?? props.min,
  showSnackbar: false,
  snackbarText: ''
})

const emit = __emit

watch(
  () => props.default,
  (newVal) => {
    if (newVal && inRange(newVal, props.min + 1, props.max + 1)) {
      state.quantity = newVal
    }
  }
)

function decrement() {
  if (state.quantity > 0) {
    if (state.quantity === props.min && props.textMin) {
      state.showSnackbar = true
      state.snackbarText = t(props.textMin)
    }
    if (state.quantity > props.min) {
      emit('change', --state.quantity)
    }
  }
}

function increment() {
  if (state.quantity < props.max) {
    emit('change', ++state.quantity)
  } else if (props.textMax) {
    state.showSnackbar = true
    state.snackbarText = t(props.textMax, { max: props.max })
  }
}

return (_ctx: any,_cache: any) => {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      class: _normalizeClass([
        'text-none',
        {
          'input-quantity--disabled': state.quantity === __props.min
        }
      ]),
      color: state.quantity > 0 ? 'primary' : '',
      height: __props.dimensions,
      "min-width": __props.dimensions,
      width: __props.dimensions,
      ripple: false,
      size: "large",
      variant: "outlined",
      rounded: "0",
      onClick: _withModifiers(decrement, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fa_icon, { icon: ['fas', 'minus'] })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "color", "height", "min-width", "width"]),
    _createVNode(_component_v_text_field, {
      value: state.quantity,
      style: {"width":"44px"},
      type: "number",
      readonly: "",
      "hide-details": "",
      density: "compact",
      variant: "plain",
      "bg-color": "surface"
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode(_component_v_btn, {
      class: _normalizeClass({ 'input-quantity--disabled': state.quantity === __props.max }),
      color: state.quantity > 0 ? 'primary' : '',
      height: __props.dimensions,
      "min-width": __props.dimensions,
      width: __props.dimensions,
      ripple: false,
      size: "large",
      variant: "outlined",
      rounded: "0",
      onClick: _withModifiers(increment, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fa_icon, { icon: ['fas', 'plus'] })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "color", "height", "min-width", "width"]),
    _createVNode(Snackbar, {
      modelValue: state.showSnackbar,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.showSnackbar) = $event)),
      text: state.snackbarText
    }, null, 8 /* PROPS */, ["modelValue", "text"])
  ]))
}
}

})