import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "home-label" }
const _hoisted_3 = { key: 1 }

import { isDesktop } from "@/app/composables/useBreakpoints";

export default {
  __name: 'LinkHome',
  setup(__props) {


return (_ctx, _cache) => {
  return (_unref(isDesktop))
    ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "icon-home3 home-icon" }, null, -1 /* HOISTED */)),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('App#Logo#Title Home')), 1 /* TEXT */)
      ]))
    : (!_unref(isDesktop))
      ? (_openBlock(), _createElementBlock("a", _hoisted_3, _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "icon-home3 home-icon" }, null, -1 /* HOISTED */)
        ])))
      : _createCommentVNode("v-if", true)
}
}

}