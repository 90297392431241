import _isEmpty from 'lodash/isEmpty'
import isAlphanumeric from 'validator/es/lib/isAlphanumeric'
import isCreditCard from 'validator/es/lib/isCreditCard'
import isEmail from 'validator/es/lib/isEmail'
import isInt from 'validator/es/lib/isInt'
import isLength from 'validator/es/lib/isLength'
import { each, filter, find } from 'lodash'
import { i18n } from '../vue-config/i18n'

export default class Validation {
  /**
   * @param fields { [{ id: string, val: string, validationType: string, validationField: string, required: boolean, hasError: boolean, errorMessage: string }] }
   */
  constructor(fields) {
    /**
     * @type { [{ id: string, val: string, validationType: string, validationField: string, required: boolean, hasError: boolean, errorMessage: string }] }
     */
    this.fields = fields
  }

  invalidFields() {
    return filter(this.fields, { hasError: true })
  }

  maxLength(fieldId) {
    const field = this.getField(fieldId)
    if (!isLength(field.val, { max: field.maxLength || 255 })) {
      let errorMessage = i18n.global.t(
        'Form#Validation The field {fieldName} exceeds maximum length!',
        { fieldName: i18n.global.t(field.title) }
      )
      this.addError(fieldId, errorMessage)
    }
  }

  /**
   * @param fieldId { string }
   */
  validateField(fieldId) {
    this.resetFieldValidation(fieldId)

    const field = this.getField(fieldId)
    const fieldValue = field.val ? String(field.val) : ''
    if (!field.required) {
      return
    }

    if (_isEmpty(fieldValue)) {
      let _error = i18n.global.t(
        'Form#Validation The field {fieldName} is required!',
        {
          fieldName: i18n.global.t(field.title)
        }
      )
      if (_isEmpty(field.val)) {
        this.addError(fieldId, _error)
      }
      return
    }

    switch (field.validationType) {
      case 'text':
      case 'number':
      case 'datalist':
        break
      case 'email':
      case 'email_check': {
        if (!isEmail(field.val)) {
          const _error = i18n.global.t(
            'Form#Validation Please enter correct email, e.g johndoe@gmail.com'
          )
          this.addError(fieldId, _error)
        } else if (field.validationType === 'email_check') {
          const fieldToCheck = find(this.fields, { id: field.validationField })
          if (!fieldToCheck) {
            throw new Error(
              `There is no field with ID "${field.validationField}" for emails comparison.`
            )
          }

          if (fieldToCheck.val !== field.val) {
            let _error = i18n.global.t(
              'Form#Validation The given email addresses are different'
            )
            this.addError(fieldId, _error)
          }
        }
        break
      }
      case 'password': {
        if (
          !isLength(field.val, { min: 6, max: undefined }) ||
          !isAlphanumeric(field.val) ||
          !field.val.match('[0-9]')
        ) {
          const _error = i18n.global.t(
            'Form#Validation Password must be 6 or more characters and contain at least 1 number'
          )
          this.addError(fieldId, _error)
        }
        break
      }
      case 'phone': {
        field.val = field.val.replace(/[.\- ]/g, '')
        if (
          !field.val.match(
            /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
          )
        ) {
          const _error = i18n.global.t(
            'Form#Validation The entered phone number is not correct'
          )
          this.addError(fieldId, _error)
        }
        break
      }
      case 'cardNumber': {
        if (!isCreditCard(field.val)) {
          const _error = i18n.global.t(
            'Form#Validation Please enter correct credit card number'
          )
          this.addError(fieldId, _error)
        }
        break
      }
      case 'cardCVV': {
        if (!isInt(field.val, { gt: 2, lt: 5 })) {
          const _error = i18n.global.t(
            'Form#Validation Please enter correct CVV (3-4 numbers on the back of the card)'
          )
          this.addError(fieldId, _error)
        }
        break
      }
      case 'reservationId': {
        if (!field.val.match(/^SB4-[a-zA-Z0-9]{10}$/)) {
          const _error = i18n.global.t(
            'Form#Validation Reservation number format must be SB4-XXXXXXXX'
          )
          this.addError(fieldId, _error)
        }
        break
      }
      case 'name': {
        this.maxLength(fieldId)
        break
      }
      case 'postal': {
        this.maxLength(fieldId)
        break
      }
      default: {
        // eslint-disable-next-line no-console
        console.warn(`Validation type '${field.validationType}' does not exist`)
        break
      }
    }
  }

  /**
   * @param fieldId { string }
   * @param errorMessage { string }
   */
  addError(fieldId, errorMessage) {
    const field = this.getField(fieldId)

    field.hasError = true
    field.errorMessage = errorMessage
  }

  /**
   * @param fieldId { string }
   */
  resetFieldValidation(fieldId) {
    const field = this.getField(fieldId)

    field.hasError = false
    field.errorMessage = ''
  }

  validate() {
    each(this.fields, ({ id }) => {
      this.validateField(id)
    })
  }

  isValid() {
    return _isEmpty(this.invalidFields())
  }

  /**
   * @param fieldId { string }
   * @return { { id: string, val: string, validationType: string, validationField: string, required: boolean, hasError: boolean, errorMessage: string } }
   */
  getField(fieldId) {
    let field = find(this.fields, { id: fieldId })
    if (!field) {
      throw new Error(`There is no field with ID "${fieldId}"`)
    }

    return field
  }
}
