import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__heading form__paymentcard_logos",
  "data-role": "checkout-animation-body"
}
const _hoisted_2 = ["src", "alt", "title"]
const _hoisted_3 = {
  class: "form__message",
  "data-role": "checkout-animation-body"
}
const _hoisted_4 = {
  class: "form__body",
  "data-role": "checkout-animation-body"
}
const _hoisted_5 = { class: "fieldset" }
const _hoisted_6 = { class: "field__row" }
const _hoisted_7 = { class: "cvv-block mb-0" }
const _hoisted_8 = { class: "cvv-block__img pt-3" }
const _hoisted_9 = ["src", "alt", "title"]
const _hoisted_10 = { class: "error-row" }
const _hoisted_11 = { class: "field__row" }
const _hoisted_12 = { class: "field__row" }
const _hoisted_13 = {
  class: "form__footer mt-6",
  "data-role": "checkout-animation-footer"
}
const _hoisted_14 = { class: "total row-total" }
const _hoisted_15 = {
  class: "form__footer security-icons",
  "data-role": "checkout-animation-footer"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]

import { getCurrentInstance, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { PortalTarget } from 'portal-vue'
import { debounce, find } from 'lodash'
import {
  isRequiredValidator,
  ccExpirationValidator,
  isCCExpiredValidator
} from '@/app/system/validators.ts'
import { autocomplete } from '@/app/system/constants/form'
import store from '@/app/store'

import field from '@/components/UI/Controls/Inputs/fieldText.vue'
import fieldCard from '@/components/UI/Controls/Inputs/fieldCard.vue'
import fieldCVV from '@/components/UI/Controls/Inputs/fieldCVV.vue'
import OrderTotalPrice from '@/components/Order/OrderTotalPrice.vue'
import CheckboxPrivacyPolicy from '@/components/UI/CheckboxPrivacyPolicy.vue'
import Loader from '@/components/UI/Loader.vue'
import PaymentInlineError from './PaymentInlineError.vue'

import amex from '@/app/static/images/payment-icons/amex.png'
import bank from '@/app/static/images/payment-icons/bank.png'
import cb from '@/app/static/images/payment-icons/cb.png'
import diners from '@/app/static/images/payment-icons/diners.png'
import discover from '@/app/static/images/payment-icons/discover.png'
import jcb from '@/app/static/images/payment-icons/jcb.png'
import maestro from '@/app/static/images/payment-icons/maestro.png'
import mastercard from '@/app/static/images/payment-icons/mastercard.png'
import uatp from '@/app/static/images/payment-icons/uatp.png'
import visa from '@/app/static/images/payment-icons/visa.png'
import cvvImage from '@/app/static/images/payment-icons/cvv.png'
import pci from '@/app/static/images/payment-icons/pci.png'
import ssl from '@/app/static/images/payment-icons/ssl.png'


import { mapGetters } from 'vuex'
import { form } from '@/app/system/mixins/form'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const __default__ = {
  name: 'PaymentBaseCard',
  mixins: [form],
  props: {
    isCreditCardValid: {
      type: Boolean,
      required: true
    },
    isCvvValid: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fields: {
        cardNumber: {
          id: 'credit_number',
          val: '',
          title: this.$t('Checkout#Payment#Field Credit Card Number'),
          validationType: 'cardNumber',
          hasError: false,
          required: false,
          errorMessage: ''
        },
        cardOwner: {
          id: 'card_owner',
          val: '',
          title: this.$t('Checkout#Payment#Field Card Owner'),
          validationType: 'text',
          hasError: false,
          required: true,
          errorMessage: ''
        },
        cvv: {
          id: 'cvv',
          val: '',
          title: this.$t('Checkout#Payment#Field CVV'),
          validationType: 'cardCVV',
          hasError: false,
          required: false,
          errorMessage: '',
          portalTarget: 'error-cvv'
        }
      },
      isFormDirty: false,
      isFormValid: false,
      cardExpiration: '',
      termsAccepted: false
    }
  },
  computed: {
    ...mapGetters([
      'acceptedPaymentCards',
      'inlineBookingError',
      'reservationLoading'
    ]),
    fieldNumber: ({ fields }) => fields.cardNumber,
    fieldOwner: ({ fields }) => fields.cardOwner,
    fieldCVV: ({ fields }) => fields.cvv,
    debouncedValidateInput: (vm) =>
      debounce((...args) => {
        vm.validateInput(...args)
        vm.isFormDirty = true
      }, 500),
    btnText: (vm) => {
      if (vm.inlineBookingError) {
        return vm.$t('Checkout#Payment#Button Return home')
      } else {
        return vm.reservationLoading
          ? vm.$t('Checkout#Payment#Button Please wait')
          : vm.isFormValid && vm.isFormDirty && !vm.termsAccepted
            ? vm.$t(
                'Checkout#Payment#Button You have to accept the Privacy Policy'
              )
            : vm.$t('Checkout#Payment#Button Place Order')
      }
    },
    btnDisabled: (vm) =>
      vm.reservationLoading ||
      !vm.$_validator.isValid() ||
      !vm.isFormDirty ||
      !vm.isFormValid ||
      !vm.cardExpiration ||
      !vm.isCreditCardValid ||
      !vm.isCvvValid ||
      !vm.termsAccepted
  },
  mounted() {
    this.$nextTick(() => {
      const cardHolder = this.$refs['cardHolder']
      if (cardHolder) {
        const { height } = cardHolder.$el.getBoundingClientRect()
        this.$emit('set-field-height', height)
      }
    })
  },
  methods: {
    normalizeExpiryInput() {
      const value = this.cardExpiration
      if (value.length === 2 && value.charAt(2) !== '/') {
        // Insert "/" after the first two characters
        this.cardExpiration = value.slice(0, 2) + '/'
      }
      this.$nextTick(() => {
        // Handles the case when slash is not inserted in the right place
        if (value.length !== 3 && value.endsWith('/')) {
          this.cardExpiration = value.slice(0, -1)
        }
        // Removes all invalid characters (e.g., not numbers and slash)
        const invalidCharRegex = /[^0-9/]/
        if (invalidCharRegex.test(value)) {
          this.cardExpiration = value.replace(invalidCharRegex, '')
        }
        // Prevent further input once the desired length is reached (e.g., 5 characters)
        if (this.cardExpiration.length > 5) {
          this.cardExpiration = this.cardExpiration.slice(0, 5)
        }
      })
    },
    findFieldValue(id) {
      return this.findField(id).val
    },
    findField(id) {
      return find(this.fields, { id })
    },
    submitComplete() {
      this.$store.commit('SET_RESERVATION_LOADING', true)
      this.$emit('submit', {
        cardHolder: this.findFieldValue('card_owner'),
        expiryMonth: Number(this.cardExpiration.split('/')[0]),
        expiryYear: Number(`20${this.cardExpiration.split('/')[1]}`)
      })
    },
    validateInput(field) {
      this.$_validator.validateField(field.id)
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  emits: ['set-field-height', 'submit'],
  setup(__props, { emit: __emit }) {

const instance = getCurrentInstance()
const currentRoute = useRoute()

const { t } = useI18n()
const emit = __emit

watch(
  () => store.getters.availabilitiesLoaded,
  (isLoaded) => {
    if (
      isLoaded &&
      currentRoute.name != 'ReservationPayment' &&
      !store.getters.allowPayment
    ) {
      instance.proxy.goHomeAndReload()
    }
  }
)

const cvvImageAlt = computed(() =>
  t(
    'Checkout#Payment#CVV The last three digits displayed on the back of your credit card'
  )
)

const cardLogos = {
  AX: amex,
  BC: bank,
  BL: cb,
  CB: cb,
  DN: diners,
  DS: discover,
  EC: mastercard,
  JC: jcb,
  MA: maestro,
  MC: mastercard,
  TP: uatp,
  VI: visa
}

return (_ctx, _cache) => {
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_form = _resolveComponent("v-form")

  return (_openBlock(), _createBlock(_component_v_form, {
    class: "form form-checkout scale-label",
    modelValue: _ctx.isFormValid,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.isFormValid) = $event)),
    onSubmit: _withModifiers(_ctx.submit, ["prevent"])
  }, {
    default: _withCtx(() => [
      (_ctx.acceptedPaymentCards.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(_unref(t)('Checkout#Payment#AcceptedCards We accept:')), 1 /* TEXT */),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptedPaymentCards, (card, i) => {
              return (_openBlock(), _createElementBlock("img", {
                key: card.code,
                src: cardLogos[card.code],
                alt: card.company,
                title: card.company
              }, null, 8 /* PROPS */, _hoisted_2))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_unref(t)(
            "Checkout#Payment#Guarantee Your card won't be charged, we only need your card details to guarantee your booking."
          )), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "field" }, [
            _createElementVNode("div", { class: "field__control" }, [
              _createElementVNode("div", {
                id: "card-number",
                class: "theme-container",
                style: {"height":"56px"}
              })
            ])
          ], -1 /* HOISTED */)),
          _createVNode(field, {
            type: "text",
            ref: "cardHolder",
            modelValue: _ctx.fieldOwner.val,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.fieldOwner.val) = $event)),
            "autofill-name": _unref(autocomplete).creditCardName,
            placeholder: _ctx.fieldOwner.title,
            description: _ctx.fieldOwner.description,
            "has-error": _ctx.fieldOwner.hasError,
            "error-message": _ctx.fieldOwner.errorMessage,
            onInput: _cache[1] || (_cache[1] = $event => (_ctx.debouncedValidateInput(_ctx.fieldOwner)))
          }, null, 8 /* PROPS */, ["modelValue", "autofill-name", "placeholder", "description", "has-error", "error-message"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.cardExpiration,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.cardExpiration) = $event)),
              class: "px-0",
              style: {"width":"60%","height":"56px"},
              label: _unref(t)('Checkout#Payment#ExpirationDate Expiration date'),
              autocomplete: _unref(autocomplete).creditCardExpiration,
              rules: [
              _unref(isRequiredValidator),
              _unref(ccExpirationValidator),
              _unref(isCCExpiredValidator)
            ],
              onInput: _ctx.normalizeExpiryInput,
              variant: "outlined",
              rounded: "0"
            }, {
              message: _withCtx(({ message }) => [
                _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "onInput"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("img", {
                  class: "cvv-img",
                  src: _unref(cvvImage),
                  alt: cvvImageAlt.value,
                  title: cvvImageAlt.value
                }, null, 8 /* PROPS */, _hoisted_9)
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "field" }, [
                _createElementVNode("div", { class: "field__control" }, [
                  _createElementVNode("div", {
                    id: "card-cvv",
                    class: "theme-container",
                    style: {"height":"56px"}
                  })
                ])
              ], -1 /* HOISTED */))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_unref(PortalTarget), {
                name: _ctx.fields.cvv.portalTarget
              }, null, 8 /* PROPS */, ["name"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              (_ctx.inlineBookingError)
                ? (_openBlock(), _createBlock(PaymentInlineError, { key: 0 }))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(OrderTotalPrice)
        ]),
        _withDirectives(_createVNode(CheckboxPrivacyPolicy, {
          onChange: _cache[3] || (_cache[3] = $event => (_ctx.termsAccepted = $event))
        }, null, 512 /* NEED_PATCH */), [
          [_vShow, !_ctx.inlineBookingError]
        ]),
        _createVNode(_component_v_btn, {
          class: "text-none",
          type: "submit",
          color: "primary",
          disabled: _ctx.btnDisabled,
          ripple: false,
          block: "",
          tile: "",
          size: "x-large"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.btnText), 1 /* TEXT */),
            (_ctx.reservationLoading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[7] || (_cache[7] = _createTextVNode("   ")),
                  _createVNode(Loader, {
                    size: 30,
                    absolute: false,
                    thin: ""
                  })
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("img", { src: _unref(ssl) }, null, 8 /* PROPS */, _hoisted_16),
        _createElementVNode("img", { src: _unref(pci) }, null, 8 /* PROPS */, _hoisted_17)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onSubmit"]))
}
}

})