import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {height: '650px', width: '100%'} }
const _hoisted_2 = {
  key: 0,
  class: "block__loader"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vue_friendly_iframe = _resolveComponent("vue-friendly-iframe")
  const _component_Loader = _resolveComponent("Loader")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_vue_friendly_iframe, {
      scrolling: "yes",
      frameborder: "0",
      src: $props.url,
      className: "paymentIframe",
      onLoad: $options.onLoad
    }, null, 8 /* PROPS */, ["src", "onLoad"]), [
      [_vShow, !$data.loading]
    ]),
    ($data.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Loader)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}