import { mapGetters, mapMutations } from 'vuex'
import { RoomStay } from '../../store/modules/bookingRequest'

export const MAX_NUMBER_OF_ROOMS = 4

export default {
  computed: {
    ...mapGetters(['bookingRequest']),
    maxNumberOfRooms: () => MAX_NUMBER_OF_ROOMS,
    isPossibleToAddRoomStay: (vm) =>
      Number(vm.bookingRequest.rooms) < vm.maxNumberOfRooms
  },
  methods: {
    ...mapMutations(['ADD_NEW_ROOM_STAY', 'REMOVE_ROOM_STAY']),
    addRoom() {
      if (this.bookingRequest.rooms < MAX_NUMBER_OF_ROOMS) {
        this.ADD_NEW_ROOM_STAY({ roomStay: new RoomStay() })
      }
    },
    removeRoom(roomStay) {
      this.REMOVE_ROOM_STAY(roomStay)
    },
    clearGuests() {
      this.$emitter.emit('reset-guests')
    }
  }
}
