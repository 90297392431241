import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "text-h6 mt-10" }
const _hoisted_3 = { class: "text-body-1 mb-10" }

import { ref } from 'vue'
import store from '@/app/store'
import HotelContacts from '@/components/Common/HotelContacts.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MaintenanceView',
  setup(__props) {

const logoImg = ref<HTMLImageElement | null>(null)
const logoStyle = ref({})

const handleImageLoad = () => {
  if (!logoImg.value) {
    return
  }

  const { naturalWidth, naturalHeight } = logoImg.value
  const maxWidth = 450
  const maxHeight = 300

  let width = naturalWidth
  let height = naturalHeight

  if (naturalHeight > naturalWidth) {
    // Vertical image
    if (height > maxHeight) {
      height = maxHeight
      width = (naturalWidth * maxHeight) / naturalHeight
    }
  } else {
    // Horizontal image
    if (width > maxWidth) {
      width = maxWidth
      height = (naturalHeight * maxWidth) / naturalWidth
    }
  }

  logoStyle.value = {
    width: `${width}px`,
    height: `${height}px`
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "maintenance-view fill-height" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        justify: "center",
        align: "center",
        class: "fill-height"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "9",
            class: "text-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, {
                elevation: "1",
                class: "pt-5 pb-3 px-3"
              }, {
                default: _withCtx(() => [
                  (_unref(store).state.customization.logoUrl)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        ref_key: "logoImg",
                        ref: logoImg,
                        src: _unref(store).state.customization.logoUrl,
                        style: _normalizeStyle(logoStyle.value),
                        alt: "hotel logo",
                        onLoad: handleImageLoad
                      }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(
                'Maintenance#Title Our booking-engine is currently down for maintenance.'
              )), 1 /* TEXT */),
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(
                'Maintenance#Text We expect to be back soon. Thanks for your patience.'
              )), 1 /* TEXT */),
                  _createVNode(HotelContacts)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})