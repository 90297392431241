import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form form-checkout scale-label" }
const _hoisted_2 = {
  class: "form__message",
  "data-role": "checkout-animation-body"
}
const _hoisted_3 = {
  class: "form__body",
  "data-role": "checkout-animation-body"
}
const _hoisted_4 = { class: "fieldset" }
const _hoisted_5 = { class: "error-row" }
const _hoisted_6 = { class: "field__row" }
const _hoisted_7 = { class: "form__footer total row-total" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_payment_inline_error = _resolveComponent("payment-inline-error")
  const _component_order_total_price = _resolveComponent("order-total-price")
  const _component_checkbox_privacy_policy = _resolveComponent("checkbox-privacy-policy")
  const _component_loader = _resolveComponent("loader")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(
            'Checkout#Payment#NoCC No credit card is required to guarantee this reservation.'
          )), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('Checkout#Payment#NoCC You will pay at the hotel directly.')), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.inlineBookingError)
              ? (_openBlock(), _createBlock(_component_payment_inline_error, { key: 0 }))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_order_total_price),
      _withDirectives(_createVNode(_component_checkbox_privacy_policy, {
        onChange: _cache[0] || (_cache[0] = $event => ($data.acceptPolicy = $event))
      }, null, 512 /* NEED_PATCH */), [
        [_vShow, !_ctx.inlineBookingError]
      ]),
      _createVNode(_component_v_btn, {
        class: "text-none",
        color: "primary",
        disabled: _ctx.reservationLoading || !$data.acceptPolicy,
        ripple: false,
        size: "x-large",
        block: "",
        tile: "",
        onClick: $options.submit
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString($options.btnText), 1 /* TEXT */),
          (_ctx.reservationLoading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[1] || (_cache[1] = _createTextVNode("   ")),
                _createVNode(_component_loader, {
                  size: 30,
                  absolute: false,
                  thin: ""
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])
    ])
  ]))
}