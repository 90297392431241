import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hotel-contacts mt-5 text-center text-body-2 font-weight-light" }
const _hoisted_2 = { class: "font-weight-light" }
const _hoisted_3 = { class: "font-weight-bold" }
const _hoisted_4 = { class: "d-flex justify-center align-center" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "d-flex justify-center align-center" }
const _hoisted_7 = ["href"]

import { computed } from 'vue'
import store from '@/app/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelContacts',
  setup(__props) {

const address = computed((): string => {
  const addresses: string[] = []
  const contactInfo: {
    addresses: Array<{
      addressLine: string
      streetNmbr: string
      postalCode: string
      cityName: string
      countryName: string
    }>
  } = store.getters.contactInfo
  if (!!contactInfo && !!contactInfo.addresses) {
    contactInfo.addresses.forEach((el) => {
      addresses.push(
        [
          [el.addressLine, el.streetNmbr].join(' '),
          el.postalCode,
          el.cityName,
          el.countryName
        ].join(', ')
      )
    })
  }
  return addresses.join(';')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("strong", _hoisted_3, _toDisplayString(_unref(store).getters.hotelName), 1 /* TEXT */),
      _createElementVNode("address", null, _toDisplayString(address.value), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "icon-mail mr-2" }, null, -1 /* HOISTED */)),
      _createElementVNode("a", {
        class: "text-primary text-decoration-underline",
        href: `mailto:${_unref(store).getters.hotelEmailAddress}`
      }, _toDisplayString(_unref(store).getters.hotelEmailAddress), 9 /* TEXT, PROPS */, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[1] || (_cache[1] = _createElementVNode("i", { class: "icon-phone mr-2" }, null, -1 /* HOISTED */)),
      _createElementVNode("a", {
        class: "text-primary text-decoration-underline",
        href: `tel:${_unref(store).getters.hotelPhoneNumber}`
      }, _toDisplayString(_unref(store).getters.hotelPhoneNumber), 9 /* TEXT, PROPS */, _hoisted_7)
    ])
  ]))
}
}

})