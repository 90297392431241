import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_field_dropdown = _resolveComponent("field-dropdown")

  return (_openBlock(), _createBlock(_component_field_dropdown, {
    style: {"margin-bottom":"1.5rem"},
    list: $options.fontFamilyList,
    value: $props.selectedFont,
    "show-active-item": true,
    onInput: _cache[0] || (_cache[0] = (newValue) => _ctx.$emit('input', newValue))
  }, null, 8 /* PROPS */, ["list", "value"]))
}