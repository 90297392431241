import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-wrapper flex-container small-align-center small-align-middle" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_logo = _resolveComponent("logo")

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        class: "action close",
        to: {name: 'home'}
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "icon-close" }, null, -1 /* HOISTED */)
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_logo)
    ])
  ]))
}