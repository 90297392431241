import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "colorpicker" }
const _hoisted_2 = { class: "colorpicker__row" }
const _hoisted_3 = {
  class: "colorpicker__wrap",
  ref: "colorpicker"
}
const _hoisted_4 = { class: "colorpicker__picker" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_chrome_picker = _resolveComponent("chrome-picker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_text_field, {
          class: "no-theme",
          value: $options.inputValue,
          onFocus: $options.showPicker,
          onBlur: $options.hidePicker,
          "hide-details": "hide-details",
          readonly: "readonly",
          rounded: "0",
          variant: "outlined"
        }, null, 8 /* PROPS */, ["value", "onFocus", "onBlur"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "current-color",
            style: _normalizeStyle({ 'background-color': $data.colors.hex }),
            onClick: _cache[0] || (_cache[0] = (...args) => ($options.togglePicker && $options.togglePicker(...args)))
          }, null, 4 /* STYLE */),
          ($data.displayPicker)
            ? (_openBlock(), _createBlock(_component_chrome_picker, {
                key: 0,
                value: $data.colors,
                onInput: $options.colorChange
              }, null, 8 /* PROPS */, ["value", "onInput"]))
            : _createCommentVNode("v-if", true)
        ])
      ], 512 /* NEED_PATCH */)
    ])
  ]))
}