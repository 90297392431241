import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createVNode as _createVNode, renderList as _renderList } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "order-list-item position-relative pb-2"
}
const _hoisted_2 = { class: "room-info order_info__room_price" }
const _hoisted_3 = {
  key: 0,
  class: "room-info__img mr-4"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "room-info__body" }
const _hoisted_6 = { class: "room-info__title mb-2" }
const _hoisted_7 = {
  key: 0,
  class: "title"
}
const _hoisted_8 = { class: "rate-plan text-caption" }
const _hoisted_9 = { class: "rate-plan text-caption" }
const _hoisted_10 = { class: "room-info__price" }
const _hoisted_11 = { class: "room-info__guest" }
const _hoisted_12 = { class: "cancellation-policy text-body-2 py-4" }
const _hoisted_13 = { class: "block__subtotal mt-3 mb-1" }
const _hoisted_14 = { class: "total total_room mb-2" }
const _hoisted_15 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_16 = { class: "title font-weight-bold" }
const _hoisted_17 = { class: "count font-weight-bold" }
const _hoisted_18 = {
  key: 0,
  class: "total total_room_vat mb-2"
}
const _hoisted_19 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_20 = { class: "title" }
const _hoisted_21 = { class: "count" }
const _hoisted_22 = { class: "total total_room_tax mb-2" }
const _hoisted_23 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_24 = { class: "title ml-3 font-weight-light" }
const _hoisted_25 = { class: "count font-weight-light" }
const _hoisted_26 = { class: "total total_additional mb-2" }
const _hoisted_27 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_28 = { class: "title font-weight-bold" }
const _hoisted_29 = { class: "count font-weight-bold" }
const _hoisted_30 = { class: "total total_room_service mb-2" }
const _hoisted_31 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_32 = { class: "title font-weight-bold" }
const _hoisted_33 = { class: "count font-weight-bold" }
const _hoisted_34 = { class: "total total_room_tax mb-2" }
const _hoisted_35 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_36 = { class: "title ml-3 font-weight-light" }
const _hoisted_37 = { class: "count font-weight-light" }
const _hoisted_38 = { class: "total grand-total mt-2 mb-1" }
const _hoisted_39 = { class: "d-flex align-center justify-space-between font-weight-bold text-uppercase text-body-2" }
const _hoisted_40 = { class: "title font-weight-bold" }
const _hoisted_41 = { class: "count" }
const _hoisted_42 = { class: "total discount mb-2" }
const _hoisted_43 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_44 = { class: "title" }
const _hoisted_45 = { class: "value" }
const _hoisted_46 = { class: "count" }
const _hoisted_47 = { class: "total grand-total mt-2" }
const _hoisted_48 = { class: "d-flex align-center justify-space-between text-body-2" }
const _hoisted_49 = { class: "title font-weight-bold text-uppercase mb-2" }
const _hoisted_50 = {
  key: 3,
  class: "total total_local_taxes"
}
const _hoisted_51 = { class: "d-flex align-center justify-space-between text-body-2 font-italic" }
const _hoisted_52 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_default_photo = _resolveComponent("default-photo")
  const _component_price = _resolveComponent("price")
  const _component_info_line = _resolveComponent("info-line")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_price_separated = _resolveComponent("price-separated")
  const _component_loader = _resolveComponent("loader")
  const _component_snackbar = _resolveComponent("snackbar")

  return ($props.roomStay.order)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($options.isItemRemovable)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              class: "text-none order-list-item__btn-remove",
              color: "primary",
              height: 40,
              "min-width": 40,
              width: 40,
              ripple: false,
              variant: "outlined",
              size: "large",
              tile: "",
              onClick: _cache[0] || (_cache[0] = $event => ($options.handlePopup($props.roomStay)))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "icon-close" }, null, -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          (!!$options.thumbnail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  class: "img-cover",
                  src: $options.thumbnail
                }, null, 8 /* PROPS */, _hoisted_4)
              ]))
            : (_openBlock(), _createBlock(_component_default_photo, {
                key: 1,
                class: "room-info__img"
              })),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              ($options.roomName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($options.roomName), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              ($props.roomStay.order.rate)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[3] || (_cache[3] = _createTextVNode(" - ")),
                    _createElementVNode("span", _hoisted_8, _toDisplayString($props.roomStay.order.rate.ratePlanName), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true),
              ($props.roomStay.order.hotelPackageName)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _cache[4] || (_cache[4] = _createTextVNode(" - ")),
                    _createElementVNode("span", _hoisted_9, _toDisplayString($props.roomStay.order.hotelPackageName), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_price, {
                price: $props.roomStay.order.totalPrice,
                discount: $props.roomStay.order.discount,
                numNights: $props.roomStay.order.nbNights
              }, null, 8 /* PROPS */, ["price", "discount", "numNights"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_info_line, {
                adultNb: $props.roomStay.order.nbAdults,
                childrenNb: $props.roomStay.order.nbChildren,
                infantsNb: $props.roomStay.order.nbInfants,
                dateStart: $props.roomStay.order.startDate,
                dateEnd: $props.roomStay.order.endDate,
                nightsNb: $props.roomStay.order.nbNights
              }, null, 8 /* PROPS */, ["adultNb", "childrenNb", "infantsNb", "dateStart", "dateEnd", "nightsNb"])
            ])
          ])
        ]),
        ($options.showCancellationPolicy)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_v_divider),
              _createElementVNode("div", _hoisted_12, _toDisplayString($data.cancellationPolicy), 1 /* TEXT */),
              _createVNode(_component_v_divider)
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString($options.roomName) + " × " + _toDisplayString(_ctx.$t('Checkout#ViewOrder#NumNights nights', $props.roomStay.order.nbNights)), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_price_separated, {
                  width: $options.longestPriceString,
                  price: 
                $options.formatPrice(
                  $props.roomStay.order.totalPrice - $props.roomStay.totalServicesPrice,
                  { currency: _ctx.currency }
                )
              
                }, null, 8 /* PROPS */, ["width", "price"])
              ])
            ])
          ]),
          ($props.roomStay.order.totalVat)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('Checkout#ViewOrder Including VAT')), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_price_separated, {
                      width: $options.longestPriceString,
                      price: $options.formatPrice($props.roomStay.order.totalVat, { currency: _ctx.currency })
                    }, null, 8 /* PROPS */, ["width", "price"])
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.isTaxServiceEnabled && _ctx.isSameCurrency)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_loader, {
                      key: 0,
                      size: 50,
                      absolute: false
                    }))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.roomStay.order.taxes, ({
              taxType,
              taxDescription,
              taxPercentage,
              taxAmount,
              taxCurrency
            }) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, _toDisplayString(taxType + ' - ' + taxDescription + ' - ' + taxPercentage + '%'), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_25, [
                            taxCurrency
                              ? (_openBlock(), _createBlock(_component_price_separated, {
                                  key: 0,
                                  width: $options.longestPriceString,
                                  price: $options.formatPrice(taxAmount, { currency: taxCurrency })
                                }, null, 8 /* PROPS */, ["width", "price"]))
                              : _createCommentVNode("v-if", true)
                          ])
                        ])
                      ]))
                    }), 256 /* UNKEYED_FRAGMENT */))
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('Checkout#ViewOrder Additional')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_price_separated, {
                  width: $options.longestPriceString,
                  price: $options.formatPrice($props.roomStay.totalServicesPrice, { currency: _ctx.currency })
                }, null, 8 /* PROPS */, ["width", "price"])
              ])
            ])
          ]),
          ($props.roomStay.order.services)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_v_divider, { class: "mb-2" }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.roomStay.order.services, (service) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.serviceInfoByInstanceId(service.instance)?.[0]?.name ?? ''), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_33, [
                        _createVNode(_component_price_separated, {
                          width: $options.longestPriceString,
                          price: $options.formatPrice(service.totalPrice, { currency: _ctx.currency })
                        }, null, 8 /* PROPS */, ["width", "price"])
                      ])
                    ]),
                    (service.taxes && _ctx.isTaxServiceEnabled)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(service.taxes, ({
                taxType,
                taxDescription,
                taxPercentage,
                taxAmount,
                taxCurrency
              }) => {
                          return (_openBlock(), _createElementBlock("div", _hoisted_34, [
                            _createElementVNode("div", _hoisted_35, [
                              _createElementVNode("div", _hoisted_36, _toDisplayString(taxType +
                    ' - ' +
                    taxDescription +
                    ' - ' +
                    taxPercentage +
                    '%'), 1 /* TEXT */),
                              _createElementVNode("div", _hoisted_37, [
                                _createVNode(_component_price_separated, {
                                  width: $options.longestPriceString,
                                  price: $options.formatPrice(taxAmount, { currency: taxCurrency })
                                }, null, 8 /* PROPS */, ["width", "price"])
                              ])
                            ])
                          ]))
                        }), 256 /* UNKEYED_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($options.discountText)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createVNode(_component_v_divider),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t('Checkout#ViewOrder Subtotal')), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_41, [
                      _createVNode(_component_price_separated, {
                        width: $options.longestPriceString,
                        price: 
                  $options.formatPrice(
                    $props.roomStay.order.totalPrice + $props.roomStay.taxesTotalAmount,
                    { currency: _ctx.currency }
                  )
                
                      }, null, 8 /* PROPS */, ["width", "price"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", _hoisted_44, [
                      _cache[5] || (_cache[5] = _createElementVNode("i", { class: "icon-pricetags price-discount-icon" }, null, -1 /* HOISTED */)),
                      _createElementVNode("span", _hoisted_45, " " + _toDisplayString($options.discountText), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createVNode(_component_price_separated, {
                        class: "font-discounted",
                        width: $options.longestPriceString,
                        price: 
                  $options.formatPrice(-1 * $props.roomStay.order.totalDiscount, { currency: _ctx.currency })
                
                      }, null, 8 /* PROPS */, ["width", "price"])
                    ])
                  ])
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]),
        ($options.discountText)
          ? (_openBlock(), _createBlock(_component_v_divider, { key: 2 }))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("div", _hoisted_48, [
            _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t('Checkout#ViewOrder Total for')) + " " + _toDisplayString(_ctx.$t('Checkout#ViewOrder#NumNights nights', $props.roomStay.order.nbNights)), 1 /* TEXT */),
            _createVNode(_component_price_separated, {
              class: "font-weight-bold",
              width: $options.longestPriceString,
              price: 
            $options.formatPrice(
              $props.roomStay.order.totalPrice +
                $props.roomStay.taxesTotalAmount -
                $props.roomStay.order.totalDiscount,
              { currency: _ctx.currency }
            )
          
            }, null, 8 /* PROPS */, ["width", "price"])
          ])
        ]),
        ($props.roomStay.order.totalLocalTax)
          ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.$t('Checkout#ViewOrder Local taxes (not included)')), 1 /* TEXT */),
                _createVNode(_component_price_separated, {
                  width: $options.longestPriceString,
                  price: $options.formatPrice($props.roomStay.order.totalLocalTax, { currency: _ctx.currency })
                }, null, 8 /* PROPS */, ["width", "price"])
              ])
            ]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_snackbar, {
          modelValue: $data.showSnackbar,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.showSnackbar) = $event)),
          text: _ctx.$t('Toast#Reservation#Modify The room was cancelled')
        }, null, 8 /* PROPS */, ["modelValue", "text"])
      ]))
    : _createCommentVNode("v-if", true)
}