import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "room__info-block block-room-description" }
const _hoisted_2 = { class: "block__content" }
const _hoisted_3 = {
  key: 0,
  class: "text"
}

import { type PropType } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomDescription',
  props: {
  description: {
    type: Object as PropType<{
      texts: Array<string>
    }>,
    required: true
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.description.texts.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.description.texts, (paragraph) => {
              return (_openBlock(), _createElementBlock("pre", null, _toDisplayString(paragraph.text ?? paragraph) + "\n        ", 1 /* TEXT */))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})