import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "label-icon"
}
const _hoisted_4 = { class: "label-text" }
const _hoisted_5 = { class: "block__title title" }
const _hoisted_6 = ["placeholder", "id", "size", "value", "data-id", "autocomplete"]
const _hoisted_7 = { class: "block__content" }
const _hoisted_8 = { class: "content-inner" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["for"]
const _hoisted_12 = {
  key: 0,
  class: "label-icon"
}
const _hoisted_13 = ["data-id", "id", "autocomplete"]
const _hoisted_14 = {
  key: 0,
  selected: "selected",
  style: {"display":"none"}
}
const _hoisted_15 = ["selected", "value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.isDesktop)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["dropdown-menu", { 'is-active': $data.active }]),
        disabled: $props.disabled
      }, [
        ($props.labelID)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: "dropdown-menu__label",
              for: $props.labelID
            }, [
              ($props.labelIconClass)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createElementVNode("i", {
                      class: _normalizeClass($props.labelIconClass)
                    }, null, 2 /* CLASS */)
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("span", _hoisted_4, _toDisplayString($props.labelText), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_2))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("input", {
            onClick: _cache[0] || (_cache[0] = (...args) => ($options.toggleStatus && $options.toggleStatus(...args))),
            readonly: "readonly",
            type: "text",
            placeholder: $props.title,
            class: _normalizeClass($options.inputClasses),
            id: $props.labelID || $props.id,
            size: $options.maxLength,
            value: $options.findValue($props.value ? $props.value : $props.defaultValue),
            "data-id": $props.value || $props.defaultValue,
            autocomplete: $props.autofillName,
            onInput: _cache[1] || (_cache[1] = $event => ($options.returnValue($event.target.dataset.id))),
            onKeydown: [
              _cache[2] || (_cache[2] = _withKeys(_withModifiers(
          () => {
            $options.selectNextOption(true)
          }
        , ["stop","prevent"]), ["up"])),
              _cache[3] || (_cache[3] = _withKeys(_withModifiers(
          () => {
            $options.selectNextOption()
          }
        , ["stop","prevent"]), ["down"]))
            ],
            onKeyup: [
              _cache[4] || (_cache[4] = _withKeys(_withModifiers($event => ($data.active = false), ["stop","prevent"]), ["enter"])),
              _cache[5] || (_cache[5] = _withKeys(_withModifiers($event => ($data.active = false), ["stop","prevent"]), ["esc"]))
            ]
          }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_6)
        ]),
        _createVNode(_Transition, {
          name: "dropdown",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", {
                  class: "line",
                  style: _normalizeStyle({ top: $data.line.top + 'px', height: $data.line.height + 'px' })
                }, null, 4 /* STYLE */),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("li", {
                      class: _normalizeClass(
                $props.showActiveItem
                  ? { 'is-active': $props.value ? item.id === $props.value : index === 0 }
                  : ''
              )
                    }, [
                      _createElementVNode("span", {
                        class: "value",
                        onClick: $event => ($options.returnValue(item.id)),
                        onMouseover: _cache[6] || (_cache[6] = $event => ($options.setLineToHoverItem($event.target))),
                        onMouseout: _cache[7] || (_cache[7] = (...args) => ($options.setLineToActiveItem && $options.setLineToActiveItem(...args)))
                      }, _toDisplayString(item.value), 41 /* TEXT, PROPS, NEED_HYDRATION */, _hoisted_9)
                    ], 2 /* CLASS */)), [
                      [_vShow, $props.showActiveItem || item.id !== $props.value]
                    ])
                  }), 256 /* UNKEYED_FRAGMENT */))
                ])
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, $data.active]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "dropdown-select",
        disabled: $props.disabled
      }, [
        ($props.labelID)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: "dropdown-select__label",
              for: $props.labelID
            }, [
              _createTextVNode(_toDisplayString($props.labelText), 1 /* TEXT */),
              ($props.labelIconClass)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                    _createElementVNode("i", {
                      class: _normalizeClass($props.labelIconClass)
                    }, null, 2 /* CLASS */)
                  ]))
                : _createCommentVNode("v-if", true)
            ], 8 /* PROPS */, _hoisted_11))
          : _createCommentVNode("v-if", true),
        (!!$options.currentItem.icon && !$setup.isDesktop)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: _normalizeClass([$options.currentItem.icon])
            }, null, 2 /* CLASS */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("select", {
          "data-id": $props.value || $props.defaultValue,
          class: _normalizeClass($options.inputClasses),
          id: $props.labelID || $props.id,
          autocomplete: $props.autofillName,
          onChange: _cache[8] || (_cache[8] = $event => ($options.returnValue($event.target.value)))
        }, [
          ($props.showDefaultOption)
            ? (_openBlock(), _createElementBlock("option", _hoisted_14, _toDisplayString($props.title), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              selected: item.id === $props.value,
              value: item.id
            }, _toDisplayString(item.value), 9 /* TEXT, PROPS */, _hoisted_15))
          }), 256 /* UNKEYED_FRAGMENT */))
        ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_13)
      ], 8 /* PROPS */, _hoisted_10))
}