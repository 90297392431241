import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5 font-weight-bold mb-1" }
const _hoisted_2 = {
  class: "text-caption mb-5",
  style: {"font-size":"10px !important","opacity":"0.7"}
}
const _hoisted_3 = { class: "text-caption text-center mb-4" }

import Swal from 'sweetalert2'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import router from '@/app/router'
import store from '@/app/store'
import { autocomplete } from '@/app/system/constants/form'
import {
  isRequiredValidator,
  isEmailValidator,
  reservationIdValidator
} from '@/app/system/validators.ts'
import HotelContacts from '@/components/Common/HotelContacts.vue'
import Loader from '@/components/UI/Loader.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservationCancelForm',
  setup(__props) {

const { t } = useI18n()

const state: {
  isFormValid: boolean
  reservationEmail: string
  reservationId: string
  customError: {
    email: string
    id: string
  }
} = reactive({
  isFormValid: false,
  reservationEmail: router.currentRoute.params?.email || '',
  reservationId: router.currentRoute.params?.reservationId || '',
  customError: {
    email: '',
    id: ''
  }
})

async function submit() {
  store.dispatch('setCancellationLoading', true)

  store
    .dispatch('getCancellationPolicies', { reservationId: state.reservationId })
    .then(async (response) => {
      if (response.error) {
        return
      }
      const { isConfirmed } = await Swal.fire({
        icon: 'warning',
        title: t(
          'Cancellation#Form#Popup#Title Are you sure you want to cancel the reservation?'
        ),
        text: response,
        showCancelButton: true,
        cancelButtonText: t('Cancellation#Form#Popup#Button#Cancel Cancel'),
        confirmButtonText: t('Cancellation#Form#Popup#Button#Confirm Confirm')
      })

      if (!isConfirmed) {
        store.dispatch('setCancellationLoading', false)
        return
      }

      store
        .dispatch('sendCancellationRequest', {
          reservationEmail: state.reservationEmail,
          reservationId: state.reservationId
        })
        .catch(() => {
          state.customError.email = t(
            'Cancellation#Form#Email#Error Reservation email {email} do not match',
            { email: state.reservationEmail }
          )
          store.dispatch('setCancellationLoading', false)
        })
    })
    .catch(() => {
      state.customError.id = t(
        'Cancellation#Form#ReservationId#Error Reservation with ID {reservationId} is missing',
        { reservationId: state.reservationId }
      )
      store.dispatch('setCancellationLoading', false)
    })
}

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    modelValue: state.isFormValid,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.isFormValid) = $event)),
    onSubmit: _withModifiers(submit, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)('Cancellation#Form#Title Cancel your reservation')), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)(
          'Cancellation#Form Please enter your email address and reservation number below'
        )), 1 /* TEXT */),
      _createVNode(_component_v_text_field, {
        modelValue: state.reservationEmail,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.reservationEmail) = $event)),
        type: "email",
        name: "email",
        label: _unref(t)('Cancellation#Form#Field Reservation email address'),
        autocomplete: _unref(autocomplete).email,
        rules: [_unref(isRequiredValidator), _unref(isEmailValidator)],
        "error-messages": state.customError.email ? [state.customError.email] : [],
        rounded: "0",
        variant: "outlined"
      }, {
        message: _withCtx(({ message }) => [
          _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label", "autocomplete", "rules", "error-messages"]),
      _createVNode(_component_v_text_field, {
        modelValue: state.reservationId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.reservationId) = $event)),
        name: "reservationId",
        label: _unref(t)('Cancellation#Form#Field Reservation reference'),
        rules: [_unref(isRequiredValidator), _unref(reservationIdValidator)],
        "error-messages": state.customError.id ? [state.customError.id] : [],
        rounded: "0",
        variant: "outlined"
      }, {
        message: _withCtx(({ message }) => [
          _createTextVNode(_toDisplayString(_unref(t)(message)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label", "rules", "error-messages"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(
          'Cancellation#Form#Description The reservation reference format is SB4-XXXXXXXX'
        )), 1 /* TEXT */),
      _createVNode(_component_v_btn, {
        class: "text-none",
        type: "submit",
        color: "primary",
        disabled: _unref(store).getters.cancellationLoading || !state.isFormValid,
        ripple: false,
        size: "x-large",
        block: "",
        tile: ""
      }, {
        default: _withCtx(() => [
          (_unref(store).getters.cancellationLoading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("span", null, _toDisplayString(_unref(t)('Cancellation#Form#Button Please wait')) + "  ", 1 /* TEXT */),
                _createVNode(Loader, {
                  size: 30,
                  absolute: false,
                  thin: ""
                })
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_unref(t)('Cancellation#Form#Button Cancel the reservation')), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(HotelContacts)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}
}

})