import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import AppHeader from '@/components/Common/AppHeader.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'Reservation',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "mb-12 justify-center" }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "shine" }, null, -1 /* HOISTED */)),
          (_ctx.$route.name == 'ReservationModify')
            ? (_openBlock(), _createBlock(AppHeader, { key: 0 }))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: _normalizeClass([
          'reservation',
          _ctx.$route.name == 'ReservationPayment' && [
            'cell',
            'large-8',
            'large-offset-2'
          ]
        ])
          }, [
            _renderSlot(_ctx.$slots, "reservation-header"),
            _renderSlot(_ctx.$slots, "reservation-content")
          ], 2 /* CLASS */),
          _createVNode(_component_router_view)
        ]),
        _: 3 /* FORWARDED */
      })
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})