import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "actions-toolbar"
}
const _hoisted_2 = { class: "action tertiary" }
const _hoisted_3 = { class: "promo-code__btn_title text-body-2" }
const _hoisted_4 = {
  key: 2,
  class: "field__item"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "label__title mb-0" }
const _hoisted_7 = { class: "ml-auto" }
const _hoisted_8 = {
  key: 0,
  class: "actions-toolbar pt-0"
}

import { inject, computed, ref, type Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { isDesktop, isMobile } from '@/app/composables/useBreakpoints'
import store from '@/app/store'
import ButtonAnimated from '@/components/UI/Controls/ButtonAnimated.vue'
import mobileHeading from '@/components/Common/HeadingMobile.vue'
import formText from '@/components/UI/Controls/Inputs/fieldText.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Promo',
  props: {
    active: { type: Boolean, default: false },
    disabled: { type: Boolean }
  },
  emits: ['togglePromoCode'],
  setup(__props: any, { emit: __emit }) {

const router = useRouter()
const { query } = useRoute()
const { t } = useI18n()
const emitter = inject('emitter')
const emit = __emit



const storedCode = computed(() => store.getters.bookingRequest.promoCodeInput)
const shownPromoCodeInput: Ref<boolean> = ref(false)
const code: Ref<string> = ref(query?.promoCode || storedCode.value || '')

const booked = computed(() => store.getters.bookingRequest.nbBookedRooms > 0)
const isPromoCodeApplied = computed(
  () => shownPromoCodeInput.value || storedCode.value
)

const applyPromoCode = () => {
  if (code.value !== storedCode.value) {
    const formattedCode = code.value.replace(/ /g, '').toUpperCase()
    store.commit('UPDATE_BOOKING_REQUEST', { promoCodeInput: formattedCode })
    store.dispatch('getRoomsAvailabilities')

    if (query.promoCode) {
      router.replace({ query: { ...query, promoCode: formattedCode } })
    }
    if (!isDesktop.value) {
      emit('togglePromoCode')
    }

    emitter.emit('promo-code-submitted', formattedCode)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field promo-code no-scroll_promoCodes", {
      'is-booked': booked.value,
      'is-active': _ctx.active,
      'module__aside-menu': !_unref(isDesktop)
    }])
  }, [
    (_unref(isMobile))
      ? (_openBlock(), _createBlock(mobileHeading, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('togglePromoCode'))),
          onClear: _cache[1] || (_cache[1] = ($event: any) => (emit('togglePromoCode'))),
          title: _unref(t)('Search#Form#Promo Enter your promo code')
        }, null, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true),
    (!isPromoCodeApplied.value && _unref(isDesktop))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (shownPromoCodeInput.value = true), ["prevent"]))
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("i", { class: "icon-pricetags bolder" }, null, -1 /* HOISTED */)),
              _cache[5] || (_cache[5] = _createTextVNode(" ")),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('Search#Form#Promo I have a promotion code')), 1 /* TEXT */)
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (isPromoCodeApplied.value || shownPromoCodeInput.value || !_unref(isDesktop))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (!_unref(isMobile))
            ? (_openBlock(), _createElementBlock("label", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createElementVNode("i", { class: "icon-pricetags bolder" }, null, -1 /* HOISTED */)),
                  _createElementVNode("span", null, _toDisplayString(_unref(t)('Search#Form#Promo Promotion code')), 1 /* TEXT */)
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", null, [
            _createVNode(formText, {
              modelValue: code.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((code).value = $event)),
              type: "text",
              "validation-type": "text",
              id: "promoCode",
              placeholder: _unref(t)('Search#Form#Promo Enter your promo code')
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
            _createElementVNode("div", _hoisted_7, [
              (_unref(isDesktop))
                ? (_openBlock(), _createBlock(ButtonAnimated, {
                    key: 0,
                    color: "secondary",
                    disabled: _ctx.disabled || !code.value.length,
                    size: "x-large",
                    block: "",
                    onClick: _withModifiers(applyPromoCode, ["prevent","stop"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('Search#Form#Button Apply')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"]))
                : _createCommentVNode("v-if", true)
            ]),
            (!_unref(isDesktop))
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (!_unref(isDesktop))
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        class: "text-none",
                        color: "primary",
                        disabled: _ctx.disabled || !code.value.length,
                        ripple: false,
                        size: "x-large",
                        block: "",
                        tile: "",
                        onClick: applyPromoCode
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('Search#Form#Button Apply')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["disabled"]))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})