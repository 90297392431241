import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field__control" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_dropdown = _resolveComponent("dropdown")
  const _component_Portal = _resolveComponent("Portal")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field__item", [$props.className, { 'has-error': $props.hasError }]])
  }, [
    _renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({ value: $props.value }))),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_dropdown, {
        list: $props.list,
        title: $props.title,
        value: $props.value,
        "default-value": $props.defaultValue,
        "show-active-item": true,
        labelID: $props.labelId,
        labelText: $props.labelText,
        labelIconClass: $props.labelIconClass,
        "show-default-option": $props.showDefaultOption,
        id: $props.id,
        readonly: "readonly",
        "autofill-name": $props.autofillName,
        "has-error": $props.hasError,
        onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('input', $event)))
      }, null, 8 /* PROPS */, ["list", "title", "value", "default-value", "labelID", "labelText", "labelIconClass", "show-default-option", "id", "autofill-name", "has-error"]),
      _createVNode(_component_Portal, {
        to: $props.portalTarget,
        disabled: !$props.portalTarget
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: "error",
            persisted: ""
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("p", { class: "field__error" }, _toDisplayString($props.errorMessage), 513 /* TEXT, NEED_PATCH */), [
                [_vShow, $props.hasError]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to", "disabled"])
    ])
  ], 2 /* CLASS */))
}