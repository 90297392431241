import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_loader = _resolveComponent("app-loader")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_fade_transition = _resolveComponent("v-fade-transition")
  const _component_router_view = _resolveComponent("router-view")
  const _component_v_main = _resolveComponent("v-main")
  const _component_app_footer = _resolveComponent("app-footer")
  const _component_v_app = _resolveComponent("v-app")

  return (_openBlock(), _createBlock(_component_v_app, { id: "app" }, {
    default: _withCtx(() => [
      _createVNode(_component_app_loader),
      _createVNode(_component_v_main, { "min-height": "90vh" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component, route }) => [
              _createVNode(_component_v_fade_transition, { mode: "out-in" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_v_container, {
                    ref: "wrapper",
                    key: route.path,
                    class: _normalizeClass([
              'page-wrapper shine pa-4 mb-16',
              {
                'custom-container': route.name != 'customization-form',
                'fill-height': route.name == 'maintenance'
              }
            ]),
                    fluid: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.route.name != 'maintenance')
        ? (_openBlock(), _createBlock(_component_app_footer, { key: 0 }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}