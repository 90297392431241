import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-h5 pt-3 font-weight-bold" }

import { ref, computed, watch, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { isDesktop } from '@/app/composables/useBreakpoints'
import { useDisplay } from 'vuetify'
import { getImageKitUrls, getResolution } from '@/app/system/helper'
import store from '@/app/store'

import PackageImages from '@/components/UI/Vuetify/ImageSlider.vue'
import PackageSearch from '@/components/Search/SearchPackage.vue'
import PackageRoomsDetails from '@/components/Packages/PackageRoomsDetails.vue'
import PackageDescription from '@/components/Rooms/RoomDescription.vue'
import Loader from '@/components/UI/Loader.vue'


export default {
  __name: 'PackageLanding',
  setup(__props) {

const router = useRouter()
const { params } = useRoute()
const display = useDisplay()

const panels = ref([])

const hotelPackageId = computed(() => params.packageId)
const hotelPackage = computed(() =>
  store.getters.hotelPackages.find((p) => p.id === hotelPackageId.value)
)

const packageImages = computed(() =>
  getImageKitUrls(
    hotelPackage.value?.descriptions.images,
    getResolution(display),
    true
  )
)

const isLoading = computed(
  () => store.getters.loading || !store.getters.hotelPackagesLoaded
)

const carouselHeight = computed(() => {
  const heights = {
    Mobile: 'auto',
    Tablet: 450,
    Desktop: 500
  }
  return heights[getResolution(display)]
})

onBeforeMount(() => {
  store.commit('REMOVE_LAST_ROOM_STAY_ORDER')
})

const getPackageAvailabilities = async (id = hotelPackageId.value) => {
  store.commit('UPDATE_CURRENT_ROOM_STAY', {
    nbAdults: store.getters.currentRoomStayGuests.adults,
    nbChildren: 0,
    nbInfants: 0
  })
  store.dispatch('getPackagesAvailabilities', { hotelPackageId: id })
}

watch(
  () => [store.getters.hotelPackagesLoaded, store.getters.currency],
  ([isLoaded, currency]) => {
    if (isLoaded && hotelPackage.value) {
      getPackageAvailabilities()
    } else if (isLoaded && !hotelPackage.value) {
      router.push({ name: 'home' })
    }
  },
  { immediate: true }
)

return (_ctx, _cache) => {
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createBlock(_component_v_sheet, { elevation: "1" }, {
    default: _withCtx(() => [
      (hotelPackage.value)
        ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(PackageImages, {
                        height: carouselHeight.value,
                        images: packageImages.value,
                        title: hotelPackage.value.name
                      }, null, 8 /* PROPS */, ["height", "images", "title"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(PackageSearch, { onSubmit: getPackageAvailabilities })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, { class: "room-info room-panel align-center mt-5 mb-lg-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "room__heading col-12 col-lg-9" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", _hoisted_1, _toDisplayString(hotelPackage.value.name), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, { class: "mt-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "mb-5 mb-lg-10" }, {
                    default: _withCtx(() => [
                      (hotelPackage.value.descriptions)
                        ? (_openBlock(), _createBlock(PackageDescription, {
                            key: 0,
                            description: hotelPackage.value.descriptions,
                            "modal-title": hotelPackage.value.name
                          }, null, 8 /* PROPS */, ["description", "modal-title"]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panels, {
                        modelValue: panels.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((panels).value = $event)),
                        readonly: false,
                        variant: "accordion",
                        multiple: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(PackageRoomsDetails, { "hotel-package-id": hotelPackageId.value }, null, 8 /* PROPS */, ["hotel-package-id"])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : (_openBlock(), _createBlock(_component_v_col, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "d-flex align-center justify-center",
                    style: {"height":"70vh"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(Loader, { absolute: false })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
    ]),
    _: 1 /* STABLE */
  }))
}
}

}