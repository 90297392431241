import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { ReservationStatus as ReservationRole } from '@/app/system/constants/reservation'

import ReservationHeader from '@/components/Reservation/ReservationHeader.vue'
import ReservationCancelForm from '@/components/Reservation/ReservationCancelForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservationCancel',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Reservation Cancel')
})

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "8"
          }, {
            default: _withCtx(() => [
              _createVNode(ReservationHeader, {
                slot: "reservation-header",
                role: _unref(ReservationRole).Cancel
              }, null, 8 /* PROPS */, ["role"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_Transition, {
                slot: "reservation-content",
                name: "fade",
                mode: "out-in",
                appear: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_sheet, { class: "pa-5" }, {
                    default: _withCtx(() => [
                      _createVNode(ReservationCancelForm)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})