import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'
import DefaultPic from '@/app/static/images/no-photo.png'


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultImage',
  props: {
  height: {
    type: [Number, String],
    required: false,
    default: 'auto'
  },
  lowScale: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props

const defaultPic = computed(() => DefaultPic)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['default-image', { 'low-scaled': __props.lowScale }]),
    style: _normalizeStyle(`height: ${typeof __props.height == 'number' ? __props.height + 'px' : __props.height}`)
  }, [
    _createElementVNode("img", { src: defaultPic.value }, null, 8 /* PROPS */, _hoisted_1)
  ], 6 /* CLASS, STYLE */))
}
}

})