import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container customer-account__content-wrapper mt-5" }
const _hoisted_2 = { class: "row row-margin" }
const _hoisted_3 = {
  key: 0,
  class: "cell large-4"
}
const _hoisted_4 = { class: "cell large-8" }

import { isDesktop } from '@/app/composables/useBreakpoints'
import MenuContainer from './CustomerAccountMenu.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerAccount',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(isDesktop))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_v_sheet, null, {
              default: _withCtx(() => [
                _createVNode(MenuContainer)
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component, route }) => [
            _createVNode(_Transition, {
              name: "menu",
              mode: "out-in",
              appear: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}
}

})