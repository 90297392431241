import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rate-plan-item" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "pr-5 flex-grow-1" }
const _hoisted_4 = {
  class: "text-body-2 text-justify",
  style: {"line-height":"1rem","white-space":"pre-wrap"}
}
const _hoisted_5 = { class: "flex-column flex-wrap" }
const _hoisted_6 = { class: "d-flex flex-column align-end text-no-wrap mr-3" }
const _hoisted_7 = {
  key: 0,
  class: "text-disabled text-caption"
}
const _hoisted_8 = { class: "text-decoration-line-through" }

import { getCurrentInstance, reactive, computed, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { formatPrice } from '@/app/system/helper'
import { RATE_DESC_MAX_LEN } from '@/app/constants'
import store from '@/app/store'
import CancellationConditionBadge from '@/components/Common/Badges/CancellationConditionBadge.vue'
import GuaranteeTypeBadge from '@/components/Common/Badges/GuaranteeTypeBadge.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RatePlanItem',
  props: {
  roomId: {
    type: String,
    required: true
  },
  item: {
    type: Object as PropType<{
      availability: number
      cancellationConditions: 'flexible' | 'nonRefundable'
      currency: string
      discount: number
      hasServices: boolean
      hotelCurrency: string
      hotelTotalDiscount: number
      hotelTotalLocalTax: number
      hotelTotalPrice: number
      hotelTotalVat: number
      isBar: boolean
      limitedOffer: boolean
      nbNights: number
      popularity: number
      productId: string
      ratePlanId: string
      reference: string
      totalLocalTax: number
      totalPrice: number
      totalVat: number
      rate: {
        currencyCode: string
        guaranteeType: 'None'
        ratePlanCode: string
        ratePlanName: string
        ratePlanType: string
        taxes: unknown[]
        descriptions: {
          images: Array<{
            category: string
            createDateTime: string
            dimensionCategory: 'Desktop' | 'Mobile' | 'Original' | 'Thumbnail'
            format: number
            imageHeight: string
            imageId: string
            imageUrl: string
            imageWidth: string
            isOriginalIndicator: number | null
            lastModifyDateTime: string
            recordId: string
            sort: number
          }>
          texts: Array<string>
        }
      }
    }>,
    required: true
  }
},
  setup(__props) {

const instance = getCurrentInstance()

const { t } = useI18n()

const props = __props

const state: {
  showFullDescription: boolean
} = reactive({
  showFullDescription: false
})

const description = computed(() => {
  const text = props.item.rate?.descriptions.texts.join('\n\n') || ''
  return state.showFullDescription
    ? text
    : text.length < RATE_DESC_MAX_LEN
      ? text
      : text.slice(0, RATE_DESC_MAX_LEN) + '...'
})

function ratePlanPrice(product: {
  currency: string
  discount: number
  totalPrice: number
  nbNights: number
}): string {
  return product.discount > 0
    ? formatPrice((product.totalPrice - product.discount) / product.nbNights, {
        currency: product.currency
      })
    : formatPrice(product.totalPrice / product.nbNights, {
        currency: product.currency
      })
}

function isBtnDisabled(productId: string): boolean {
  return (
    store.getters.roomAvailabilities[props.roomId] < 1 ||
    store.getters.isAllRoomStaysBooked ||
    store.getters.allowPayment ||
    store.getters.roomAvailabilities[productId] === 0
  )
}

function bookRatePlan(productId: string): void {
  if (
    !store.getters.isAllRoomStaysBooked ||
    store.getters.roomAvailabilities[props.roomId] > 0
  ) {
    instance?.proxy.addOrderToRoomStay(props.roomId, productId)
  }
  instance?.proxy.goToServices(props.roomId, productId)
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" xs=\"column\" doesn't work "),
    _createVNode(_component_v_row, {
      class: "rate-row d-flex ma-0",
      sm: "row",
      xs: "column"
    }, {
      default: _withCtx(() => [
        _createCommentVNode(" Rate name and description "),
        _createVNode(_component_v_col, {
          lg: "6",
          md: "6",
          sm: "6",
          class: "pa-4"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_card_title, {
                  class: "text-body-2 font-weight-bold pa-0 mb-2",
                  style: {"word-break":"break-word"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(__props.item.rate?.ratePlanName), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createElementVNode("pre", _hoisted_4, _toDisplayString(description.value) + "\n            ", 1 /* TEXT */),
                (description.value.length > _unref(RATE_DESC_MAX_LEN))
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      class: "text-none text-decoration-underline px-0 pt-2",
                      density: "compact",
                      ripple: false,
                      variant: "plain",
                      text: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (state.showFullDescription = !state.showFullDescription))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(state.showFullDescription
                  ? _unref(t)('AvailabilityItem#Button Show less')
                  : _unref(t)('AvailabilityItem#Button Show more')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _createVNode(_component_v_divider, { vertical: "" })
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          lg: "6",
          class: "pl-0 d-flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "d-flex align-center pa-0" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(CancellationConditionBadge, {
                        condition: __props.item.cancellationConditions
                      }, null, 8 /* PROPS */, ["condition"]),
                      _createVNode(GuaranteeTypeBadge, {
                        type: __props.item.rate?.guaranteeType || ''
                      }, null, 8 /* PROPS */, ["type"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  lg: "6",
                  class: "py-0 px-2 d-flex align-center justify-end"
                }, {
                  default: _withCtx(() => [
                    _createCommentVNode(" Rate price && Book btn "),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", {
                        class: _normalizeClass([
                  'text-body-2 font-weight-bold',
                  { 'text-error': __props.item.discount > 0 }
                ])
                      }, _toDisplayString(ratePlanPrice(__props.item)), 3 /* TEXT, CLASS */),
                      (__props.item.discount > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(formatPrice)(__props.item.totalPrice / __props.item.nbNights, {
                      currency: __props.item.currency
                    })), 1 /* TEXT */)
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createVNode(_component_v_btn, {
                      class: "text-capitalize",
                      color: "primary",
                      disabled: isBtnDisabled(__props.item.productId),
                      ripple: false,
                      tile: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (bookRatePlan(__props.item.productId)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('AvailabilityItem#Button Book')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["disabled"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_divider, { class: "mx-3" })
  ]))
}
}

})