import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'
import { isEmpty, omit } from 'lodash'
import { routes } from './routes'

import store from '../store'
import { handleChannelCallback } from '../system/channelManager'
import {
  normalizeQueryParams,
  isValidHotelId,
  isMaintenanceMode
} from '../system/helper'

// TODO:
// polyfyll for 'vue-body-class'
// need to search for alternatives or refactor this
class VueBodyClass {
  constructor(routes) {
    this.routes = routes
  }

  guard(to, next) {
    const body = document.body
    Array.from(body.classList).forEach((cls) => body.classList.remove(cls))

    // Add the body class defined in the route metadata
    const bodyClass = to.meta?.bodyClass
    if (bodyClass) {
      body.classList.add(bodyClass)
    }

    // Continue navigation
    next()
  }
}

const vueBodyClass = new VueBodyClass(routes)
const isInitialNavigation = (from) => from === START_LOCATION

let isQueryParsed = false
let hotelId = null

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition && window.location === window.parent.location) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  hotelId = to.params.hotelId ?? (to.query.hotelId || to.hash.split('/')[1])

  if (isInitialNavigation(from) && to.hash) {
    // TODO: use better approach
    store.state.hashUrl = true
    next({ path: to.hash.replace('#', ''), query: to.query })
  }

  if (isInitialNavigation(from) && !isValidHotelId(hotelId)) {
    // if hotelId is missing in the URL(path or query param) cancel the navigation and redirect to the env URL
    window.location.href = process.env.NO_HOTEL_REDIRECT_URL
    next(false)
  }

  vueBodyClass.guard(to, next)
  /**
   * At the very first "beforeEach" hook call the app instance isn't injected to the router yet
   * so additional check of the app methods presence is needed
   */
  router.app?.shouldSessionBeStarted &&
  router.app.shouldSessionBeStarted(to.name)
    ? store.commit('RESET_SESSION_TIMEOUT', router.app.sessionExpirationHandler)
    : store.commit('CLEAR_SESSION_TIMEOUT')

  // Redirect to maintenance page if the app is in maintenance mode
  if (isMaintenanceMode()) {
    next({ name: 'maintenance' })
  } else if (!isMaintenanceMode() && to.name === 'maintenance') {
    next({ name: 'home' })
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (isInitialNavigation(from) && isValidHotelId(hotelId)) {
    store.commit('SET_HOTEL_ID', hotelId)

    if (!isEmpty(to.query) && !isQueryParsed) {
      let query = normalizeQueryParams(to.query)
      isQueryParsed = true
      const { bundleId, sessionId, channelId, route } = query
      const channel = parseInt(channelId)

      if (channel) {
        store.commit('SET_CHANNEL_ID', channel)
        handleChannelCallback(channel, to)
      }

      // Preserve all query params except 'hotelId' and 'route'
      const preservedQuery = omit(query, ['hotelId', 'route'])

      if (route) {
        switch (route) {
          case 'room':
            next({
              path: `${hotelId}/${route}/${query.roomId}`,
              query: { ...preservedQuery, ...omit(query, ['roomId']) }
            })
            break
          case 'bundle':
            next({
              path: `${hotelId}/${route}/${channel}/${sessionId}/${bundleId}`,
              query: {
                ...preservedQuery,
                ...omit(query, ['channelId', 'sessionId', 'bundleId'])
              }
            })
            break
          default:
            next({
              path: `${hotelId}`,
              query: preservedQuery
            })
        }
      } else {
        if (to.query['hotelId']) {
          next({
            path: `${hotelId}`,
            query: preservedQuery
          })
        } else {
          next()
        }
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
