import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reservation-header" }
const _hoisted_2 = { class: "header-wrapper d-flex justify-center small-align-center small-align-middle" }
const _hoisted_3 = {
  key: 0,
  class: "icon-angle-left"
}
const _hoisted_4 = {
  key: 1,
  class: "icon-close"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theme_toggler = _resolveComponent("theme-toggler")
  const _component_logo = _resolveComponent("logo")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createBlock(_component_v_sheet, { class: "mb-3 px-2 pt-2" }, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!$options.isConfirmationPage)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "action",
                onClick: _cache[0] || (_cache[0] = (...args) => ($options.navigate && $options.navigate(...args)))
              }, [
                ($options.showButtonBack)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                  : (!$data.prevRoute)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                    : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_theme_toggler, { style: {"position":"absolute","right":"0"} }),
          _createVNode(_component_logo, { onClick: $options.logoClickHandler }, null, 8 /* PROPS */, ["onClick"])
        ])
      ]),
      _createVNode(_component_v_divider)
    ]),
    _: 1 /* STABLE */
  }))
}