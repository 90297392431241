import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "block__title" }
const _hoisted_2 = { class: "block__content" }
const _hoisted_3 = {
  class: "content-inner",
  "data-role": "content-inner"
}
const _hoisted_4 = {
  key: 0,
  class: "text"
}
const _hoisted_5 = {
  key: 1,
  class: "products block__accordion"
}
const _hoisted_6 = { class: "item__info" }
const _hoisted_7 = { class: "item__title rate-plan__name" }
const _hoisted_8 = { class: "item__price" }
const _hoisted_9 = { class: "products__content" }

import { isMobile } from '@/app/composables/useBreakpoints'

import { chain, filter } from 'lodash'
import { mapGetters } from 'vuex'
import { tabs } from '@/app/system/mixins/tabs'
import { PRICE_ADDITIONAL_LENGTH } from '@/app/constants'
import { formatPrice, getMaxLength } from '@/app/system/helper'
import { filterRooms } from '@/app/system/rooms-filters'

import BaseAccordion from '@/components/UI/BaseAccordion.vue'
import ButtonBookNow from '@/components/UI/Controls/ButtonBookNow.vue'
import CheckInOut from './RoomCheckInOut.vue'
import Price from '@/components/Common/Price/Price.vue'
import PriceDetails from './RoomPriceDetails.vue'
import PriceSeparated from '@/components/Common/Price/PriceSeparated.vue'
import ProductCancellationPolicy from './RoomCancellationPolicy.vue'
import RateDescription from './RoomRate.vue'
import SortDropdown from '@/components/Common/SortDropdown.vue'

const __default__ = {
  name: 'ProductsDetails',
  components: {
    SortDropdown,
    ProductCancellationPolicy,
    RateDescription,
    PriceDetails,
    BaseAccordion,
    CheckInOut,
    PriceSeparated,
    ButtonBookNow,
    Price
  },
  mixins: [tabs],
  props: {
    roomId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    ...mapGetters(['currency', 'rooms', 'roomFilters', 'sorterName']),
    products: (vm) =>
      vm.rooms.find(({ room }) => room.id === vm.roomId)?.products,
    longestPriceString: (vm) =>
      getMaxLength([
        formatPrice(
          chain(vm.products)
            .map((product) => product.totalPrice)
            .max()
            .value(),
          { currency: vm.currency }
        ) + PRICE_ADDITIONAL_LENGTH
      ])
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["room__info-block", ['mt-5', { 'is-active': _ctx.init && _ctx.isExpanded }]]),
    style: {"width":"100%"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "d-flex justify-space-between font-weight-bold mb-3",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.isExpanded = !_ctx.isExpanded))
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('SingleRoomView#RoomView#Title Rate plans')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.products.length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                  'SingleRoomView#RoomView#Available rooms No rooms available for these dates'
                )), 1 /* TEXT */)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                return (_openBlock(), _createBlock(BaseAccordion, {
                  key: product.productId
                }, {
                  info: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(product.rate?.ratePlanName || ''), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(Price, {
                          price: product.totalPrice,
                          discount: product.discount,
                          numNights: product.nbNights
                        }, {
                          default: _withCtx(({ price }) => [
                            _createVNode(PriceSeparated, {
                              price: price,
                              width: !_unref(isMobile) ? _ctx.longestPriceString : 5,
                              "big-font": !_unref(isMobile)
                            }, null, 8 /* PROPS */, ["price", "width", "big-font"])
                          ]),
                          "price-old": _withCtx(({ price }) => [
                            _createVNode(PriceSeparated, {
                              price: price,
                              width: !_unref(isMobile) ? _ctx.longestPriceString : 5,
                              "big-font": !_unref(isMobile)
                            }, null, 8 /* PROPS */, ["price", "width", "big-font"])
                          ]),
                          "price-discount": _withCtx(({ price }) => [
                            _createVNode(PriceSeparated, {
                              price: price,
                              width: !_unref(isMobile) ? _ctx.longestPriceString : 5,
                              "big-font": !_unref(isMobile)
                            }, null, 8 /* PROPS */, ["price", "width", "big-font"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["price", "discount", "numNights"])
                      ])
                    ]),
                    _createElementVNode("div", {
                      class: "products__item__btn actions-toolbar",
                      onClick: _withModifiers(() => {}, ["prevent","stop"])
                    }, [
                      _createVNode(ButtonBookNow, {
                        "room-id": __props.roomId,
                        "product-id": product.productId
                      }, null, 8 /* PROPS */, ["room-id", "product-id"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      (product.rate)
                        ? (_openBlock(), _createBlock(RateDescription, {
                            key: 0,
                            ratePlanName: product.rate.ratePlanName,
                            description: product.rate.descriptions
                          }, null, 8 /* PROPS */, ["ratePlanName", "description"]))
                        : _createCommentVNode("v-if", true),
                      _createVNode(PriceDetails, {
                        "init-on": "mobile",
                        item: product,
                        "active-state": false
                      }, null, 8 /* PROPS */, ["item"]),
                      _createVNode(ProductCancellationPolicy, {
                        class: "pl-4 mt-5",
                        "init-on": "mobile",
                        roomId: __props.roomId,
                        ratePlanId: product.rate?.ratePlanCode
                      }, null, 8 /* PROPS */, ["roomId", "ratePlanId"])
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
      ])
    ])
  ], 2 /* CLASS */))
}
}

})