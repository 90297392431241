export class LocalStorage {
  static setItem(key: string, value: any) {
    try {
      const serializedValue = JSON.stringify(value)
      localStorage.setItem(key, serializedValue)
    } catch (error) {
      console.error('Error saving to localStorage: ', error)
    }
  }

  static getItem(key: string) {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      console.error('Error reading from localStorage: ', error)
      return null
    }
  }

  static removeItem(key: string) {
    try {
      localStorage.removeItem(key)
      return key
    } catch (error) {
      console.error('Error removing from localStorage: ', error)
      return null
    }
  }
}
