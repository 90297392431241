import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, onMounted, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { getCSSVariable } from '@/app/system/helper'
import IconItemList from '@/components/Common/IconItemList.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'GuaranteeTypeBadge',
  props: {
  type: {
    type: String as PropType<'None' | 'PrePay'>,
    required: true
  }
},
  setup(__props) {

const { t } = useI18n()
const props = __props

const itemMap = ref({
  None: {
    text: t('Badge#Guarantee None'),
    id: 'none',
    icon: 'fa check',
    color: ''
  },
  PrePay: {
    text: t('Badge#Guarantee PrePay'),
    id: 'pay-now',
    icon: 'fat credit-card'
  }
})

onMounted(() => {
  const successColor = getCSSVariable('--v-theme-success')
  itemMap.value.None.color = successColor || '#65AD14'
})

return (_ctx: any,_cache: any) => {
  return (itemMap.value[__props.type])
    ? (_openBlock(), _createBlock(IconItemList, {
        key: 0,
        items: [itemMap.value[__props.type]]
      }, null, 8 /* PROPS */, ["items"]))
    : _createCommentVNode("v-if", true)
}
}

})