import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import store from '@/app/store'


export default {
  __name: 'LocalizationToggler',
  setup(__props) {

const i18nEnabled = ref(true)
const { locale, fallbackLocale } = useI18n()

watch(i18nEnabled, (isEnabled) => {
  if (isEnabled) {
    locale.value = store.state.language
    fallbackLocale.value = store.state.language
  } else {
    locale.value = 'xx'
    fallbackLocale.value = 'xx'
  }
})

return (_ctx, _cache) => {
  const _component_v_switch = _resolveComponent("v-switch")

  return (_openBlock(), _createBlock(_component_v_switch, {
    modelValue: i18nEnabled.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((i18nEnabled).value = $event)),
    label: "i18n",
    ripple: false,
    "hide-details": "",
    color: "primary"
  }, null, 8 /* PROPS */, ["modelValue"]))
}
}

}