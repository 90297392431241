import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, type Ref } from 'vue'
import BaseInput from './BaseInput.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCvv',
  props: /*@__PURE__*/_mergeModels({
  placeholder: {
    type: String,
    required: true
  }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



const model = _useModel<string>(__props, "modelValue")

const showPassword: Ref<boolean> = ref(false)

const iconName = computed(() =>
  showPassword.value ? 'icon-eye-blocked' : 'icon-eye'
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(BaseInput, {
      modelValue: model.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      class: "field__input",
      autocomplete: "cc-csc",
      pattern: "^[0-9]{3,4}$",
      type: showPassword.value ? 'tel' : 'password',
      placeholder: __props.placeholder,
      "max-length": 4,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (model.value = $event.target.value))
    }, null, 8 /* PROPS */, ["modelValue", "type", "placeholder"]),
    _createElementVNode("span", {
      class: "toggle",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (showPassword.value = !showPassword.value))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(iconName.value)
      }, null, 2 /* CLASS */)
    ])
  ]))
}
}

})