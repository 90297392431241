import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

const navigator = window.navigator
const navigatorLanguage = (navigator.language || '').substring(0, 2)
export const fallbackLang = process.env.FALLBACK_LANGUAGE
export const browserLang = navigatorLanguage || fallbackLang

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  missingWarn: false,
  fallbackLocale: 'en',
  locale: 'en',
  messages: {
    en: require('@/locales/en.json')
  }
})

export function setupI18n(options = { locale: 'en' }) {
  if (options.locale) {
    setI18nLanguage(i18n, options.locale)
  }
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  try {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
    )
    const messageData = messages.default || messages

    i18n.global.setLocaleMessage(locale, messageData)
    setI18nLanguage(i18n, locale)

    return nextTick()
  } catch (error) {
    console.error(`Failed to load locale ${locale}:`, error)
    return nextTick()
  }
}
