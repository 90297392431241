import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "price-breakdown" }
const _hoisted_2 = { style: {"vertical-align":"middle"} }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { style: {"vertical-align":"middle"} }
const _hoisted_5 = {
  class: "text-right",
  style: {"vertical-align":"middle"}
}
const _hoisted_6 = {
  key: 0,
  class: "mt-3",
  id: "conversion-notice"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_price_separated = _resolveComponent("price-separated")
  const _component_v_table = _resolveComponent("v-table")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_table, null, {
      default: _withCtx(() => [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.orderedPriceBreakdown, (item) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: item.label,
              class: _normalizeClass({ highlight: item.highlight })
            }, [
              (item.value > 0 && !item.hotelCurrency)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("td", _hoisted_2, _toDisplayString(item.label), 1 /* TEXT */),
                    _createElementVNode("td", {
                      class: _normalizeClass(["text-right", { discount: item.discount }]),
                      style: {"vertical-align":"middle"}
                    }, [
                      _createVNode(_component_price_separated, {
                        width: $options.longestPriceString,
                        price: item.preformattedValue
                      }, null, 8 /* PROPS */, ["width", "price"])
                    ], 2 /* CLASS */)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */))
          }), 128 /* KEYED_FRAGMENT */)),
          (!_ctx.isSameCurrency)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
                _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.$t(
                  'SingleRoomView#PriceDetails#Table Property currency grand total:'
                )) + " * ", 1 /* TEXT */),
                _createElementVNode("td", _hoisted_5, [
                  _createVNode(_component_price_separated, {
                    width: $options.longestPriceString,
                    price: $options.hotelGrandTotal,
                    "hotel-currency": !_ctx.isSameCurrency
                  }, null, 8 /* PROPS */, ["width", "price", "hotel-currency"])
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    (!_ctx.isSameCurrency)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, " * " + _toDisplayString(_ctx.$t('SingleRoomView#PriceDetails#Conversion notice', {
          currency: _ctx.currency,
          hotelCurrency: _ctx.hotelCurrency
        })), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}