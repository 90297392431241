import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page__content hotel-package" }

import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import AppHeader from '@/components/Common/AppHeader.vue'
import PackageLanding from '@/components/Packages/PackageLanding.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Package',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Package')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppHeader),
    _createVNode(PackageLanding)
  ]))
}
}

})