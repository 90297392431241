import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import store from '@/app/store'


export default {
  __name: 'AppFooter',
  setup(__props) {

const currentRoute = useRoute()
const { t } = useI18n()
const links = ref([
  {
    label: 'Footer#Links Privacy policy',
    name: 'customer-account-privacy-policy',
    target: '_blank'
  },
  {
    label: 'Footer#Links General terms',
    name: 'customer-account-general-terms',
    target: '_blank'
  },
  {
    label: 'Footer#Links Cancel a reservation',
    name: 'ReservationCancel',
    target: '_self'
  }
])

watch(
  () => currentRoute.name,
  (name) => {
    if (name == 'ReservationCancel') {
      links.value.pop()
    } else if (links.value.length < 3) {
      links.value.push({
        label: 'Footer#Links Cancel a reservation',
        name: 'ReservationCancel',
        target: '_self'
      })
    }
  },
  { immediate: true }
)

return (_ctx, _cache) => {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_footer = _resolveComponent("v-footer")

  return (_openBlock(), _createBlock(_component_v_footer, {
    absolute: "",
    class: "pa-0",
    padless: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        justify: "center",
        "no-gutters": ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, ({ label, name, target }) => {
            return (_openBlock(), _createBlock(_component_v_btn, {
              key: name,
              to: { name },
              target: target,
              disabled: _unref(store).getters.loading,
              size: "x-large",
              variant: "plain"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)(label)), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "target", "disabled"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

}