import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["placeholder", "autocomplete", "readOnly"]
const _hoisted_2 = ["id", "type", "placeholder", "autocomplete", "readOnly", "list"]

import { ref, computed, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseField from './BaseField.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'fieldText',
  props: /*@__PURE__*/_mergeModels({
    id: {},
    placeholder: { default: '' },
    autofillName: {},
    type: {},
    description: { default: '' },
    icon: { default: '' },
    readOnly: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    errorMessage: { default: '' },
    portalTarget: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["focus", "blur", "checkedInput"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n()

const emit = __emit

const model = _useModel<string>(__props, "modelValue")

const props = __props

const showPassword: Ref<boolean> = ref(false)

const fieldType = computed(() =>
  props.type !== 'password'
    ? props.type
    : props.showPassword
      ? 'text'
      : 'password'
)

const btnText = computed(() =>
  showPassword.value
    ? t('FormField#Password Hide')
    : t('FormField#Password Show')
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseField, {
    icon: _ctx.icon,
    description: _ctx.description,
    "error-message": _ctx.errorMessage,
    "has-error": _ctx.hasError,
    "portal-target": _ctx.portalTarget,
    type: _ctx.type
  }, {
    default: _withCtx(() => [
      (_ctx.type === 'textarea')
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
            class: "field__input",
            style: {"height":"56px","resize":"none"},
            placeholder: _ctx.placeholder,
            autocomplete: _ctx.autofillName,
            readOnly: _ctx.readOnly,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (model.value = $event.target.value)),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (emit('checkedInput', true))),
            onFocus: _cache[3] || (_cache[3] = ($event: any) => (emit('focus', $event))),
            onBlur: _cache[4] || (_cache[4] = ($event: any) => (emit('blur', $event)))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)), [
            [_vModelText, model.value]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((model).value = $event)),
            id: _ctx.id,
            class: "theme-container field__input",
            style: {"height":"56px"},
            type: fieldType.value,
            placeholder: _ctx.placeholder,
            autocomplete: _ctx.autofillName,
            readOnly: _ctx.readOnly,
            list: _ctx.id,
            onInput: _cache[6] || (_cache[6] = ($event: any) => (model.value = $event.target.value)),
            onChange: _cache[7] || (_cache[7] = ($event: any) => (emit('checkedInput', true))),
            onFocus: _cache[8] || (_cache[8] = ($event: any) => (emit('focus', $event))),
            onBlur: _cache[9] || (_cache[9] = ($event: any) => (emit('blur', $event)))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)), [
            [_vModelDynamic, model.value]
          ]),
      (_ctx.type === 'password')
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "toggle",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (showPassword.value = !showPassword.value))
          }, _toDisplayString(btnText.value), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["icon", "description", "error-message", "has-error", "portal-target", "type"]))
}
}

})