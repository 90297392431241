import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  class: "order-list__body overflow-hidden",
  "data-role": "content"
}
const _hoisted_3 = {
  class: "content-inner",
  "data-role": "content-inner"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_order_list_item = _resolveComponent("order-list-item")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["order-list d-flex flex-column justify-center", { 'is-active': _ctx.active || !$props.showToggler }])
  }, [
    _createVNode(_component_v_divider),
    ($props.showToggler)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "order-list__header text-caption text-center cursor-pointer position-relative py-2",
          onClick: _cache[0] || (_cache[0] = (...args) => ($options.handleState && $options.handleState(...args)))
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('Checkout#ViewOrder#Title View my order')), 1 /* TEXT */),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon" }, [
            _createElementVNode("i", { class: "icon-angle-down" })
          ], -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.roomStays, (roomStay, i) => {
          return (_openBlock(), _createBlock(_component_order_list_item, {
            key: i,
            "room-stay": roomStay,
            "is-reservation-expired": $props.isReservationExpired
          }, null, 8 /* PROPS */, ["room-stay", "is-reservation-expired"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.active]
    ])
  ], 2 /* CLASS */))
}