import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { ReservationStatus as ReservationRole } from '@/app/system/constants/reservation'

import ReservationHeader from '@/components/Reservation/ReservationHeader.vue'
import PaymentCard from '@/components/Checkout/Payment/PaymentCard.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservationPayment',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Reservation Payment')
})

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "8"
          }, {
            default: _withCtx(() => [
              _createVNode(ReservationHeader, {
                slot: "reservation-header",
                role: _unref(ReservationRole).Pay
              }, null, 8 /* PROPS */, ["role"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(PaymentCard, {
                slot: "reservation-content",
                reservation: _ctx.$attrs.reservationId
              }, null, 8 /* PROPS */, ["reservation"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})