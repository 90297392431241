import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition } from "vue"

import ReservationHeader from '@/components/Reservation/ReservationHeader.vue'
import { ReservationStatus as ReservationRole } from '@/app/system/constants/reservation'

export default /*@__PURE__*/_defineComponent({
  __name: 'Checkout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0" }, {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "shine" }, null, -1 /* HOISTED */)),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            md: 12,
            lg: 8,
            class: "px-0"
          }, {
            default: _withCtx(() => [
              _createVNode(ReservationHeader, {
                role: _unref(ReservationRole).Book
              }, null, 8 /* PROPS */, ["role"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in",
            appear: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})