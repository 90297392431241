import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { useCookies } from '@vueuse/integrations/useCookies'
import dayjs from 'dayjs'

import router from '../router'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import RoutingModule from './modules/routing'
import SessionModule from './modules/session'
import bookingRequest from './modules/bookingRequest'
import { isLocalStorageAccessible, isDevMode } from '../system/helper'
import analyticsPlugin from './plugins/analytics'
import { SESSION_DURATION } from '../constants.js'

const storage = isLocalStorageAccessible()
let vuexLocal = {}

if (storage) {
  vuexLocal = new VuexPersistence({
    key: 'SB4.vuex',
    storage: window.localStorage,
    modules: ['bookingRequest']
  })
}

const cookies = useCookies(['bookingRequest'])

const storePlugin = (store) => {
  store.watch(
    (_, getters) => getters.bookingRequest,
    (bookingRequest) => {
      cookies.set('bookingRequest', bookingRequest, {
        expires: dayjs().add(SESSION_DURATION, 'minute').toDate()
      })
    }
  )

  store.subscribe((mutation) => {
    if (getters.hotelId) {
      switch (mutation.type) {
        case 'SET_CURRENCY':
          if (
            router.currentRoute.value.name !== 'ReservationModify' &&
            router.currentRoute.value.name !== 'package'
          ) {
            store.dispatch('getRoomsAvailabilities')
          }
          break
        case 'SET_LANGUAGE':
          store.dispatch('getDescriptiveInfo')
          break
      }
    }
  })
}

const plugins = [storePlugin, analyticsPlugin]

if (vuexLocal) {
  plugins.push(vuexLocal.plugin)
}

const store = createStore({
  state,
  mutations,
  getters,
  actions,
  modules: {
    RoutingModule,
    SessionModule,
    bookingRequest
  },
  plugins
})

export const useStore = () => store
export default store
