import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bundles__block block-form" }
const _hoisted_2 = { class: "block__title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "block__content" }
const _hoisted_5 = {
  key: 1,
  class: "block__loader"
}
const _hoisted_6 = { class: "actions-toolbar" }
const _hoisted_7 = { class: "action primary" }
const _hoisted_8 = { class: "value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OrderList = _resolveComponent("OrderList")
  const _component_Loader = _resolveComponent("Loader")
  const _component_ButtonAnimated = _resolveComponent("ButtonAnimated")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('Bundle#Landing#Title Your bundle')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!$data.loading)
        ? (_openBlock(), _createBlock(_component_OrderList, {
            key: 0,
            "room-stays": _ctx.bookingRequest.roomStays,
            "active-state": true
          }, null, 8 /* PROPS */, ["room-stays"]))
        : _createCommentVNode("v-if", true),
      ($data.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Loader)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ButtonAnimated, {
          onClick: $options.proceedToCheckout,
          disabled: $data.loading
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "overlay" }, null, -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('Bundle#Landing#Button Proceed to checkout')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick", "disabled"])
      ])
    ])
  ]))
}