import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'
import store from '@/app/store'
import { formatPrice } from '@/app/system/helper'
import OrderPriceSection from '@/components/Order/OrderPriceSection.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderTotalPrice',
  setup(__props) {

const currency = computed(() => store.getters.currency)
const hotelCurrency = computed(() => store.getters.hotelCurrency)

const computedPrice = computed(() =>
  formatPrice(store.getters.reservationTotalPrice, {
    currency: currency.value || 'EUR'
  })
)

const computedLocalPrice = computed(() =>
  formatPrice(store.getters.reservationHotelTotalPrice, {
    currency: hotelCurrency.value || currency.value
  })
)

const showLocalPrice = computed(() => currency.value !== hotelCurrency.value)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(OrderPriceSection, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Checkout#Payment#Total Grand Total')), 1 /* TEXT */)
      ]),
      price: _withCtx(() => [
        _createTextVNode(_toDisplayString(computedPrice.value), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    (showLocalPrice.value)
      ? (_openBlock(), _createBlock(OrderPriceSection, { key: 0 }, _createSlots({
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Checkout#Payment#Total#Local Grand total (hotel currency)')), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, [
          (_unref(store).getters.reservationHotelTotalPrice)
            ? {
                name: "price",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(computedLocalPrice.value), 1 /* TEXT */)
                ]),
                key: "0"
              }
            : undefined
        ]), 1024 /* DYNAMIC_SLOTS */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})