import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_base_field = _resolveComponent("base-field")

  return (_openBlock(), _createBlock(_component_base_field, {
    icon: $props.icon,
    description: $props.description,
    "error-message": $props.errorMessage,
    "has-error": $props.hasError,
    "portal-target": $props.portalTarget
  }, null, 8 /* PROPS */, ["icon", "description", "error-message", "has-error", "portal-target"]))
}