import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import store from '@/app/store'
import { useGtm } from '@gtm-support/vue-gtm'

import ReservationForm from '@/components/Reservation/ReservationForm.vue'
import OrderInfoLine from '@/components/Order/OrderInfoLine.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutGuestInfo',
  setup(__props) {

const { t } = useI18n()
const gtm = useGtm()

const ga4Cart = computed(() => store.getters.ga4Cart)

useHead({
  title: () => t('Guest Info')
})

onMounted(() => {
  gtm.trackEvent({
    event: 'begin_checkout',
    ...ga4Cart.value
  })
})

return (_ctx: any,_cache: any) => {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: "v-sheet-margins pa-4",
    elevation: "1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { justify: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { xs: "12" }, {
                default: _withCtx(() => [
                  _createVNode(OrderInfoLine),
                  _createVNode(_component_v_divider, { class: "my-4" })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_row, {
            class: "mt-0",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                xs: "12",
                class: "pt-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(ReservationForm, {
                    role: "Book",
                    "guest-info": _unref(store).getters.bookingRequest.guestInfo
                  }, null, 8 /* PROPS */, ["guest-info"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})