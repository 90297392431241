import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'CurrencySelect',
  setup(__props) {

const { t, messages, locale } = useI18n()

const store = useStore()

const selectref = ref()
const attrs: Record<string, any> = {
  ref: selectref,
  eager: true,
  singleLine: true,
  noDataText: t('Currency#Switcher#NoResults Currency not found')
}

const localMessages = ref([])

watch(
  () => [locale.value],
  async () => {
    if (locale.value) {
      await store.dispatch('getCurrenciesTranslations')
    }
  },
  {
    immediate: true
  }
)

const currencies = computed(() => {
  const currenciesState = store.state.currencies
  if (!store.state.currencies?.preferred || !store.state.currencies?.others) {
    return []
  }

  const preferredCurrencies = store.state.currencies.preferred.sort((a, b) =>
    a > b ? 1 : -1
  )
  const otherCurrencies = store.state.currencies.others.sort((a, b) =>
    a > b ? 1 : -1
  )

  return [...preferredCurrencies, '-', ...otherCurrencies]
})

watch(
  () => [currencies.value, store.getters.language],
  () => {
    localMessages.value = currencies.value.map((currency) => {
      if (currency === '-') {
        return {
          value: '-',
          title: 'Divider',
          isDivider: true
        }
      }

      let title = currency
      if (messages.value[store.getters.language]?.[currency]) {
        title = `${currency} - ${t(messages.value[store.getters.language][currency])}`
      } else {
        title = currency
      }

      return {
        value: currency,
        title
      }
    })
  },
  {
    immediate: true,
    deep: true
  }
)

const value = computed({
  get: () =>
    localMessages.value.find(
      (option) => option.value == store.state.currency && !option.isDivider
    )?.value ||
    store.state.currency ||
    'EUR',
  set: (newValue) => {
    if (!store.getters.loading && newValue && newValue !== '-') {
      store.commit('SET_CURRENCY', newValue)
      selectref.value.blur()
    }
  }
})

const isLoading = computed(
  () =>
    store.getters.loading ||
    !store.getters.descriptiveInfoLoaded ||
    !store.getters.availabilitiesLoaded ||
    !store.getters.hotelPackagesLoaded
)

function onKeyDown(event): void {
  if (event.key === 'Escape') {
    selectref.value.blur()
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createBlock(_component_v_autocomplete, _mergeProps(attrs, {
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    items: localMessages.value,
    disabled: isLoading.value,
    loading: isLoading.value || _ctx.isTranslationsLoading,
    variant: "underlined",
    onKeydown: onKeyDown
  }), {
    item: _withCtx(({ item, props }) => [
      (item.raw.isDivider)
        ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
        : (_openBlock(), _createBlock(_component_v_list_item, _mergeProps({ key: 1 }, props, {
            title: item.raw.title
          }), null, 16 /* FULL_PROPS */, ["title"]))
    ]),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["modelValue", "items", "disabled", "loading"]))
}
}

})