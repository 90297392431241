import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, isRef as _isRef, createBlock as _createBlock } from "vue"

import { computed, watch, onMounted } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/app/store'


export default {
  __name: 'SortDropdown',
  setup(__props) {

const { t } = useI18n()
const store = useStore()

const items = computed(() => [
  {
    id: 'popularity',
    value: t('Search#Results#Sorting Popularity')
  },
  {
    id: 'roomName',
    icon: 'icon-bed',
    value: t('Search#Results#Sorting Title')
  },
  {
    id: 'pricesUp',
    icon: 'icon-sort-amount-asc',
    value: t('Search#Results#Sorting Price Up')
  },
  {
    id: 'pricesDown',
    icon: 'icon-sort-amount-desc',
    value: t('Search#Results#Sorting Price Down')
  }
])

const defaultSorting = computed({
  get: () => store.getters.defaultSorting,
  set: (val) => (selectedSorting.value = val)
})

const selectedSorting = useLocalStorage('SB4.sort-by', defaultSorting.value)

watch(selectedSorting, (newSorting) => {
  store.commit('SET_SORTER_NAME', newSorting)
})

onMounted(() => {
  store.commit('SET_SORTER_NAME', selectedSorting.value)
})

return (_ctx, _cache) => {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_select = _resolveComponent("v-select")

  return (_openBlock(), _createBlock(_component_v_select, {
    modelValue: _unref(selectedSorting),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (_isRef(selectedSorting) ? (selectedSorting).value = $event : null)),
    class: "toolbar__sorter float-right",
    items: items.value,
    "item-value": "id",
    "item-title": "value",
    label: "Sort by",
    density: "compact",
    variant: "outlined",
    rounded: "0",
    "hide-details": "",
    "single-line": ""
  }, {
    "append-inner": _withCtx(() => [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("mdi-sort")
        ])),
        _: 1 /* STABLE */
      })
    ]),
    item: _withCtx(({ props, item }) => [
      _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
        prepend: _withCtx(() => [
          (item.raw.icon)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass([item.raw.icon, 'pr-3']),
                style: {"font-size":"22px","color":"rgba(0, 0, 0, 0.54)"}
              }, null, 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "items"]))
}
}

}