import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "field__item" }
const _hoisted_2 = { class: "selected-room" }
const _hoisted_3 = { class: "selected-room__label d-flex align-center mb-1" }
const _hoisted_4 = { class: "text-caption" }
const _hoisted_5 = { class: "button-container" }
const _hoisted_6 = { class: "selected-room__description theme-container" }
const _hoisted_7 = { class: "selected-room__room-name" }
const _hoisted_8 = { class: "selected-room__price" }
const _hoisted_9 = {
  key: 0,
  class: "selected-room__rateplan"
}
const _hoisted_10 = { class: "selected-room__occupancy" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_fa_icon = _resolveComponent("fa-icon")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "label-icon" }, [
          _createElementVNode("i", { class: "icon-person" })
        ], -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, _toDisplayString($options.title), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_v_btn, {
            class: "pa-0",
            slim: "slim",
            variant: "text",
            "min-width": 25,
            height: 25,
            ripple: false,
            onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('remove', $props.index)), ["stop","prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_fa_icon, {
                class: "text-body-1",
                icon: ['fas', 'xmark']
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString($options.roomName), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_8, _toDisplayString($options.price), 1 /* TEXT */),
        ($options.ratePlanName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($options.ratePlanName), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_10, "(" + _toDisplayString($options.occupancy) + ")", 1 /* TEXT */)
      ])
    ])
  ]))
}