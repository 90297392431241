import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "module__info-line" }
const _hoisted_2 = { class: "item__key" }
const _hoisted_3 = { class: "item__value" }
const _hoisted_4 = { class: "item__key" }
const _hoisted_5 = { class: "item__value" }
const _hoisted_6 = { class: "item__key" }
const _hoisted_7 = { class: "item__value" }
const _hoisted_8 = { class: "item__key" }
const _hoisted_9 = { class: "item__value" }
const _hoisted_10 = { class: "item__key" }
const _hoisted_11 = { class: "item__value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('Checkout#ViewOrder#Title Your order:')), 1 /* TEXT */),
    _createElementVNode("div", {
      class: _normalizeClass(['info-line__item', { 'text-caption': $setup.isMobile }])
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('Checkout#ViewOrder#Check-in Check-in:')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatDate(_ctx.currentRoomStay.startDate)), 1 /* TEXT */)
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(['info-line__item', { 'text-caption': $setup.isMobile }])
    }, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('Checkout#ViewOrder#Check-out Check-out:')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatDate(_ctx.currentRoomStay.endDate)), 1 /* TEXT */)
    ], 2 /* CLASS */),
    ($options.isMultiBooking)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(['info-line__item', { 'text-caption': $setup.isMobile }])
          }, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('Checkout#ViewOrder#Rooms Number of rooms:')), 1 /* TEXT */),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.bookingRequest.nbBookedRooms), 1 /* TEXT */)
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(['info-line__item', { 'text-caption': $setup.isMobile }])
          }, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('Checkout#ViewOrder#Guests Total guests:')), 1 /* TEXT */),
            _createElementVNode("span", _hoisted_9, _toDisplayString($options.nbGuestsTotal), 1 /* TEXT */)
          ], 2 /* CLASS */)
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['info-line__item', { 'text-caption': $setup.isMobile }])
        }, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('Checkout#ViewOrder#Guests Guests:')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_11, _toDisplayString($options.nbGuests), 1 /* TEXT */)
        ], 2 /* CLASS */))
  ]))
}