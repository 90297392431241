import * as Sentry from '@sentry/vue'
import { isDevMode } from '../system/helper'
import { setupI18n } from './i18n'

const i18n = setupI18n()

export function initSentry(app, router) {
  if (process.env.SENTRY_ENABLED !== 'true') {
    return
  }

  Sentry.init({
    app,
    sendClientReports: false,
    allowUrls: [/(.*\.)?hotel-spider\.com.*/],
    ignoreErrors: [
      'HierarchyRequestError',
      'Cmprm',
      'Hotjar',
      // when local storage is disabled, browsers may throw exceptions
      'Local storage is not supported',
      'SecurityError: The operation is insecure.',
      'NavigationDuplicated',
      'NS_ERROR_FILE_CORRUPTED',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'QuotaExceededError',
      /can't redefine non-configurable property "userAgent"/,
      'Meta Pixel',
      /Can't find variable: fbq/,
      /not authorized to show the cookie banner/,
      'separated-price__cu/rrency'
    ],
    environment: process.env.NODE_ENV,
    dsn: 'https://a073850e583042f5bd3d93e39a320fe8@sentry.io/1866565',
    release: 'spider-booking@' + process.env.npm_package_version,
    logErrors: isDevMode(),
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        tracingOrigins: [
          process.env.CRS_ENDPOINT,
          process.env.TRANSLATIONS_ENDPOINT
        ]
      }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/.*\.hotel-spider\.com/],
    beforeSend(event) {
      event.tags = event.tags || {}
      event.tags['hotelId'] = store.getters.hotelId
      event.tags['locale'] = i18n.global.locale.value
      return event
    }
  })
}
