import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseField from './BaseField.vue'
import InputCvv from './InputCvv.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'fieldCVV',
  props: /*@__PURE__*/_mergeModels({
    placeholder: {},
    description: { default: '' },
    icon: {},
    hasError: { type: Boolean, default: false },
    errorMessage: { default: '' },
    portalTarget: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const model = _useModel<string>(__props, "modelValue")

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseField, {
    icon: _ctx.icon,
    description: _ctx.description,
    "error-message": _ctx.errorMessage,
    "has-error": _ctx.hasError,
    "portal-target": _ctx.portalTarget
  }, {
    default: _withCtx(() => [
      _createVNode(InputCvv, {
        modelValue: model.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
        placeholder: _ctx.placeholder,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (model.value = $event.target.value))
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["icon", "description", "error-message", "has-error", "portal-target"]))
}
}

})