import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "text--secondary text-center" }

import { computed } from 'vue'
import PackagesListItem from '@/components/Packages/PackagesListItem.vue'
import SkeletonRoomItem from '@/components/UI/Vuetify/SkeletonRoomItem.vue'

import store from '@/app/store'


export default {
  __name: 'PackagesList',
  setup(__props) {

const loading = computed(
  () => !store.getters.loading && !store.getters.hotelPackagesLoaded
)

const hotelPackages = computed(() =>
  store.getters.hotelPackages
    .filter(({ publicPackage }) => publicPackage === true)
    .sort((a, b) => a.sort - b.sort)
)

return (_ctx, _cache) => {
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      tag: "div",
      class: "packages-list",
      name: "list"
    }, {
      default: _withCtx(() => [
        (loading.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (index) => {
              return _createVNode(SkeletonRoomItem, { key: index })
            }), 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hotelPackages.value, (item) => {
                return (_openBlock(), _createBlock(PackagesListItem, {
                  key: item.id,
                  item: item
                }, null, 8 /* PROPS */, ["item"]))
              }), 128 /* KEYED_FRAGMENT */)),
              _createVNode(_component_v_sheet, { key: "all-loaded" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('Search#Results#AllResults All results loaded')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ], 64 /* STABLE_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

}