import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "room__info-block block-packages" }
const _hoisted_2 = { class: "block__content" }
const _hoisted_3 = { class: "block__items block__accordion" }

import { computed } from 'vue'
import { getImageKitUrls, getResolution } from '@/app/system/helper'
import store from '@/app/store'
import Accordion from '@/components/Reservation/ReservationServicesAccordion'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomUpsell',
  props: {
  mainPrice: {
    type: Number,
    required: true
  }
},
  setup(__props) {



function getDescription(item) {
  const images = getImageKitUrls(
    item.room.multimediaDescriptions.images,
    getResolution(this.$vuetify.display)
  )

  const texts = item.room.multimediaDescriptions.texts.map((t) => t.text)

  return {
    images,
    texts
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).getters.upsell, (item, index) => {
          return (_openBlock(), _createBlock(_unref(Accordion), {
            id: item.productId,
            key: index,
            title: item.room?.roomTypeName,
            price: item.totalPrice,
            discount: item.discount,
            "main-price": __props.mainPrice,
            description: getDescription(item),
            "mode-link": ""
          }, null, 8 /* PROPS */, ["id", "title", "price", "discount", "main-price", "description"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}
}

})