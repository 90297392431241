import mitt, { type Emitter } from 'mitt'

type EmitterEvents = {
  'refresh-session': undefined
  'reset-search-params': undefined
  'apply-filters': undefined
  'reset-filters': undefined
  'open-dates': undefined
  'apply-dates': undefined
  'reset-dates': undefined
  'apply-guests': undefined
  'reset-guests': undefined
  'refresh-price-range-value': undefined
  'refresh-search-fields': undefined
  'destroy-resize-modal': undefined
  'dropdown': undefined
  'payrails-client-init': boolean
  'juspay-client-init': boolean
  'promo-code-submitted': string
  'secure-fields:ready': undefined
  'secure-fields:change': object
  'secure-fields:validate': object
  'secure-fields:success': { transactionId: string }
  'secure-fields:error': object
}

type ExtendedEmitter<T extends Record<string, unknown>> = Emitter<T> & {
  once: <K extends keyof T>(event: K, callback: (arg: T[K]) => void) => void
}

const emitter = mitt<EmitterEvents>() as ExtendedEmitter<EmitterEvents>

emitter.once = function <K extends keyof EmitterEvents>(event: K, callback: (arg: EmitterEvents[K]) => void) {
  const handler = (arg: EmitterEvents[K]) => {
    callback(arg)
    emitter.off(event, handler)
  }
  emitter.on(event, handler)
}

const MittPlugin = {
  install(Vue: any) {
    if (Vue.config?.globalProperties) {
      Vue.config.globalProperties.$emitter = emitter
    }

    // Provide the emitter for Composition API
    if (Vue.provide) {
      Vue.provide('emitter', emitter)
    }
  }
}

export { emitter, MittPlugin }
