import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex justify-center",
  style: {"padding":"1.2rem"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_mobile_heading = _resolveComponent("mobile-heading")
  const _component_date_range_picker = _resolveComponent("date-range-picker")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field date no-scroll_dates", {
      'is-active': $props.active,
      'module__aside-menu v-navigation-drawer': !$setup.isDesktop
    }])
  }, [
    (!$setup.isDesktop)
      ? (_openBlock(), _createBlock(_component_mobile_heading, {
          key: 0,
          onClose: $options.applyDates,
          onClear: $options.clearDates,
          title: _ctx.$t('Search#Form#Field Dates')
        }, null, 8 /* PROPS */, ["onClose", "onClear", "title"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_date_range_picker, {
      range: $options.dates,
      "start-date": _ctx.bookingRequest.checkIn,
      "end-date": _ctx.bookingRequest.checkOut,
      inline: "",
      "popover-visibility": "visible",
      onInput: _cache[0] || (_cache[0] = $event => ($options.updateDates($event)))
    }, null, 8 /* PROPS */, ["range", "start-date", "end-date"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, {
        class: "text-none",
        color: "primary",
        width: "50%",
        ripple: false,
        size: "x-large",
        tile: "",
        onClick: $options.applyDates
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#Button Apply')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_v_btn, {
        class: "text-none",
        width: "50%",
        ripple: false,
        variant: "plain",
        size: "x-large",
        tile: "",
        onClick: $options.clearDates
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#Mobile Reset dates')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$setup.isDesktop]
    ])
  ], 2 /* CLASS */))
}