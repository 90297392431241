import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "fa-ul pa-0" }


export default /*@__PURE__*/_defineComponent({
  __name: 'IconItemList',
  props: {
  items: {
    type: Array,
    required: true
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.id,
        class: _normalizeClass(item.class),
        style: _normalizeStyle({ color: item.color })
      }, [
        _createElementVNode("span", {
          class: "fa-li",
          style: _normalizeStyle({ color: item.iconColor })
        }, [
          _createVNode(_component_fa_icon, {
            icon: item.icon.split(' ')
          }, null, 8 /* PROPS */, ["icon"])
        ], 4 /* STYLE */),
        _createTextVNode(" " + _toDisplayString(item.text), 1 /* TEXT */)
      ], 6 /* CLASS, STYLE */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})