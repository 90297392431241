import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menu__block switchers"
}
const _hoisted_2 = { class: "menu__block menu-categories no-scroll_menu" }
const _hoisted_3 = { class: "value" }
const _hoisted_4 = { class: "value" }

import { isDesktop } from '@/app/composables/useBreakpoints'


import { mapGetters } from 'vuex'
import { VDivider } from 'vuetify/components/VDivider'
import { forceRemoveModalClasses } from '@/app/system/helper'
import LanguageSelect from '@/components/UI/Controls/Select/LanguageSelect.vue'
import CurrencySelect from '@/components/UI/Controls/Select/CurrencySelect.vue'
import ThemeToggler from '@/components/UI/Controls/Select/ThemeToggler.vue'

const __default__ = {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      line: {
        top: 0,
        height: 0
      }
    }
  },
  computed: {
    ...mapGetters(['termstext', 'currency', 'loading']),
    menuCategories() {
      return [
        {
          title: this.$t('MyAccount#Menu Privacy Policy'),
          icon: 'terms-conditions',
          link: 'privacy-policy'
        },
        {
          title: this.$t('MyAccount#Menu General Terms'),
          icon: 'terms-conditions',
          link: 'general-terms'
        }
      ]
    }
  },
  methods: {
    hideEmptyLink(val) {
      return val === 'general-terms' && !this.termstext ? 'empty-link' : ''
    },
    setLineToActiveItem() {
      this.$nextTick(function () {
        let _container = document.querySelector('.menu-categories'),
          _activeLink = _container.querySelector('.router-link-exact-active'),
          _activeLinkContainer
        if (!_activeLink) {
          return
        }
        _activeLinkContainer = _activeLink.closest('.menu-categories__item')
        this.line.top = _activeLinkContainer.offsetTop
        this.line.height = _activeLinkContainer.offsetHeight
        this.$forceUpdate()
      })
    }
  },
  mounted() {
    this.setLineToActiveItem()
  },
  beforeUnmount() {
    forceRemoveModalClasses()
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'CustomerAccountMenu',
  emits: ['close-menu'],
  setup(__props, { emit: __emit }) {

const emit = __emit

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu__container", { 'is-active': __props.active }])
  }, [
    (!_unref(isDesktop))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(CurrencySelect, { class: "mr-3" }),
          _createVNode(_unref(VDivider), { vertical: "" }),
          _createVNode(LanguageSelect, { class: "ml-3" }),
          _createElementVNode("div", {
            class: "action__close pl-4",
            onClick: _cache[0] || (_cache[0] = $event => (emit('close-menu')))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "icon-close" }, null, -1 /* HOISTED */)
          ]))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "line line_menu",
        style: _normalizeStyle({ top: _ctx.line.top + 'px', height: _ctx.line.height + 'px' })
      }, null, 4 /* STYLE */),
      _createElementVNode("div", {
        class: "menu-categories__item",
        "data-role": "item",
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.setLineToActiveItem && _ctx.setLineToActiveItem(...args)))
      }, [
        _createVNode(_component_router_link, { to: { name: 'ReservationCancel' } }, {
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "icon-my-orders" }, null, -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('MyAccount#Menu Cancel a reservation')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuCategories, (category) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["menu-categories__item", _ctx.hideEmptyLink(category.link)]),
          "data-role": "item",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.setLineToActiveItem && _ctx.setLineToActiveItem(...args)))
        }, [
          _createVNode(_component_router_link, {
            to: { name: `customer-account-${category.link}` }
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(`icon-${category.icon}`)
              }, null, 2 /* CLASS */),
              _createElementVNode("span", _hoisted_4, _toDisplayString(category.title), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
        ], 2 /* CLASS */))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}
}

})