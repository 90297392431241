import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import { computed, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/app/store'
import { isDesktop, isMobile } from '@/app/composables/useBreakpoints'
import { isDevMode } from '@/app/system/helper'
import Logo from '@/components/Common/AppHeaderLogo.vue'
import MobileMenu from '@/components/UI/MenuMobile.vue'
import HomeLink from '@/components/UI/LinkHome.vue'
import LanguageSelect from '@/components/UI/Controls/Select/LanguageSelect.vue'
import CurrencySelect from '@/components/UI/Controls/Select/CurrencySelect.vue'
import ThemeToggler from '@/components/UI/Controls/Select/ThemeToggler.vue'
import LocalizationToggler from '@/components/UI/Controls/Select/LocalizationToggler.vue'


export default {
  __name: 'AppHeader',
  setup(__props) {

const instance = getCurrentInstance()
const route = useRoute()
const hotelHomepage = computed(() => store.getters.hotelHomepage)

const refreshSession = () => {
  route.name == 'ReservationModify' && instance.proxy.clearAndRefreshSession()
  instance.proxy.goHomeAndReload(true)
}

return (_ctx, _cache) => {
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { class: "d-flex justify-space-between align-center pa-3 mb-2" }, {
        default: _withCtx(() => [
          (!_unref(isDesktop))
            ? (_openBlock(), _createBlock(MobileMenu, { key: 0 }))
            : _createCommentVNode("v-if", true),
          _createVNode(Logo, {
            class: "mx-auto ml-lg-2",
            onClick: refreshSession
          }),
          (!_unref(isDesktop) && hotelHomepage.value)
            ? (_openBlock(), _createBlock(HomeLink, {
                key: 1,
                class: "mobile_home_link",
                href: hotelHomepage.value,
                target: "_blank"
              }, null, 8 /* PROPS */, ["href"]))
            : _createCommentVNode("v-if", true),
          (!_unref(isDesktop))
            ? (_openBlock(), _createBlock(ThemeToggler, { key: 2 }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      (_unref(isDesktop))
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 0,
            class: "d-flex justify-end align-center ga-4"
          }, {
            default: _withCtx(() => [
              _createVNode(ThemeToggler),
              (_unref(isDevMode)())
                ? (_openBlock(), _createBlock(LocalizationToggler, { key: 0 }))
                : _createCommentVNode("v-if", true),
              (hotelHomepage.value)
                ? (_openBlock(), _createBlock(HomeLink, {
                    key: 1,
                    href: hotelHomepage.value,
                    target: "_blank"
                  }, null, 8 /* PROPS */, ["href"]))
                : _createCommentVNode("v-if", true),
              (_unref(route).name != 'ReservationModify')
                ? (_openBlock(), _createBlock(CurrencySelect, { key: 2 }))
                : _createCommentVNode("v-if", true),
              _createVNode(LanguageSelect)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
}

}