import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "count" }

import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ReservationConfirmation from '@/components/Common/Confirmation.vue'
import TrackingByChannel from '@/components/Tracking/TrackingByChannel.vue'


export default {
  __name: 'ReservationModifyConfirmation',
  setup(__props) {

const store = useStore()
const { t } = useI18n()
const route = useRoute()

useHead({
  title: () => t('App#PageTitle#Reservation Modify Confirmation')
})

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(ReservationConfirmation, { role: "update" }, _createSlots({
    "confirmation-header": _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_unref(t)(
            'Reservation#Modify#Success Your reservation has been modified successfully'
          )), 1 /* TEXT */)
    ]),
    "confirmation-order-number\"": _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(t)('Checkout#ThankYou Order number')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(route).params.reservationId), 1 /* TEXT */)
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (!!_unref(store).getters.orderInfo.reservationId)
      ? {
          name: "confirmation-3rd-scripts",
          fn: _withCtx(() => [
            (!_unref(store).getters.isOwnChannel)
              ? (_openBlock(), _createBlock(TrackingByChannel, {
                  key: 0,
                  trivagoConversionMethod: "update"
                }))
              : _createCommentVNode("v-if", true)
          ]),
          key: "0"
        }
      : undefined
  ]), 1024 /* DYNAMIC_SLOTS */))
}
}

}