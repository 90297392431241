import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "checkout__block block-thank-you",
  "data-role": "checkout-animation-container"
}
const _hoisted_2 = {
  class: "thank-you__heading",
  "data-role": "checkout-animation-header"
}
const _hoisted_3 = { class: "text-center mb-8" }
const _hoisted_4 = {
  key: 1,
  class: "thank-you__body",
  "data-role": "checkout-animation-body"
}
const _hoisted_5 = { class: "order-info" }
const _hoisted_6 = { class: "info__number flex-center flex-column" }
const _hoisted_7 = {
  class: "thank-you__footer",
  "data-role": "checkout-animation-footer"
}
const _hoisted_8 = { class: "confirm-message" }
const _hoisted_9 = {
  key: 0,
  class: "text-decoration-underline"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loader = _resolveComponent("loader")
  const _component_hotel_contacts = _resolveComponent("hotel-contacts")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_sheet, { class: "pa-5" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "confirmation-header")
        ]),
        (_ctx.reservationLoading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_loader, {
                class: "mb-4",
                absolute: false
              }),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Checkout#Payment#Loading Payment in progress...')), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "confirmation-body"),
              _createElementVNode("div", _hoisted_5, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "info__icon" }, [
                  _createElementVNode("i", { class: "icon-my-account" })
                ], -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "confirmation-order-number")
                ])
              ])
            ])),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (!_ctx.reservationLoading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('Checkout#ThankYou An email confirmation has been sent to you')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_hotel_contacts, { class: "mb-5" })
          ]),
          _createVNode(_component_v_btn, {
            class: "text-none",
            disabled: _ctx.reservationLoading,
            ripple: false,
            variant: "outlined",
            size: "x-large",
            block: "",
            tile: "",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.goHomeAndReload(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($options.btnText), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"]),
          _createVNode(_component_v_btn, {
            class: "text-none mt-5",
            color: "primary",
            disabled: _ctx.reservationLoading,
            ripple: false,
            size: "x-large",
            block: "",
            tile: "",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.goHomeAndReload(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Checkout#Payment#Button Return home')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"])
        ]),
        _renderSlot(_ctx.$slots, "confirmation-3rd-scripts")
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}