import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "privacy-policy" }
const _hoisted_2 = {
  for: "policy",
  class: "cursor-pointer"
}

import { ref, watch, computed, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxPrivacyPolicy',
  emits: ["change"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()

const emit = __emit

const accepted: Ref<boolean> = ref(false)

watch(accepted, (val) => {
  emit('change', val)
})

const privacyPolicyLabel = computed(() =>
  t('PrivacyPolicy#Privacy Policy label')
)
const termsLabel = computed(() => t('PrivacyPolicy#Terms label'))
const cancellationText = computed(() =>
  t('PrivacyPolicy#Cancellatioon Conditions label')
)
const baseLabel = computed(() =>
  t('PrivacyPolicy#Checkbox I accept the terms', {
    'privacy-policy': '{privacy-policy-link}',
    terms: '{terms-link}',
    'cancellation-conditions': cancellationText.value
  })
)

return (_ctx: any,_cache: any) => {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_checkbox, {
      modelValue: accepted.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((accepted).value = $event)),
      id: "policy",
      "hide-details": ""
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createElementVNode("label", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseLabel.value.split('{privacy-policy-link}'), (part, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (index === 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(part), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_router_link, {
                  to: { name: 'customer-account-privacy-policy' },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(privacyPolicyLabel.value), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(part.split('{terms-link}'), (subPart, subIndex) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (subIndex === 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(subPart), 1 /* TEXT */)
                        ], 64 /* STABLE_FRAGMENT */))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_router_link, {
                            to: { name: 'customer-account-general-terms' },
                            target: "_blank"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(termsLabel.value), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createTextVNode(" " + _toDisplayString(subPart), 1 /* TEXT */)
                        ], 64 /* STABLE_FRAGMENT */))
                  ], 64 /* STABLE_FRAGMENT */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ], 64 /* STABLE_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ]))
}
}

})