import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page404 d-flex justify-center align-center" }
const _hoisted_2 = { class: "page404__content text-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "page404__text" }
const _hoisted_5 = { class: "description mt-4" }

import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { NOT_FOUND_REDIRECT_TIMEOUT } from '@/app/constants'
import store from '@/app/store'


export default /*@__PURE__*/_defineComponent({
  __name: '404',
  setup(__props) {

const router = useRouter()
const { t } = useI18n()

useHead({
  title: () =>
    t('App#PageTitle#404 Page not found. You will be redirected soon')
})

onMounted(() => {
  setTimeout(() => {
    router.push({ name: 'home' })
  }, NOT_FOUND_REDIRECT_TIMEOUT)
})

const logoUrl = computed(
  () =>
    store.getters.customization.logoUrl ||
    process.env.ASSETS_URL + '/img/logo.jpg'
)

return (_ctx: any,_cache: any) => {
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_sheet, { class: "pa-5" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "page404__img mb-2",
            src: logoUrl.value,
            alt: "logo"
          }, null, 8 /* PROPS */, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "error" }, "404", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('App#PageTitle#404 Page not found. You will be redirected soon')), 1 /* TEXT */)
          ])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}
}

})