import { unref as _unref, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "field guests no-scroll_guests" }
const _hoisted_2 = {
  key: 0,
  style: {"width":"100%"}
}
const _hoisted_3 = {
  key: 0,
  class: "px-6"
}
const _hoisted_4 = {
  key: 0,
  class: "room-guests-title"
}
const _hoisted_5 = { class: "room-guests-title__text" }
const _hoisted_6 = { class: "room-guests-title__icon" }
const _hoisted_7 = { class: "guests-item-wrapper" }
const _hoisted_8 = { class: "guest-item d-flex justify-space-between align-center my-3" }
const _hoisted_9 = { class: "guests-item__description" }
const _hoisted_10 = { class: "text-body-2" }
const _hoisted_11 = {
  key: 1,
  class: "guests-item-wrapper"
}
const _hoisted_12 = { class: "guest-item d-flex justify-space-between align-center my-3" }
const _hoisted_13 = { class: "guests-item__description" }
const _hoisted_14 = { class: "text-body-2" }
const _hoisted_15 = { class: "text-caption font-weight-thin" }
const _hoisted_16 = {
  key: 2,
  class: "guests-item-wrapper"
}
const _hoisted_17 = { class: "guest-item d-flex justify-space-between align-center my-3" }
const _hoisted_18 = { class: "guests-item__description" }
const _hoisted_19 = { class: "text-body-2" }
const _hoisted_20 = { class: "text-caption font-weight-thin" }
const _hoisted_21 = {
  key: 1,
  class: "module__content"
}

import { isDesktop } from '@/app/composables/useBreakpoints'

import { mapGetters } from 'vuex'
import { inRange } from 'lodash'
import GuestsModal from '@/components/Search/Fields/GuestsPopupDesktop.vue'
import InputQuantity from '@/components/UI/Controls/Inputs/InputQuantity.vue'
import SelectedRoom from '@/components/Search/Fields/SelectedRoom.vue'

const __default__ = {
  name: 'Guests',
  components: {
    GuestsModal,
    InputQuantity,
    SelectedRoom
  },
  emits: ['removeRoom'],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    roomStayIndex: {
      type: Number,
      default: 1
    },
    roomStay: {
      type: Object,
      required: true
    },
    showRoomIndex: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['removeRoom'],
  data() {
    return {
      guestsUpdateKey: 0,
      adultsShow: false,
      adultsMin: 0,
      adultsMax: 0,
      childrenShow: false,
      childrenMin: 0,
      childrenMax: 0,
      infantsShow: false,
      infantsMin: 0,
      infantsMax: 0
    }
  },
  computed: {
    ...mapGetters([
      'bookingRequest',
      'currentRoomStay',
      'descriptiveInfoLoaded',
      'guestsBounds',
      'isAdultsOnlyHotel',
      'childrenAgeRange',
      'infantsAgeRange',
      'minChildAge',
      'maxChildAge',
      'minInfantAge',
      'maxInfantAge'
    ]),
    computedGuests() {
      const { adults, children, infants } = this.guestsBounds
      const { nbAdults, nbChildren, nbInfants } = this.currentRoomStay

      const adultsTitle = this.isAdultsOnlyHotel
        ? this.$t('Search#Form#Field#NumPersonsPlural Persons')
        : this.$t('Search#Form#Field#NumAdultsPlural Adults')

      const guests = {
        adults: {
          id: 'adults',
          title: adultsTitle,
          default: inRange(nbAdults, adults.min, adults.max + 1),
          min: adults.min,
          max: adults.max,
          show: this.adultsShow
        }
      }
      if (this.$route.name !== 'package') {
        guests.children = {
          id: 'children',
          title: 'Search#Form#Field#NumChildrenPlural Children',
          default: inRange(nbChildren, children.min, children.max + 1),
          min: children.min,
          max: children.max,
          show: this.childrenShow
        }

        guests.infants = {
          id: 'infants',
          title: 'Search#Form#Field#NumInfantsPlural Infants',
          default: inRange(nbInfants, infants.min, infants.max + 1),
          min: infants.min,
          max: infants.max,
          show: this.infantsShow
        }
      }
      return guests
    }
  },
  watch: {
    descriptiveInfoLoaded: {
      immediate: true,
      handler(descriptiveInfoLoaded) {
        if (!descriptiveInfoLoaded) {
          return
        }

        const { adults, children, infants } = this.guestsBounds

        this.adultsMin = adults.min
        this.adultsMax = adults.max
        this.adultsShow = adults.max > 0

        this.childrenMin = children.min
        this.childrenMax = children.max
        this.childrenShow =
          children.max > children.min && this.maxChildAge > this.minChildAge

        this.infantsMin = infants.min
        this.infantsMax = infants.max
        this.infantsShow =
          infants.max > infants.min && this.maxInfantAge > this.minInfantAge

        if (this.hideMinors()) {
          this.childrenShow = false
          this.infantsShow = false
        }
      }
    }
  },
  mounted() {
    this.$emitter.on('apply-guests', () => {
      this.submitSearchInfo()
      this.$store.dispatch('getRoomsAvailabilities')
    })
  },
  beforeUnmount() {
    this.$emitter.off('apply-guests')
  },
  methods: {
    hideMinors() {
      return this.$route.name === 'package'
    },
    onGuestsQuantityChange({ guestType, quantity }) {
      this.bookingRequest.roomStays[this.roomStayIndex][guestType] = quantity
    },
    clearGuests(index) {
      this.bookingRequest.roomStays[index].adults = this.guestsBounds.adults.min
      this.bookingRequest.roomStays[index].children =
        this.guestsBounds.children.min
      this.bookingRequest.roomStays[index].infants =
        this.guestsBounds.infants.min

      this.guestsUpdateKey += 1
      this.$emitter.emit('reset-guests')
    },
    removeRoom() {
      this.$emit('removeRoom')
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {


return (_ctx, _cache) => {
  const _component_v_divider = _resolveComponent("v-divider")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.roomStay.isBooked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createBlock(GuestsModal, {
            key: _ctx.guestsUpdateKey,
            "room-stay-index": __props.roomStayIndex,
            "room-stay": __props.roomStay,
            guests: _ctx.computedGuests,
            onChange: _ctx.onGuestsQuantityChange,
            onClear: _cache[0] || (_cache[0] = $event => (_ctx.clearGuests($event))),
            onRemove: _ctx.removeRoom
          }, null, 8 /* PROPS */, ["room-stay-index", "room-stay", "guests", "onChange", "onRemove"])), [
            [_vShow, _unref(isDesktop)]
          ]),
          (!_unref(isDesktop))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (__props.showRoomIndex)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, " Room #" + _toDisplayString(__props.roomStayIndex + 1), 1 /* TEXT */),
                      _createElementVNode("span", _hoisted_6, [
                        (_ctx.bookingRequest.rooms > 1)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: "icon-close",
                              onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.removeRoom && _ctx.removeRoom(...args)))
                            }))
                          : _createCommentVNode("v-if", true)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode(" Adults "),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.isAdultsOnlyHotel
                    ? _ctx.$t('Search#Form#Field#NumPersonsPlural Persons')
                    : _ctx.$t('Search#Form#Field#NumAdultsPlural Adults')), 1 /* TEXT */)
                    ]),
                    (_ctx.adultsShow)
                      ? (_openBlock(), _createBlock(InputQuantity, {
                          key: 0,
                          min: _ctx.adultsMin,
                          max: _ctx.adultsMax,
                          default: __props.roomStay.adults,
                          onChange: _cache[2] || (_cache[2] = $event => (
                _ctx.onGuestsQuantityChange({
                  guestType: 'adults',
                  quantity: $event
                })
              ))
                        }, null, 8 /* PROPS */, ["min", "max", "default"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _createVNode(_component_v_divider)
                ]),
                _createCommentVNode(" Children "),
                (_ctx.$route.name !== 'package' && _ctx.childrenShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('Search#Form#Field#NumChildrenPlural Children')), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_15, [
                            (_ctx.childrenAgeRange.minAge)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(_ctx.$t(
                      'Search#Form#Field#GuestsAge {minAge}-{maxAge} years old',
                      _ctx.childrenAgeRange
                    )), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(_ctx.$t(
                      'Search#Form#Field#GuestsAge Under {maxAge} years old',
                      _ctx.childrenAgeRange
                    )), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                          ])
                        ]),
                        _createVNode(InputQuantity, {
                          min: _ctx.childrenMin,
                          max: _ctx.childrenMax,
                          default: __props.roomStay.children,
                          onChange: _cache[3] || (_cache[3] = $event => (
                _ctx.onGuestsQuantityChange({
                  guestType: 'children',
                  quantity: $event
                })
              ))
                        }, null, 8 /* PROPS */, ["min", "max", "default"])
                      ]),
                      _createVNode(_component_v_divider)
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode(" Infants "),
                (_ctx.$route.name !== 'package' && _ctx.infantsShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('Search#Form#Field#NumInfantsPlural Infants')), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_20, [
                            (_ctx.infantsAgeRange.minAge)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(_ctx.$t(
                      'Search#Form#Field#GuestsAge {minAge}-{maxAge} years old',
                      _ctx.infantsAgeRange
                    )), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(_ctx.$t(
                      'Search#Form#Field#GuestsAge Under {maxAge} years old',
                      _ctx.infantsAgeRange
                    )), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                          ])
                        ]),
                        _createVNode(InputQuantity, {
                          min: _ctx.infantsMin,
                          max: _ctx.infantsMax,
                          default: __props.roomStay.infants,
                          onChange: _cache[4] || (_cache[4] = $event => (
                _ctx.onGuestsQuantityChange({
                  guestType: 'infants',
                  quantity: $event
                })
              ))
                        }, null, 8 /* PROPS */, ["min", "max", "default"])
                      ]),
                      _createVNode(_component_v_divider)
                    ]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (__props.roomStay.isBooked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode(SelectedRoom, {
            index: __props.roomStayIndex,
            onRemove: _ctx.removeRoom
          }, null, 8 /* PROPS */, ["index", "onRemove"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})