import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "text-h5 font-weight-bold" }
const _hoisted_2 = {
  key: 0,
  class: "subtitle-1"
}
const _hoisted_3 = {
  key: 2,
  class: "font-weight-bold mt-3"
}
const _hoisted_4 = { class: "text-lg-h6 font-weight-bold" }
const _hoisted_5 = {
  key: 0,
  class: "images"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "text"
}
const _hoisted_8 = { class: "text-lg-h6 font-weight-bold" }
const _hoisted_9 = {
  key: 1,
  class: "text-body-2 mt-2 mb-5"
}
const _hoisted_10 = { class: "text-lg-h6 font-weight-bold" }
const _hoisted_11 = { class: "text-lg-h6 font-weight-bold" }
const _hoisted_12 = { class: "text-lg-h6 font-weight-bold" }
const _hoisted_13 = { class: "text-lg-h6 font-weight-bold" }

import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { isMobile, isDesktop } from '@/app/composables/useBreakpoints'
import { useDisplay } from 'vuetify'

import store from '@/app/store'


import { inject } from 'vue'
import { mapGetters } from 'vuex'
import { each, find, isEmpty } from 'lodash'
import { event } from 'vue-gtag'
import Swal from 'sweetalert2'
import { getImageKitUrls, getResolution } from '@/app/system/helper'

import BlockPrice from './RoomPriceDetails.vue'
import BlockRoomDescription from './RoomDescription.vue'
import BlockTerms from './RoomCancellationPolicy.vue'
import BlockUpgrades from './RoomUpgrades.vue'
import BlockUpsell from './RoomUpsell.vue'
import ButtonBookNow from '@/components/UI/Controls/ButtonBookNow.vue'
import CompareMe from '@/components/Tracking/CompareMe123.vue'
import ImageSlider from '@/components/UI/Vuetify/ImageSlider.vue'
import InfolineWithCalendar from '@/components/Common/InfoLine.vue'
import ItemPrice from '@/components/Common/Price/Price.vue'
import Loader from '@/components/UI/Loader.vue'
import ProductsDetails from './RoomProductsDetails.vue'
import RoomFacilities from './RoomFacilities.vue'

const __default__ = {
  name: 'RoomLanding',
  components: {
    BlockPrice,
    BlockRoomDescription,
    BlockTerms,
    BlockUpgrades,
    BlockUpsell,
    ButtonBookNow,
    CompareMe,
    ImageSlider,
    InfolineWithCalendar,
    ItemPrice,
    Loader,
    ProductsDetails,
    RoomFacilities
  },
  computed: {
    ...mapGetters([
      'availabilitiesLoaded',
      'currentRoomStay',
      'descriptiveInfo',
      'descriptiveInfoLoaded',
      'filteredRooms',
      'ga4ViemItem',
      'ga4ProductItems',
      'isGoogleAdsChannel',
      'isRoomOrProductAvailable',
      'measureCode',
      'productsLoaded',
      'productById',
      'roomById',
      'upsell',
      'upgrades'
    ]),
    loading: (vm) =>
      !vm.availabilitiesLoaded ||
      !vm.productsLoaded ||
      !vm.descriptiveInfoLoaded,
    id: (vm) => vm.$route.params.roomId,
    isRoomMode: (vm) =>
      !vm.loading &&
      !!find(vm.descriptiveInfo.facilityInfo.guestRooms, { id: vm.id }),
    product: (vm) =>
      vm.isRoomMode
        ? vm.productById(vm.id)?.products[0] || vm.roomById(vm.id)?.products[0]
        : vm.productById(vm.id),
    room: (vm) =>
      vm.isRoomMode ? vm.roomById(vm.id).room : vm.product.room?.room,
    roomArea() {
      const measureUnit = this.measureCode === 14 ? 'm²' : 'ft²'
      if (this.room) {
        return `${this.$t('SingleRoomView#Description#Surface Surface')}: ${
          this.room.typeRoom.size
        } ${measureUnit}`
      }
      return ''
    },
    ratePlanName: (vm) => vm.product?.rate?.ratePlanName || '',
    rateDescription: (vm) => vm.product?.rate?.descriptions,
    btnText() {
      return this.loading
        ? this.$t('SingleRoomView#Book#Button Please wait')
        : this.$t('SingleRoomView#Book#Button Book Now')
    },
    selectedImages() {
      return getImageKitUrls(
        this.room?.multimediaDescriptions.images,
        getResolution(this.$vuetify.display)
      )
    }
  },
  watch: {
    $route() {
      this.updatePage()
    },
    async availabilitiesLoaded(availabilitiesLoaded) {
      if (
        availabilitiesLoaded &&
        this.productsLoaded &&
        !this.isRoomOrProductAvailable(this.id)
      ) {
        const { isConfirmed } = await Swal.fire({
          text: this.$t(
            'SingleRoomView#NoAvailability#Text Not available on these date. Redirecting to the home page'
          ),
          icon: 'warning',
          confirmButtonText: this.$t(
            'SingleRoomView#NoAvailability#Button Okay'
          ),
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        if (isConfirmed) {
          this.goHomeAndReload()
        }
      }
    },
    loading: {
      immediate: true,
      handler(loading) {
        if (loading || !this.product?.rate) {
          return
        }
        this.submitCurrentRoomStayInfo(this.id, this.product)
        this.$store.dispatch('getUpgrades', {
          roomId: this.id,
          ratePlanCode: this.product.rate.ratePlanCode,
          price: this.product.totalPrice - this.product.discount
        })
        this.$store.dispatch('getUpsell', {
          ratePlanCode: this.product.rate.ratePlanCode,
          price: this.product.totalPrice - this.product.discount
        })
      }
    },
    product(product) {
      if (!isEmpty(product) && product.productId !== this.id) {
        this.$store.dispatch('replaceRoute', {
          name: 'room',
          params: { roomId: product.productId }
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$gtm?.trackEvent({
        event: 'view_item',
        ecommerce: this.ga4ViemItem
      })
    })
    this.$emitter.on('refresh-session', () => {
      this.clearAndRefreshSession()
      this.goHomeAndReload()
    })

    // const onScrollHandler = () => {
    //   if (!this.$refs.buttonBook || this.isMobile) {
    //     return;
    //   }
    //
    //   const { y, height } = this.$refs.buttonBook.$el.getBoundingClientRect();
    //   this.$emitter.emit("single-room-view_book-now_top", y + height);
    // };

    // this.eventListeners = [
    // ["scroll", onScrollHandler],
    // ["resize", onScrollHandler],
    // ]

    // each(this.eventListeners, ([event, callback]) => {
    //   window.addEventListener(event, callback)
    // })
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
    this.$emitter.off('refresh-session')

    // each(this.eventListeners, ([event, callback]) => {
    //   window.removeEventListener(event, callback)
    // })
  },
  methods: {
    updatePage() {
      const page = document.querySelector('.page__content')
      window.scrollTo(0, 0)
      page.classList.add('page-enter')
      page.addEventListener(
        'transitionend',
        function () {
          this.classList.remove('page-enter')
          this.classList.add('page-leave')
          this.addEventListener(
            'transitionend',
            function () {
              this.classList.remove('page-leave')
            },
            { once: true }
          )
        },
        { once: true }
      )
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

const { t } = useI18n()
const display = useDisplay()

const panels = display.mobile.value ? [2, 3, 4, 5] : [0, 1, 2, 3, 4, 5]
const pannelsDisabled = ref(true)
const readonly = ref(false)

const carouselHeight = computed(() => {
  const heights = {
    Mobile: 'auto',
    Tablet: 450,
    Desktop: 500
  }
  return heights[getResolution(display)]
})

return (_ctx, _cache) => {
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card = _resolveComponent("v-card")
  const _component_portal = _resolveComponent("portal")
  const _component_v_sheet = _resolveComponent("v-sheet")

  return (!_ctx.loading && _ctx.product && _ctx.room)
    ? (_openBlock(), _createBlock(_component_v_sheet, {
        key: 0,
        class: "module__room",
        elevation: "1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(CompareMe),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(ImageSlider, {
                        height: carouselHeight.value,
                        images: _ctx.selectedImages,
                        title: _ctx.room.roomTypeName
                      }, null, 8 /* PROPS */, ["height", "images", "title"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(InfolineWithCalendar, { roomId: _ctx.id }, null, 8 /* PROPS */, ["roomId"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, {
                class: "room-info room-panel mb-lg-5 mt-lg-0 mt-md-5",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "room__heading col-12 col-lg-9" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.room.roomTypeName), 1 /* TEXT */),
                      (_unref(isDesktop) && !_ctx.isRoomMode)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.ratePlanName), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (_ctx.product.productId)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        class: "text-center",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.isRoomMode)
                            ? (_openBlock(), _createBlock(ItemPrice, {
                                key: 0,
                                price: _ctx.product.totalPrice,
                                discount: _ctx.product.discount,
                                currency: _ctx.product.currency,
                                numNights: _ctx.product.nbNights,
                                "total-local-tax": _ctx.product.totalLocalTax,
                                "show-grand-total": "show-grand-total",
                                "show-guest-number": "show-guest-number"
                              }, null, 8 /* PROPS */, ["price", "discount", "currency", "numNights", "total-local-tax"]))
                            : _createCommentVNode("v-if", true),
                          (!_ctx.isRoomMode)
                            ? (_openBlock(), _createBlock(ButtonBookNow, {
                                key: 1,
                                class: "mt-2",
                                ref: "buttonBook",
                                "product-id": _ctx.product.productId,
                                "room-id": _ctx.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.btnText), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["product-id", "room-id"]))
                            : _createCommentVNode("v-if", true),
                          (!_unref(isDesktop))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.ratePlanName), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, { class: "mt-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "mb-5 mt-lg-0 mt-md-5 mb-lg-8" }, {
                    default: _withCtx(() => [
                      (_ctx.room)
                        ? (_openBlock(), _createBlock(BlockRoomDescription, {
                            key: 0,
                            description: _ctx.room.multimediaDescriptions
                          }, null, 8 /* PROPS */, ["description"]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panels, {
                        modelValue: _unref(panels),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (_isRef(panels) ? (panels).value = $event : null)),
                        readonly: readonly.value,
                        variant: "accordion",
                        multiple: ""
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.isRoomMode)
                            ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                                default: _withCtx(() => [
                                  (
                  _ctx.rateDescription.images.length || _ctx.rateDescription.texts.length
                )
                                    ? (_openBlock(), _createBlock(_component_v_expansion_panel_title, {
                                        key: 0,
                                        class: "px-0",
                                        "hide-actions": !_unref(isMobile)
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h2", _hoisted_4, _toDisplayString(_unref(t)(
                      'SingleRoomView#Description#RatePlan More information about the rateplan:'
                    )) + " " + _toDisplayString(_ctx.ratePlanName), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      }, 8 /* PROPS */, ["hide-actions"]))
                                    : _createCommentVNode("v-if", true),
                                  _createVNode(_component_v_expansion_panel_text, null, {
                                    default: _withCtx(() => [
                                      (_ctx.rateDescription.images.length)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rateDescription.images, (src) => {
                                              return (_openBlock(), _createElementBlock("img", { src: src }, null, 8 /* PROPS */, _hoisted_6))
                                            }), 256 /* UNKEYED_FRAGMENT */))
                                          ]))
                                        : _createCommentVNode("v-if", true),
                                      (_ctx.rateDescription.texts.length)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rateDescription.texts, (sentence) => {
                                              return (_openBlock(), _createElementBlock("p", null, _toDisplayString(sentence), 1 /* TEXT */))
                                            }), 256 /* UNKEYED_FRAGMENT */))
                                          ]))
                                        : _createCommentVNode("v-if", true)
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }))
                            : _createCommentVNode("v-if", true),
                          (_ctx.room.amenities.length)
                            ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_expansion_panel_title, {
                                    class: "pl-0",
                                    "hide-actions": !_unref(isMobile)
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h2", _hoisted_8, _toDisplayString(_unref(t)('SingleRoomView#Facilities#Title Facilities')), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["hide-actions"]),
                                  (_ctx.room.amenities.length)
                                    ? (_openBlock(), _createBlock(_component_v_expansion_panel_text, {
                                        key: 0,
                                        class: "px-0"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(RoomFacilities, {
                                            items: _ctx.room.amenities
                                          }, null, 8 /* PROPS */, ["items"])
                                        ]),
                                        _: 1 /* STABLE */
                                      }))
                                    : _createCommentVNode("v-if", true),
                                  (_ctx.room.typeRoom.size > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.roomArea), 1 /* TEXT */))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                _: 1 /* STABLE */
                              }))
                            : _createCommentVNode("v-if", true),
                          (!_ctx.isRoomMode)
                            ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
                                key: 2,
                                "hide-actions": !_unref(isMobile)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_expansion_panel_title, {
                                    class: "px-0",
                                    "hide-actions": !_unref(isMobile)
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t('SingleRoomView#PriceDetails#Title Price details')), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["hide-actions"]),
                                  _createVNode(_component_v_expansion_panel_text, { width: "50%" }, {
                                    default: _withCtx(() => [
                                      _createVNode(BlockPrice, { item: _ctx.product }, null, 8 /* PROPS */, ["item"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["hide-actions"]))
                            : _createCommentVNode("v-if", true),
                          (!_ctx.loading && !!_ctx.product)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                (!_ctx.isRoomMode)
                                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: "px-0",
                                          "hide-actions": !_unref(isMobile)
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("h2", _hoisted_11, _toDisplayString(_unref(t)(
                        'SingleRoomView#Conditions#Title Cancellation Conditions'
                      )), 1 /* TEXT */)
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["hide-actions"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(BlockTerms, { class: "pl-4 mb-0" })
                                          ]),
                                          _: 1 /* STABLE */
                                        }),
                                        (!_unref(isMobile))
                                          ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
                                          : _createCommentVNode("v-if", true)
                                      ]),
                                      _: 1 /* STABLE */
                                    }))
                                  : _createCommentVNode("v-if", true),
                                (!_unref(isEmpty)(_ctx.upgrades))
                                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          "hide-actions": !_unref(isMobile)
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("h2", _hoisted_12, _toDisplayString(_unref(t)('SingleRoomView#Upgrades#Title Upgrade Offer')), 1 /* TEXT */)
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["hide-actions"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(BlockUpgrades, {
                                              "main-price": _ctx.product.totalPrice - _ctx.product.discount
                                            }, null, 8 /* PROPS */, ["main-price"])
                                          ]),
                                          _: 1 /* STABLE */
                                        }),
                                        _createVNode(_component_v_divider)
                                      ]),
                                      _: 1 /* STABLE */
                                    }))
                                  : _createCommentVNode("v-if", true),
                                (!_unref(isEmpty)(_ctx.upsell))
                                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 2 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          "hide-actions": !_unref(isMobile)
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("h2", _hoisted_13, _toDisplayString(_unref(t)('SingleRoomView#Upsell#Title Choose another room')), 1 /* TEXT */)
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["hide-actions"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(BlockUpsell, {
                                              "main-price": _ctx.product.totalPrice - _ctx.product.discount
                                            }, null, 8 /* PROPS */, ["main-price"])
                                          ]),
                                          _: 1 /* STABLE */
                                        }),
                                        _createVNode(_component_v_divider)
                                      ]),
                                      _: 1 /* STABLE */
                                    }))
                                  : _createCommentVNode("v-if", true)
                              ], 64 /* STABLE_FRAGMENT */))
                            : _createCommentVNode("v-if", true),
                          (_ctx.isRoomMode && _ctx.product.productId)
                            ? (_openBlock(), _createBlock(ProductsDetails, {
                                key: 4,
                                "init-on": "mobile",
                                "room-id": _ctx.room.id
                              }, null, 8 /* PROPS */, ["room-id"]))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue", "readonly"]),
                      (!_ctx.isRoomMode && !_unref(isMobile))
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            md: "4",
                            sm: "6",
                            class: "mx-auto mt-6 pa-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(ButtonBookNow, {
                                "product-id": _ctx.product.productId,
                                "room-id": _ctx.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.btnText), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["product-id", "room-id"])
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              (_unref(isMobile) && !_ctx.isRoomMode)
                ? (_openBlock(), _createBlock(_component_portal, {
                    key: 0,
                    to: "fixed-footer"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        class: "flex",
                        width: "100%",
                        variant: "flat",
                        tile: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { width: "100%" }, {
                            default: _withCtx(() => [
                              _createVNode(ButtonBookNow, {
                                "product-id": _ctx.product.productId,
                                "room-id": _ctx.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.btnText), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["product-id", "room-id"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }))
    : (_openBlock(), _createBlock(Loader, { key: 1 }))
}
}

})