import { unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module__content px-0" }
const _hoisted_2 = { class: "fieldset" }
const _hoisted_3 = { class: "actions-toolbar" }

import { isMobile, isDesktop } from '@/app/composables/useBreakpoints'

import { mapGetters, mapMutations } from 'vuex'
import { isEqual, sortBy } from 'lodash'
import HeadingMobile from '@/components/Common/HeadingMobile.vue'
import FilterPrice from '@/components/Filters/FilterPrice.vue'
import FilterItem from '@/components/Filters/FilterItem.vue'

const __default__ = {
  name: 'Filters',
  components: {
    FilterItem,
    HeadingMobile,
    PriceRange: FilterPrice
  },
  emits: ['toggleFilters'],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterNames: {
        titles: 'titles',
        roomFacilities: 'amenities'
      },
      filters: {},
      prices: [this.minPrice ?? 0, this.maxPrice ?? 0]
    }
  },
  computed: {
    ...mapGetters(['roomFilters', 'filtersCode']),
    ...mapGetters({
      allFilters: 'filters'
    }),
    roomTypes() {
      const roomTypeCodes = this.filtersCode(this.filterNames.titles)
      const roomTypes = []

      roomTypeCodes.forEach((roomTypeCode) => {
        roomTypes.push({
          label: roomTypeCode,
          code: roomTypeCode
        })
      })

      return roomTypes
    },
    amenities() {
      const amenityCodes = this.filtersCode(this.filterNames.roomFacilities)
      const amenityCodeDetails = this.allFilters(
        this.filterNames.roomFacilities
      )
      const amenities = []

      amenityCodes.forEach((code, index) => {
        amenities.push({
          label: amenityCodeDetails[index],
          code: String(code)
        })
      })
      return sortBy(amenities, 'label')
    },
    showPriceRangeFilter: (vm) => !isEqual(vm.roomFilters.priceRange, [0, 0])
  },
  methods: {
    ...mapMutations(['SET_ROOM_FILTERS']),
    clearFilters() {
      this.$emitter.emit('reset-filters')
      this.$emit('toggleFilters')
    },
    applyFilters() {
      this.$emitter.emit('apply-filters')
      this.$emit('toggleFilters')
    },
    filterRooms(filters) {
      this.filters = { ...this.filters, ...filters }
      this.SET_ROOM_FILTERS(this.filters)
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {


return (_ctx, _cache) => {
  const _component_price_range = _resolveComponent("price-range")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'module__page-filters no-scroll_filters',
      { 'module__aside-menu': !_unref(isDesktop) }
    ])
  }, [
    _withDirectives(_createVNode(HeadingMobile, {
      title: _ctx.$t('App#Filters#Title Filter'),
      onClose: _ctx.applyFilters,
      onClear: _ctx.clearFilters
    }, null, 8 /* PROPS */, ["title", "onClose", "onClear"]), [
      [_vShow, _unref(isMobile)]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createVNode(_component_price_range, null, null, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.showPriceRangeFilter]
        ]),
        (_ctx.roomTypes.length > 0)
          ? (_openBlock(), _createBlock(FilterItem, {
              key: 0,
              choices: _ctx.roomTypes,
              "filter-id": _ctx.filterNames.titles,
              "default-filter": _ctx.$t('App#Filters#Button All Rooms'),
              title: _ctx.$t('App#Filters#Title Room Type'),
              onFilter: _ctx.filterRooms
            }, null, 8 /* PROPS */, ["choices", "filter-id", "default-filter", "title", "onFilter"]))
          : _createCommentVNode("v-if", true),
        (_ctx.amenities.length > 0)
          ? (_openBlock(), _createBlock(FilterItem, {
              key: 1,
              choices: _ctx.amenities,
              "filter-id": _ctx.filterNames.roomFacilities,
              "default-filter": _ctx.$t('App#Filters#Button All Facilities'),
              title: _ctx.$t('App#Filters#Title Room Facility'),
              onFilter: _ctx.filterRooms
            }, null, 8 /* PROPS */, ["choices", "filter-id", "default-filter", "title", "onFilter"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    (!_unref(isDesktop))
      ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_btn, {
        class: "text-none",
        color: "primary",
        width: "50%",
        ripple: false,
        tile: "",
        size: "x-large",
        onClick: _ctx.applyFilters
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('App#Filters#Button Apply')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_v_btn, {
        class: "text-none",
        width: "50%",
        ripple: false,
        variant: "plain",
        tile: "",
        size: "x-large",
        onClick: _ctx.clearFilters
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('App#Filters#Button Clear all filters')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_unref(isDesktop)]
    ])
  ], 2 /* CLASS */))
}
}

})