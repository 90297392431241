import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { isDesktop } from '@/app/composables/useBreakpoints'


export default {
  __name: 'LanguageSelect',
  setup(__props) {

const { t } = useI18n()

const store = useStore()

const selectref = ref()

const attrs = {
  ref: selectref,
  eager: true,
  singleLine: true,
  noDataText: t('Language#Switcher#NoResults Language not found')
}

const value = computed({
  get: () => store.state.language || 'en',
  set: (newLang) => {
    if (!store.getters.loading && newLang) {
      store.commit('SET_LANGUAGE', newLang)
      selectref.value?.blur()
    }
  }
})

const options = computed(() =>
  Object.entries(store.state.languages).map(([code, label]) => ({
    title: label,
    value: code
  }))
)

const isLoading = computed(
  () =>
    store.getters.loading ||
    !store.getters.descriptiveInfoLoaded ||
    !store.getters.availabilitiesLoaded ||
    !store.getters.hotelPackagesLoaded
)

function onKeyDown(event) {
  if (event.key === 'Escape') {
    selectref.value.blur()
  }
}

return (_ctx, _cache) => {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")

  return (_openBlock(), _createBlock(_component_v_autocomplete, _mergeProps(attrs, {
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((value).value = $event)),
    items: options.value,
    disabled: isLoading.value,
    loading: isLoading.value,
    variant: "underlined",
    onKeydown: onKeyDown
  }), null, 16 /* FULL_PROPS */, ["modelValue", "items", "disabled", "loading"]))
}
}

}