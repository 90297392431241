import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, unref as _unref, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "price-box"
}
const _hoisted_2 = { class: "price font-weight-bold" }
const _hoisted_3 = { class: "price-count" }
const _hoisted_4 = {
  key: 0,
  class: "price price__old mb-2 font-weight-bold"
}
const _hoisted_5 = { class: "price-count text-decoration-line-through" }
const _hoisted_6 = { class: "price-count text-error" }
const _hoisted_7 = {
  key: 0,
  class: "price-info text-caption"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "price mb-0"
}
const _hoisted_11 = { class: "price-info text-caption" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 2,
  class: "price-box"
}
const _hoisted_15 = { class: "price font-weight-bold" }
const _hoisted_16 = { class: "price-count" }
const _hoisted_17 = { class: "price-info text-caption" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 0,
  class: "price mb-0"
}
const _hoisted_21 = { class: "price-info text-caption" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 3,
  class: "price-box"
}
const _hoisted_25 = { class: "price font-weight-bold" }
const _hoisted_26 = { class: "price-count" }
const _hoisted_27 = {
  key: 0,
  class: "price-info text-caption"
}
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = {
  key: 0,
  class: "price mb-0"
}
const _hoisted_31 = { class: "price-info text-caption" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 0 }

import { isMobile } from '@/app/composables/useBreakpoints'

import { mapGetters } from 'vuex'
import { formatPrice } from '@/app/system/helper'
import { CURRENCY_DEFAULT } from '@/app/constants'

const __default__ = {
  name: 'Price',
  props: {
    price: {
      type: Number,
      default: 0
    },
    discount: {
      type: Number,
      default: 0
    },
    mainPrice: {
      type: Number,
      default: 0
    },
    totalLocalTax: {
      type: Number,
      required: false,
      default: 0
    },
    numNights: {
      type: Number,
      default: 1
    },
    showGrandTotal: {
      type: Boolean,
      required: false,
      default: false
    },
    showGuestNumber: {
      type: Boolean,
      default: () => false
    },
    vertical: Boolean
  },
  computed: {
    ...mapGetters(['currentRoomStay', 'language', 'currency']),
    serviceIncludedPrice() {
      return formatPrice(this.price, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    },
    discountedPricePerNight() {
      return formatPrice((this.price - this.discount) / this.numNights, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    },
    standardPricePerNight() {
      return formatPrice(this.price / this.numNights, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    },
    priceDifference() {
      return formatPrice(this.price - this.discount - this.mainPrice, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    },
    discountedGrandTotal() {
      return formatPrice(this.price - this.discount + this.totalLocalTax, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    },
    standardGrandTotal() {
      return formatPrice(this.price + this.totalLocalTax, {
        currency: this.currency || CURRENCY_DEFAULT
      })
    },
    textNumberOfPersons: (vm) => {
      const { nbAdults, nbChildren, nbInfants } = vm.currentRoomStay

      return `${vm.$t('Price#Display#Module for')} ${vm.guestsCountString(
        nbAdults,
        nbChildren,
        nbInfants,
        true
      )}`
    }
  }
}


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {


return (_ctx, _cache) => {
  return (__props.mainPrice > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.priceDifference), 1 /* TEXT */)
        ])
      ]))
    : (__props.discount > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["price-box", { 'vertical-position': __props.vertical }])
        }, [
          (!__props.showGrandTotal)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "price-old", { price: _ctx.standardPricePerNight }, () => [
                    _createTextVNode(_toDisplayString(_ctx.standardPricePerNight), 1 /* TEXT */)
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("p", {
            class: _normalizeClass(["price", {
        'vertical-position mb-0': _unref(isMobile),
        price__discount: !__props.showGrandTotal
      }])
          }, [
            _createElementVNode("span", _hoisted_6, [
              _renderSlot(_ctx.$slots, "price-discount", { price: _ctx.discountedPricePerNight }, () => [
                _createTextVNode(_toDisplayString(__props.showGrandTotal ? _ctx.discountedGrandTotal : _ctx.discountedPricePerNight), 1 /* TEXT */)
              ]),
              (!_unref(isMobile))
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    (__props.showGrandTotal)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t(
                    'SingleRoomView#PriceDetails#GrandTotal Grand total, including taxes'
                  )), 1 /* TEXT */))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('Price#Display#Module Average price per night')), 1 /* TEXT */),
                          _cache[0] || (_cache[0] = _createTextVNode("  ")),
                          (__props.showGuestNumber)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.textNumberOfPersons), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ], 2 /* CLASS */),
          (_unref(isMobile))
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, [
                  (__props.showGrandTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t(
                'SingleRoomView#PriceDetails#GrandTotal Grand total, including taxes'
              )), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('Price#Display#Module Average price per night')), 1 /* TEXT */),
                        _cache[1] || (_cache[1] = _createTextVNode("  ")),
                        (__props.showGuestNumber)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.textNumberOfPersons), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true)
                      ], 64 /* STABLE_FRAGMENT */))
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : (__props.price === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("p", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, [
                _renderSlot(_ctx.$slots, "default", { price: _ctx.serviceIncludedPrice }),
                (!_unref(isMobile))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[3] || (_cache[3] = _createTextVNode("  ")),
                      _createElementVNode("span", _hoisted_17, [
                        (__props.showGrandTotal)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t(
                    'SingleRoomView#PriceDetails#GrandTotal Grand total, including taxes'
                  )), 1 /* TEXT */))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('Price#Display#Module Average price per night')), 1 /* TEXT */),
                              _cache[2] || (_cache[2] = _createTextVNode("  ")),
                              (__props.showGuestNumber)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.textNumberOfPersons), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true)
                            ], 64 /* STABLE_FRAGMENT */))
                      ])
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            (_unref(isMobile))
              ? (_openBlock(), _createElementBlock("p", _hoisted_20, [
                  _createElementVNode("span", _hoisted_21, [
                    (__props.showGrandTotal)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t(
                'SingleRoomView#PriceDetails#GrandTotal Grand total, including taxes'
              )), 1 /* TEXT */))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('Price#Display#Module Average price per night')), 1 /* TEXT */),
                          _cache[4] || (_cache[4] = _createTextVNode("  ")),
                          (__props.showGuestNumber)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.textNumberOfPersons), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createElementVNode("p", _hoisted_25, [
              _createElementVNode("span", _hoisted_26, [
                _renderSlot(_ctx.$slots, "default", { price: _ctx.standardPricePerNight }, () => [
                  _createTextVNode(_toDisplayString(__props.showGrandTotal ? _ctx.standardGrandTotal : _ctx.standardPricePerNight), 1 /* TEXT */)
                ]),
                (!_unref(isMobile))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_27, [
                      (__props.showGrandTotal)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.$t(
                    'SingleRoomView#PriceDetails#GrandTotal Grand total, including taxes'
                  )), 1 /* TEXT */))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Price#Display#Module Average price per night')), 1 /* TEXT */),
                            _cache[5] || (_cache[5] = _createTextVNode("  ")),
                            (__props.showGuestNumber)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.textNumberOfPersons), 1 /* TEXT */))
                              : _createCommentVNode("v-if", true)
                          ], 64 /* STABLE_FRAGMENT */))
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            (_unref(isMobile))
              ? (_openBlock(), _createElementBlock("p", _hoisted_30, [
                  _createElementVNode("span", _hoisted_31, [
                    (__props.showGrandTotal)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t(
                'SingleRoomView#PriceDetails#GrandTotal Grand total, including taxes'
              )), 1 /* TEXT */))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('Price#Display#Module Average price per night')), 1 /* TEXT */),
                          _cache[6] || (_cache[6] = _createTextVNode("   ")),
                          (__props.showGuestNumber)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.textNumberOfPersons), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]))
}
}

})