import { useFetch } from '@vueuse/core'
import { useStore } from '@/app/store'
import { parseJson } from '../system/helper'
import SentryClient from '../system/SentryClient'

type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

interface RequestOptions {
  /**
   * @param urlCustom requires full URL "https://..."
   * This may be used outside the Vue instance
   * By default, @var baseUrl is used.
   * The value is received from "env" constants
   */
  readonly method?: HTTPMethod
  readonly url: string
  readonly urlCustom?: string
  readonly query?: Record<string, string | number | boolean>
  readonly headers?: Headers
  readonly body?: string | object | FormData
  readonly rawText?: boolean
}

class HttpService {
  public static baseUrl = process.env.CRS_ENDPOINT
  public static translationsUrl = process.env.TRANSLATIONS_ENDPOINT

  public static async request(options: RequestOptions): Promise<unknown> {
    const url = (options.urlCustom && new URL(options.urlCustom)) || new URL(`${this.baseUrl}/${options.url}`)

    const store = useStore()
    const apiKeyMapping = parseJson(process.env.CHANNEL_KEYS)
    url.searchParams.append('apiKey', apiKeyMapping[store.state.channelId] || process.env.API_KEY)

    if (options.query) {
      Object.keys(options.query).forEach(key => url.searchParams.append(key, String(options.query?.[key])))
    }

    const requestParams: RequestInit = {
      method: options.method ?? 'GET' as HTTPMethod,
      headers: new Headers(options.headers ?? {
        ...(!(options.body instanceof FormData) && { 'Content-Type': 'application/json' })
      })
    }

    if (options.body) {
      if (options.body instanceof FormData) {
        requestParams.body = options.body
      } else {
        requestParams.body = JSON.stringify(options.body)
      }
    }

    const { data, statusCode } = await useFetch(url.toString(), requestParams, {
      /**
       * @method afterFetch is static request interceptor
       * @param ctx represents Request instance
       * @returns Request instance
       */
      async afterFetch(ctx) {
        /**
         * some "after fetch" code here
         * it is required to @return @var ctx from interceptor
         */        
        return ctx
      }
    })

    /**
     * status code 204 means that request was successful,
     * but nothing from response body was received, not even 'null'
     */
    if (statusCode.value === 204) {
      return null
    }

    // returns bodyText (raw)
    if (options.rawText) {
      return data.value as string
    }

    if (typeof data.value === 'string') {
      return parseJson(data.value)
    } else if (data.value !== null) {
      return data.value
    } else {
      return null
    }
  }

  public static async requestSecured(options: RequestOptions, sentryMessage: string) {
    const store = useStore()
    try {
      store.commit('SET_LOADING', true)
      return await this.request(options)
    } catch (error) {
      SentryClient.captureRequestException({
        errorMessage: sentryMessage,
        errorObject: error as object
      })
    } finally {
      store.commit('SET_LOADING', false)
    }
  }
}

export { HttpService }
export default HttpService