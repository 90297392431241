import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Snackbar',
  props: /*@__PURE__*/_mergeModels({
    color: { default: 'primary' },
    text: { default: '' },
    timeout: { default: 3000 }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const model = _useModel(__props, "modelValue")



return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
    color: _ctx.color,
    timeout: _ctx.timeout,
    onInput: _cache[2] || (_cache[2] = ($event: any) => (model.value = $event.target.value))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_icon, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('input', false)))
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("mdi-close")
        ])),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.text) + " ", 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "color", "timeout"]))
}
}

})