import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "room__info-block block-description" }
const _hoisted_2 = { class: "block__content" }
const _hoisted_3 = { class: "includes-wrapper includes-wrapper_room" }
const _hoisted_4 = { class: "includes" }
const _hoisted_5 = {
  key: 0,
  class: "includes-title"
}
const _hoisted_6 = {
  key: 1,
  class: "includes-title"
}
const _hoisted_7 = {
  key: 1,
  class: "text mt-2"
}

import { computed, type PropType, Ref } from 'vue'
import { useDisplay } from 'vuetify'
import { getImageKitUrls, getResolution } from '@/app/system/helper'
import ImageSlider from '@/components/UI/Vuetify/ImageSlider.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomRate',
  props: {
  ratePlanName: {
    type: String,
    required: true
  },
  description: {
    type: Object as PropType<{
      images: Array<{
        createDateTime: string
        imageId: string
        imagePath: string
        lastModifyDateTime: string
        sort: string
      }>
      texts: Array<string>
    }>,
    required: true
  }
},
  setup(__props) {

const display = useDisplay()

const props = __props

const resolution: Ref<'Desktop' | 'Tablet' | 'Mobile'> = computed(() =>
  getResolution(display)
)

const images = computed(() => {
  return props.description?.images.length
    ? getImageKitUrls(props.description.images, resolution.value)
    : []
})

const carouselHeight: number = computed(() => {
  const heights = {
    Mobile: 200,
    Tablet: 250,
    Desktop: 300
  }
  return heights[resolution.value]
})

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          (__props.description?.images.length || __props.description?.texts.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(
                'SingleRoomView#Description#RatePlan More information about the rateplan:'
              )) + "  ", 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (__props.description?.images.length || __props.description?.texts.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(__props.ratePlanName), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      (images.value.length)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                lg: "6",
                md: "6",
                sm: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(ImageSlider, {
                    height: carouselHeight.value,
                    images: images.value,
                    title: __props.ratePlanName
                  }, null, 8 /* PROPS */, ["height", "images", "title"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (__props.description?.texts.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.description.texts, (sentence) => {
              return (_openBlock(), _createElementBlock("p", {
                key: sentence,
                class: "my-0"
              }, [
                (sentence.trim().length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(sentence), 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})