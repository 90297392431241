import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "module__privacy-policy module__menu-content" }
const _hoisted_2 = { class: "privacy-policy__content menu-content__wrapper mt-5" }
const _hoisted_3 = { class: "menu-content__heading menu-content__heading d-flex pt-5 px-5" }
const _hoisted_4 = { class: "menu-content__container pa-5" }
const _hoisted_5 = ["innerHTML"]

import { computed, watch } from 'vue'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import store from '@/app/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerAccountPrivacyPolicy',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#Privacy Policy')
})

const policyTerms = computed(() => {
  const defaultPrivacyPolicy = store.getters.defaultPrivacyPolicy
  const privacyPolicy = store.getters.privacyPolicy
  return defaultPrivacyPolicy || privacyPolicy
})

watch(policyTerms, (value) => {
  if (!value) {
    store.dispatch('getPrivacyPolicy')
  }
})

return (_ctx: any,_cache: any) => {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("i", { class: "icon-terms-conditions" }, null, -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString(_unref(t)('MyAccount#Titles Privacy policy')), 1 /* TEXT */)
          ]),
          _createVNode(_component_v_divider),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "privacy-policy",
              innerHTML: policyTerms.value
            }, null, 8 /* PROPS */, _hoisted_5)
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})