import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-image" }
const _hoisted_2 = { class: "image-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_5 = {
  class: "upload-container d-flex flex-column justify-space-between ml-2",
  style: {"width":"100%"}
}
const _hoisted_6 = {
  key: 2,
  class: "mt-2"
}
const _hoisted_7 = { class: "no-theme text-caption" }

import { reactive, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'InputImage',
  props: {
  imageUrl: {
    type: String,
    required: false,
    default: ''
  },
  dimensions: {
    type: Object as PropType<{
      w: number
      h: number
    }>,
    required: false,
    default: null
  },
  removable: {
    type: Boolean,
    required: false,
    default: false
  }
},
  emits: ["change", "remove"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()

const emit = __emit

const props = __props

const state: {
  selectedImage: File | null
  imageUrl: string | null
} = reactive({
  selectedImage: null,
  imageUrl: props.imageUrl
})

watch(
  () => props.imageUrl,
  (value) => {
    state.imageUrl = props.imageUrl
  }
)

function emitImage() {
  if (state.selectedImage) {
    emit('change', {
      file: state.selectedImage,
      fileName: state.selectedImage.name
    })
    state.imageUrl = state.selectedImage.name
  }
}

function removeImage() {
  state.selectedImage = null
  state.imageUrl = null
  emit('remove')
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_file_input = _resolveComponent("v-file-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (state.imageUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: state.imageUrl
          }, null, 8 /* PROPS */, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (__props.removable && __props.imageUrl)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            class: "text-none",
            color: "primary",
            ripple: false,
            rounded: "0",
            onClick: removeImage
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('CustomizationForm#Field#File#Remove Remove File')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : (_openBlock(), _createBlock(_component_v_file_input, {
            key: 1,
            modelValue: state.selectedImage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.selectedImage) = $event)),
            class: "no-theme",
            accept: "image/*",
            label: _unref(t)('CustomizationForm#Field#File#Choose Choose File'),
            "prepend-icon": "mdi-image",
            density: "compact",
            variant: "outlined",
            rounded: "0",
            "hide-details": "",
            onChange: emitImage
          }, null, 8 /* PROPS */, ["modelValue", "label"])),
      (__props.dimensions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('CustomizationForm#Field#File#Dimensions Ideal dimensions')) + ": " + _toDisplayString(__props.dimensions.w) + "px ✕ " + _toDisplayString(__props.dimensions.h) + "px ", 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})