import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-caption" }
const _hoisted_2 = { class: "block__content theme-container pa-4 elevation-2" }
const _hoisted_3 = { class: "guests-item__description" }
const _hoisted_4 = { class: "text-body-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-caption font-weight-thin"
}
const _hoisted_6 = {
  key: 1,
  class: "text-caption font-weight-thin"
}
const _hoisted_7 = { class: "actions-toolbar flex-center justify-space-between mt-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_fa_icon = _resolveComponent("fa-icon")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_input_quantity = _resolveComponent("input-quantity")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["guests-popup-desktop field__item", { 'is-booked': $props.roomStay.isBooked, 'is-current': $options.current }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-menu", { 'is-active': $data.active }])
    }, [
      _createElementVNode("div", {
        class: "dropdown-menu__label d-flex align-center mb-1",
        for: "guests__count",
        onClick: _cache[1] || (_cache[1] = $event => ($data.active = !$data.active))
      }, [
        _createElementVNode("div", null, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "label-icon" }, [
            _createElementVNode("i", { class: "icon-person" })
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Search#Form#Field#NumGuests Guests')), 1 /* TEXT */)
        ]),
        (_ctx.bookingRequest.rooms > 1)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              class: "pa-0",
              slim: "slim",
              variant: "text",
              "min-width": 25,
              height: 25,
              ripple: false,
              onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('remove', $props.roomStayIndex)), ["stop","prevent"]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, {
                  class: "text-body-1",
                  icon: ['fas', 'xmark']
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", {
        id: "guests__count",
        class: "block__title theme-container cursor-pointer py-4 rounded-0",
        onClick: _cache[2] || (_cache[2] = $event => ($data.active = !$data.active))
      }, [
        _createElementVNode("span", null, _toDisplayString($options.guestsCompiledString), 1 /* TEXT */)
      ]),
      _createVNode(_Transition, {
        name: "dropdown",
        persisted: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.guests, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "guests-item d-flex justify-space-between align-center mb-3",
                key: item.id
              }, [
                (item.show)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(item.title)), 1 /* TEXT */),
                        (item.id == 'children')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              (_ctx.childrenAgeRange.minAge)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t(
                        'Search#Form#Field#GuestsAge {minAge}-{maxAge} years old',
                        _ctx.childrenAgeRange
                      )), 1 /* TEXT */)
                                  ], 64 /* STABLE_FRAGMENT */))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t(
                        'Search#Form#Field#GuestsAge Under {maxAge} years old',
                        _ctx.childrenAgeRange
                      )), 1 /* TEXT */)
                                  ], 64 /* STABLE_FRAGMENT */))
                            ]))
                          : _createCommentVNode("v-if", true),
                        (item.id == 'infants')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              (_ctx.infantsAgeRange.minAge)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t(
                        'Search#Form#Field#GuestsAge {minAge}-{maxAge} years old',
                        _ctx.infantsAgeRange
                      )), 1 /* TEXT */)
                                  ], 64 /* STABLE_FRAGMENT */))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t(
                        'Search#Form#Field#GuestsAge Under {maxAge} years old',
                        _ctx.infantsAgeRange
                      )), 1 /* TEXT */)
                                  ], 64 /* STABLE_FRAGMENT */))
                            ]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _createVNode(_component_input_quantity, {
                        min: item.min,
                        max: item.max,
                        default: $props.roomStay[item.id],
                        onChange: $event => (
                  _ctx.$emit('change', { guestType: item.id, quantity: $event })
                )
                      }, null, 8 /* PROPS */, ["min", "max", "default", "onChange"])
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]))
            }), 128 /* KEYED_FRAGMENT */)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_v_btn, {
                class: "text-none",
                ripple: false,
                variant: "plain",
                size: "x-large",
                tile: "",
                onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.$emit('clear', $props.roomStayIndex)), ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#RoomIndex#Button Clear')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_btn, {
                class: "text-none",
                color: "primary",
                ripple: false,
                variant: "plain",
                size: "x-large",
                tile: "",
                onClick: _cache[4] || (_cache[4] = _withModifiers($event => ($data.active = !$data.active), ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Search#Form#Button Apply')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ], 512 /* NEED_PATCH */), [
            [_vShow, $data.active]
          ])
        ]),
        _: 1 /* STABLE */
      })
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}