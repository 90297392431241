import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "field__control" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "field__description"
}

import { Portal } from 'portal-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseField',
  props: {
    type: {},
    description: { default: '' },
    icon: {},
    errorMessage: { default: '' },
    hasError: { type: Boolean, default: false },
    portalTarget: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", { 'has-error': _ctx.hasError }])
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "field__title" }, null, -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.icon
          }, null, 8 /* PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_unref(Portal), {
        to: _ctx.portalTarget,
        disabled: !_ctx.portalTarget
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: "error",
            persisted: ""
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("p", { class: "field__error mb-0" }, _toDisplayString(_ctx.errorMessage), 513 /* TEXT, NEED_PATCH */), [
                [_vShow, _ctx.hasError]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to", "disabled"]),
      (_ctx.description && !_ctx.hasError && _ctx.type !== 'hidden')
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.description), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ])
  ], 2 /* CLASS */))
}
}

})