import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_Transition, {
    ref: "modal",
    name: "modal",
    css: false,
    onBeforeEnter: $options.beforeEnter,
    onEnter: $options.enter,
    onLeave: $options.leave
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "modal-mask",
        onClick: _cache[1] || (_cache[1] = (...args) => ($options.close && $options.close(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true),
              _createElementVNode("button", {
                class: "modal-close modal-close_with-bg",
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('close')))
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "icon-close pa-3" }, null, -1 /* HOISTED */)
              ]))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "body", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ])
          ])
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["onBeforeEnter", "onEnter", "onLeave"]))
}