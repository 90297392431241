import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Loader',
  props: {
  absolute: {
    type: Boolean,
    required: false,
    default: true
  },
  size: {
    type: Number,
    required: false,
    default: 70
  },
  thick: {
    type: Boolean,
    required: false,
    default: false
  },
  thin: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['loader', { 'loader-thick': __props.thick, 'loader-thin': __props.thin }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['spinner', { 'position-absolute': __props.absolute }]),
      style: _normalizeStyle(`width: ${__props.size}px; height: ${__props.size}px`)
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "right-side" }, [
        _createElementVNode("div", { class: "bar" })
      ], -1 /* HOISTED */),
      _createElementVNode("div", { class: "left-side" }, [
        _createElementVNode("div", { class: "bar" })
      ], -1 /* HOISTED */)
    ]), 6 /* CLASS, STYLE */)
  ], 2 /* CLASS */))
}
}

})