import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import AppHeader from '@/components/Common/AppHeader.vue'
import RoomsContainer from '@/components/Rooms/RoomsContainer.vue'


export default {
  __name: 'AllRooms',
  setup(__props) {

const { t } = useI18n()

useHead({
  title: () => t('App#PageTitle#All Rooms')
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AppHeader),
    _createVNode(RoomsContainer)
  ]))
}
}

}