import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "guest-info-wrapper mb-0" }
const _hoisted_2 = { class: "guests-count" }
const _hoisted_3 = { class: "date-range mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString($options.getGuestCount), 1 /* TEXT */)
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString($options.dateRange), 1 /* TEXT */)
  ]))
}