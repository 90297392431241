import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "form form-checkout scale-label" }
const _hoisted_2 = {
  key: 0,
  class: "form__message",
  "data-role": "checkout-animation-body"
}
const _hoisted_3 = {
  class: "form__body",
  "data-role": "checkout-animation-body"
}
const _hoisted_4 = { class: "fieldset" }
const _hoisted_5 = { class: "error-row" }
const _hoisted_6 = { class: "form__footer total row-total" }
const _hoisted_7 = { class: "payment-containers mt-4" }

import { getCurrentInstance, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Swal from 'sweetalert2'
import store from '@/app/store'
import { localStorageHandler } from '@/app/system/mixins/localStorage'

import CheckboxPrivacyPolicy from '@/components/UI/CheckboxPrivacyPolicy.vue'
import Loader from '@/components/UI/Loader.vue'
import OrderTotalPrice from '@/components/Order/OrderTotalPrice.vue'
import PaymentInlineError from './PaymentInlineError.vue'
import { usePaymentIntegration } from '@/app/composables/usePaymentIntegration'
import { ReservationStatus } from '@/app/system/constants/reservation'


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentBookingPreview',
  props: {
    paymentProvider: {}
  },
  setup(__props: any) {

const { proxy } = getCurrentInstance()

const { t } = useI18n()

const props = __props

const isPolicyAccepted = ref<boolean>(false)
const bookingResponseCache = ref<BookingResponse | null>(null)
const transactionData = ref<
  InitiatePaymentResponseJuspay | InitiatePaymentResponsePayrails | null
>(null)
const transactionError = ref<boolean>(false)
const reservationLoading = computed(() => store.getters.reservationLoading)
const initPaymentRetries = ref<number>(0)
// TODO: rethink booking error feedback. Error text "booking failed, session expired" doesn't cover all cases
// use swal instead?
const inlineBookingError = computed(() => store.getters.inlineBookingError)

const btnText = computed(() => {
  if (inlineBookingError.value) {
    return t('Checkout#Payment#Button Return home')
  } else {
    return reservationLoading.value
      ? t('Checkout#Payment#Button Please wait')
      : !isPolicyAccepted.value
        ? t('Checkout#Payment#Button You have to accept the Privacy Policy')
        : t('Checkout#Payment#Button Proceed to payment')
  }
})

const isSubmitDisabled = computed(
  () =>
    store.getters.reservationLoading ||
    transactionError.value ||
    !isPolicyAccepted.value ||
    (store.getters.isTaxServiceEnabled &&
      !store.getters.bookingRequest.roomStays[0].order?.taxes?.length)
)

// If session expired error (submit button text is "Return home")
const refreshSession = async () => {
  store.commit('SET_RESERVATION_LOADING', false)
  store.commit('SET_INLINE_BOOKING_REQUEST_ERROR', false)
  proxy.clearAndRefreshSession()
  store.dispatch('replaceRoute', { name: 'home' })
}

// Init payment request has failed
const processPaymentError = async (error) => {
  const MAX_INIT_PAYMENT_RETRIES = 3
  initPaymentRetries.value++
  transactionError.value = true
  const { isConfirmed, isDismissed } = await Swal.fire({
    icon: 'error',
    title: t('App#Error Oops!'),
    text:
      initPaymentRetries.value < MAX_INIT_PAYMENT_RETRIES
        ? t(
            'Checkout#Payment#Error Something went wrong during payment initialization'
          )
        : t(
            'Checkout#Payment#Error Cannot initiate payment, your reservation will be discarded'
          ),
    confirmButtonText:
      initPaymentRetries.value < MAX_INIT_PAYMENT_RETRIES
        ? t('Checkout#Payment#Button Retry payment')
        : t('Checkout#Payment#Button Return home')
  })
  if (isConfirmed || isDismissed) {
    if (initPaymentRetries.value < MAX_INIT_PAYMENT_RETRIES) {
      transactionError.value = false
      submit()
    } else {
      try {
        const reservationId =
          JSON.parse(localStorageHandler.lsGet('reservation'))?.id ??
          bookingResponseCache.value.reservationId
        await store.dispatch('discardReservation', { reservationId })
      } finally {
        refreshSession()
      }
    }
  }
}

/**
 * Submit test cases:
 * 1. Send initial booking request, send initPayment request
 * 2. Booking request has failed, initPayment cannot be executed
 * 3. Booking request has succeeded, initPayment request has failed:
 * bookingRequest data is cached, user should be able to retry payment only
 */
const { initPayment } = usePaymentIntegration()

const submit = async () => {
  if (inlineBookingError.value) {
    refreshSession()
  } else {
    if (!bookingResponseCache.value) {
      const reservation = { ...store.getters.bookingRequestToBeSent }

      store.commit('UPDATE_ORDER_INFO', {
        userName: store.getters.bookingRequest.guestInfo.givenName
      })

      const bookingResponse = await store.dispatch('sendBookingRequest', {
        reservation,
        requestType: ReservationStatus.Book
      })

      if (bookingResponse) {
        bookingResponseCache.value = bookingResponse
        store.commit('CLEAR_SESSION_TIMEOUT')
      }
    }

    try {
      const { reservationId, profileId = window.crypto.randomUUID() } =
        bookingResponseCache.value

      const initPaymentResponse = await store.dispatch(
        'initPaymentTransaction',
        {
          reservationId,
          profileId,
          amount: store.getters.reservationHotelTotalPrice
        }
      )

      if (initPaymentResponse.status === 'success') {
        transactionData.value = initPaymentResponse
        await initPayment(initPaymentResponse)
      } else if (
        paymentResponse.status === 'error' ||
        paymentResponse.status === 'fail'
      ) {
        throw new Error('Payment initialization failed')
      }
    } catch (error) {
      processPaymentError(error)
    }
  }
}

const { initializePaymentProvider } = usePaymentIntegration()

onMounted(async () => {
  await initializePaymentProvider(props.paymentProvider)
})

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!transactionData.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_unref(t)(
            'Checkout#Payment#CCAvenue Please validate your reservation and proceed to payment.'
          )), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (inlineBookingError.value)
            ? (_openBlock(), _createBlock(PaymentInlineError, { key: 0 }))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(OrderTotalPrice),
      (!transactionData.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _withDirectives(_createVNode(CheckboxPrivacyPolicy, {
              onChange: _cache[0] || (_cache[0] = ($event: any) => (isPolicyAccepted.value = $event))
            }, null, 512 /* NEED_PATCH */), [
              [_vShow, !inlineBookingError.value]
            ]),
            _createVNode(_component_v_btn, {
              class: "text-none mt-4",
              color: "primary",
              disabled: isSubmitDisabled.value,
              ripple: false,
              block: "",
              tile: "",
              size: "x-large",
              onClick: submit
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(btnText.value), 1 /* TEXT */),
                (reservationLoading.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[1] || (_cache[1] = _createTextVNode("   ")),
                      _createVNode(Loader, {
                        size: 30,
                        absolute: false,
                        thin: ""
                      })
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.paymentProvider === 'juspay')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: "juspayContainer",
            style: _normalizeStyle({
          height: transactionData.value ? '700px' : '0px'
        })
          }, null, 4 /* STYLE */))
        : _createCommentVNode("v-if", true),
      (_ctx.paymentProvider === 'payrails')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            id: "payrails-payment-container",
            style: _normalizeStyle({
          display: transactionData.value ? 'block' : 'none',
          height: 'auto'
        })
          }, null, 4 /* STYLE */))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})