import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "header__logo" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 1,
  class: "text-h5"
}

import { computed } from 'vue'
import store from '@/app/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeaderLogo',
  emits: ['click'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const logoUrl = computed(() =>
  store.state.customization?.logoUrl ? store.state.customization.logoUrl : ''
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      style: {"cursor":"pointer"},
      title: _ctx.$t('App#Logo#Title Home'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click')))
    }, [
      (logoUrl.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: logoUrl.value,
            alt: _ctx.$t('App#Logo#Alt Main logo')
          }, null, 8 /* PROPS */, _hoisted_3))
        : (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_unref(store).state.descriptiveInfo.hotelName), 1 /* TEXT */))
    ], 8 /* PROPS */, _hoisted_2)
  ]))
}
}

})