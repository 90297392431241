import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import PackagesList from '@/components/Packages/PackagesList.vue'

export default {
  __name: 'PackagesContainer',
  setup(__props) {


return (_ctx, _cache) => {
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createBlock(_component_v_row, { class: "justify-center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { lg: "8" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_divider),
          _createVNode(PackagesList)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

}