import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "radio-group"
}
const _hoisted_2 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.list, (item, index) => {
          return (_openBlock(), _createElementBlock("label", {
            key: index,
            class: _normalizeClass(["d-flex justify-center align-center", {
        active: item.value === $data.radio,
        'no-theme': item.value !== $data.radio
      }])
          }, [
            _createTextVNode(_toDisplayString(item.value) + " ", 1 /* TEXT */),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.radio) = $event)),
              type: "radio",
              value: item.value,
              onChange: _cache[1] || (_cache[1] = $event => (_ctx.$emit('change', $data.radio)))
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [
              [_vModelRadio, $data.radio]
            ])
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}