import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import AppHeader from '@/components/Common/AppHeader.vue'
import PackagesContainer from '@/components/Packages/PackagesContainer.vue'


export default {
  __name: 'AllPackages',
  setup(__props) {

const { t } = useI18n()
const title = computed(() => `${t('App#PageTitle#All Packages')}`)

useHead({
  title
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AppHeader),
    _createVNode(PackagesContainer)
  ]))
}
}

}