import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "column-reverse" }
const _hoisted_2 = {
  class: "text-body-2 py-3",
  style: {"line-height":"1rem"}
}
const _hoisted_3 = { style: {"white-space":"pre-wrap"} }

import { computed, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/app/composables/useBreakpoints'
import { getImageKitUrls } from '@/app/system/helper'
import { PRODUCT_DESC_MAX_LEN } from '@/app/constants'
import store from '@/app/store'

import RoomImages from '@/components/Rooms/RoomImages.vue'
import ButtonAnimated from '@/components/UI/Controls/ButtonAnimated.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PackagesListItem',
  props: {
  item: {
    type: Object as PropType<{
      id: string
      name: string
      guaranteeType: string
      hasServices: boolean
      showAmongResults: boolean
      maxAdultOccupancy: number
      maxChildOccupancy: number
      maxOccupancy: number
      minOccupancy: number
      descriptions: {
        images: Array<{
          createDateTime: string
          imageId: string
          imagePath: string
          lastModifyDateTime: string
          sort: number
        }>
        texts: Array<string>
      }
    }>,
    required: true
  }
},
  setup(__props) {

const props = __props

const { t } = useI18n()

const selectedImages = computed(() =>
  getImageKitUrls(props.item.descriptions?.images, 'Mobile', true)
)

const description = computed(() => {
  const text = props.item.descriptions?.texts?.[0] || ''
  return text.length < PRODUCT_DESC_MAX_LEN
    ? text
    : text.slice(0, PRODUCT_DESC_MAX_LEN) + '...'
})

const btnText = computed(() => {
  return store.getters.loading
    ? t('Search#Results#Room#Button Please wait')
    : t('AvailabilityItem#Button Details & Book')
})

return (_ctx: any,_cache: any) => {
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "container--fluid px-0 py-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "overflow-hidden",
        elevation: "2",
        rounded: "0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            sm: "column",
            class: _normalizeClass(['ma-0', { 'flex-column': _unref(isMobile) }])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_badge, {
                    content: _unref(t)('Packages#Item#Badge Special deal'),
                    color: "#E6AF0E",
                    "text-color": "#FFF",
                    "offset-y": 20,
                    rounded: false,
                    inline: ""
                  }, null, 8 /* PROPS */, ["content"]),
                  (__props.item.id)
                    ? (_openBlock(), _createBlock(RoomImages, {
                        key: 0,
                        images: selectedImages.value,
                        lazy: false,
                        "link-to": { name: 'package', params: { packageId: __props.item.id } }
                      }, null, 8 /* PROPS */, ["images", "link-to"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { class: "pa-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "ma-0 fill-height" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "py-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_1, [
                              _createCommentVNode(" <max-occupancy v-if=\"item\" :room=\"item\" /> "),
                              (__props.item.id)
                                ? (_openBlock(), _createBlock(_component_router_link, {
                                    key: 0,
                                    to: { name: 'package', params: { packageId: __props.item.id } },
                                    style: {"text-decoration":"none","color":"inherit"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, {
                                        class: "text-body-1 font-weight-bold pa-0",
                                        style: {"word-break":"break-word"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(__props.item.name), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["to"]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _createElementVNode("div", _hoisted_2, [
                              _createElementVNode("div", _hoisted_3, _toDisplayString(description.value), 1 /* TEXT */),
                              (__props.item.id)
                                ? (_openBlock(), _createBlock(_component_router_link, {
                                    key: 0,
                                    to: { name: 'package', params: { packageId: __props.item.id } },
                                    style: {"text-decoration":"none","color":"inherit"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        class: "text-none text-decoration-underline px-1",
                                        ripple: false,
                                        variant: "text"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_unref(t)('AvailabilityItem#Button Show more')), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["to"]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _createVNode(_component_v_spacer),
                            _createCommentVNode(" Average price per night "),
                            _createCommentVNode(" <div class=\"column-reverse\">\n                  <div>\n                    <div class=\"price-from text-capitalize text-body-1 mt-2\">\n                      {{ t('Checkout#ViewOrder#From from') }}\n                    </div>\n                    <div class=\"text-h6\">\n                      {{ formatPrice(235, { currency: 'CHF' }) }}\n                    </div>\n                    <div class=\"text-body-2\">\n                      {{ t('Price#Display#Module Average price per night') }}\n                    </div>\n                  </div>\n                </div> ")
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6",
                        "offset-md": "6",
                        "align-self": "end",
                        class: "pt-0 pr-1 pb-1 d-flex flex-column"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: { name: 'package', params: { packageId: __props.item.id } }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(ButtonAnimated, {
                                class: "flex-grow-0",
                                large: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(btnText.value), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["to"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})