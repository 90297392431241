import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inner" }


import { defineComponent } from 'vue'

const __default__ = defineComponent({
  name: 'ButtonAnimated',
  inheritAttrs: false
})


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    block: { type: Boolean, default: true },
    animation: { type: Boolean, default: true }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, _mergeProps(_ctx.$attrs, {
    class: ['text-none', { slide: _ctx.animation }],
    color: "primary",
    ripple: false,
    variant: "outlined",
    block: "",
    tile: ""
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["class"]))
}
}

})